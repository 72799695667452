import { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormCheck,
  Row,
  Table,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Checkbox,
  CustomInput,
  CustomModal,
  FormFieldService,
  GoBack,
  Layout,
  Thumbsnail,
} from "../../../components";
import {
  IconArrowReturn,
  IconCancel,
  IconEdit,
  IconPrint,
} from "../../../components/widgets/Icons";
import { DetailLoading } from "../../../components/widgets/Loading";
import {
  disableScroll,
  getDiscountAmountByPercentage,
  handelGetPersons,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
  totalAmount,
  totalBalance,
  totalPaidAmount,
} from "../../../services";
import {
  actionData,
  dataService,
  getMethod,
  postMethod,
  putMethod,
} from "../../../services/api";
import Invoice from "./cpn/Invoice";
import Payments from "./cpn/Payments";
import SetInvoicePdf from "../../../components/pdf/SetInvoicePdf";

const Detail = () => {
  const history = useHistory();
  const [checkData, setCheckData] = useState<any[]>();
  const [jewelleryOwners, setJewelleryOwners] = useState([]);
  const location: any = useLocation();
  const [show, setShow] = useState(false);
  const [isReturnForm, setShowReturnForm] = useState(false);
  const [persons, setPersons] = useState([]);
  const item = location.state.item;
  const [loading, setLoading] = useState(false);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);
  const [showDataCheck, setShowDataCheck] = useState({
    showGemsLotWeight: false,
    showGemsPairWeight: true,
    showGemsSingleWeight: true,
    showGemsWeight: true,
    showSaleNotes: false,
  });

  const [returnFormData, setReturnFormData] = useState({
    id: "",
    sku: "",
    itemNameDefaultValue: "",
    acceptedBy: null,
    acceptedByDefaultValue: "",
    refundedBy: null,
    refundedByDefaultValue: "",
    refundAmount: "",
  });

  const [formData, setFormData] = useState<any>({
    buyerId: null,
    buyerDefaultValue: "",
    netPrice: "",
    saleNotes: "",
    serviceFee: "",
    discountType: "by_amount",
    discountValue: "",
    createdAt: "",
  });

  const fields = [
    {
      id: "buyerId",
      label: "Customer",
      value: formData.buyerId,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.buyerDefaultValue,
    },
    {
      id: "netPrice",
      label: "Net price",
      value: formData.netPrice,
      inputType: "number",
      wantString: true,
      colCount: 6,
    },
    // { id: "discountValue", label: "Discount", value: formData.discountValue, inputType: "number", wantString: true, colCount: 6, },
    // { id: "saleNotes", label: "Sale note", value: formData.saleNotes, inputType: "textarea", disableMargin: true },
  ];

  const returnFields = [
    // { id: "sku", label: "Jewellery *", value: formData.sku, type: "suggestion-select", data: jewelleries, defaultLabel: formData.itemNameDefaultValue },
    // { id: "acceptedBy", label: "Accepted by", value: formData.acceptedBy, type: "suggestion-select", data: persons, defaultLabel: formData.acceptedByDefaultValue },
    {
      id: "acceptedBy",
      label: "Accepted by",
      value: returnFormData.acceptedBy,
      type: "select",
      data: jewelleryOwners,
    },
    {
      id: "refundedBy",
      label: "Refunded by",
      value: returnFormData.refundedBy,
      type: "select",
      data: jewelleryOwners,
    },
    {
      id: "refundAmount",
      label: "Refund amount",
      value: returnFormData.refundAmount,
      inputType: "number",
      wantString: true,
    },
  ];

  const showForm = (item?: any) => {
    setFormData({
      buyerId: item?.buyer?.id || null,
      buyerDefaultValue: item?.buyer?.fullName || "",
      netPrice: item?.netPrice || "",
      currency: item?.currency || "",
      saleNotes: item?.saleNotes || "",
      serviceFee: item?.serviceFee || "",
      discountType: item?.discountType || "by_amount",
      discountValue: item?.discountValue || "",
      createdAt: item?.createdAt || "",
    });
    handleShow(setShow);
  };

  const showReturnForm = (item?: any) => {
    setReturnFormData({
      id: item.id || "",
      sku: item.sku || "",
      itemNameDefaultValue: "",
      acceptedBy: item.acceptedBy || "",
      acceptedByDefaultValue: "",
      refundedBy: item.refundedBy || "",
      refundedByDefaultValue: "",
      refundAmount: item.refundAmount || "",
    });
    handleShow(setShowReturnForm);
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const [data, setData] = useState<any>();
  const componentRef = useRef(null);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  const handleGetData = () => {
    setLoading(true);
    actionData(getMethod, "sales/sets/" + item.id).then((result) => {
      setData(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });

    actionData(getMethod, "owners").then((result) => {
      let temp: any = [];
      if (result && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({
            key: item.ownerId,
            label: item.fullName,
            customKey: true,
          });
        });
      }
      setJewelleryOwners(temp);
    });
  };

  const handleSubmit = (method: string) => {
    if (formData?.buyerId) {
      setLoading(true);
      // if (!formData.discountValue) {
      //     formData.discountValue = null
      // }

      // if (!formData.serviceFee) {
      //     formData.serviceFee = null
      // }

      dataService(method, formData, [], `sales/sets/${data.id}`, true).then(
        (result) => {
          if (result.success) {
            toast.success(result.message);
            handleGetData();
            handleClose(setShow);
          } else {
            toast.error("Something wrong!");
          }
        }
      );
    } else {
      toast.error("Please choose customer!");
    }
  };

  const handleReturnFormChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, returnFormData, setReturnFormData, wantString);
  };

  const handleReturnSubmit = () => {
    if (returnFormData?.refundAmount) {
      let temp: any = [];
      checkData?.forEach((_item: any) => {
        temp.push({ jewelleryId: _item });
      });
      let body = {
        acceptedBy: returnFormData.acceptedBy,
        refundedBy: returnFormData.refundedBy,
        refundAmount: returnFormData.refundAmount,
        returnedJewellery: temp,
      };

      dataService(
        postMethod,
        body,
        [],
        `return/set/${data.invoiceNumber}`,
        true
      ).then((result) => {
        if (result.success) {
          toast.success(result.message);
          history.push("/jewellery-set-return-lists");
        } else {
          toast.error("Something wrong!");
        }
      });
    } else {
      toast.error("Please fill data!");
    }
  };

  const detailInfo = [
    { label: "Invoice no.", value: data?.invoiceNumber || "-" },
    { label: "Seller name", value: data?.seller?.fullName || "-" },
    { label: "Selling date", value: handleDateFormat(data?.createdAt) },
    // { label: "Inventory type", value: inventoryTypes.filter((item: any) => item.key === data?.jewellery?.inventoryType)[0]?.label },
    // { label: "Service fee", value: numberFormat(data?.serviceFee) || "-", hide: data?.jewellery?.inventoryType === 'shop_inventory' || data?.jewellery?.inventoryType === 'private_item' },
    { label: "Sale notes", value: data?.saleNotes || "-" },
    {
      label: "Sale status",
      value: <Badge className="bg-danger">Canceled</Badge>,
      hide: !data?.isCancel,
    },
  ];

  const paymentInfo = [
    {
      label: "Total amount",
      value: numberFormat(totalAmount(data?.netPrice, data)) || "-",
    },
    {
      label: "Paid amount",
      value: numberFormat(totalPaidAmount(data?.paymentTrx)) || "-",
    },
    { label: "Balance", value: numberFormat(totalBalance(data)) || "-" },
  ];

  const handleClick = (evt: any) => {
    // Check uncheck action
    const { id, checked } = evt.target;

    let temp: any[] = [];

    if (checked) {
      checkData?.forEach((_item: any) => temp.push(_item));
      temp.push(parseInt(id));
    } else {
      checkData?.forEach((_item: any) => {
        if (_item !== parseInt(id)) {
          temp.push(_item);
        }
      });
    }

    setCheckData(temp);
  };

  const handleCancel = () => {
    swal({
      text: `Are you sure to cancel this sale?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // let _formData: any = {
        //     draftOrdersId: draftOrder?.id
        // }

        actionData(putMethod, `cancel/sets/${data?.id}`, {}).then((result) => {
          if (result.success) {
            swal(result.message, {
              icon: "success",
              buttons: [false],
              timer: 1500,
            });
            handleGetData();
            // history.push("/sales")
          } else {
            toast.error(result.message);
          }
        });
      }
    });
  };

  return (
    <Layout title="Set sale detail">
      {loading ? (
        <DetailLoading />
      ) : (
        <div>
          <div className="flex-between mb-4">
            <GoBack label="Jewellery set sales" />
            <div style={{ display: "none" }}>
              <SetInvoicePdf
                ref={componentRef}
                data={data}
                showDataCheck={showDataCheck}
              />
            </div>
            {!(data?.isCancel || data?.isReturn) ? (
              <div>
                <Button
                  className="text-white bg-grad me-3"
                  onClick={showReturnForm}
                >
                  <IconArrowReturn /> <span className="ms-2">Return</span>
                </Button>
                <Button
                  className="text-white bg-grad me-3"
                  onClick={() => showForm(data)}
                >
                  <IconEdit /> <span className="ms-2">Edit sale</span>
                </Button>
                <Button
                  className="text-white bg-grad me-3"
                  onClick={PrintAction}
                >
                  <IconPrint /> <span className="ms-2">Print invoice</span>
                </Button>
                {(user?.userRole === "Owner" ||
                  user?.userRole === "Admin" ||
                  user?.userRole === "Manager") && (
                    <Button
                      className="text-white bg-danger border-none"
                      onClick={handleCancel}
                    >
                      <IconCancel /> <span className="ms-2">Sale cancel</span>
                    </Button>
                  )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <Row>
            <Col md={8}>
              <Invoice data={data} showDataCheck={showDataCheck} />
            </Col>
            <Col>
              <div
                className="d-flex flex-column justify-content-between"
                style={{ height: "100%" }}
              >
                <div>
                  <Table responsive striped className="border">
                    <tbody>
                      {detailInfo.map(
                        (item: any, index: number) =>
                          !item.hide && (
                            <tr key={index}>
                              <td
                                className="text-muted"
                                style={{ width: "40%" }}
                              >
                                {item.label}
                              </td>
                              <td>{item.value}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>

                  {/* {data?.isGift ? <></> : <div>
                                        {!data?.isCancel ? <div>
                                            {data?.jewellery?.gemstones.length > 0 ? <div>
                                                <small>Show gemstone weight in sale invoice</small>

                                                {showCheckList.showGemsLotWeightCheck && <Form.Check type="checkbox" label="Lot weight" id="showLotWeight" className='me-4 cursor mt-2' checked={showDataCheck.showGemsLotWeight} onChange={(e) => setShowDataCheck({ ...showDataCheck, showGemsLotWeight: e.target.checked })} />}

                                                {showCheckList.showGemsPairWeightCheck && <Form.Check type="checkbox" label="Pair weight" id="showPariWeight" className='me-4 cursor mt-2' checked={showDataCheck.showGemsPairWeight} onChange={(e) => setShowDataCheck({ ...showDataCheck, showGemsPairWeight: e.target.checked })} />}

                                                {showCheckList.showGemsSingleWeightCheck && <Form.Check type="checkbox" label="Single weight" id="showSingleWeight" className='me-4 cursor mt-2' checked={showDataCheck.showGemsSingleWeight} onChange={(e) => setShowDataCheck({ ...showDataCheck, showGemsSingleWeight: e.target.checked })} />}
                                                <hr />
                                            </div> : <></>}
                                        </div> : <></>}
                                    </div>} */}

                  {!(data?.isCancel || data?.isReturn) ? (
                    <Form.Check
                      type="checkbox"
                      label="Show gems weight"
                      id="showGemsWeight"
                      className="mb-3 cursor"
                      checked={showDataCheck.showGemsWeight}
                      onChange={(e) =>
                        setShowDataCheck({
                          ...showDataCheck,
                          showGemsWeight: e.target.checked,
                        })
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {!(data?.isCancel || data?.isReturn) ? (
                    <Form.Check
                      type="checkbox"
                      label="Show sale notes"
                      id="showSaleNotes"
                      className="me-4 cursor"
                      checked={showDataCheck.showSaleNotes}
                      onChange={(e) =>
                        setShowDataCheck({
                          ...showDataCheck,
                          showSaleNotes: e.target.checked,
                        })
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>

                {data?.isGift ? (
                  <></>
                ) : (
                  <Table responsive striped className="border mb-0">
                    <tbody>
                      {paymentInfo.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="text-muted" style={{ width: "40%" }}>
                            {item.label}
                          </td>
                          <td>
                            <span className="flex-end">{item.value}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </Col>
            {data?.isGift ? (
              <></>
            ) : (
              <Col md={12} className="mt-4">
                <Payments detail={data} handleGetData={handleGetData} />
              </Col>
            )}
          </Row>
        </div>
      )}

      <CustomModal
        show={isReturnForm}
        handleClose={() => handleClose(setShowReturnForm)}
        id={returnFormData?.id}
        handleSubmit={handleReturnSubmit}
        loading={loading}
        lg
        customTitle="Return form"
      >
        <Table responsive className="bg-light mb-4">
          <thead>
            <tr>
              <th>
                {/* <Checkbox
                    key={index}
                    type="checkbox"
                    name={item.sku}
                    id={item.id}
                    handleClick={(e: any) => handleCheckClick(e, item)}
                    isChecked={isCheckData && isCheckData!.includes(item.id)}
                /> */}
              </th>
              <th>Item</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data?.sets?.jewellery.map((subItem: any, index: any) => (
              <tr key={index}>
                <td>
                  <span className="d-flex align-items-center">
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={subItem?.id}
                      id={subItem?.id}
                      handleClick={(e: any) => handleClick(e)}
                      isChecked={checkData && checkData!.includes(subItem?.id)}
                    />
                  </span>
                </td>
                <td>
                  <span className="d-flex align-items-center">
                    <div className="thumbnail-list-image me-2">
                      <Thumbsnail images={subItem?.images} />
                    </div>
                    <div>
                      <div>{subItem?.itemName}</div>
                      <small className="text-muted mt-1 d-block">
                        {subItem?.sku}
                      </small>
                    </div>
                  </span>
                </td>
                <td>
                  <span className="flex-end">
                    {numberFormat(subItem?.sellingPrice)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <FormFieldService
          fields={returnFields}
          handleChange={handleReturnFormChange}
          formData={returnFormData}
          setFormData={setReturnFormData}
        />
      </CustomModal>

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={data?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          formData={formData}
          setFormData={setFormData}
        />

        <Row>
          <Col md={6}>
            <Form.Group className="input-form-control mb-3">
              <div className="flex-between">
                <Form.Label>{`Discount ${formData.discountType === "by_amount" ? "amount" : "(%)"
                  }`}</Form.Label>

                <FormCheck
                  id="switch"
                  className="switch"
                  type="switch"
                  label={"%"}
                  checked={formData.discountType === "by_percentage"}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      discountType:
                        formData.discountType === "by_amount"
                          ? "by_percentage"
                          : "by_amount",
                      discountValue: "",
                    })
                  }
                />
              </div>

              <Form.Control
                type={"number"}
                id={"discountValue"}
                value={formData.discountValue}
                onChange={(e: any) =>
                  setFormData({ ...formData, discountValue: e.target.value })
                }
                onWheel={disableScroll}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <CustomInput
              id={"saleNotes"}
              label={"Sale note"}
              value={formData.saleNotes}
              handleChange={(e: any) =>
                setFormData({ ...formData, saleNotes: e.target.value })
              }
              inputType={"textarea"}
            />
          </Col>
        </Row>

        <div className="flex-end">
          <div className="w-50">
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">Net price</small>
              <small>{numberFormat(formData?.netPrice)}</small>
            </div>
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">
                Discount{" "}
                {formData.discountType === "by_percentage" &&
                  `(${formData.discountValue}%)`}
              </small>
              <small>
                {formData.discountValue &&
                  formData.discountValue !== "0" &&
                  "-"}{" "}
                {formData.discountValue
                  ? numberFormat(
                    formData.discountType === "by_percentage"
                      ? getDiscountAmountByPercentage(
                        formData?.netPrice,
                        formData
                      )
                      : formData.discountValue
                  )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top">
              <small className="text-muted d-block">
                Total ({data?.currency})
              </small>
              <small>
                {numberFormat(totalAmount(formData?.netPrice, formData))}
              </small>
            </div>
          </div>
        </div>
      </CustomModal>
    </Layout>
  );
};

export default Detail;
