import React from "react";
import { Image } from "react-bootstrap";

interface LogoProps {
  width?: number;
}

const Logo: React.FC<LogoProps> = ({ width = 50 }) => {
  return (
    <div className="bg-black p-1">
      <Image src="images/logo.png" className="p-2" width={width} />
    </div>
  );
};

export default Logo;
