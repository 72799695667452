import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  Layout,
  Lang,
  TableRowButtons,
  CustomModal,
  FormFieldService,
  ImageLoading,
  GoBack,
} from "../../components";
import {
  IconAdd,
  IconClose,
  IconImage,
  IconLock2,
  IconUpload,
} from "../../components/widgets/Icons";
import { FileWithPath, useDropzone } from "react-dropzone";
import { handleClose, handleShow, inputChangeService } from "../../services";
import {
  actionData,
  dataService,
  deleteAvatar,
  deleteMethod,
  getMethod,
  postMethod,
  putMethod,
  responseService,
  uploadAvatar,
} from "../../services/api";
import { toast } from "react-toastify";
import { LangContext } from "../../context";
import { TableLoading } from "../../components/widgets/Loading";
import swal from "sweetalert";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showImageModel, setShowImageModel] = useState(false);
  const [showDeleteModel, setShowDeleteMode] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [data, setData] = useState([]);
  const [countries, setCounties] = useState([]);
  const [regions, setRegions] = useState([]);
  const [fileName, setFileName] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const [formData, setFormData] = useState<any>({
    userId: null,
    userRoleId: null,
    fullName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    stateId: null,
    countryId: null,
    password: "",
    confirmPassword: "",
    avatar: null,
    active: true,
  });

  const required = ["fullName", "email", "phone", "userRoleId", "password"];
  const editRequired = ["fullName", "email", "phone", "userRoleId"];

  const fields = [
    { id: "fullName", label: "Username *", value: formData.fullName },
    { id: "email", label: "Email *", value: formData.email },
    {
      id: "userRoleId",
      label: "User role *",
      value: formData.userRoleId,
      type: "select",
      data: userRoles,
    },
    { id: "phone", label: "Phone", value: formData.phone },
    { id: "city", label: "City", value: formData.city },
    { id: "address", label: "Address", value: formData.address },
  ];

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    setLoading(true);
    actionData(getMethod, `countries`).then((result: any) => {
      setCounties(result.data);
    });
    actionData(getMethod, `user-role`).then((result: any) => {
      let temp: any = [];
      result.data.forEach((item: any) => {
        temp.push({ key: item.id, label: item.roleName, customKey: true });
      });
      setUserRoles(temp);
    });
    actionData(getMethod, "users").then((result) => {
      setData(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageUploadLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );
        uploadAvatar(tempFormData).then((data) => {
          setFormData({ ...formData, avatar: data[0] });
          setFileName(data[0].fileName);
          setImageUploadLoading(false);

          if (formData?.userId) {
            let _formData = {
              userId: formData.userId,
              avatar: data[0],
            };

            actionData(postMethod, "avatar/user", _formData).then(
              (result: any) => {
                if (result?.id || result?.success) {
                  toast.success("Successfully avatar updated!");
                  handleGetData();
                  handleClose(setShowImageModel);
                }
              }
            );
          }
        });
      }, 1000);
    },
  });

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = (item?: any) => {
    setFormData({
      userId: item.id || null,
      userRoleId: item.userRoleId || null,
      fullName: item.fullName || "",
      email: item.email || "",
      phone: item.phone || "",
      address: item.address || "",
      city: item.city || "",
      stateId: item.stateId || null,
      countryId: item.countryId || null,
      password: "",
      confirmPassword: "",
      avatar: null,
      active: item.active,
    });

    if (item.id) {
      handleGetRegions(item.countryId || "");
    }
    setFileName("");
    handleShow(setShow);
  };

  const showImage = (item?: any) => {
    if (item?.avatar) {
      setFileName(item?.avatar.fileName);
    } else {
      setFileName("");
    }

    setFormData({
      userId: item.id || null,
      userRoleId: null,
      fullName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      stateId: null,
      countryId: null,
      password: "",
      confirmPassword: "",
      avatar: null,
    });

    handleShow(setShowImageModel);
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    setLoading(true);
    dataService(
      method,
      formData,
      method === postMethod ? required : editRequired,
      method === postMethod ? "user" : `user/${formData.userId}`,
      method === putMethod
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleDeleteUploaded = async () => {
    deleteAvatar({ file: fileName }).then((data) => {
      setFileName("");
      setFormData({ ...formData, avatar: null });
      handleClose(setShowDeleteMode);

      if (formData.userId) {
        actionData(deleteMethod, "avatar", { fileName: fileName }).then(
          (result) => {
            toast.success("Successfully deleted!");
            handleGetData();
            handleClose(setShowImageModel);
          }
        );
      }
    });
  };

  const handleDelete = (item: any) => {
    swal({
      text: `Are you sure to delete ${item?.fullName}!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        handleDeleteResponse(
          await actionData(deleteMethod, `user/${item.id}`, null),
          item?.avatar?.fileName
        );
      }
    });
  };

  const handleDeleteResponse = (result: any, file: string) => {
    if (result?.success || result?.id || result?.persons?.id) {
      toast.success(result?.message || "Successfully done!");

      if (file) {
        deleteAvatar({ file: file }).then(() => {
          setFileName("");
          setFormData({ ...formData, avatar: null });
        });
      }

      handleGetData();
      handleClose && handleClose();
    } else {
      toast.error(result?.message);
      setLoading && setLoading(false);
    }
  };

  const handleSelectCountry = (evt: any) => {
    const value = evt.target.value;
    setFormData({ ...formData, countryId: value ? parseInt(value) : "" });
    handleGetRegions(value);
  };

  const handleGetRegions = async (countryId: string) => {
    actionData(getMethod, `states/` + countryId).then((result) => {
      setRegions(result.data);
    });
  };

  return (
    <Layout title="Users">
      <div className="flex-between mb-4">
        <GoBack label="Configuration" />
        <div className="d-flex align-items-center">
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconAdd />{" "}
            <span className="ms-2">
              <Lang label="createNew" />
            </span>
          </Button>
        </div>
      </div>

      {loading ? (
        <TableLoading />
      ) : (
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>{translate("no.")}</th>
              <th>User</th>
              <th>City</th>
              <th>Role</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((item: any, index: any) => (
              <tr key={index}>
                <td>
                  <span className="flex-center">{index + 1}</span>
                </td>
                <td>
                  <span className="d-flex align-items-center">
                    <div className="thumbnail-list-image me-2 ">
                      {item?.avatar ? (
                        <Image
                          src={`${RES_API_URL}${item?.avatar?.url}`}
                          alt="no_found"
                        />
                      ) : (
                        <Image src={"images/no-image.png"} />
                      )}
                    </div>
                    <div>
                      <div>{item?.fullName}</div>
                      <small className="text-muted mt-2 d-block">
                        {item?.email}
                      </small>
                      <small className="text-muted mt-2">{item?.phone}</small>
                    </div>
                  </span>
                </td>
                <td>
                  <span className="flex-center">{item.city}</span>
                </td>
                <td>
                  <span className="flex-center">{item.roleName}</span>
                </td>
                <td>
                  <span className="flex-center">
                    <Badge className={item.active ? "bg-grad" : "bg-danger"}>
                      {item.active ? "Active" : "Inactive"}
                    </Badge>
                  </span>
                </td>
                <td>
                  {item.roleName === "Owner" ? (
                    <span className="flex-center">
                      <IconLock2 />
                    </span>
                  ) : (
                    <span className="flex-center">
                      <Button
                        className="btn-sm ms-2 bg-grad"
                        onClick={() => showImage(item)}
                      >
                        <IconImage />
                      </Button>
                      <TableRowButtons
                        item={item}
                        showForm={showForm}
                        handleDelete={handleDelete}
                        flex="flex-center"
                      />
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.userId}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
        />

        <Row>
          <Col md={6}>
            <Form.Group className="mb-4 input-form-control">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                aria-label="Select"
                className="mb-4"
                id="countryId"
                value={formData.countryId || ""}
                onChange={handleSelectCountry}
              >
                <option value="">Select</option>
                {countries.map((item: any, index: number) => (
                  <option value={item.id} key={index}>
                    {item.countryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-4 input-form-control">
              <Form.Label>Region / State</Form.Label>
              <Form.Control
                as="select"
                aria-label="Select"
                className="mb-4"
                id="stateId"
                value={formData.stateId || ""}
                onChange={handleChange}
              >
                <option value="">Select</option>
                {regions.map((item: any, index: number) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {formData.userId ? (
            <div className="d-flex">
              <Form.Check
                inline
                label={"Active"}
                type={"radio"}
                id={`activeTrue`}
                name="active"
                checked={formData.active}
                onChange={() => setFormData({ ...formData, active: true })}
              />
              <Form.Check
                inline
                label={"Inactive"}
                name="active"
                type={"radio"}
                id={`activeFalse`}
                checked={!formData.active}
                onChange={() => setFormData({ ...formData, active: false })}
              />
            </div>
          ) : (
            <></>
          )}

          {!formData.userId ? (
            <>
              <Col md={6}>
                <Form.Group className={"input-form-control"}>
                  <Form.Label>Password *</Form.Label>
                  <Form.Control
                    type="password"
                    className="mb-4"
                    id="password"
                    onChange={handleChange}
                    value={formData?.password}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className={"input-form-control"}>
                  <Form.Label>Confirm password *</Form.Label>
                  <Form.Control
                    type="password"
                    id="confirmPassword"
                    onChange={handleChange}
                    value={formData?.confirmPassword}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>

        {!formData.userId ? (
          <div>
            {fileName ? (
              <div className="d-flex justify-content-center  mt-4">
                <div className="image-area rounded">
                  <img
                    src={`${RES_API_URL}/assets/avatar/${fileName}`}
                    className="thumb-img"
                    alt={"no_alt"}
                  />
                  <button
                    className="btn remove-image"
                    onClick={() => handleShow(setShowDeleteMode)}
                    type="button"
                  >
                    <IconClose />
                  </button>
                </div>
              </div>
            ) : (
              <div {...getRootProps({ className: " mb-3" })}>
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center">
                  {imageUploadLoading ? (
                    <ImageLoading />
                  ) : (
                    <Button className="text-white mt-3 bg-grad">
                      <IconUpload /> Upload Image
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </CustomModal>

      <Modal
        show={showImageModel}
        onHide={() => handleClose(setShowImageModel)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fileName ? (
            <div className="d-flex justify-content-center  mt-4">
              <div className="image-area rounded">
                <img
                  src={`${RES_API_URL}/assets/avatar/${fileName}`}
                  className="thumb-img"
                  alt={"no_alt"}
                />
                <button
                  className="btn remove-image"
                  onClick={() => handleShow(setShowDeleteMode)}
                  type="button"
                >
                  <IconClose />
                </button>
              </div>
            </div>
          ) : (
            <div {...getRootProps({ className: " mb-3" })}>
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center">
                {imageUploadLoading ? (
                  <ImageLoading />
                ) : (
                  <Button className="text-white mt-3 bg-grad">
                    <IconUpload /> Upload Image
                  </Button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button className='bg-success' onClick={() => handleClose(setShowDeleteMode)}>
                        Cancel
                    </Button>
                    <Button className='bg-danger' onClick={() => handleDeleteUploaded(target?.fileName, target?.id)}>
                        Delete
                    </Button>
                </Modal.Footer> */}
      </Modal>

      <Modal
        show={showDeleteModel}
        onHide={() => handleClose(setShowDeleteMode)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete image?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => handleClose(setShowDeleteMode)}
          >
            Cancel
          </Button>
          <Button className="bg-danger" onClick={() => handleDeleteUploaded()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Users;
