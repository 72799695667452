import { useContext, useEffect, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { FormFieldService } from "../../../../components";
import { PageContext, VariableContext } from "../../../../context";
import { inventoryTypes } from "../../../../data";
import {

  handleClose,
  inputChangeService,
} from "../../../../services";
import {
  actionData,
  gemsAvgSizesRoute,
  getMethod,
} from "../../../../services/api";

const GemFilter = ({
  show,
  setShow,
  handleGetData,
  handleGetDataByFilter,
}: {
  show: boolean;
  setShow: any;
  handleGetData: any;
  handleGetDataByFilter: any;
}) => {
  const [outlets, setOutlets] = useState([]);
  const [stoneTypes, setGemStoneTypes] = useState([]);
  const [stockTypes, setGemStockTypes] = useState([]);
  const [page, setPage] = useContext(PageContext);
  const [variable, setVariable] = useContext(VariableContext);
  const [formData, setFormData] = useState<any>({
    sku: "",
    itemName: "",
    outletName: "",
    inventoryType: "",
    stoneType: "",
    stockType: "",
    purchaseDate: ""

  });

  // const saleStatus = [{ key: "in_stock", label: "In stock", customKey: true }, { key: "sold_out", label: "Sold out", customKey: true }, { key: "consignments", label: "Consignments", customKey: true }]

  const fields = [
    { id: "itemName", label: "Item name", value: formData.itemName },
    { id: "sku", label: "SKU", value: formData.sku },

    {
      id: "stoneType",
      label: "Gemstone type",
      value: formData.stoneType,
      type: "select",
      data: stoneTypes,
      wantString: true,
    },
    {
      id: "stockType",
      label: "Gemstock type",
      value: formData.stockType,
      type: "select",
      data: stockTypes,
      wantString: true,
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData.outletName,
      type: "select",
      data: outlets,
      wantString: true,
    },

    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
    },
    {
      id: "purchaseDate",
      label: "Purchase date",
      value: formData.purchaseDate,
      type: "date",
    },

  ];

  useEffect(() => {
    handleGetAttributes();
    if (variable?.jewelleryFilterActionData) {
      let _data = variable?.jewelleryFilterActionData;
      setFormData({
        sku: _data?.sku || "",
        itemName: _data?.itemName || "",
        outletName: _data?.outletName || "",
        inventoryType: _data?.inventoryType || "",
        stoneType: _data?.stoneType || "",
        stockType: _data?.stockType || "",
        purchaseDate: _data?.stockType || "",
      });
    }
    actionData(getMethod, "owners").then((result) => {
      let temp: any = [];
      if (result && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({
            key: item.ownerId,
            label: item.fullName,
            customKey: true,
          });
        });
      }
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
      }
    });
    actionData(getMethod, "gems-stock-type").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.label }));
        setGemStockTypes(temp);
      }
    });
    actionData(getMethod, "gems-stone-type").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.label }));
        setGemStoneTypes(temp);
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleGetAttributes = async () => {
    actionData(getMethod, gemsAvgSizesRoute).then((result) => {
      let sizes: any = [];
      let sizesByPcs: any = [];
      result.data.forEach((item: any) => {
        if (item.unit === "pcs_per_cts") {
          sizesByPcs.push(item);
        } else {
          sizes.push(item);
        }
      });
    });
  };

  const handleInputChange = (newValue: string) => {
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleClearFilter = () => {
    setFormData({
      sku: "",
      itemName: "",
      outletName: "",
      inventoryType: "",
      stoneType: '',
      stockType: '',
      purchaseDate: ""
    });

    setPage({ ...page, current: 0 });
    handleClose(setShow);
    handleGetData('all', "shop_inventory", '',);
    setVariable({
      ...variable,
      jewelleryFilterActionData: {},
      isJewelleryFilter: false,
      inventoryJewelleryType: "shop_inventory",
    });
  };

  const filterAction = () => {
    let _data: any = {};
    formData.purchaseDate = formData?.purchaseDate
      ? new Date(formData?.purchaseDate).toISOString()
      : null;

    for (let [key, value] of Object.entries(formData)) {
      if (value !== null && value !== "" && value !== undefined) {
        _data[key] = value;
      }
    }

    handleClose(setShow);
    setVariable({
      ...variable,
      jewelleryFilterActionData: _data,
      isJewelleryFilter: true,
      inventoryJewelleryType: "",
    });
    handleGetDataByFilter(0, _data);
  };

  return (
    <Offcanvas
      size='sm'
      show={show}
      onHide={() => handleClose(setShow)}
      className="jewellery-filter"
    >
      <Offcanvas.Header closeButton className="bg-grad">
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-4">
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
          handleInputChange={handleInputChange}
        />
        <Row className="mt-3">
          <Col>
            <Button
              className="w-100 btn-wh bg-danger"
              onClick={handleClearFilter}
            >
              Clear filter
            </Button>
          </Col>
          <Col>
            <Button className="w-100 btn-wh bg-grad" onClick={filterAction}>
              Search
            </Button>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default GemFilter;
