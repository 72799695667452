import React from "react";
import { QRView } from "..";
import { numberFormat } from "../../services";

const LabelPdf = React.forwardRef((props: any, ref: any) => {
  return (
    <section ref={ref} className="print-labels-sticker">
      {props.items &&
        props.items.map((item: any, index: number) => (
          <div className="label-card-container" key={index}>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ marginRight: "0.21in", width: "0.6in" }}
            >
              <QRView text={item?.sku} width={35} />
              <h6 className="card-label">
                {item?.jewelleryWeight} {item?.jewelleryWeightUnit}
              </h6>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="card-label">{item?.sku}</h6>
              {item?.gemstones?.totalWeight || item?.diamonds?.totalWeight ? (
                <h6 className="card-label">
                  {item?.gemstones?.totalWeight
                    ? `G-${item?.gemstones?.totalWeight}`
                    : ""}{" "}
                  {item?.diamonds?.totalWeight
                    ? ` D-${item?.diamonds?.totalWeight}`
                    : ""}
                </h6>
              ) : (
                <></>
              )}
              {item?.gold?.totalGoldWeight ? (
                <h6 className="card-label">
                  M-{parseFloat(item?.gold?.totalGoldWeight || 0)}
                </h6>
              ) : (
                <></>
              )}
              <h6 className="card-label">
                {numberFormat(item?.price?.sellingPrice)}{" "}
                {item?.price?.currency === "MMK"
                  ? "Ks."
                  : item?.price?.currency}
              </h6>
            </div>
          </div>
        ))}
    </section>
  );
});

export default LabelPdf;
