import { Col, Modal, Row, Table } from "react-bootstrap";
import { InfoTable } from "../../components";
import { handleClose } from "../../services";
//const RES_API_URL = process.env.REACT_APP_RES_API_URL

const JewelleryDetailInfo = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: any;
}) => {
  const data = [
    // { label: "Name", value: "Spinel Single Piece Earrings (MTD)" },
    { label: "SKU", value: "ERRN-0422-000437" },
    { label: "Jewellery type", value: "Earrings" || "-" },
    { label: "Jewellery style", value: "Single Piece Diamonds Pat" || "-" },
    // { label: "Jewellery weight", value: product?.jewelleryWeight ? `${product?.jewelleryWeight} ${product?.jewelleryWeightUnit}` : "-" },
    { label: "Jewellery weight", value: "7.45 grams" || "-" },
    // { label: "Jewellery for", value: "Women" || "-" },
  ];

  const otherData = [
    { label: "Ring width", value: "13" },
    { label: "Description", value: "Ready Made Bought" },
    { label: "Selling price", value: "35,000,000 MMK" },
  ];

  return (
    <Modal show={show} onHide={() => handleClose(setShow)} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <small>Spinel Single Piece Earrings (MTD)</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-2">
          <Row className="mb-4">
            <Col md={2}>
              <div className="detail-image">
                <img
                  src="images/no-image.png"
                  alt="Thumbnail"
                  className="float-right"
                />
              </div>
            </Col>
            <InfoTable data={data} />
            <InfoTable data={otherData} notes="Ready Made Bought Product" />
          </Row>

          <hr />

          <Row className="mb-4">
            <Col>
              <h6 className="text-muted mb-3">Gemstones</h6>
              <Table responsive striped className="mb-0 bg-light">
                <thead>
                  <tr>
                    <th>
                      <span className="ps-3">Gemstone type</span>
                    </th>
                    <th>Quantity</th>
                    <th>
                      <span className="flex-center">Weight</span>
                    </th>
                    <th>
                      <span className="flex-end">Avg.size</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="ps-3">Almandine Garnet</span>
                    </td>
                    <td>4</td>
                    <td>
                      <span className="flex-center">12.5 carat</span>
                    </td>
                    <td>
                      <span className="flex-end">3.13</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <h6 className="text-muted mb-3">Diamonds</h6>
              <Table responsive striped className="mb-0 bg-light">
                <thead>
                  <tr>
                    <th>
                      <span className="ps-3">Quantity</span>
                    </th>
                    <th>
                      <span className="flex-center">Weight</span>
                    </th>
                    <th>
                      <span className="flex-end">Avg.size</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="ps-3">196</span>
                    </td>
                    <td>
                      <span className="flex-center">2.85 carat</span>
                    </td>
                    <td>
                      <span className="flex-end">0.02</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <h6 className="text-muted mb-3">Gold</h6>
          <Table responsive striped className="mb-0 bg-light">
            <thead>
              <tr>
                <th>
                  <span className="ps-3">Material type used</span>
                </th>
                <th>Material purity</th>
                <th>Weight</th>
                <th>Goldsmith loss (grams)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="ps-3">Yellow Gold</span>
                </td>
                <td>18K Gold</td>
                <td>15.13 grams</td>
                <td>1 grams 0.14.4.5 Ready Made Product</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JewelleryDetailInfo;
