import { useContext, useEffect, useState } from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { InfoCard, Lang, Layout } from "../../components";
import {
  IconDiamond,
  IconGem,
  IconPayment,
} from "../../components/widgets/Icons";
import { DetailLoading } from "../../components/widgets/Loading";
import { LangContext, VariableContext } from "../../context";
import { numberFormat } from "../../services";
import { actionData, getMethod } from "../../services/api";
import { StockInfo } from "./cpn/StockInfo";

function Dashboard() {
  const icon = <IconGem size={24} />;
  const [inventory, setInventory] = useState<any>();
  const [saleSummary, setSaleSummary] = useState<any>();
  const [inventoryWeight, setInventoryWeigh] = useState<any>();
  const [inventoryOnHoldWeight, setInventoryOnHoldWeigh] = useState<any>();
  const [inventoryUnavailableWeight, setInventoryUnavailableWeigh] =
    useState<any>();
  const [inventoryCost, setInventoryCost] = useState<any>();
  const [variable, setVariable] = useContext(VariableContext);
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [loading, setLoading] = useState(false);

  const paymentIcon = <IconPayment size={24} />;
  const tempUser: any = localStorage.getItem("user");

  const [data, setData] = useState({
    instockCount: 0,
    onHoldCount: 0,
    soldOutCount: 0,
  });
  const user = tempUser && JSON.parse(tempUser);
  const cards = [
    {
      id: "jewelleries",
      label: "Jewelleries",
      value: inventory?.jewellery?.inStock?.count,
      color: "primary",
      icon: <IconGem size={24} />,
    },
    {
      id: "jewelleries-set",
      label: "Jewelleries set",
      value: inventory?.jewellery_sets?.inStock?.count,
      color: "primary",
      icon: <IconGem size={24} />,
    },
    {
      id: "calibrated",
      label: "Calibrated gems",
      value: inventory?.cali_gemstones?.inStock?.count,
      color: "success",
      icon: <IconDiamond size={24} />,
    },
    {
      id: "gems",
      label: "Gems",
      value: inventory?.gemstones?.inStock?.count,
      color: "success",
      icon: <IconDiamond size={24} />,
    },
  ];

  const costCards = [
    {
      label: "Jewellery",
      inStockValue: numberFormat(
        inventoryCost?.instock?.totalJewelleryCost || 0
      ),
      color: "primary",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalJewelleryCost || 0),
    },
    {
      label: "Gems",
      inStockValue: numberFormat(inventoryCost?.instock?.totalGemsCost || 0),
      color: "success",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalGemsCost || 0),
    },
    {
      label: "Diamond",
      inStockValue: numberFormat(
        inventoryCost?.instock?.totalDiamondsCost || 0
      ),
      color: "info",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalDiamondsCost || 0),
    },
    {
      label: "Gold",
      inStockValue: numberFormat(inventoryCost?.instock?.totalGoldCost || 0),
      color: "warning",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalGoldCost || 0),
    },
    {
      label: "All total",
      inStockValue: numberFormat(inventoryCost?.instock?.allTotalCost || 0),
      color: "primary",
      onHoldValue: numberFormat(inventoryCost?.onhold?.allTotalCost || 0),
    },
  ];

  const revenueList = [
    {
      label: "Total sale",
      value: numberFormat(saleSummary?.salesAmount || 0),
      color: "primary",
    },
    {
      label: "Gross revenue",
      value: numberFormat(saleSummary?.grossRevenue || 0),
      color: "primary",
    },
    {
      label: "Account receivable",
      value: numberFormat(saleSummary?.accountsReceivable || 0),
      color: "primary",
    },
    {
      label: "Cost of goods sold",
      value: numberFormat(saleSummary?.costOfGoodsSold || 0),
      color: "success",
    },
    {
      label: "Gross profit/loss",
      value: numberFormat(saleSummary?.grossProfitLoss || 0),
      color: "info",
    },
  ];

  const weightCards = [
    {
      label: "Gemstone weight (carat)",
      availableValue: numberFormat(inventoryWeight?.totalGemsWeight || 0),
      onHoldValue: numberFormat(inventoryOnHoldWeight?.totalGemsWeight || 0),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalGemsWeight || 0
      ),
    },
    {
      label: "Diamond weight (carat)",
      availableValue: numberFormat(inventoryWeight?.totalDiamondsWeight || 0),
      onHoldValue: numberFormat(
        inventoryOnHoldWeight?.totalDiamondsWeight || 0
      ),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalDiamondsWeight || 0
      ),
    },
    {
      label: "Gold raw weight (grams)",
      availableValue: numberFormat(inventoryWeight?.totalGoldWeight || 0),
      onHoldValue: numberFormat(inventoryOnHoldWeight?.totalGoldWeight || 0),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalGoldWeight || 0
      ),
    },
    {
      label: "Gold smith loss weight (grams)",
      availableValue: numberFormat(
        inventoryWeight?.totalGoldSmithLossWeight || 0
      ),
      onHoldValue: numberFormat(
        inventoryOnHoldWeight?.totalGoldSmithLossWeight || 0
      ),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalGoldSmithLossWeight || 0
      ),
    },
  ];

  const accountCards = [
    {
      id: "sale-list",
      label: "Total sales",
      value: numberFormat(saleSummary?.salesCount || 0),
      color: "primary",
      icon: "jewellery",
      show: true,
    },
  ];

  useEffect(() => {
    handleGetData("shop_inventory");
    // eslint-disable-next-line
  }, []);

  const handleGetData = async (inventoryType: string) => {
    let ownerId =
      inventoryType === "private_item"
        ? user?.userRole !== "Owner"
          ? `&ownerId=${user?.ownerId}`
          : ""
        : "";

    setLoading(true);
    actionData(
      getMethod,
      `count/inventory?inventoryType=${inventoryType}${ownerId}`
    ).then((result) => {
      setInventory(result.data);

      setData({
        instockCount: result.data?.jewellery?.inStock?.count,
        onHoldCount: result.data?.jewellery?.onHold?.count,
        soldOutCount: result.data?.jewellery?.soldOut?.count,
      });

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });

    actionData(
      getMethod,
      `reports/sale/summary/jewellery?ownerId=${ownerId}&inventoryType=${inventoryType}`
    ).then((result) => {
      setSaleSummary(result.data);
    });

    actionData(getMethod, `user/${user?.userId}`).then((result: any) => {
      let userData = result.data;

      setVariable({
        ...variable,
        fullName: userData?.fullName,
        avatar: userData?.avatar,
      });
    });

    actionData(
      getMethod,
      `cost/inventory?inventoryType=${inventoryType}${ownerId}`
    ).then((result) => {
      setInventoryCost(result.data);
    });

    actionData(
      getMethod,
      `weight/inventory?inventoryType=${inventoryType}${ownerId}`
    ).then((result) => {
      setInventoryWeigh(result?.data?.inStock);
      setInventoryOnHoldWeigh(result?.data?.onHold);
      setInventoryUnavailableWeigh(result?.data?.soldOut);
    });
  };

  return (
    <Layout title={<Lang label="dashboard" />}>
      <div className="mb-4">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text className="bg-grad">
              <Lang label="inventoryType" />
            </InputGroup.Text>
            <Form.Control
              as="select"
              aria-label="Select"
              onChange={(e) => {
                handleGetData(e.target.value);
                setVariable({
                  ...variable,
                  inventoryJewelleryType: e.target.value,
                });
              }}
            >
              <option value="shop_inventory">{translate("shopItems")}</option>
              <option value="service_item">{translate("serviceItems")}</option>
              <option value="ordered_item">{translate("orderedItems")}</option>
              <option value="private_item">{translate("privateItems")}</option>
            </Form.Control>
          </InputGroup>
        </Col>
      </div>

      {loading ? (
        <DetailLoading />
      ) : (
        <Row>
          <Col md={8}>
            <Row className="">
              {cards.map((item: any, index: number) => (
                <Col md={6} className="mb-3" key={index}>
                  <InfoCard
                    id={item.id}
                    label={item.label}
                    value={item.value}
                    color={item.color}
                    icon={item.icon}
                  />
                </Col>
              ))}
            </Row>
            <StockInfo
              inStock={data.instockCount}
              soldOut={data.soldOutCount}
              onHold={data.onHoldCount}
              total={data.instockCount + data.onHoldCount + data.soldOutCount}
            />
            <div className="shadow-sm p-4 mb-4 bg-white">
              <Table responsive className="mb-0">
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <span className="fw-bold mb-2">Weights</span>
                    </td>
                    <td>
                      <span className="fw-bold flex-end">In stock</span>
                    </td>
                    <td>
                      <span className="fw-bold flex-end">On hold</span>
                    </td>
                    <td>
                      <span className="fw-bold flex-end">Sold out</span>
                    </td>
                  </tr>
                  {weightCards.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className=" text-muted ">{item.label}</td>
                      <td>
                        <h6 className="fw-bold flex-end">
                          {item.availableValue}
                        </h6>
                      </td>
                      <td>
                        <h6 className="fw-bold flex-end">{item.onHoldValue}</h6>
                      </td>
                      <td>
                        <h6 className="fw-bold flex-end">
                          {item.unavailableValue}
                        </h6>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="shadow-sm p-4 mb-4 bg-white">
              <Table responsive className="mb-0">
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <span className="fw-bold mb-2">Total Cost</span>
                    </td>
                    <td>
                      <span className="fw-bold flex-end">In stock (Cost)</span>
                    </td>
                    <td>
                      <span className="fw-bold flex-end">On hold (Cost)</span>
                    </td>
                  </tr>
                  {costCards.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className=" text-muted ">{item.label}</td>
                      <td>
                        <h6 className="fw-bold flex-end">
                          <small>{item.inStockValue}</small>
                        </h6>
                      </td>
                      <td>
                        <h6 className="fw-bold flex-end">
                          <small>{item.onHoldValue}</small>
                        </h6>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={4}>
            <Row>
              {accountCards.map(
                (item: any, index: number) =>
                  item.show && (
                    <Col md={12} key={index}>
                      <InfoCard
                        id={item.id}
                        label={item.label}
                        value={item.value}
                        color={item.color}
                        icon={item.icon === "jewellery" ? icon : paymentIcon}
                        h6
                        mb
                      />
                    </Col>
                  )
              )}
            </Row>

            <div className="shadow-sm p-4 mb-4 bg-white">
              <Table responsive className="mb-0">
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <span className="fw-bold mb-2">Sale summary</span>
                    </td>
                    <td>
                      <div>
                        <span className="fw-bold flex-end">Amount</span>
                      </div>
                    </td>
                  </tr>
                  {revenueList.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className=" text-muted ">{item.label}</td>
                      <td>
                        <h6 className="fw-bold flex-end">
                          <small>{item.value}</small>
                        </h6>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </Layout>
  );
}

export default Dashboard;
