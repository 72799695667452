import { useContext, useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Layout,
  GoBack,
  CustomModal,
  Thumbsnail,
  NoData,
} from "../../components";
import { JewellerySelect } from "../../components/jewellery";
import { IconAdd, IconDelete, IconFile } from "../../components/widgets/Icons";
import { TableLoading } from "../../components/widgets/Loading";
import { PageContext } from "../../context/Page";
import { inventoryTypes } from "../../data";
import {
  getSearchParam,
  handleClose,
  handleShow,
  numberFormat,
} from "../../services";
import {
  actionData,
  dataService,
  deleteService,
  getMethod,
  postMethod,
  responseService,
} from "../../services/api";

const SetDetails = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const itemId = getSearchParam("id");
  const [data, setData] = useState<any>([]);
  const [page] = useContext(PageContext);
  const [jewelleryId, setJewelleryId] = useState();

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const handleGetData = (skip: number) => {
    setLoading(true);
    actionData(getMethod, "set-detail/" + itemId).then((result) => {
      setData(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = () => {
    if (jewelleryId !== "") {
      let formData = {
        setsId: itemId,
        jewelleryId: jewelleryId,
      };
      formData.setsId = parseInt(itemId);
      setLoading(true);
      dataService(postMethod, formData, [], "set-detail").then((result) => {
        handleResponse(result);
      });
    } else {
      toast.error("Please select jewellery!");
    }
  };

  const handleDelete = (jewellery: any) => {
    deleteService(
      `${jewellery?.itemName} from set `,
      { setsId: itemId, jewelleryId: jewellery?.jewelleryId },
      "set-detail",
      handleResponse
    );
  };

  return (
    <Layout
      title={
        <span>
          {data?.itemName}{" "}
          <Badge className="bg-success ms-2">
            {
              inventoryTypes.filter(
                (type: any) => type.key === data?.inventoryType
              )[0]?.label
            }
          </Badge>
        </span>
      }
    >
      <div className="flex-between mb-4">
        <GoBack label="Jewellery sets" />
        <div className="d-flex align-items-center">
          <Button
            className="text-white bg-grad ms-3"
            onClick={() => handleShow(setShow)}
          >
            <IconAdd /> <span className="ms-2">Add jewellery</span>
          </Button>
        </div>
      </div>

      {loading ? (
        <TableLoading />
      ) : (
        <>
          {data?.jewellery?.length > 0 ? (
            <Table
              responsive
              className={page ? "mb-4" : "mb-0"}
              striped
              bordered
            >
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Inventory status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data?.jewellery?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <span className="flex-center">{index + 1}</span>
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        {!item?.availability && (
                          <div className="soldout-container">
                            <div className="sold-out">SOLD OUT!</div>
                          </div>
                        )}
                        <div className="thumbnail-list-image me-2">
                          <Thumbsnail images={item?.images} />
                        </div>
                        <div>
                          <div>{item?.itemName}</div>
                          <small className="text-muted mt-2">{item?.sku}</small>
                        </div>
                      </span>
                    </td>
                    <td>
                      <span className="flex-end">
                        {numberFormat(item?.sellingPrice)}
                      </span>
                    </td>
                    <td>{item?.currency}</td>
                    <td>
                      <span className="flex-center">
                        <Badge
                          className={
                            item?.availability ? "bg-grad" : "bg-danger"
                          }
                        >
                          {item?.availability ? "Available" : "Unavailable"}
                        </Badge>
                      </span>
                    </td>
                    <td>
                      <span className="flex-center">
                        <Button
                          className="bg-grad btn-sm me-2"
                          onClick={() =>
                            history.push(
                              `/jewellery-detail?id=${item?.jewelleryId}`
                            )
                          }
                        >
                          <IconFile />
                        </Button>
                        <Button
                          className="bg-danger btn-sm"
                          onClick={() => handleDelete(item)}
                        >
                          <IconDelete />
                        </Button>
                        {/* <small className="text-danger cursor">Remove from set</small> */}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <NoData />
          )}
        </>
      )}

      <CustomModal
        show={show}
        customTitle={`Add jewellery (${
          inventoryTypes?.filter(
            (type: any) => type.key === data?.inventoryType
          )[0]?.label
        })`}
        handleClose={() => handleClose(setShow)}
        id={null}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <div className="p-2">
          <JewellerySelect
            noBg
            data={data?.jewellery}
            setJewelleryId={setJewelleryId}
            inventoryType={data?.inventoryType}
          />
        </div>
      </CustomModal>
    </Layout>
  );
};

export default SetDetails;
