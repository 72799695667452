import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { inventoryTypes } from "../../../../data";
import { handleDateFormat } from "../../../../services";
import InvoiceImage from "../../../../components/pdf/InvoiceImage";

const Summary = ({
  inventoryType,
  weightCards,
  costCards,
}: {
  inventoryType: string;
  weightCards: any;
  costCards: any;
}) => {
  return (
    <div className="bg-card">
      {/* <div className="d-flex flex-column align-items-center mb-4">
        <Image src="images/mr.spinel.png" width={200} />
      </div> */}
      <InvoiceImage />

      <div className="flex-between mb-4">
        <label className="ms-1 fw-bold ">Gem sale report (summary)</label>
        <div>
          <small className="flex-end mb-1">
            Report date :
            <label className="fw-bold ms-1">
              {handleDateFormat(new Date())}
            </label>
          </small>
          <small>
            Inventory type :{" "}
            {
              inventoryTypes.filter(
                (item: any) => item.key === inventoryType
              )[0]?.label
            }
          </small>
        </div>
      </div>

      <Row>
        <Col md={6}>
          <Table responsive striped className="mb-0 border">
            <tbody>
              {weightCards.map((item: any, index: number) => (
                <tr key={index}>
                  <td className=" text-muted ">{item.label}</td>
                  <td>
                    <h6 className="fw-bold flex-end">{item.value}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col md={6}>
          <Table responsive striped className="mb-0 border ">
            <tbody>
              {costCards.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="text-muted ">{item.label}</td>
                  <td>
                    <h6 className="fw-bold flex-end">{item.value}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
