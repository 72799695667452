import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ScrollToTop } from "./components";
import {
  NotFound,
  PrivateRoute,
  privateRoutes,
  PublicRoute,
  publicRoutes,
} from "./routes";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {publicRoutes.map((item: any, index: number) => (
          <PublicRoute
            exact
            path={`/${item.route}`}
            component={item.component}
            key={index}
          />
        ))}

        {privateRoutes.map((item: any, index: number) => (
          <PrivateRoute
            exact
            path={`/${item.route}`}
            component={item.component}
            key={index}
          />
        ))}

        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
