import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GoBack, Layout } from "../../../components";
import { DetailLoading } from "../../../components/widgets/Loading";
import { getDataService, gemsRoute } from "../../../services/api";
import { Info, Stages, Prices, Images } from "./cpn";
import ChildGem from "./cpn/ChildGem";
import Investors from "./cpn/Investors";
import { Button } from "react-bootstrap";
import { IconEdit, IconPrint } from "../../../components/widgets/Icons";
import { useReactToPrint } from "react-to-print";
import GemDetailPdf from "../../../components/pdf/GemDetailPdf";
import { getSearchParam } from "../../../services";

const Index = () => {
  const history = useHistory();
  const itemId = getSearchParam('id');
  // const location: any = useLocation();
  // const item = location.state.item;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const componentRef = useRef(null);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    getDataService(gemsRoute(itemId), setLoading, setData, null, 0);
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  return (
    <Layout title="Gems detail">
      {loading ? (
        <DetailLoading />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <GoBack label="Gems" />
            <div>

              <div style={{ display: "none" }}>
                <GemDetailPdf ref={componentRef} data={data} />
              </div>
              <Button onClick={PrintAction} className="bg-grad">
                <IconPrint /> <span className="ms-2">Print details</span>
              </Button>
              <Button
                className="text-white bg-grad ms-2"
                disabled={Boolean(data?.childGemstones?.length)}
                onClick={() =>
                  history.push({
                    pathname: `/edit-gems`,
                    search: `?id=${data?.id}`,
                    state: { item: data },
                  })
                }
              >
                <IconEdit />
                <span className="ms-2">Edit</span>
              </Button>
            </div>
          </div>

          <Info product={data} />

          <Images product={data} handleGetData={handleGetData} />

          <Prices product={data} handleGetData={handleGetData} />

          {data?.price?.sharedInvestorsQty >= 1 && (
            <Investors product={data} handleGetData={handleGetData} />
          )}

          <Stages product={data} handleGetData={handleGetData} />

          {data?.childGemstones?.length > 0 && (
            <ChildGem product={data} handleGetData={handleGetData} />
          )}
        </>
      )}
    </Layout>
  );
};

export default Index;
