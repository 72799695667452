import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { NoData } from "../../../components";
import { CartContext } from "../../../context/Cart";
import { getStoreUser, handleShow, numberFormat } from "../../../services";
import {
  dataService,
  posSalesRoute,
  postMethod,
  responseForRoutes,
} from "../../../services/api";
import CartItem from "../cpn/CartItem";
import CustomerInfo from "../cpn/CustomerInfo";
import SaleConfirmModal from "../cpn/SaleConfirmModal";

const PosCart = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [person, setPerson] = useState<any>();
  const [cart, setCart] = useContext(CartContext);
  const [loading, setLoading] = useState<any>();
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);
  const [formData, setFormData] = useState<any>({
    currency: "",
    refundAmount: 0,
    sellerId: user.ownerId,
    buyerId: "",
    itemId: "",
    netPrice: "",
    saleNotes: "",
    paymentMethodId: "",
    paymentSeries: "down_payment",
    amount: "",
    serviceFee: "",
    discountType: "by_amount",
    discountValue: "",
    isGift: false,
  });

  const saleAction = () => {
    if (cart?.item?.itemName) {
      if (person) {
        //
        setFormData({
          ...formData,
          amount: cart?.netPrice,
          serviceFee: (cart?.netPrice * 0.05).toString(),
          saleNotes: "",
          paymentMethodId: "",
          paymentSeries: "down_payment",
          discountType: "by_amount",
          discountValue: "",
        });
        handleShow(setShow);
      } else {
        toast.error("Please select customer!");
      }
    } else {
      toast.error("Please select jewellery!");
    }
  };

  const handleSaleConfirm = () => {
    formData.createdAt =
      formData.createdAt && new Date(formData.createdAt).toISOString();
    setLoading(true);

    const prepareFormData = () => {
      return {
        ...formData,
        sellerId: getStoreUser()?.userId,
        buyerId: person?.value,
        itemId: cart?.item?.id,
        netPrice: formData.isGift ? "" : cart?.netPrice || "",
        currency: cart?.currency,
        serviceFee:
          formData.isGift ||
            ["shop_inventory", "private_item"].includes(cart?.item?.inventoryType)
            ? ""
            : formData.serviceFee,
        discountType: formData.isGift ? "" : formData.discountType,
        discountValue: formData.isGift ? "" : formData.discountValue,
        paymentMethodId: formData.isGift ? "" : formData.paymentMethodId,
        paymentSeries: formData.isGift ? "" : formData.paymentSeries,
        amount: formData.isGift ? "" : formData.amount,
      };
    };

    if (formData.isGift || formData.paymentMethodId) {
      dataService(postMethod, prepareFormData(), [], posSalesRoute).then(
        (result) => {
          setLoading(false);
          if (result.success) {
            responseForRoutes(result, history, "jewellery-sales");
            setCart({ ...cart, item: {}, netPrice: "" });
          }
        }
      );
    } else {
      setLoading(false);
      toast.error("Please choose payment method!");
    }
  };


  return (
    <section
      className="pos-cart"
      style={{ position: cart?.item?.itemName ? "fixed" : "relative" }}
    >
      <section className="p-4 pos-cart-scroll-area">
        <CustomerInfo person={person} setPerson={setPerson} />

        {cart?.item?.itemName ? (
          <>
            <CartItem isGift={formData.isGift} />

            <Form.Check
              type="checkbox"
              label="Gift item"
              id="giftItem"
              className="me-4 cursor mt-4"
              checked={formData.isGift}
              onChange={(e) =>
                setFormData({ ...formData, isGift: !formData.isGift })
              }
            />
          </>
        ) : (
          <div className="mt-5">
            <NoData title="item" />
          </div>
        )}
      </section>
      <SaleConfirmModal
        loading={loading}
        show={show}
        setShow={setShow}
        handleSaleConfirm={handleSaleConfirm}
        person={person}
        formData={formData}
        setFormData={setFormData}
      />
      {cart?.item?.itemName && person && (
        <section className="pos-sale-btn" onClick={saleAction}>
          <label>Sale</label>
          {cart?.netPrice && (
            <label>
              {cart?.currency} {numberFormat(cart?.netPrice)}
            </label>
          )}
        </section>
      )}
    </section>
  );
};

export default PosCart;
