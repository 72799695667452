import React from "react";
import { Table } from "react-bootstrap";
import { QRView, ThumbsnailImage } from "..";
import { inventoryTypes } from "../../data";
import { handleDateTimeFormat, numberFormat } from "../../services";
import Logo from "../common/Logo";

const GemDetailPdf = React.forwardRef((props: any, ref: any) => {
    let product = props.data;
    console.log(product);
    const invenstedAmount = product?.gemstonesInvestors.map((item: any) => {
        return `${item?.investmentType === 'By amount' ? ` ${item.investmentAmount}` : ` ${item.investmentPercentage} % `}`;
    }).join(' ; ');

    let inventoryTypeLabel = inventoryTypes.filter(
        (item: any) => item.key === product?.inventoryType
    )[0]?.label;

    const details = [
        { label: "SKU", value: product?.sku, fontWeight: "bold" },
        { label: "Stock type", value: product?.stockType || "-" },
        { label: "Stone type", value: product?.stoneType || "-" },
        {
            label: "Buying state",
            value: product?.buyingState || "-",
        },
        {
            label: "Mining location",
            value: product?.miningLocation || "-",
        },
        {
            label: "Purchase date",
            value: product?.purchaseDate
                ? handleDateTimeFormat(product?.purchaseDate)
                : "-",
            secondLabel: "Selling date",
            secondValue: product?.sellingDate
                ? handleDateTimeFormat(product?.sellingDate)
                : "-",
        },
        {
            label: "Selling date",
            value: product?.sellingDate
                ? handleDateTimeFormat(product?.sellingDate)
                : "-",
        },
    ];

    const prices = [
        {
            label: "Invested USD rate",
            value: product?.price?.investedUSDRate
                ? `${numberFormat(product?.price?.investedUSDRate)}  ${product?.price?.currency || "MMK"
                }`
                : "-",
        },
        {
            label: "Other cost",
            value: product?.price?.otherCost
                ? `${numberFormat(product?.price?.otherCost)}  ${product?.price?.currency || "MMK"
                }`
                : "-",
        },
        {
            label: "Buying price",
            value: product?.price?.buyingPrice
                ? `${numberFormat(product?.price?.buyingPrice)}  ${product?.price?.currency || "MMK"
                }`
                : "-",
        },
        {
            label: "Number of invenstors",
            value:
                product?.price?.sharedInvestorsQty != null
                    ? `${numberFormat(product?.price?.sharedInvestorsQty)} ${invenstedAmount && " - "} ${invenstedAmount} `
                    : "-",
        },
        {
            label: "Payment due date",
            value: product?.price?.paymentDueDate
                ? handleDateTimeFormat(product?.price?.paymentDueDate)
                : "-",
        },
        {
            label: "Selling price",
            value: product?.price?.sellingPrice
                ? `${numberFormat(product?.price?.sellingPrice)}  ${product?.price?.currency || "MMK"
                }`
                : "-",
        },
        {
            label: "Selling price per cts",
            value: product?.price?.sellingPricePerCts
                ? `${numberFormat(product?.price?.sellingPricePerCts)}  ${product?.price?.currency || "MMK"
                }`
                : "-",
        },
    ];

    const GemstoneDetails = ({ data }: { data: any }) => {
        const stockType = product?.stockType; // Assuming stockType is part of each gemstone object

        return (
            <div>
                {data?.map((gemstone: any, index: number) => {
                    const stage = [
                        {
                            label: "Dimension",
                            value: gemstone?.dimension ? `${gemstone?.dimension}` : "-",
                        },
                        ...(stockType === "Lots"
                            ? [
                                {
                                    label: "Number of Pieces",
                                    value: gemstone?.numberOfPcs || "-",
                                },
                            ]
                            : []),
                        ...(stockType === "Lots"
                            ? [
                                {
                                    label: "Gem size",
                                    value:
                                        gemstone?.size != null
                                            ? gemstone.size + " " + (
                                                gemstone.sizeUnit === "avg_size"
                                                    ? "ct."
                                                    : "pcs/ct."
                                            )
                                            : "-",
                                },
                            ]
                            : []),
                        ...(stockType === "Pair"
                            ? [
                                {
                                    label: "Pcs 1 weight",
                                    value:
                                        gemstone?.pcsOneWeight + " " + gemstone?.weightUnit ||
                                        "-",
                                },
                                {
                                    label: "Pcs 2 weight",
                                    value:
                                        gemstone?.pcsTwoWeight + " " + gemstone?.weightUnit ||
                                        "-",
                                },
                            ]
                            : []),
                        ...(stockType !== "Pair"
                            ? [{
                                label: "Weight",
                                value: gemstone?.weight + " " + gemstone?.weightUnit || "-",
                            }]
                            : []),
                        { label: "Shape", value: gemstone?.shapeAndCut || "-" },
                        { label: "Color", value: gemstone?.color || "-" },
                        { label: "Clarity", value: gemstone?.clarity || "-" },
                        { label: "Treatment", value: gemstone?.treatment || "-" },
                    ];
                    return (
                        <div key={index}>
                            <h6 className="fw-bold print-text-lg mb-3">Stage {index + 1}</h6>
                            <Table responsive className="print-table border" striped>
                                <tbody>
                                    {stage.map((item: any, index: number) => (
                                        <tr key={index}>
                                            <td style={{ width: "50%" }}>{item.label}</td>
                                            <td style={{ width: "50%" }}>{item.value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <section ref={ref} className="print-container">
            <div>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        {/* <img src="images/logo.png" alt="Thumbnail" width={60} /> */}
                        <Logo />

                        <div className="flex-fill inv-title ms-3">
                            <h6 className="mb-0 fw-bold">{product?.itemName}</h6>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            <small className="print-text d-block mb-1">
                                {handleDateTimeFormat(product?.createdAt)}
                            </small>
                            <small className="print-text-lg">{inventoryTypeLabel}</small>
                        </div>

                        <QRView text={product?.sku} width={50} />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-3">
                        <div className="print-thumnail">
                            <ThumbsnailImage item={product} />
                        </div>
                        {product?.price?.sellingPrice && (
                            <div className="p-3 rounded-3 bg-light text-center mt-4">
                                <h6 className="fw-bold mb-0 print-color-red print-text-price">
                                    {numberFormat(product?.price?.sellingPrice)}{" "}
                                    {product?.price?.currency || "MMK"}
                                </h6>
                            </div>
                        )}
                    </div>
                    <div className="col-9">
                        <Table responsive striped className="print-table mb-3 border">
                            <tbody>
                                {details.map(
                                    (item: any, index: number) =>
                                        !item.hide && (
                                            <tr key={index}>
                                                <td className={item.color || ""}>{item.label}</td>
                                                <td className={item.color || ""}>{item.value}</td>
                                                {/* {item.secondLabel && <td>{item.secondLabel}</td>}
                                        {item.secondValue && <td>{item.secondValue}</td>} */}
                                            </tr>
                                        )
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <GemstoneDetails data={product?.stages} />
                    </div>
                    <div className="col">
                        <h6 className="fw-bold print-text-lg mb-3">Price Information</h6>
                        <Table responsive className="print-table border" striped>
                            <tbody>
                                {prices?.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <td style={{ width: "50%" }} className={item.color || ""}>
                                            {item.label}
                                        </td>
                                        <td style={{ width: "50%" }} className={item.color || ""}>
                                            {item.value}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>

                {product?.notes && (
                    <div className="p-2 rounded-3 bg-light mt-4">
                        <small className="fw-bold text-muted print-text d-block">
                            Remarks
                        </small>
                        <small className="wrap-text print-text">{product?.notes}</small>
                    </div>
                )}
                <div className="col">
                    {product?.childGemstones?.length > 1 && (
                        <>
                            <h6 className="fw-bold print-text-lg mb-3">Child Gemstones</h6>
                            <Table responsive className="print-table border" striped>
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>SKU</td>
                                        <td>Stone type</td>
                                        <td>Stock type</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {product?.childGemstones?.map((item: any, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.itemName}</td>
                                            <td>{item?.sku}</td>
                                            <td>{item.stoneType}</td>
                                            <td>{item.stockType}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </div>

                <footer />
            </div>
        </section>
    );
});

export default GemDetailPdf;
