import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InfoTable, ThumbsnailImage } from "../../components";
import JewelleryDetailInfo from "./JewelleryDetailInfo";

const JewelleryInfo = ({ jewellery }: { jewellery: any }) => {
  const [show, setShow] = useState(false);

  const data = [
    { label: "Name", value: jewellery?.itemName },
    { label: "SKU", value: jewellery?.sku },
    { label: "Jewellery type", value: jewellery?.jewelleryType || "-" },
    { label: "Jewellery style", value: jewellery?.jewelleryStyle || "-" },
    {
      label: "Jewellery weight",
      value: jewellery?.jewelleryWeight
        ? `${jewellery?.jewelleryWeight} ${jewellery?.jewelleryWeightUnit}`
        : "-",
    },
  ];

  return (
    <div>
      <JewelleryDetailInfo show={show} setShow={setShow} />

      <Row>
        <Col md={4}>
          <div className="detail-item-image">
            <ThumbsnailImage item={jewellery} />
          </div>
          {/* <div className="text-center">
                        <Button className="bg-grad mt-4" onClick={() => setShow(true)}><IconFile /> <span className="ms-2">More detail</span></Button>
                    </div> */}
        </Col>
        <InfoTable data={data} />
      </Row>
    </div>
  );
};

export default JewelleryInfo;
