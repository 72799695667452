import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";
import { NoData } from "..";
import { personData } from "../../data";
import { handelGetPersons, showPersonForm } from "../../services";
import { IconAdd } from "../widgets/Icons";
import PersonInfoAccordion from "./PersonInfoAccordion";
import PersonModel from "./PersonModel";

const PersonSelect = ({
  person,
  setPerson,
  defaultValue,
  noSelect = false,
}: {
  person: any;
  setPerson: any;
  defaultValue?: any;
  noSelect?: boolean;
}) => {
  const [show, setShow] = useState(false);
  const [persons, setPersons] = useState([]);
  const [formData, setFormData] = useState(personData);

  useEffect(() => {
    handelGetPersons("", setPersons);
  }, []);

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const index = persons.findIndex((x: any) => x.value === defaultValue);

  return (
    <section className="bg-card">
      <PersonModel
        show={show}
        setShow={setShow}
        formData={formData}
        setFormData={setFormData}
      />

      {!noSelect && (
        <InputGroup className="mb-4">
          <section style={{ width: "85%" }}>
            <Select
              isClearable
              options={persons}
              value={persons[index] || null}
              onInputChange={handleInputChange}
              onChange={(value: any) => setPerson(value)}
              placeholder={"Select broker"}
              classNamePrefix="react-select"
              className="react-select-container-half-radius"
              styles={{
                control: (base: any, state: any) => ({
                  ...base,
                  boxShadow: state.isFocused ? 0 : 0,
                }),
              }}
            />
          </section>

          <InputGroup.Text
            className="bg-grad text-white d-flex justify-content-center cursor"
            style={{ width: "15%" }}
            onClick={() => showPersonForm(setFormData, setShow)}
          >
            <span>
              <IconAdd />
            </span>
          </InputGroup.Text>
        </InputGroup>
      )}

      {person ? (
        <PersonInfoAccordion person={person} />
      ) : (
        <NoData title="broker" />
      )}
    </section>
  );
};

export default PersonSelect;
