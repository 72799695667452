import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  Layout,
  Lang,
  TableLayout,
  TableRowButtons,
  CustomModalService,
  GoBack,
} from "../../components";
import { IconAdd } from "../../components/widgets/Icons";
import { PageContext } from "../../context/Page";
import { handleClose, handleShow, inputChangeService } from "../../services";
import { getDataService } from "../../services/api";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);
  // const [isEmail, setEmail] = useState(true)
  const [formData, setFormData] = useState({
    id: "",
    email: "",
  });

  // const required = ["email"]

  const fields = [
    { id: "email", label: "Email name *", value: formData.email, colCount: 12 },
  ];

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService("payment/methods", setLoading, setData, setPage, skip);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = (item?: any) => {
    setFormData({
      id: item?.id || "",
      email: item?.email || "",
    });
    handleShow(setShow);
  };

  // const handleResponse = (result: any) => {
  //     responseService(result, handleGetData, handleClose(setShow), null, null, setLoading)
  // }

  const handleSubmit = (method: string) => {
    setLoading(true);
    // dataService(method, formData, required, paymentMethodsRoute + (formData.paymentMenthodId ? `/${formData.paymentMenthodId}` : "")).then(result => {
    //     handleResponse(result)
    // })
  };

  const handleDelete = (item: any) => {
    // deleteService(item?.bankName, {}, paymentMethodsRoute + `/${item?.id}`, handleResponse)
  };

  return (
    <Layout title="Email notification">
      <div className="flex-between mb-4">
        <GoBack label="Configuration" />
        <div className="d-flex align-items-center">
          <Form.Group className="border-none">
            <Form.Check type="checkbox" label="Email notification" id="email" />
          </Form.Group>
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconAdd />{" "}
            <span className="ms-2">
              <Lang label="createNew" />
            </span>
          </Button>
        </div>
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Email</th>

            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">{index + 1}</span>
              </td>
              <td>test@gmail.com</td>
              <td>
                <span className="flex-center">
                  {/* <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                                        <Button className="btn-sm bg-grad"><IconFile /></Button>
                                    </OverlayTrigger> */}
                  <TableRowButtons
                    item={item}
                    showForm={showForm}
                    handleDelete={handleDelete}
                    flex="flex-center"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
    </Layout>
  );
};

export default Index;
