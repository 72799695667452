import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { GoBack, Layout } from "../../../components";
import { IconPrint } from "../../../components/widgets/Icons";
import { DetailLoading } from "../../../components/widgets/Loading";
import { jewelleryRoute, getDataService } from "../../../services/api";
import { DetailPdf } from "../../../components/pdf";
import { Info, Gemstones, Diamonds, Gold, Prices, Images } from "./cpn";
import { getSearchParam } from "../../../services";

const Index = () => {
  const itemId = getSearchParam('id');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const componentRef = useRef(null);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  const handleGetData = () => {
    getDataService(jewelleryRoute(itemId), setLoading, setData, null, 0);
  };

  return (
    <Layout title="Jewellery detail">
      {loading ? (
        <DetailLoading />
      ) : (
        <>
          <div className="flex-between">
            <GoBack label="Jewelleries" />
            <div style={{ display: "none" }}>
              <DetailPdf ref={componentRef} data={data} />
            </div>
            <Button onClick={PrintAction} className="bg-grad">
              <IconPrint /> <span className="ms-2">Print details</span>
            </Button>
          </div>

          <Info product={data} />

          <Images product={data} handleGetData={handleGetData} />

          <Gemstones product={data} handleGetData={handleGetData} />

          <Diamonds product={data} handleGetData={handleGetData} />

          <Gold product={data} handleGetData={handleGetData} />

          <Prices product={data} handleGetData={handleGetData} />

          {/* <Inventory product={data} handleGetData={handleGetData} /> */}
        </>
      )}
    </Layout>
  );
};

export default Index;
