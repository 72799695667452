import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  Layout,
  Lang,
  TableLayout,
  TableRowButtons,
  GoBack,
  NoData,
  CustomModal,
} from "../../components";
import { IconAdd } from "../../components/widgets/Icons";
import { PageContext } from "../../context/Page";
import { getNumber, handleClose, handleShow } from "../../services";
import {
  customRoute,
  dataService,
  deleteService,
  getDataService,
  responseService,
} from "../../services/api";
import { TableLoading } from "../../components/widgets/Loading";
import { toast } from "react-toastify";

const GemColors = () => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useContext(PageContext);

  const [formData, setFormData] = useState({
    id: null,
    gemsColor: "",
  });

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService(
      customRoute("cali_gems-color", skip),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleChange = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const showForm = (item?: any) => {
    setFormData({
      id: item?.id || null,
      gemsColor: item?.gemsColor || "",
    });
    handleShow(setShow);
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    if (formData?.gemsColor) {
      const _newFormData = {
        gemsColor: formData?.gemsColor,
      };

      setLoading(true);
      dataService(
        method,
        _newFormData,
        [],
        "cali_gems-color" + (formData?.id ? `/${formData?.id}` : ""),
        true
      ).then((result) => {
        handleResponse(result);
      });
    } else {
      toast.error("Please provide required data!");
    }
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.gemsColor,
      {},
      `cali_gems-color/${item?.id}`,
      handleResponse
    );
  };

  return (
    <Layout title="Calibrated gem colors">
      <div className="flex-between mb-4">
        <GoBack label="Configuration" />

        <div className="d-flex align-items-center">
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconAdd />
            <span className="ms-2">
              <Lang label="createNew" />
            </span>
          </Button>
        </div>
      </div>

      {loading && <TableLoading />}

      {!loading && data?.length > 0 && (
        <TableLayout
          loading={loading}
          data={data}
          page={page}
          setPage={setPage}
          handleGetData={handleGetData}
        >
          <thead>
            <tr>
              <th className="text-center">No.</th>
              <th>Color name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data?.map((item: any, index: any) => (
              <tr key={index}>
                <td className="text-center">
                  {getNumber(index, page.current)}
                </td>
                <td>{item?.gemsColor}</td>
                <td>
                  <span className="flex-center">
                    <TableRowButtons
                      item={item}
                      showForm={showForm}
                      handleDelete={handleDelete}
                      flex="flex-center"
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </TableLayout>
      )}

      {!loading && !data?.length && <NoData />}

      <CustomModal
        customTitle={
          formData?.id ? "Edit calibrated gem color" : "Add new color"
        }
        show={show}
        handleSubmit={handleSubmit}
        handleClose={() => handleClose(setShow)}
        id={formData?.id}
        loading={loading}
      >
        <Form.Group className="">
          <Form.Label className="required">Calibrated gem color</Form.Label>
          <Form.Control
            autoFocus
            value={formData?.gemsColor}
            onChange={(e: any) => handleChange("gemsColor", e.target.value)}
          />
        </Form.Group>
      </CustomModal>
    </Layout>
  );
};

export default GemColors;
