import { Badge, Table } from "react-bootstrap";
import { NoData } from "../../../../components";
import { inventoryTypes } from "../../../../data";
import {
  handleDateFormat,
  netPrice,
  numberFormat,
  totalAmount,
} from "../../../../services";
import Logo from "../../../../components/common/Logo";
// const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Details = ({
  data,
  pageCurrent,
  inventoryType,
}: {
  data: any;
  pageCurrent: number;
  inventoryType: string;
}) => {
  // const thumbsnailImage = (item: any) => {
  //   let tempImages = item.images;
  //   let isExist = false;
  //   let tempDiv = (
  //     <img
  //       src="images/no-image.png"
  //       alt="Thumbnail"
  //       width={60}
  //       height={60}
  //       className="object-fit rounded my-2"
  //     />
  //   );

  //   if (tempImages && tempImages.length > 0) {
  //     tempImages.forEach((img: any) => {
  //       if (img.thumbnail) {
  //         isExist = true;
  //         tempDiv = (
  //           <img
  //             src={`${RES_API_URL}${img.url}`}
  //             alt="Thumbnail"
  //             width={60}
  //             height={60}
  //             className="object-fit rounded cursor my-2"
  //           />
  //         );
  //       }
  //     });

  //     if (!isExist) {
  //       tempDiv = (
  //         <img
  //           src={`${RES_API_URL}${tempImages[0].url}`}
  //           alt="Thumbnail"
  //           width={60}
  //           height={60}
  //           className="rounded cursor my-2"
  //         />
  //       );
  //     }
  //   }

  //   return tempDiv;
  // };

  return (
    <div className="bg-card">
      <div className="flex-between mb-2">
        <div className="d-flex align-items-center">
          <Logo />
          {/* <Image src="images/logo.png" width={50} /> */}
          <div className="ms-2">
            <h5 className="text-primary fw-bold mb-0">
              {process.env.REACT_APP_APP_TITLE}
            </h5>
            <small className="text-primary ">Natural Gems & Jewellery</small>
          </div>
        </div>
        <div>
          <small className="flex-end mb-1">
            Date : {handleDateFormat(new Date())}
          </small>
          <small>
            Calibrated gem sale report (
            {
              inventoryTypes.filter(
                (item: any) => item.key === inventoryType
              )[0]?.label
            }
            )
          </small>
        </div>
      </div>

      {data?.length > 0 ? (
        <Table responsive striped bordered className="mb-0 report-table">
          <thead className="bg-primary text-white">
            <tr>
              <th>No.</th>
              <th>Invoice no.</th>
              <th>Customer name</th>
              <th>Amount</th>
              <th>Payment</th>
              <th>Selling date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item: any, index: any) => (
              <tr key={index}>
                <td>
                  <span className="flex-center">
                    {index + 1 + pageCurrent * 80}
                  </span>{" "}
                </td>
                <td>{item?.invoiceNumber}</td>
                <td>{item?.cali_gems_buyer?.fullName}</td>
                <td>
                  <span className="flex-end">
                    {item?.sale_cali_gemstone_items?.[0]?.currency}{" "}
                    {numberFormat(
                      totalAmount(
                        netPrice(item?.sale_cali_gemstone_items)?.toString(),
                        item
                      )
                    )}
                  </span>
                </td>
                <td className="text-center">
                  <Badge bg="success">{item?.paymentStatus}</Badge>
                </td>
                <td>
                  {item?.createdAt ? handleDateFormat(item?.createdAt) : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default Details;
