import React from "react";
import { inventoryTypes } from "../../data";
import { Image, Table } from "react-bootstrap";
import { handleDateFormat, numberFormat } from "../../services";
import Logo from "../common/Logo";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const GemSaleDetailsPdf = React.forwardRef((props: any, ref: any) => {
  let inventoryType = props.inventoryType;
  let data = props.data;
  // let pageCurrent = props.pageCurrent
  const paginate = 8;
  let pageTotal = Math.ceil(data.length / paginate);
  const pages: any = [];

  for (let i = 0; i < pageTotal; i++) {
    pages.push({ id: i });
  }

  const ReportLists = ({ current }: { current: number }) => {
    return (
      <>
        <div className="flex-between mb-2">
          <div className="d-flex align-items-center">
            {/* <Image src="images/logo.png" width={50} /> */}
            <Logo />
            <div className="ms-2">
              <h5 className="text-primary fw-bold mb-0">
                {process.env.REACT_APP_APP_TITLE}
              </h5>
              <small className="text-primary ">Natural Gems & Jewellery</small>
            </div>
          </div>
          <div>
            <small className="flex-end mb-1">
              Date : {handleDateFormat(new Date())}
            </small>
            <small>
              Calibrated gem sale report (
              {
                inventoryTypes.filter(
                  (item: any) => item.key === inventoryType
                )[0]?.label
              }
              )
            </small>
          </div>
        </div>

        <Table responsive striped bordered className="mb-0 print-table">
          <thead className="bg-primary text-white">
            <tr>
              {/* <th>No.</th> */}
              <th>Selling date</th>
              <th>Item</th>
              <th>Owner name</th>
              <th>Stock type</th>
              <th>Stone type</th>
              <th>Mining location</th>
              <th>Outlet name</th>
              <th>Selling price</th>
              <th>Currency</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .slice(current * paginate, current * paginate + paginate)
                .map((item: any, index: any) => (
                  <tr key={index}>
                    {/* <td>
                      <span className="flex-center">
                        {index + 1 + pageCurrent * 80}
                      </span>{" "}
                    </td> */}
                    <td>
                      <span className="flex-center">{item?.sellingDate}</span>
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <div className="thumbnail-list-image me-2">
                          {item?.images?.length ? (
                            <Image
                              src={`${RES_API_URL}${item?.images?.[0]?.url}`}
                              width={60}
                              height={60}
                              className="object-fit rounded my-2"
                              alt=""
                            />
                          ) : (
                            <Image
                              src={"images/no-image.png"}
                              width={60}
                              height={60}
                              alt="no_found"
                              className="object-fit rounded cursor my-2"
                            />
                          )}
                        </div>
                        <div>
                          <div>{item?.itemName}</div>
                          <small className="text-muted my-2 d-block">
                            {item?.sku}
                          </small>
                        </div>
                      </span>
                    </td>
                    <td>{item?.gemstonesOwner?.fullName}</td>
                    <td>{item?.stockType}</td>
                    <td>{item?.stoneType}</td>
                    <td>{item?.miningLocation}</td>
                    <td>{item?.outletName}</td>
                    <td>
                      <span className="flex-end">
                        {item?.price?.sellingPrice
                          ? `${numberFormat(item?.price?.sellingPrice)}`
                          : "-"}
                      </span>
                    </td>
                    <td>
                      <span className="flex-start">
                        {item?.price?.currency
                          ? `${numberFormat(item?.price?.currency)}`
                          : "-"}
                      </span>
                    </td>
                    {/* <td>{item?.sellingDate ? item?.sellingDate : "-"}</td> */}
                  </tr>
                ))}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <section ref={ref} className="print-container">
      {pages.map((item: number, index: number) => (
        <div key={index}>
          <ReportLists current={index} />
          {item !== pages.length - 1 && <footer />}
        </div>
      ))}
    </section>
  );
});

export default GemSaleDetailsPdf;
