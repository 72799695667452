import { useState, useRef, useEffect } from "react";
import { login } from "../../services";
import { getAuth } from "../../services/auth";
import { Spinner } from "react-bootstrap";

function Login(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [disabled, setDisabled] = useState(false);
  const mountedRef = useRef(true);

  const handleLogin = (evt?: any) => {
    evt.preventDefault();
    setDisabled(true);
    setLoginErr("");

    getAuth({ email, password }).then((user) => {
      if (user?.accessToken) {
        login(JSON.stringify(user));
        props.history.push(
          user?.roleName === "Owner" || user?.roleName === "Admin"
            ? "/dashboard"
            : "/point-of-sale"
        );
      } else {
        setDisabled(false);
      }
      setLoginErr(user?.error);
    });
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <main className="signin-container">
      <div className="form-signin">
        <form className="text-center" autoComplete="off" onSubmit={handleLogin}>
          <img
            className="mb-3 mt-0"
            src={"images/logo.png"}
            alt=""
            width="112"
            height="69"
          />
          <h1 className="h4 mb-3 fw-normal text-white">
            {process.env.REACT_APP_APP_TITLE}
            <br />
            <span className="text-white my-3 fs-6">Please sign in</span>
            {loginErr ? (
              <div className="text-danger fs-6 text-start p-2 my-2">
                {loginErr}
              </div>
            ) : (
              ""
            )}
          </h1>

          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>

          {/* <div className="checkbox mb-3 text-start">
            <label>
              <input type="checkbox" value="remember-me" /> Remember me
            </label>
          </div> */}

          <button
            disabled={disabled}
            className="w-100 btn btn-lg mt-2"
            type="submit"
          >
            {disabled ? <Spinner animation="border" size="sm" /> : "Sign In"}
          </button>
        </form>
      </div>
    </main>
  );
}

export default Login;
