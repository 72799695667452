import { useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router-dom";
import {
  // IconClockHistory,
  IconDelete,
  // IconEdit,
  IconFile,
} from "../../../../components/widgets/Icons";
import { handleClose, handleShow, numberFormat } from "../../../../services";
import { RES_API_URL, moveToTrashService } from "../../../../services/api";
import { Checkbox } from "../../../../components";

interface Target {
  id?: number;
  itemName?: string;
  image?: string;

}

export const Grid = ({
  item,
  index,
  handleResponse,
  handleCheckClick,
  isCheckData
}: {
  item: any;
  index: any;
  handleResponse: any;
  handleCheckClick: any;
  isCheckData: any,
}) => {
  const history = useHistory();
  //  const { dispatch: { translate } } = useContext(LangContext);

  const goDetail = () => {
    history.push(
      `/calibrated-gems-detail?id=${item.id}`,
    );
  };

  const [target, setTarget] = useState<Target>();
  const [show, setShow] = useState(false);

  const handleDelete = () => {
    moveToTrashService(
      item?.itemName,
      null,
      `trash/cali_gemstones/${item?.id}`,
      handleResponse
    );
    // deleteService(item?.itemName, null, `${gemsRoute()}/${item?.id}`, handleResponse)
  };

  // const productImage = () => {
  //     let tempImages = item.productColors
  //     let tempDiv = <Image src={'images/noimage.png'} />;
  //     if (tempImages.length > 0) {
  //         tempDiv = <Image src={`${RES_API_URL}${tempImages[0]}`} alt={tempImages[0].url} />
  //     }
  //     return tempDiv;
  // }

  const thumbsnailImage = (item: any) => {
    let tempImages = item.images;
    let isExist = false;
    let tempDiv = (
      <img
        src="images/no-image.png"
        alt="Thumbnail"
        className="float-right inv-thumb"
      />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="no img"
              className="float-right inv-thumb cursor"
              onClick={() => {
                setTarget({ itemName: item?.itemName, image: img.url });
                handleShow(setShow);
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="no img"
            className="float-right inv-thumb cursor"
            onClick={() => {
              setTarget({ itemName: item?.itemName, image: tempImages[0].url });
              handleShow(setShow);
            }}
          />
        );
      }
    }

    return tempDiv;
  };

  return (
    <section className="product-card">
      {show && (
        <Lightbox
          mainSrc={`${RES_API_URL}${target?.image}`}
          imageTitle={`${item?.itemName} (${item?.sku})`}
          imageCaption={`${item?.jewelleryType} / ${item?.jewelleryWeight} ${item?.jewelleryWeightUnit
            } / ${item?.currency} ${numberFormat(item?.sellingPrice)}`}
          onCloseRequest={() => handleClose(setShow)}
        />
      )}

      <section className="product-card-image" onClick={goDetail}>{thumbsnailImage(item)}</section>
      <section className="product-card-info">
        <section >
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold mb-3">{item?.itemName}</h6>

            <span className="flex-center">
              <Checkbox
                key={index}
                type="checkbox"
                name={item.sku}
                id={item.id}
                handleClick={(e: any) => handleCheckClick(e, item)}
                isChecked={isCheckData && isCheckData!.includes(item.id)}
              />
              {/* <span className="ms-3">{getNumber(index, pageCurrent)}</span> */}
            </span>{" "}
          </div>
          <div className="d-flex align-items-center mb-3">
            {item?.availability && !item?.isSoldout && (
              <Badge bg="primary">In stock</Badge>
            )}
            {!item?.availability && !item?.isSoldout && (
              <Badge bg="success">Consignment</Badge>
            )}
            {item?.isSoldout && <Badge bg="danger">Sold out</Badge>}
            {item?.childGemstones?.length > 0 && (
              <Badge bg="info" className="ms-1">
                Splited
              </Badge>
            )}
          </div>
          <small className="d-block mb-1">
            <span className="d-block">{item?.sku}</span>
            <span className="d-block text-muted">
              {item?.stoneType} {item?.stockType ? `(${item?.stockType})` : ""}
            </span>
            <span className="d-block text-muted">
              {item?.stages?.length > 0 && (
                <>
                  {item?.stages?.[item?.stages?.length - 1]?.dimension &&
                    `${item?.stages?.[item?.stages?.length - 1]?.dimension}, `}

                  {/* {item?.stages?.[item?.stages?.length - 1]?.numberOfPcs &&
                    `${
                      item?.stages?.[item?.stages?.length - 1]?.numberOfPcs
                    } Pcs, `} */}

                  {item?.stages?.[item?.stages?.length - 1]?.weight &&
                    `${item?.stages?.[item?.stages?.length - 1]?.weight} ${item?.stages?.[item?.stages?.length - 1]?.weightUnit
                    }`}
                </>
              )}
            </span>
            <span className="d-block text-muted">
              {item?.stages?.length > 0 && (
                <>
                  {item?.stages?.[item?.stages?.length - 1]?.color &&
                    `${item?.stages?.[item?.stages?.length - 1]?.color}, `}

                  {item?.stages?.[item?.stages?.length - 1]?.clarity &&
                    `${item?.stages?.[item?.stages?.length - 1]?.clarity}`}
                </>
              )}
            </span>
            <span className="text-muted">
              {item?.miningLocation && `Mine: ${item?.miningLocation}`}
            </span>
          </small>
        </section>
        <section className="d-flex justify-content-between align-items-center mt-4">
          <section onClick={goDetail} style={{ width: "40%" }}>
            <small
              className="d-block mb-1 fw-bold text-truncate"
              title={
                item?.price?.sellingPrice &&
                `${item?.price?.currency} ${numberFormat(
                  item?.price?.sellingPrice
                )}`
              }
            >
              {item?.price?.sellingPrice &&
                `${item?.price?.currency} ${numberFormat(
                  item?.price?.sellingPrice
                )}`}
            </small>
          </section>
          <section>
            {/* <OverlayTrigger overlay={<Tooltip>Calibrated gems stages</Tooltip>}>
              <Button
                className="text-white btn-sm bg-grad me-2"
                onClick={() =>
                  history.push({
                    pathname: "/calibrated-gems-stages",
                    state: { item: item },
                  })
                }
              >
                <IconClockHistory size={18} />
              </Button>
            </OverlayTrigger> */}

            <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
              <Button className="text-white btn-sm bg-grad" onClick={goDetail}>
                <IconFile />
              </Button>
            </OverlayTrigger>

            {/* <OverlayTrigger overlay={<Tooltip>Edit calibrated gems</Tooltip>}>
              <Button
                className="text-white btn-sm bg-grad"
                disabled={Boolean(item?.childGemstones?.length)}
                onClick={() =>
                  history.push({
                    pathname: "/edit-calibrated-gems",
                    state: { item: item },
                  })
                }
              >
                <IconEdit />
              </Button>
            </OverlayTrigger> */}

            <OverlayTrigger overlay={<Tooltip>Move to trash</Tooltip>}>
              <Button
                className="ms-2 bg-danger btn-sm"
                disabled={Boolean(item?.childGemstones?.length)}
                onClick={handleDelete}
              >
                <IconDelete />
              </Button>
            </OverlayTrigger>
          </section>
        </section>
      </section>
    </section>
  );
};
