import { useState, createContext } from "react";

interface Cart {
  isGift: boolean;
  buyerId: any;
  item: object;
  items: [];
  netPrice: String;
}

const contextDefaultValues: Cart = {
  isGift: false,
  buyerId: null,
  item: {},
  items: [],
  netPrice: "",
};

const CartContext = createContext<any>(contextDefaultValues);

function CartProvider(props: any) {
  const [cart, setCart] = useState<Cart>(contextDefaultValues);

  return (
    <CartContext.Provider value={[cart, setCart]}>
      {props.children}
    </CartContext.Provider>
  );
}

export { CartContext, CartProvider };
