const API_URL = process.env.REACT_APP_API_URL

export const getAuth = async ({ email, password }: any) => {
  let _data = {
    email: email,
    password: password,
  }

  try {
    const response = await fetch(`${API_URL}/sign-in`, {
      method: 'POST',
      body: JSON.stringify(_data),
      headers: { 'Content-type': 'application/json' },
    })
    const json = await response.json()
    return json
  } catch (err) {
    return console.log(err)
  }
}
