import { useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import {
  actionData,
  getJewellerySuggest,
  getMethod,
  jewelleryRoute,
  postMethod,
} from "../../services/api";
import { CustomSuggestionSelect } from "../widgets/CustomInput";
import { DivLoading } from "../widgets/Loading";
import JewelleryInfo from "./JewelleryInfo";

const JewellerySelect = ({
  data,
  jewelleryId,
  setJewelleryId,
  noBg = false,
  inventoryType = "",
}: {
  data?: any;
  jewelleryId?: any;
  setJewelleryId: any;
  noBg?: boolean;
  inventoryType?: string;
}) => {
  const [jewelleries, setJewelleries] = useState([]);
  const [jewellery, setJewellery] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    handelJewellerySuggest("");
    // eslint-disable-next-line
  }, []);

  const handelJewellerySuggest = async (keyword: string) => {
    let formData = {
      keyword: keyword,
      sort: "desc",
      availability: "Available",
      inventoryType: inventoryType || "",
    };
    actionData(postMethod, getJewellerySuggest(), formData).then((result) => {
      let temp: any = [];

      result.data.forEach((item: any) => {
        data?.find((d: any) => d?.sku)?.sku !== item?.sku &&
          temp.push({
            ...item,
            value: item.id,
            label: `${item.itemName} (${item.sku})`,
            sku: item.sku,
            itemName: item.itemName,
          });
      });

      setJewelleries(temp);
    });
  };

  const handleInputChange = (newValue: string) => {
    handelJewellerySuggest(newValue);
  };

  const handleOnChange = useCallback(
    (data: any) => {
      if (data) {
        setLoading(true);
        actionData(getMethod, jewelleryRoute(data?.value)).then((result) => {
          setJewellery(result.data);
          setJewelleryId(result.data.id);
          setTimeout(() => {
            setLoading(false);
          }, 800);
        });
      } else {
        setJewellery(data);
        setJewelleryId("");
      }
    },
    [setJewelleryId]
  );

  useEffect(() => {
    if (jewelleryId) {
      handleOnChange({ value: jewelleryId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jewelleryId]);

  return (
    <section className={noBg ? "" : "bg-card"}>
      <CustomSuggestionSelect
        handleInputChange={handleInputChange}
        handleOnChange={handleOnChange}
        value={jewelleryId}
        id={""}
        label={""}
        data={jewelleries}
        showLabel={false}
        placeholder={`Search jewellery by sku or name`}
        disableMargin
      />

      {isLoading ? (
        <div className="mt-4">
          <DivLoading />
        </div>
      ) : (
        <div>
          {jewellery ? (
            <div className="mt-4">
              <JewelleryInfo jewellery={jewellery} />{" "}
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <Image src="images/search.png" width={170} />
              <small className="text-muted">
                Please find jewellery by sku code or item name!
              </small>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default JewellerySelect;
