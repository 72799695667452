import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  Layout,
  SearchInput,
  Lang,
  TableLayout,
  TableRowButtons,
  CustomModal,
  FormFieldService,
  ImageLoading,
} from "../../components";
import {
  IconAdd,
  IconCamera,
  IconClose,
  IconFile,
  IconImage,
  IconUpload,
} from "../../components/widgets/Icons";
import { PageContext } from "../../context/Page";
import {
  dateFormat2,
  getNumber,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../services";
import {
  actionData,
  dataService,
  deleteAvatar,
  deleteMethod,
  getDataService,
  getMethod,
  getPersons,
  personsRoute,
  postMethod,
  putMethod,
  responseService,
  uploadAvatar,
} from "../../services/api";
import { FileWithPath, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { personTypes } from "../../data";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [show, setShow] = useState(false);
  const [showImageModel, setShowImageModel] = useState(false);
  const [showDetailModel, setShowDetailModel] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);
  const [countries, setCounties] = useState([]);
  const [regions, setRegions] = useState([]);
  const [shippingRegions, setShippingRegions] = useState([]);
  const [fileName, setFileName] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [person, setPerson] = useState<any>({});

  const [formData, setFormData] = useState<any>({
    id: "",
    fullName: "",
    phone: "",
    phoneOther: "",
    email: "",
    idType: "ord",
    avatar: "",
    billingCountryId: null,
    billingStateId: null,
    billingCity: "",
    billingAddress: "",
    shippingCountryId: null,
    shippingStateId: null,
    shippingCity: "",
    shippingAddress: "",
    dateOfBirth: "",
    note: "",
  });
  const required = ["fullName"];

  const fields = [
    { id: "fullName", label: "Full name *", value: formData.fullName },
    { id: "phone", label: "Phone *", value: formData.phone },
    { id: "phoneOther", label: "Phone other", value: formData.phoneOther },
    { id: "email", label: "Email", value: formData.email },
    {
      id: "dateOfBirth",
      label: "Date of birth",
      value: handleDateFormat(formData.dateOfBirth, dateFormat2),
      type: "date",
    },
    {
      id: "idType",
      label: "Person type",
      value: formData.idType,
      type: "select",
      data: personTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "note",
      label: "Note",
      value: formData.note,
      inputType: "textarea",
      colCount: 12,
    },
  ];

  const billingField = [
    { id: "billingCity", label: "City", value: formData.billingCity },
    {
      id: "billingAddress",
      label: "Address",
      value: formData.billingAddress,
      disableMargin: true,
    },
  ];

  const shippingField = [
    { id: "shippingCity", label: "City", value: formData.shippingCity },
    {
      id: "shippingAddress",
      label: "Address",
      value: formData.shippingAddress,
      disableMargin: true,
    },
  ];

  useEffect(() => {
    handleGetData(page.current);
    actionData(getMethod, `countries`).then((result: any) => {
      setCounties(result.data);
    });
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number, _searchString?: string) => {
    getDataService(
      getPersons(skip, _searchString),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageUploadLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );
        uploadAvatar(tempFormData).then((data) => {
          setFormData({ ...formData, avatar: data[0] });
          setFileName(data[0].fileName);
          setImageUploadLoading(false);

          if (formData?.id) {
            let _formData = {
              personsId: formData.id,
              avatar: data[0],
            };

            actionData(postMethod, "avatar/person", _formData).then(
              (result: any) => {
                if (result?.id || result?.success) {
                  toast.success("Successfully avatar updated!");
                  handleGetData(page.current);
                  handleClose(setShowImageModel);
                }
              }
            );
          }
        });
      }, 1000);
    },
  });

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = (item?: any) => {
    setFormData({
      id: item.id || "",
      fullName: item.fullName || "",
      phone: item.phone || "",
      phoneOther: item.phoneOther || "",
      email: item.email || "",
      idType: item.idType || "ord",
      billingCountryId: item.billingAddress?.countryId || null,
      billingStateId: item.billingAddress?.stateId || null,
      billingCity: item.billingAddress?.city || "",
      billingAddress: item.billingAddress?.address || "",
      shippingCountryId: item.shippingAddress?.countryId || null,
      shippingStateId: item.shippingAddress?.stateId || null,
      shippingCity: item.shippingAddress?.city || "",
      shippingAddress: item.shippingAddress?.address || "",
      dateOfBirth: item.dateOfBirth || "",
      note: item.note || "",
    });

    if (item.id && item.billingAddress?.countryId) {
      handleGetRegions(item.billingAddress?.countryId || "");
    }

    if (item.id && item.shippingAddress?.countryId) {
      handleGetShippingRegions(item.shippingAddress?.countryId || "");
    }

    setFileName("");
    handleShow(setShow);
  };

  const handleSerachEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      if (e.target.value) {
        handleGetData(0, e.target.value);
      } else {
        handleGetData(0);
        setSearchString("");
      }
    }
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    formData.dateOfBirth =
      formData.dateOfBirth && new Date(formData.dateOfBirth).toISOString();

    let _formData = {
      avatar: formData.avatar || null,
      fullName: formData.fullName,
      phone: formData.phone,
      idType: formData.idType || "ord",
      phoneOther: formData.phoneOther,
      email: formData.email,
      dateOfBirth: formData.dateOfBirth,
      note: formData.note,
      shippingAddress: {
        address: formData.shippingAddress || "",
        city: formData.shippingCity || "",
        stateId: formData.shippingStateId || null,
        countryId: formData.shippingCountryId || null,
      },
      billingAddress: {
        address: formData.billingAddress || "",
        city: formData.billingCity || "",
        stateId: formData.billingStateId || null,
        countryId: formData.billingCountryId || null,
      },
    };

    setLoading(true);
    dataService(
      method,
      _formData,
      required,
      method === postMethod ? personsRoute : `${personsRoute}/${formData.id}`,
      method === putMethod
    ).then((result) => {
      handleResponse(result);
    });
  };

  const showImage = (item?: any) => {
    if (item?.avatar) {
      setFileName(item?.avatar.fileName);
    } else {
      setFileName("");
    }

    setFormData({
      id: item.id || "",
      fullName: "",
      phone: "",
      phoneOther: "",
      email: "",
      idType: "ord",
      avatar: "",
      billingCountryId: null,
      billingStateId: null,
      billingCity: "",
      billingAddress: "",
      shippingCountryId: null,
      shippingStateId: null,
      shippingCity: "",
      shippingAddress: "",
      dateOfBirth: "",
      note: "",
    });

    handleShow(setShowImageModel);
  };

  // const handleDelete = (item: any) => {
  //     deleteService(item?.fullName, {  }, `${personsRoute}/${item?.id}`, handleResponse)
  // }

  const handleDelete = (item: any) => {
    swal({
      text: `Are you sure to delete ${item?.fullName}!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        handleDeleteResponse(
          await actionData(deleteMethod, `${personsRoute}/${item.id}`, null),
          item?.avatar?.fileName
        );
      }
    });
  };

  const handleDeleteResponse = (result: any, file: string) => {
    if (result?.success) {
      toast.success(result?.message || "Successfully done!");

      if (file) {
        deleteAvatar({ file: file }).then(() => {
          setFileName("");
          setFormData({ ...formData, avatar: null });
        });
      }

      handleGetData(page.current);
    } else {
      toast.error(result?.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = (skip: number) => {
    handleGetData(skip, searchString);
  };

  const handleSelectCountry = (evt: any) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      billingCountryId: value ? parseInt(value) : "",
    });
    handleGetRegions(value);
  };

  const handleGetRegions = async (countryId: string) => {
    actionData(getMethod, `states/` + countryId).then((result) => {
      setRegions(result.data);
    });
  };

  const handleShippingSelectCountry = (evt: any) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      shippingCountryId: value ? parseInt(value) : "",
    });
    handleGetShippingRegions(value);
  };

  const handleGetShippingRegions = async (countryId: string) => {
    actionData(getMethod, `states/` + countryId).then((result) => {
      setShippingRegions(result.data);
    });
  };

  const handleDeleteUploaded = async () => {
    deleteAvatar({ file: fileName }).then((data) => {
      setFileName("");
      setFormData({ ...formData, avatar: null });
      handleClose(setShowDeleteModel);

      if (formData.id) {
        actionData(deleteMethod, "avatar", { fileName: fileName }).then(
          (result) => {
            toast.success("Successfully deleted!");
            handleGetData(page.current);
            handleClose(setShowImageModel);
          }
        );
      }
    });
  };

  const handleShowDetail = (item: any) => {
    setPerson(item);
    handleShow(setShowDetailModel);
  };

  const details = [
    {
      label: "Full name",
      value: (
        <span>
          <span className="me-2">{person?.fullName}</span>{" "}
          {person?.idType === "vvip" ? (
            <Badge className="bg-grad">VVIP</Badge>
          ) : person?.idType === "vip" ? (
            <Badge className="bg-warning">VIP</Badge>
          ) : (
            <Badge className="bg-success">Ordinary</Badge>
          )}
        </span>
      ),
    },
    { label: "Phone", value: person?.phone },
    { label: "Phone other", value: person?.phoneOther || "-" },
  ];

  const detail2 = [
    { label: "Email", value: person?.email || "-" },
    {
      label: "Date of birth",
      value: person?.dateOfBirth ? handleDateFormat(person?.dateOfBirth) : "-",
    },
    { label: "Note", value: person?.note },
  ];

  const shipping = [
    { label: "Address", value: person?.shippingAddress?.address || "-" },
    { label: "City", value: person?.shippingAddress?.city || "-" },
    {
      label: "Region / State",
      value: person?.shippingAddress?.stateName || "-",
    },
    { label: "Country", value: person?.shippingAddress?.countryName || "-" },
  ];

  const billing = [
    { label: "Address", value: person?.billingAddress?.address || "-" },
    { label: "City", value: person?.billingAddress?.city || "-" },
    {
      label: "Region / State",
      value: person?.billingAddress?.stateName || "-",
    },
    { label: "Country", value: person?.billingAddress?.countryName || "-" },
  ];

  return (
    <Layout title="Persons">
      <div className="flex-between mb-4">
        <SearchInput
          handleSerachEnterAction={handleSerachEnterAction}
          searchString={searchString}
          setSearchString={setSearchString}
          handleClearSearch={() => {
            handleGetData(0, "");
            setSearchString("");
          }}
        />
        <div className="d-flex align-items-center">
          {page.total ? (
            <span>
              <small className="text-muted">Result : </small>
              {numberFormat(page.total)}
            </span>
          ) : (
            <></>
          )}
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconAdd />{" "}
            <span className="ms-2">
              <Lang label="createNew" />
            </span>
          </Button>
        </div>
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
        isFilter
        handleChangePage={handleChangePage}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Person</th>
            <th>Phone</th>
            <th>Person type</th>
            {/* <th>Note</th> */}
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <span className="d-flex align-items-center">
                <div className="thumbnail-list-image me-3">
                  {item?.avatar ? (
                    <Image
                      src={`${RES_API_URL}${item?.avatar?.url}`}
                      alt="no_found"
                    />
                  ) : (
                    <Image src={"images/no-image.png"} />
                  )}
                </div>
                <div>
                  <div>{item?.fullName}</div>
                  {/* <small className='text-muted mt-2 d-block'>{item?.email}</small>
                                    <small className='text-muted mt-2'>{item?.phone}</small> */}
                </div>
              </span>

              <td>
                <span className={!item.phone ? "flex-center" : ""}>
                  {item.phone || "-"}
                </span>
              </td>
              <td>
                <span className="flex-center">
                  {item.idType === "vvip" ? (
                    <Badge className="bg-grad">VVIP</Badge>
                  ) : item.idType === "vip" ? (
                    <Badge className="bg-warning">VIP</Badge>
                  ) : (
                    <Badge className="bg-success">Ordinary</Badge>
                  )}
                </span>
              </td>
              {/*  <td><span className={!item.phoneOther ? "flex-center" : ""}>{item.phoneOther || "-"}</span></td>
                            <td><span className={!item.email ? "flex-center" : ""}>{item.email || "-"}</span></td>
                            <td><span className={!item.address ? "flex-center" : ""}>{item.address || "-"}</span></td>
                            <td><span className={!item.note ? "flex-center" : ""}>{item.note || "-"}</span></td> */}
              <td>
                <span className="flex-center">
                  <Button
                    className="btn-sm bg-grad"
                    onClick={() => handleShowDetail(item)}
                  >
                    <IconFile />
                  </Button>
                  <Button
                    className="btn-sm ms-2 bg-grad"
                    onClick={() => showImage(item)}
                  >
                    <IconImage />
                  </Button>
                  <TableRowButtons
                    item={item}
                    showForm={showForm}
                    handleDelete={handleDelete}
                    flex="flex-center"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>

      {/* <CustomModalService
                show={show}
                handleClose={() => handleClose(setShow)}
                id={formData?.id}
                handleSubmit={handleSubmit}
                loading={loading}
                fields={fields}
                setShow={setShow}
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
                lg /> */}

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        xl
      >
        <Row className="align-items-center">
          {!formData.id && (
            <Col md={3}>
              {fileName ? (
                <div className="d-flex justify-content-center  mt-4">
                  <div className="image-area rounded">
                    <img
                      src={`${RES_API_URL}/assets/avatar/${fileName}`}
                      className="thumb-img"
                      alt={"no_alt"}
                    />
                    <button
                      className="btn remove-image"
                      onClick={() => handleShow(setShowDeleteModel)}
                      type="button"
                    >
                      <IconClose />
                    </button>
                  </div>
                </div>
              ) : (
                <div {...getRootProps({ className: " mb-3" })}>
                  <input {...getInputProps()} />
                  <div className="d-flex justify-content-center">
                    {imageUploadLoading ? (
                      <ImageLoading />
                    ) : (
                      <div className="position-relative">
                        <div className="avatar">
                          <IconImage size={60} />
                        </div>

                        <div className="btn-upload">
                          <IconCamera />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
          )}
          <Col md={formData.id ? 12 : 9}>
            <FormFieldService
              fields={fields}
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
              colCount={4}
            />
          </Col>
        </Row>
        {/* 
                <h6>Person type</h6>
                <div className="d-flex mb-4">
                    <Form.Check
                        inline
                        label={"Ordinary"}
                        type={"radio"}
                        id={`Ordinary`}
                        name="active"
                        checked={formData.idType === 'ord'}
                        onChange={() => setFormData({ ...formData, idType: 'ord' })}
                    />
                    <Form.Check
                        inline
                        label={"VIP"}
                        name="active"
                        type={"radio"}
                        id={`VIP`}
                        checked={formData.idType === 'vip'}
                        onChange={() => setFormData({ ...formData, idType: 'vip' })}
                    />
                    <Form.Check
                        inline
                        label={"VVIP"}
                        name="active"
                        type={"radio"}
                        id={`VVIP`}
                        checked={formData.idType === 'vvip'}
                        onChange={() => setFormData({ ...formData, idType: 'vvip' })}
                    />
                </div> */}

        <Row className="align-items-center mt-3">
          <Col>
            <h6 className="mb-0">Billing address</h6>
          </Col>
          <Col>
            <div className="flex-between align-items-center">
              <h6 className="mb-0">Shipping address</h6>
              <Button
                className="bg-grad btn-sm"
                onClick={() => {
                  if (formData.billingCountryId) {
                    handleGetShippingRegions(formData.billingCountryId);
                  }

                  setFormData({
                    ...formData,
                    shippingCountryId: formData.billingCountryId || null,
                    shippingStateId: formData.billingStateId || null,
                    shippingCity: formData.billingCity || "",
                    shippingAddress: formData.billingAddress || "",
                  });
                }}
              >
                Same as billing address
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="p-3 rounded bg-light mt-3">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2 input-form-control">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      aria-label="Select"
                      id="billingCountryId"
                      value={formData.billingCountryId || ""}
                      onChange={handleSelectCountry}
                    >
                      <option value="">Select</option>
                      {countries.map((item: any, index: number) => (
                        <option value={item.id} key={index}>
                          {item.countryName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2 input-form-control">
                    <Form.Label>Region / State</Form.Label>
                    <Form.Control
                      as="select"
                      aria-label="Select"
                      id="billingStateId"
                      value={formData.billingStateId || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {regions.map((item: any, index: number) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <FormFieldService
                  fields={billingField}
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                  colCount={12}
                />
              </Row>
            </div>
          </Col>
          <Col>
            <div className="p-3 rounded bg-light mt-3">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2 input-form-control">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      aria-label="Select"
                      id="shippingCountryId"
                      value={formData.shippingCountryId || ""}
                      onChange={handleShippingSelectCountry}
                    >
                      <option value="">Select</option>
                      {countries.map((item: any, index: number) => (
                        <option value={item.id} key={index}>
                          {item.countryName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2 input-form-control">
                    <Form.Label>Region / State</Form.Label>
                    <Form.Control
                      as="select"
                      aria-label="Select"
                      id="shippingStateId"
                      value={formData.shippingStateId || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {shippingRegions.map((item: any, index: number) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <FormFieldService
                  fields={shippingField}
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                  colCount={12}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </CustomModal>

      <Modal
        show={showImageModel}
        onHide={() => handleClose(setShowImageModel)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fileName ? (
            <div className="d-flex justify-content-center  mt-4">
              <div className="image-area rounded">
                <img
                  src={`${RES_API_URL}/assets/avatar/${fileName}`}
                  className="thumb-img"
                  alt={"no_alt"}
                />
                <button
                  className="btn remove-image"
                  onClick={() => handleShow(setShowDeleteModel)}
                  type="button"
                >
                  <IconClose />
                </button>
              </div>
            </div>
          ) : (
            <div {...getRootProps({ className: " mb-3" })}>
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center">
                {imageUploadLoading ? (
                  <ImageLoading />
                ) : (
                  <Button className="text-white mt-3 bg-grad">
                    <IconUpload /> Upload Image
                  </Button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModel}
        onHide={() => handleClose(setShowDeleteModel)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete image?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => handleClose(setShowDeleteModel)}
          >
            Cancel
          </Button>
          <Button className="bg-danger" onClick={() => handleDeleteUploaded()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDetailModel}
        onHide={() => handleClose(setShowDetailModel)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Person Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-4">
            <Col md={2}>
              {person?.avatar ? (
                <Image
                  src={`${RES_API_URL}${person?.avatar?.url}`}
                  alt="no_found"
                  width={"100%"}
                  className="rounded"
                />
              ) : (
                <Image
                  src={"images/no-image.png"}
                  width={"100%"}
                  className="rounded"
                />
              )}
            </Col>
            <Col md={5}>
              <Table responsive striped className="mb-0 border">
                <tbody>
                  {details.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="text-muted " style={{ width: "50%" }}>
                        {item.label}
                      </td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col md={5}>
              <Table responsive striped className="mb-0 border">
                <tbody>
                  {detail2.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="text-muted " style={{ width: "50%" }}>
                        {item.label}
                      </td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Billing address</h6>
              <Table responsive striped className="mb-0 border">
                <tbody>
                  {billing.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="text-muted " style={{ width: "50%" }}>
                        {item.label}
                      </td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col>
              <h6>Shipping address</h6>
              <Table responsive striped className="mb-0 border">
                <tbody>
                  {shipping.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="text-muted " style={{ width: "50%" }}>
                        {item.label}
                      </td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Index;
