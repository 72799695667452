import React from "react";
import { Col } from "react-bootstrap";
import { Lang, Layout } from "../../../components";
import PosCart from "./PosCart";
import PosItem from "./PosItem";

const Index = () => {
  return (
    <Layout
      title={
        <span>
          <Lang label="pointOfSale" /> (<Lang label="calibratedGems" />)
        </span>
      }
      noPadding
    >
      <div className="d-flex">
        <Col md={7}>
          <PosItem />
        </Col>
        <Col md={5}>
          <PosCart />
        </Col>
      </div>
    </Layout>
  );
};

export default Index;
