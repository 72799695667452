import React from "react";
import { Button, Modal } from "react-bootstrap";
import { handleDateFormat, inputChangeService } from "../../../../services";
import { FormFieldService } from "../../../../components";
import { toast } from "react-toastify";

interface ConfirmModalProps {
  show: boolean;
  data: any;
  handleToggle: () => void;
  handleSave: (data: any) => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  data,
  handleToggle,
  handleSave,
}) => {
  const [formData, setFormData] = React.useState<any>(data);

  const fields = [
    {
      id: "dealingPrice",
      label: "Dealing Price *",
      value: data?.dealingPrice,
    },
    { id: "brokerFee", label: "Broker fee *", value: data?.brokerFee },
    {
      id: "dealAt",
      label: "Deal date *",
      value: data?.dealAt ? handleDateFormat(data?.dealAt, "YYYY-MM-DD") : null,
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: data?.consignmentNotes,
      inputType: "textarea",
      disableMargin: true,
    },
  ];

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleSubmit = () => {
    if (
      ![formData?.dealingPrice, formData?.brokerFee, formData?.dealAt].includes(
        null
      )
    ) {
      handleSave(formData);
    } else {
      toast.error("Please provide required data!");
    }
  };

  return (
    <Modal show={show} onHide={handleToggle} centered>
      <Modal.Header closeButton>
        <Modal.Title>Sale confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={12}
          bg={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-success" onClick={handleToggle}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
