import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  IconAdd,
  // IconArrowLeftRight,
  IconDelete,
  IconEdit,
} from "../../../../components/widgets/Icons";
import {
  // dateFormat2,
  handleClose,
  // handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import {
  actionData,
  currencyRoute,
  dataService,
  deleteService,
  // gemsRoute,
  getMethod,
  postMethod,
  responseService,
} from "../../../../services/api";
import {
  CustomInput,
  // CustomInput,
  CustomModal,
  // CustomModalService,
  FormFieldService,
  NoData,
} from "../../../../components";

const Prices = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const price = product?.price;
  const calibratedGemWeight =
    product?.stages?.[product?.stages?.length - 1]?.weight || 0;
  const [currencies, setCurrencies] = useState([]);
  const [formData, setFormData] = useState({
    sharedInvestorsQty: null,
    interestFrom: "",
    interestRate: "",
    investedUSDRate: null,
    currency: "",
    otherCost: null,
    paymentDueDate: null,
    buyingPriceFomula: "total",
    buyingPricePerCts: "",
    buyingPrice: "",
    sellingPriceFomula: "total",
    sellingPricePerCts: "",
    sellingPrice: "",
  });

  const fields = [
    // {
    //   id: "interestFrom",
    //   label: "Interest from",
    //   value: formData.interestFrom,
    //   hide: true,
    // },
    // {
    //   id: "interestRate",
    //   label: "Interest rate",
    //   value: formData.interestRate,
    //   hide: true,
    // },
    // {
    //   id: "investedUSDRate",
    //   label: "Invested USD rate",
    //   value: formData.investedUSDRate,
    //   inputType: "number",
    //   wantString: true,
    // },
    {
      id: "currency",
      label: "Currency",
      value: formData.currency,
      type: "select",
      data: currencies,
      wantString: true,
    },
    // {
    //   id: "sharedInvestorsQty",
    //   label: "Shared investors qty",
    //   value: formData.sharedInvestorsQty,
    //   inputType: "number",
    // },
    // {
    //   id: "buyingPrice",
    //   label: "Buying price",
    //   value: formData.buyingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
    // {
    //   id: "paymentDueDate",
    //   label: "Payment due date",
    //   value: handleDateFormat(formData?.paymentDueDate, dateFormat2),
    //   type: "date",
    // },
    // {
    //   id: "otherCost",
    //   label: "Other cost",
    //   value: formData.otherCost,
    //   inputType: "number",
    // },
    {
      id: "weight",
      label: "Calibrated gem weight",
      value: product?.stages?.[product?.stages?.length - 1]?.weightUnit
        ? `${product?.stages?.[product?.stages?.length - 1]?.weight} ${
            product?.stages?.[product?.stages?.length - 1]?.weightUnit
          }`
        : "",
      inputType: "text",
      disable: true,
    },
    // {
    //   id: "buyingPrice",
    //   label: "Total cost",
    //   value: formData?.buyingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
    // {
    //   id: "sellingPrice",
    //   label: "Selling price",
    //   value: formData?.sellingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
  ];

  useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, currencyRoute).then((result) => {
      const temp: any = [];
      result.data?.map((d: any) =>
        temp.push({
          label: d?.value,
          value: d?.value,
        })
      );
      setCurrencies(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = () => {
    setFormData({
      sharedInvestorsQty: price?.sharedInvestorsQty || "",
      interestFrom: price?.interestFrom || "",
      interestRate: price?.interestRate || "",
      investedUSDRate: price?.investedUSDRate || "",
      currency: price?.currency || "",
      buyingPriceFomula: price?.buyingPriceFomula || "",
      buyingPricePerCts: price?.buyingPricePerCts || "",
      buyingPrice: price?.buyingPrice || "",
      sellingPriceFomula: price?.sellingPriceFomula || "",
      sellingPricePerCts: price?.sellingPricePerCts || "",
      sellingPrice: price?.sellingPrice || "",
      otherCost: price?.otherCost || "",
      paymentDueDate: price?.paymentDueDate || "",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    setLoading(true);

    const { buyingPriceFomula, sellingPriceFomula, ..._formData } = formData;

    _formData.paymentDueDate =
      _formData.paymentDueDate &&
      new Date(_formData.paymentDueDate).toISOString();

    dataService(
      method,
      _formData,
      [],
      `cali_gemstones/${
        method === postMethod ? product.id : product?.price?.id
      }/price`,
      true
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleDelete = () => {
    deleteService(
      "prices",
      null,
      `cali_gemstones/${product?.price?.id}/price`,
      handleResponse
    );
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const totalCost = [
    // {
    //   label: "Buying price per cts",
    //   value: price?.buyingPricePerCts
    //     ? `${price?.currency} ${numberFormat(price?.buyingPricePerCts)}`
    //     : "-",
    // },
    // {
    //   label: "Total cost",
    //   value: price?.buyingPrice
    //     ? `${price?.currency} ${numberFormat(price?.buyingPrice)}`
    //     : "-",
    // },
    {
      label: "Selling price per cts",
      value: price?.sellingPricePerCts
        ? `${price?.currency} ${numberFormat(price?.sellingPricePerCts)}`
        : "-",
    },
    {
      label: "Selling price",
      value: price?.sellingPrice
        ? `${price?.currency} ${numberFormat(price?.sellingPrice)}`
        : "-",
    },
  ];

  const prices = [
    // {
    //   label: "Other cost",
    //   value: price?.otherCost
    //     ? `${price?.currency} ${numberFormat(price?.otherCost)}`
    //     : "-",
    // },
    // {
    //   label: "Invested rate (USD)",
    //   value: price?.investedUSDRate
    //     ? `${numberFormat(price?.investedUSDRate)}`
    //     : "-",
    // },
    // { label: "Shared investors qty", value: price?.sharedInvestorsQty || "-" },
    // {
    //   label: "Payment due date",
    //   value: price?.paymentDueDate
    //     ? handleDateFormat(price?.paymentDueDate)
    //     : "-",
    // },
    { label: "Currency", value: price?.currency || "-" },
    {
      label: "Total cost",
      value: price?.buyingPrice
        ? `${price?.currency} ${numberFormat(price?.buyingPrice)}`
        : "-",
    },
  ];

  return (
    <section className="bg-card my-4">
      <section className="flex-between mb-4">
        <h6>Prices</h6>
        <div>
          <Button
            className="text-white bg-grad"
            disabled={Boolean(product?.childGemstones?.length)}
            onClick={showForm}
          >
            {product?.price?.id ? (
              <>
                <IconEdit /> <span className="ms-2">Edit prices</span>
              </>
            ) : (
              <>
                <IconAdd /> <span className="ms-2">Add prices</span>
              </>
            )}
          </Button>
          {product?.price?.id && (
            <Button
              className="text-white bg-danger ms-2"
              onClick={handleDelete}
            >
              <IconDelete /> <span className="ms-2">Delete prices</span>
            </Button>
          )}
        </div>
      </section>

      {product?.price?.id ? (
        <Row>
          <Col>
            <Table responsive striped className="mb-0">
              <tbody>
                {prices.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="ps-4 text-muted ">{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table responsive striped className="mb-0">
              <tbody>
                {totalCost.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="ps-4 text-muted ">{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <NoData noImage />
      )}

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={product?.price?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
        />

        <Row>
          <Col md={6}>
            <CustomInput
              id={"buyingPriceRate"}
              label={"Selling price per cts"}
              value={formData?.sellingPricePerCts}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  sellingPricePerCts: e.target.value,
                  sellingPrice: Math.floor(
                    calibratedGemWeight * (e.target.value || 0)
                  )?.toString(),
                });
              }}
              inputType={"number"}
            />
          </Col>

          <Col md={6}>
            <CustomInput
              id={"sellingPrice"}
              label={"Total selling price"}
              value={formData?.sellingPrice}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  sellingPrice: e.target.value,
                  sellingPricePerCts: Number(
                    e.target.value === 0
                      ? formData.sellingPricePerCts
                      : (e.target.value || 0) / calibratedGemWeight
                  )?.toString(),
                });
              }}
              inputType={"number"}
            />
          </Col>

          <Col md={6}>
            <CustomInput
              id={"buyingPrice"}
              label={"Total cost"}
              value={formData?.buyingPrice}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  buyingPrice: e.target.value,
                });
              }}
              inputType={"number"}
            />
          </Col>
        </Row>

        {/* <Row className="align-items-center">
          <Col md={5}>
            {formData.buyingPriceFomula === "perCts" ? (
              <CustomInput
                id={"buyingPriceRate"}
                label={"Buying price per cts"}
                value={formData.buyingPricePerCts}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    buyingPricePerCts: e.target.value,
                    buyingPrice: Math.floor(
                      calibratedGemWeight * (e.target.value || 0)
                    )?.toString(),
                  });
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"buyingPrice"}
                label={"Total buying price"}
                value={formData.buyingPrice}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    buyingPrice: e.target.value,
                    buyingPricePerCts: Number(
                      e.target.value === 0
                        ? formData.buyingPricePerCts
                        : (e.target.value || 0) / calibratedGemWeight
                    )?.toString(),
                  });
                }}
                inputType={"number"}
              />
            )}
          </Col>
          <Col className="mt-4">
            <div className="flex-center align-items-center">
              <Button
                className="bg-grad rounded-circle shadow-sm"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    buyingPriceFomula:
                      formData.buyingPriceFomula === "perCts"
                        ? "total"
                        : "perCts",
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </div>
          </Col>
          <Col md={5}>
            {formData.buyingPriceFomula === "perCts" ? (
              <CustomInput
                id={"buyingPrice"}
                label={"Total buying price"}
                value={formData.buyingPrice}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    buyingPrice: e.target.value,
                  })
                }
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"gemsPricePerCts"}
                label={"Buying price per cts"}
                value={formData.buyingPricePerCts}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    buyingPricePerCts: e.target.value,
                  })
                }
                inputType={"number"}
              />
            )}
          </Col>
        </Row> */}

        {/* <Row className="align-items-center">
          <Col md={5}>
            {formData.sellingPriceFomula === "perCts" ? (
              <CustomInput
                id={"buyingPriceRate"}
                label={"Selling price per cts"}
                value={formData.sellingPricePerCts}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    sellingPricePerCts: e.target.value,
                    sellingPrice: Math.floor(
                      calibratedGemWeight * (e.target.value || 0)
                    )?.toString(),
                  });
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"sellingPrice"}
                label={"Total selling price"}
                value={formData.sellingPrice}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    sellingPrice: e.target.value,
                    sellingPricePerCts: Number(
                      e.target.value === 0
                        ? formData.sellingPricePerCts
                        : (e.target.value || 0) / calibratedGemWeight
                    )?.toString(),
                  });
                }}
                inputType={"number"}
              />
            )}
          </Col>
          <Col className="mt-4">
            <div className="flex-center align-items-center">
              <Button
                className="bg-grad rounded-circle shadow-sm"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    sellingPriceFomula:
                      formData.sellingPriceFomula === "perCts"
                        ? "total"
                        : "perCts",
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </div>
          </Col>
          <Col md={5}>
            {formData.sellingPriceFomula === "perCts" ? (
              <CustomInput
                id={"sellingPrice"}
                label={"Total selling price"}
                value={formData.sellingPrice}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    sellingPrice: e.target.value,
                  })
                }
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"gemsPricePerCts"}
                label={"Selling price per cts"}
                value={formData.sellingPricePerCts}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    sellingPricePerCts: e.target.value,
                  })
                }
                inputType={"number"}
              />
            )}
          </Col>
        </Row> */}
      </CustomModal>

      {/* <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={product?.price?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        lg
      /> */}
    </section>
  );
};

export default Prices;
