import { useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router-dom";
import { CustomModal, FormFieldService } from "../../../../components";
import {
  IconDelete,
  IconEdit,
  IconFile,
  IconPointOfSale,
} from "../../../../components/widgets/Icons";
import { inventoryStatus, inventoryTypes } from "../../../../data";
import {
  handleClose,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import { moveToTrashService } from "../../../../services/api";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  itemName?: string;
  image?: string;
}

export const Grid = ({
  item,
  handleResponse,
}: {
  item: any;
  handleResponse: any;
}) => {
  const history = useHistory();
  //  const { dispatch: { translate } } = useContext(LangContext);

  const goDetail = () => {
    history.push(
      `/jewellery-detail?id=${item.id}`,
    );
  };

  const [target, setTarget] = useState<Target>();
  const [show, setShow] = useState(false);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [formData, setFormData] = useState<any>({
    status: "In",
  });

  const fields = [
    {
      id: "status",
      label: "Status",
      value: formData.status,
      type: "select",
      data: inventoryStatus,
      wantString: true,
      disableInititalSelect: true,
    },
  ];

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleDelete = () => {
    moveToTrashService(
      item?.itemName,
      null,
      `trash/jewellery/${item?.id}`,
      handleResponse
    );
  };

  // const productImage = () => {
  //     let tempImages = item.productColors
  //     let tempDiv = <Image src={'images/noimage.png'} />;
  //     if (tempImages.length > 0) {
  //         tempDiv = <Image src={`${RES_API_URL}${tempImages[0]}`} alt={tempImages[0].url} />
  //     }
  //     return tempDiv;
  // }

  const thumbsnailImage = (item: any) => {
    let tempImages = item.images;
    let isExist = false;
    let tempDiv = (
      <img
        src="images/no-image.png"
        alt="Thumbnail"
        className="float-right inv-thumb"
      />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="no img"
              className="float-right inv-thumb cursor"
              onClick={() => {
                setTarget({ itemName: item?.itemName, image: img.url });
                handleShow(setShow);
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="no img"
            className="float-right inv-thumb cursor"
            onClick={() => {
              setTarget({ itemName: item?.itemName, image: tempImages[0].url });
              handleShow(setShow);
            }}
          />
        );
      }
    }

    return tempDiv;
  };

  return (
    <section className="product-card">
      {show && (
        <Lightbox
          mainSrc={`${RES_API_URL}${target?.image}`}
          imageTitle={`${item?.itemName} (${item?.sku})`}
          imageCaption={`${item?.jewelleryType} / ${item?.jewelleryWeight} ${item?.jewelleryWeightUnit
            } / ${numberFormat(item?.price?.sellingPrice)} ${item?.price?.currency
            }`}
          onCloseRequest={() => handleClose(setShow)}
        />
      )}

      <section className="product-card-image">{thumbsnailImage(item)}</section>
      <section className="product-card-info">
        <div>
          <h6 className="fw-bold mb-1" onClick={goDetail}>
            {item?.itemName}
          </h6>
          <div className="mb-2">
            <Badge className="bg-success me-2">
              {
                inventoryTypes.filter(
                  (type: any) => type.key === item?.inventoryType
                )[0]?.label
              }
            </Badge>
            <Badge
              className={
                item?.availability === "Available" ? "bg-grad" : "bg-danger"
              }
            >
              {item?.availability === "Available" ? "In stock" : "Sold out"}
            </Badge>
          </div>
        </div>

        <section onClick={goDetail}>
          <small className="text-muted d-block mb-2">{item?.sku}</small>
          {item?.gemstones && (
            <small className="d-block mb-2">
              <span className="text-muted">Gems weight : </span>
              {item?.gemstones?.totalWeight} {item?.gemstones?.weightUnit}
            </small>
          )}
          {item?.diamonds && (
            <small className="d-block mb-2">
              <span className="text-muted">Diamonds weight : </span>
              {item?.diamonds?.totalWeight} {item?.diamonds?.weightUnit}
            </small>
          )}
          {item?.gold && (
            <small className="d-block ">
              <span className="text-muted">Gold weight : </span>
              {item?.gold?.totalGoldWeight} {item?.gold?.totalGoldWeightUnit}{" "}
              {item?.gold?.goldSmithLossNote
                ? `, ${item?.gold?.goldSmithLossNote}`
                : ""}
            </small>
          )}
        </section>
        <section className="d-flex justify-content-between align-items-center ">
          <section onClick={goDetail}>
            <small className="d-block mb-1 fw-bold">
              {item?.price?.sellingPrice
                ? `${item?.price?.currency} ${numberFormat(
                  item?.price?.sellingPrice
                )}`
                : ""}
            </small>
          </section>
          <section>
            <OverlayTrigger overlay={<Tooltip>Edit status</Tooltip>}>
              <Button
                className="text-white btn-sm bg-grad me-2"
                onClick={() => handleShow(setShowStatusForm)}
              >
                <IconPointOfSale />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
              <Button
                className="text-white btn-sm bg-grad me-2"
                onClick={goDetail}
              >
                <IconFile />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Edit jewellery</Tooltip>}>
              <Button
                className="text-white btn-sm bg-grad"
                onClick={() =>
                  history.push(
                    `/edit-jewellery?id=${item.id}`,
                  )
                }
              >
                <IconEdit />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Move to trash</Tooltip>}>
              <Button className="ms-2 bg-danger btn-sm" onClick={handleDelete}>
                <IconDelete />
              </Button>
            </OverlayTrigger>
          </section>
        </section>
      </section>

      <CustomModal
        show={showStatusForm}
        handleClose={() => handleClose(setShowStatusForm)}
        id={null}
        handleSubmit={() => handleClose(setShowStatusForm)}
        loading={false}
        customTitle="Inventory status"
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={12}
        />
      </CustomModal>
    </section>
  );
};
