import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  actionData,
  currencyRoute,
  dataService,
  getMethod,
  postMethod,
  responseForRoutes,
} from "../../../../services/api";
import { CustomInput, FormFieldService } from "../../../../components";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getStoreUser } from "../../../../services";
// import { IconArrowLeftRight } from "../../../../components/widgets/Icons";

interface PriceProps {
  formData: any;
  setFormData: (data: any) => void;
}

const Price: React.FC<PriceProps> = ({ formData, setFormData }) => {
  const history = useHistory();
  const [disabled, setDisabled] = React.useState(false);
  const [currencies, setCurrencies] = React.useState([]);
  const calibratedGemWeight = formData?.stage?.weight || 0;

  const fields = [
    // {
    //   id: "interestFrom",
    //   label: "Interest from",
    //   value: formData?.price?.interestFrom,
    //   hide: true,
    // },
    // {
    //   id: "interestRate",
    //   label: "Interest rate",
    //   value: formData?.price?.interestRate,
    //   hide: true,
    // },
    // {
    //   id: "investedUSDRate",
    //   label: "Invested USD rate",
    //   value: formData?.price?.investedUSDRate,
    //   inputType: "number",
    //   wantString: true,
    // },
    {
      id: "currency",
      label: "Currency",
      value: formData?.price?.currency,
      type: "select",
      data: currencies,
      wantString: true,
    },
    // {
    //   id: "sharedInvestorsQty",
    //   label: "Shared investors qty",
    //   value: formData?.price?.sharedInvestorsQty,
    //   inputType: "number",
    // },
    // {
    //   id: "buyingPrice",
    //   label: "Buying price",
    //   value: formData?.price?.buyingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
    // {
    //   id: "paymentDueDate",
    //   label: "Payment due date",
    //   value: handleDateFormat(formData?.price?.paymentDueDate, dateFormat2),
    //   type: "date",
    // },
    // {
    //   id: "otherCost",
    //   label: "Other cost",
    //   value: formData?.price?.otherCost,
    //   inputType: "number",
    // },
    {
      id: "weight",
      label: "Calibrated gem weight",
      value: formData?.stage?.weightUnit
        ? `${formData?.stage?.weight} ${formData?.stage?.weightUnit}`
        : "",
      inputType: "text",
      disable: true,
    },
    // {
    //   id: "buyingPrice",
    //   label: "Total cost",
    //   value: formData?.price?.buyingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
    // {
    //   id: "sellingPrice",
    //   label: "Selling price",
    //   value: formData?.price?.sellingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
  ];

  React.useEffect(() => {
    handleGetCurrency();
  }, []);

  const handleGetCurrency = async () => {
    actionData(getMethod, currencyRoute).then((result) => {
      const temp: any = [];
      result?.data?.map((d: any) =>
        temp.push({
          label: d?.value,
          value: d?.value,
        })
      );
      setCurrencies(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.type === "text" ? target.value : target.value;
    }

    setFormData({
      ...formData,
      price: {
        ...formData.price,
        [name]: value,
      },
    });
  };

  const handleSave = () => {
    setDisabled(true);

    formData.caliGem = {
      ...formData.caliGem,
      userLoginId: Number(getStoreUser()?.userId),
      published: formData?.published,
      forSale:
        formData?.caliGem?.inventoryType === "private_item"
          ? formData?.forSale
          : false,
      caliGemsOwnerId: formData?.caliGemsOwnerId,
      caliGemsBrokerId: formData?.caliGemsBrokerId,
      purchaseDate: formData?.purchaseDate
        ? new Date(formData.purchaseDate).toISOString()
        : null,
    };

    // formData.price = {
    //   ...formData.price,
    //   paymentDueDate: formData?.paymentDueDate
    //     ? new Date(formData.paymentDueDate).toISOString()
    //     : null,
    // };

    handleCreateCaliGem();
  };

  const handleCreateCaliGem = () => {
    dataService(postMethod, formData.caliGem, [], "cali_gemstones", true).then(
      (result) => {
        if (result?.success) {
          handleCreateStage(result?.data?.id);
        } else {
          toast.error(result?.message);
          setDisabled(false);
        }
      }
    );
  };

  const handleCreateStage = (id: any) => {
    formData.stage = {
      ...formData.stage,
      treatment: formData?.stage?.treatment?.join(", "),
    };
    console.log(formData?.stage);

    dataService(
      postMethod,
      formData?.stage,
      [],
      `cali_gemstones/${id}/stage`,
      true
    ).then((result) => {
      if (result?.success) {
        handleCreatePrice(result?.data?.caliGemstonesId);
      } else {
        toast.error(result?.message);
        setDisabled(false);
      }
    });
  };

  const handleCreatePrice = (stoneId: any) => {
    const _temp = { ...formData?.price };
    const { buyingPriceFomula, sellingPriceFomula, ..._formData } = _temp;

    dataService(
      postMethod,
      _formData,
      [],
      `cali_gemstones/${stoneId}/price`,
      true
    ).then((result) => {
      if (result?.success) {
        responseForRoutes(result, history, "calibrated-gems");
      } else {
        toast.error(result?.message);
        setDisabled(false);
      }
    });
  };

  return (
    <div className={`${formData?.step === 3 ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>Price information</div>
      </div>

      <div className="bg-card mt-3">
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
        />

        <Row>
          <Col md={6}>
            <CustomInput
              id={"buyingPriceRate"}
              label={"Selling price per cts"}
              value={formData.price?.sellingPricePerCts}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  price: {
                    ...formData?.price,
                    sellingPricePerCts: e.target.value,
                    sellingPrice: Math.floor(
                      calibratedGemWeight * (e.target.value || 0)
                    )?.toString(),
                  },
                });
              }}
              inputType={"number"}
            />
          </Col>

          <Col md={6}>
            <CustomInput
              id={"sellingPrice"}
              label={"Total selling price"}
              value={formData.price?.sellingPrice}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  price: {
                    ...formData?.price,
                    sellingPrice: e.target.value,
                    sellingPricePerCts: Number(
                      e.target.value === 0
                        ? formData.sellingPricePerCts
                        : (e.target.value || 0) / calibratedGemWeight
                    )?.toString(),
                  },
                });
              }}
              inputType={"number"}
            />
          </Col>

          <Col md={6}>
            <CustomInput
              id={"buyingPrice"}
              label={"Total cost"}
              value={formData.price?.buyingPrice}
              handleChange={(e: any) => {
                setFormData({
                  ...formData,
                  price: {
                    ...formData?.price,
                    buyingPrice: e.target.value,
                  },
                });
              }}
              inputType={"number"}
            />
          </Col>
        </Row>

        {/* <Row className="align-items-center">
          <Col md={5}>
            {formData.price?.buyingPriceFomula === "perCts" ? (
              <CustomInput
                id={"buyingPriceRate"}
                label={"Buying price per cts"}
                value={formData.price?.buyingPricePerCts}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      buyingPricePerCts: e.target.value,
                      buyingPrice: Math.floor(
                        calibratedGemWeight * (e.target.value || 0)
                      )?.toString(),
                    },
                  });
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"buyingPrice"}
                label={"Total buying price"}
                value={formData.price?.buyingPrice}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      buyingPrice: e.target.value,
                      buyingPricePerCts: Number(
                        e.target.value === 0
                          ? formData.price?.buyingPricePerCts
                          : (e.target.value || 0) / calibratedGemWeight
                      )?.toString(),
                    },
                  });
                }}
                inputType={"number"}
              />
            )}
          </Col>

          <Col className="mt-4">
            <div className="flex-center align-items-center">
              <Button
                className="bg-grad rounded-circle shadow-sm"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      buyingPriceFomula:
                        formData.buyingPriceFomula === "perCts"
                          ? "total"
                          : "perCts",
                    },
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </div>
          </Col>

          <Col md={5}>
            {formData.price?.buyingPriceFomula === "perCts" ? (
              <CustomInput
                id={"buyingPrice"}
                label={"Total buying price"}
                value={formData.price?.buyingPrice}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    pricce: {
                      ...formData?.price,
                      buyingPrice: e.target.value,
                    },
                  })
                }
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"gemsPricePerCts"}
                label={"Buying price per cts"}
                value={formData.price?.buyingPricePerCts}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      buyingPricePerCts: e.target.value,
                    },
                  })
                }
                inputType={"number"}
              />
            )}
          </Col>
        </Row> */}

        {/* <Row className="align-items-center">
          <Col md={5}>
            {formData.price?.sellingPriceFomula === "perCts" ? (
              <CustomInput
                id={"buyingPriceRate"}
                label={"Selling price per cts"}
                value={formData.pricce?.sellingPricePerCts}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      sellingPricePerCts: e.target.value,
                      sellingPrice: Math.floor(
                        calibratedGemWeight * (e.target.value || 0)
                      )?.toString(),
                    },
                  });
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"sellingPrice"}
                label={"Total selling price"}
                value={formData.price?.sellingPrice}
                handleChange={(e: any) => {
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      sellingPrice: e.target.value,
                      sellingPricePerCts: Number(
                        e.target.value === 0
                          ? formData.sellingPricePerCts
                          : (e.target.value || 0) / calibratedGemWeight
                      )?.toString(),
                    },
                  });
                }}
                inputType={"number"}
              />
            )}
          </Col>

          <Col className="mt-4">
            <div className="flex-center align-items-center">
              <Button
                className="bg-grad rounded-circle shadow-sm"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      sellingPriceFomula:
                        formData.price?.sellingPriceFomula === "perCts"
                          ? "total"
                          : "perCts",
                    },
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </div>
          </Col>

          <Col md={5}>
            {formData.price?.sellingPriceFomula === "perCts" ? (
              <CustomInput
                id={"sellingPrice"}
                label={"Total selling price"}
                value={formData.price?.sellingPrice}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      sellingPrice: e.target.value,
                    },
                  })
                }
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"gemsPricePerCts"}
                label={"Selling price per cts"}
                value={formData.price?.sellingPricePerCts}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    price: {
                      ...formData?.price,
                      sellingPricePerCts: e.target.value,
                    },
                  })
                }
                inputType={"number"}
              />
            )}
          </Col>
        </Row> */}
      </div>

      <div className="mt-3 d-flex justify-content-between">
        <Button
          disabled={disabled}
          className="text-white bg-grad px-4"
          onClick={() => setFormData({ ...formData, step: 2 })}
        >
          Previous
        </Button>

        <Button
          disabled={disabled}
          className="text-white bg-grad px-4"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Price;
