import React, { useState, createContext } from "react";

interface SetCart {
  item: object;
  netPrice: String;
  currency: String;
}

const contextDefaultValues: SetCart = {
  item: {},
  netPrice: "",
  currency: "",
};

const SetCartContext = createContext<any>(contextDefaultValues);

function SetCartProvider(props: any) {
  const [setCart, setSetCart] = useState<SetCart>(contextDefaultValues);

  return (
    <SetCartContext.Provider value={[setCart, setSetCart]}>
      {props.children}
    </SetCartContext.Provider>
  );
}

export { SetCartContext, SetCartProvider };
