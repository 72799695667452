import { useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip, Image, Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Layout, Lang, TableLayout } from "../../../components";
import {
  IconAdd,
  IconDelete,
  IconEdit,
  IconFile,
} from "../../../components/widgets/Icons";
import { PageContext } from "../../../context/Page";
import { getNumber, handleDateTimeFormat } from "../../../services";
import {
  customRoute,
  getDataService,
  deleteMethod,
  dataService,
  responseForRoutes,
  RES_API_URL,
} from "../../../services/api";
import { SearchInput } from "../../../components/widgets/CustomInput";
import swal from "sweetalert";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useContext(PageContext);

  useEffect(() => {
    handleGetData(page.current, "");
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number, keyword: any) => {
    getDataService(
      customRoute(`consignment/cali_gems`, skip, keyword),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  // search
  const handleSearch = (e: any) => {
    if (e.keyCode === 13) {
      handleGetData(page.current, keyword);
    }
  };

  const handleClearSearch = () => {
    setKeyword("");
    handleGetData(page.current, "");
  };

  const handleConfirmDelete = (item: any) => {
    swal({
      text: `Are you sure to delete?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        handleDelete(item);
      }
    });
  };

  const handleDelete = (item: any) => {
    dataService(deleteMethod, {}, [], `consignment/cali_gems/${item?.id}`).then(
      (result) => {
        responseForRoutes(result, history, "calibrated-gems-consignments");
        handleGetData(page.current, "");
      }
    );
  };

  return (
    <Layout
      title={
        <span>
          <Lang label="consignments" /> (<Lang label="calibratedGems" />)
        </span>
      }
    >
      <section className="flex-between mb-4">
        <SearchInput
          searchString={keyword}
          setSearchString={setKeyword}
          handleSerachEnterAction={(e: any) => handleSearch(e)}
          handleClearSearch={handleClearSearch}
        />

        <Button
          className="text-white bg-grad"
          onClick={() => history.push("calibrated-gem-consignment-form")}
        >
          <IconAdd />
          <span className="ms-2">
            <Lang label="createNew" />
          </span>
        </Button>
      </section>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Item</th>
            <th>Broker</th>
            <th className="text-center">Status</th>
            <th>Date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div className="thumbnail-list-image me-2">
                    {item?.consigned_gems?.images?.length &&
                    item?.consigned_gems?.images[0].url ? (
                      <Image
                        src={`${RES_API_URL}${item?.consigned_gems?.images[0].url}`}
                        alt={item?.consigned_gems?.itemName}
                      />
                    ) : (
                      <Image src={"images/no-image.png"} alt="" />
                    )}
                  </div>
                  <div>
                    <div>{item?.consigned_gems?.itemName}</div>
                    <small className="text-muted mt-2">
                      {item?.consigned_gems?.sku}
                    </small>
                  </div>
                </span>
              </td>
              <td>{item?.cali_gems_consignee?.fullName}</td>
              <td className="text-center">
                <Badge
                  className={
                    item?.consignmentStatus === "Pending"
                      ? "bg-grad"
                      : "bg-success"
                  }
                >
                  {item?.consignmentStatus}
                </Badge>
              </td>
              <td>
                <span className="">
                  {handleDateTimeFormat(item?.createdAt)}
                </span>
              </td>
              <td>
                <span className="">
                  <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                    <Button
                      className="btn-sm bg-grad"
                      onClick={() =>
                        history.push({
                          pathname: "/calibrated-gem-consignment-detail",
                          state: { item: item },
                        })
                      }
                    >
                      <IconFile />
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger overlay={<Tooltip>Edit consignment</Tooltip>}>
                    <Button
                      className="btn-sm bg-grad ms-2"
                      // disabled={item?.consignmentStatus !== "Pending"}
                      onClick={() =>
                        history.push({
                          pathname: "calibrated-gem-consignment-form",
                          state: { data: item },
                        })
                      }
                    >
                      <IconEdit />
                    </Button>
                  </OverlayTrigger>
                  <Button
                    className="bg-danger btn-sm ms-2"
                    disabled={item?.consignmentStatus !== "Pending"}
                    onClick={() => handleConfirmDelete(item)}
                  >
                    <IconDelete />
                  </Button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>
    </Layout>
  );
};

export default Index;
