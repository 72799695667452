import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { NoData } from "../../../components";
import { CartContext } from "../../../context/Cart";
import {
  dateFormat2,
  getStoreUser,
  handleDateFormat,
  handleShow,
  numberFormat,
} from "../../../services";
import {
  dataService,
  posSalesGemRoute,
  postMethod,
  responseForRoutes,
} from "../../../services/api";
import CartItem from "../cpn/CartItem";
import CustomerInfo from "../cpn/CustomerInfo";
import SaleConfirmModal from "../cpn/SaleConfirmModal";

const PosCart = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [person, setPerson] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [cart, setCart] = useContext(CartContext);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  const [formData, setFormData] = useState<any>({
    currency: "",
    sellerId: user?.userId,
    buyerId: "",
    itemId: "",
    netPrice: "",
    saleNotes: "",
    paymentMethodId: "",
    paymentSeries: "down_payment",
    amount: "",
    serviceFee: "",
    discountType: "by_amount",
    discountValue: "",
    createdAt: handleDateFormat(new Date(), dateFormat2),
    isGift: false,
    isConsigned: false,
  });

  const saleAction = () => {
    if (cart?.item?.itemName) {
      if (person) {
        setFormData({
          ...formData,
          amount: cart?.netPrice,
          serviceFee: (cart?.netPrice * 0.05).toString(),
          saleNotes: "",
          paymentMethodId: "",
          paymentSeries: "down_payment",
          discountType: "by_amount",
          discountValue: "",
          createdAt: handleDateFormat(new Date(), dateFormat2),

        });
        handleShow(setShow);
      } else {
        toast.error("Please select customer!");
      }
    } else {
      toast.error("Please select gem!");
    }
  };

  const handleSaleConfirm = () => {
    setLoading(true)
    formData.createdAt =
      formData.createdAt && new Date(formData.createdAt).toISOString();
    if (formData.isGift) {
      formData.buyerId = person?.value;
      formData.itemId = cart?.item?.id;
      formData.netPrice = "";
      formData.discountType = "";
      formData.serviceFee = "";
      formData.discountValue = "";
      formData.paymentMethodId = "";
      formData.paymentSeries = "";
      formData.amount = "";

      dataService(postMethod, formData, [], posSalesGemRoute, true).then(
        (result) => {
          if (result.success) {
            setLoading(false)
            responseForRoutes(result, history, "gems-sales");
            setCart({ ...cart, item: {}, netPrice: "" });
            toast.success(result?.message);
          } else {
            toast.error(result?.message);
          }
        }
      );
    } else {
      if (!formData.paymentMethodId) {
        toast.error("Please choose payment method!");
      }

      if (!formData.paymentSeries) {
        toast.error("Please choose payment series!");
      }

      if (!formData.amount) {
        toast.error("Please provide payment amount!");
      }

      if (
        formData.paymentMethodId &&
        formData.paymentSeries &&
        formData.amount
      ) {
        if (
          cart?.item.inventoryType === "shop_inventory" ||
          cart?.item.inventoryType === "private_item"
        ) {
          formData.serviceFee = 0;
        }
        formData.sellerId = getStoreUser()?.userId;
        formData.buyerId = person?.value;
        formData.itemId = cart?.item?.id;
        formData.netPrice = Number(cart?.netPrice);
        formData.currency = cart?.currency;
        formData.amount = Number(formData?.amount);
        formData.discountValue = Number(formData?.discountValue);

        dataService(postMethod, formData, [], posSalesGemRoute, true).then(
          (result) => {
            if (result.success) {
              setLoading(false)
              responseForRoutes(result, history, "gems-sales");
              setCart({ ...cart, item: {}, netPrice: "" });
              toast.success(result?.message);
            } else {
              toast.error(result?.message);
            }
          }
        );
      }
    }
  };

  return (
    <section
      className="pos-cart"
      style={{ position: cart?.item?.itemName ? "fixed" : "relative" }}
    >
      <section className="p-4 pos-cart-scroll-area">
        <CustomerInfo person={person} setPerson={setPerson} />

        {cart?.item?.itemName ? (
          <>
            <CartItem isGift={formData.isGift} />

            <Form.Check
              type="checkbox"
              label="Gift item"
              id="giftItem"
              className="me-4 cursor mt-4"
              checked={formData.isGift}
              onChange={(e) =>
                setFormData({ ...formData, isGift: !formData.isGift })
              }
            />
          </>
        ) : (
          <div className="mt-5">
            <NoData title="item" />
          </div>
        )}
      </section>

      <SaleConfirmModal
        loading={loading}
        show={show}
        setShow={setShow}
        handleSaleConfirm={handleSaleConfirm}
        person={person}
        formData={formData}
        setFormData={setFormData}
      />

      {cart?.item?.itemName && person && (
        <section className="pos-sale-btn" onClick={saleAction}>
          <label>Sale</label>
          {cart?.netPrice && (
            <label>
              {cart?.currency} {numberFormat(cart?.netPrice)}
            </label>
          )}
        </section>
      )}
    </section>
  );
};

export default PosCart;
