import React from "react";
import { Image } from "react-bootstrap";

interface InvoiceImageProps {}

const InvoiceImage: React.FC<InvoiceImageProps> = () => {
  return (
    <div className="d-flex flex-column align-items-center mb-4 bg-black">
      <Image src="images/mr.spinel.png" width={300} className="p-4" />
    </div>
  );
};

export default InvoiceImage;
