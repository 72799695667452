import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomModal } from "..";
import { personTypes } from "../../data";
import { FileWithPath, useDropzone } from "react-dropzone";
import {
  dateFormat2,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
} from "../../services";
import {
  actionData,
  dataService,
  deleteAvatar,
  getMethod,
  personsRoute,
  uploadAvatar,
} from "../../services/api";
import FormFieldService from "../services/FormFieldService";
import { ImageLoading } from "../widgets/Loading";
import { IconCamera, IconClose, IconImage } from "../widgets/Icons";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const PersonModel = ({
  show,
  setShow,
  formData,
  setFormData,
  responseAction,
}: {
  show: boolean;
  setShow: any;
  formData?: any;
  setFormData?: any;
  responseAction?: any;
}) => {
  const [countries, setCounties] = useState([]);
  const [regions, setRegions] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [shippingRegions, setShippingRegions] = useState([]);
  const [fileName, setFileName] = useState("");
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const required = ["fullName"];

  const fields = [
    { id: "fullName", label: "Full name *", value: formData.fullName },
    { id: "phone", label: "Phone *", value: formData.phone },
    { id: "phoneOther", label: "Phone other", value: formData.phoneOther },
    { id: "email", label: "Email", value: formData.email },
    {
      id: "dateOfBirth",
      label: "Date of birth",
      value: formData.dateOfBirth
        ? handleDateFormat(formData.dateOfBirth, dateFormat2)
        : null,
      type: "date",
    },
    {
      id: "idType",
      label: "Person type",
      value: formData.idType,
      type: "select",
      data: personTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "note",
      label: "Note",
      value: formData.note,
      inputType: "textarea",
      colCount: 12,
    },
  ];

  const billingField = [
    { id: "billingCity", label: "City", value: formData.billingCity },
    {
      id: "billingAddress",
      label: "Address",
      value: formData.billingAddress,
      disableMargin: true,
    },
  ];

  const shippingField = [
    { id: "shippingCity", label: "City", value: formData.shippingCity },
    {
      id: "shippingAddress",
      label: "Address",
      value: formData.shippingAddress,
      disableMargin: true,
    },
  ];

  useEffect(() => {
    actionData(getMethod, `countries`).then((result: any) => {
      setCounties(result.data);
    });
    // eslint-disable-next-line
  }, []);

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleSelectCountry = (evt: any) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      billingCountryId: value ? parseInt(value) : "",
    });
    handleGetRegions(value);
  };

  const handleGetRegions = async (countryId: string) => {
    actionData(getMethod, `states/` + countryId).then((result) => {
      setRegions(result.data);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageUploadLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );
        uploadAvatar(tempFormData).then((data) => {
          setFormData({ ...formData, avatar: data[0] });
          setFileName(data[0].fileName);
          setImageUploadLoading(false);
        });
      }, 1000);
    },
  });

  const handleShippingSelectCountry = (evt: any) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      shippingCountryId: value ? parseInt(value) : "",
    });
    handleGetShippingRegions(value);
  };

  const handleGetShippingRegions = async (countryId: string) => {
    actionData(getMethod, `states/` + countryId).then((result) => {
      setShippingRegions(result.data);
    });
  };

  const handleSubmit = (method: string) => {
    formData.dateOfBirth =
      formData.dateOfBirth && new Date(formData.dateOfBirth).toISOString();

    let _formData = {
      avatar: formData.avatar || null,
      fullName: formData.fullName,
      phone: formData.phone,
      idType: formData.idType || "ord",
      phoneOther: formData.phoneOther,
      email: formData.email,
      dateOfBirth: formData.dateOfBirth,
      note: formData.note,
      shippingAddress: {
        address: formData.shippingAddress || "",
        city: formData.shippingCity || "",
        stateId: formData.shippingStateId || null,
        countryId: formData.shippingCountryId || null,
      },
      billingAddress: {
        address: formData.billingAddress || "",
        city: formData.billingCity || "",
        stateId: formData.billingStateId || null,
        countryId: formData.billingCountryId || null,
      },
    };

    dataService(method, _formData, required, personsRoute).then((result) => {
      if (result?.success || result?.id || result?.persons?.id) {
        toast.success(result?.message || "Successfully done!");
        handleClose(setShow);
        // handleGetData(skip, sorting)
      } else {
        toast.error(result?.message || "Something wrong!");
        handleClose(setShow);
        // setLoading && setLoading(false)
      }
    });
  };

  const handleDeleteUploaded = async () => {
    deleteAvatar({ file: fileName }).then((data) => {
      setFileName("");
      setFormData({ ...formData, avatar: null });
      handleClose(setShowDeleteModel);
    });
  };

  return (
    <CustomModal
      show={show}
      handleClose={() => handleClose(setShow)}
      id={formData?.id}
      handleSubmit={handleSubmit}
      loading={false}
      xl
    >
      <Row className="align-items-center">
        <Col md={3}>
          {fileName ? (
            <div className="d-flex justify-content-center  mt-4">
              <div className="image-area rounded">
                <img
                  src={`${RES_API_URL}/assets/avatar/${fileName}`}
                  className="thumb-img"
                  alt={"no_alt"}
                />
                <button
                  className="btn remove-image"
                  onClick={() => handleShow(setShowDeleteModel)}
                  type="button"
                >
                  <IconClose />
                </button>
              </div>
            </div>
          ) : (
            <div {...getRootProps({ className: " mb-3" })}>
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center">
                {imageUploadLoading ? (
                  <ImageLoading />
                ) : (
                  <div className="position-relative">
                    <div className="avatar">
                      <IconImage size={60} />
                    </div>

                    <div className="btn-upload">
                      <IconCamera />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
        <Col md={9}>
          <FormFieldService
            fields={fields}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            colCount={4}
          />
        </Col>
      </Row>

      <Row className="align-items-center mt-3">
        <Col>
          <h6 className="mb-0">Billing address</h6>
        </Col>
        <Col>
          <div className="flex-between align-items-center">
            <h6 className="mb-0">Shipping address</h6>
            <Button
              className="bg-grad btn-sm"
              onClick={() => {
                if (formData.billingCountryId) {
                  handleGetShippingRegions(formData.billingCountryId);
                }

                setFormData({
                  ...formData,
                  shippingCountryId: formData.billingCountryId || null,
                  shippingStateId: formData.billingStateId || null,
                  shippingCity: formData.billingCity || "",
                  shippingAddress: formData.billingAddress || "",
                });
              }}
            >
              Same as billing address
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="p-3 rounded bg-light mt-3">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2 input-form-control">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    aria-label="Select"
                    id="billingCountryId"
                    value={formData.billingCountryId || ""}
                    onChange={handleSelectCountry}
                  >
                    <option value="">Select</option>
                    {countries.map((item: any, index: number) => (
                      <option value={item.id} key={index}>
                        {item.countryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2 input-form-control">
                  <Form.Label>Region / State</Form.Label>
                  <Form.Control
                    as="select"
                    aria-label="Select"
                    id="billingStateId"
                    value={formData.billingStateId || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {regions.map((item: any, index: number) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <FormFieldService
                fields={billingField}
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
                colCount={12}
              />
            </Row>
          </div>
        </Col>
        <Col>
          <div className="p-3 rounded bg-light mt-3">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2 input-form-control">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    aria-label="Select"
                    id="shippingCountryId"
                    value={formData.shippingCountryId || ""}
                    onChange={handleShippingSelectCountry}
                  >
                    <option value="">Select</option>
                    {countries.map((item: any, index: number) => (
                      <option value={item.id} key={index}>
                        {item.countryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2 input-form-control">
                  <Form.Label>Region / State</Form.Label>
                  <Form.Control
                    as="select"
                    aria-label="Select"
                    id="shippingStateId"
                    value={formData.shippingStateId || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {shippingRegions.map((item: any, index: number) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <FormFieldService
                fields={shippingField}
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
                colCount={12}
              />
            </Row>
          </div>
        </Col>
      </Row>

      <Modal
        show={showDeleteModel}
        onHide={() => handleClose(setShowDeleteModel)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete image?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => handleClose(setShowDeleteModel)}
          >
            Cancel
          </Button>
          <Button className="bg-danger" onClick={() => handleDeleteUploaded()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </CustomModal>
    // <CustomModalService
    //     show={show}
    //     handleClose={() => handleClose(setShow)}
    //     id={formData?.id}
    //     handleSubmit={handleSubmit}
    //     loading={false}
    //     fields={fields}
    //     setShow={setShow}
    //     handleChange={handleChange}
    //     formData={formData}
    //     setFormData={setFormData}
    //     lg />
  );
};

export default PersonModel;
