import { useContext, useEffect, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { FormFieldService } from "../../../../components";
import { IconGem } from "../../../../components/widgets/Icons";
import { PageContext, VariableContext } from "../../../../context";
import { availabilityData, inventoryTypes } from "../../../../data";
import {
  handelGetPersons,
  handleClose,
  inputChangeService,
} from "../../../../services";
import {
  actionData,
  gemsAvgSizesRoute,
  getMethod,
  jewelleryTypeFilterRoute,
} from "../../../../services/api";
var cn = require("classnames");

const Filter = ({
  show,
  setShow,
  handleGetData,
  handleGetDataByFilter,
}: {
  show: boolean;
  setShow: any;
  handleGetData: any;
  handleGetDataByFilter: any;
}) => {
  const [outlets, setOutlets] = useState([]);
  const [jewelleryTypes, setJewelleryTypes] = useState([]);
  const [gemsStoneTypes, setGemsStoneTypes] = useState([]);
  const [page, setPage] = useContext(PageContext);
  const [variable, setVariable] = useContext(VariableContext);
  const [sizesByPcs, setSizesByPcs] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [persons, setPersons] = useState([]);
  const [jewelleryOwners, setJewelleryOwners] = useState([]);
  const [formData, setFormData] = useState({
    sku: "",
    itemName: "",
    jewelleryType: "",
    outletName: "",
    inventoryType: "",
    jewellerySmith: "",
    jewellerySmithDefaultValue: "",
    jewelleryOwner: "",
    jewelleryOwnerDefaultValue: "",
    interestFrom: "",
    interestFromDefaultValue: "",
    gemsSizeFrom: "",
    gemsSizeTo: "",
    gemsSizeUnit: "pcs_per_cts",
    diamondsSizeFrom: "",
    diamondsSizeTo: "",
    diamondsSizeUnit: "pcs_per_cts",
    availability: "",
    gemsStoneType: "",
    // saleStatus: "",
    purchaseDateFrom: "",
    purchaseDateTo: "",
    sellingDateFrom: "",
    sellingDateTo: "",
  });

  // const saleStatus = [{ key: "in_stock", label: "In stock", customKey: true }, { key: "sold_out", label: "Sold out", customKey: true }, { key: "consignments", label: "Consignments", customKey: true }]

  const fields = [
    { id: "itemName", label: "Item name", value: formData.itemName },
    { id: "sku", label: "SKU", value: formData.sku },
    {
      id: "gemsSizeFrom",
      label:
        formData.gemsSizeUnit === "pcs_per_cts"
          ? "Gems pcs per cts (from)"
          : "Gems size (from)",
      value: formData.gemsSizeFrom,
      type: "select",
      data: formData.gemsSizeUnit === "pcs_per_cts" ? sizesByPcs : sizes,
      wantString: true,
      checkId: "gemsSizeUnit",
      checkLabel: "Size",
      checkLabelValue: formData.gemsSizeUnit,
      checkFirstValue: "pcs_per_cts",
      checkSecondValue: "avg_size",
      keyType: "value",
    },
    {
      id: "gemsSizeTo",
      label:
        formData.gemsSizeUnit === "pcs_per_cts"
          ? "Gems pcs per cts (to)"
          : "Gems size (to)",
      value: formData.gemsSizeTo,
      type: "select",
      data: formData.gemsSizeUnit === "pcs_per_cts" ? sizesByPcs : sizes,
      wantString: true,
      keyType: "value",
    },
    {
      id: "diamondsSizeFrom",
      label:
        formData.diamondsSizeUnit === "pcs_per_cts"
          ? "Diamonds pcs per cts (from)"
          : "Diamonds size (from)",
      value: formData.diamondsSizeFrom,
      type: "select",
      data: formData.diamondsSizeUnit === "pcs_per_cts" ? sizesByPcs : sizes,
      wantString: true,
      checkId: "diamondsSizeUnit",
      checkLabel: "Size",
      checkLabelValue: formData.diamondsSizeUnit,
      checkFirstValue: "pcs_per_cts",
      checkSecondValue: "avg_size",
      keyType: "value",
    },
    {
      id: "diamondsSizeTo",
      label:
        formData.diamondsSizeUnit === "pcs_per_cts"
          ? "Diamonds pcs per cts (to)"
          : "Diamonds size (to)",
      value: formData.diamondsSizeTo,
      type: "select",
      data: formData.diamondsSizeUnit === "pcs_per_cts" ? sizesByPcs : sizes,
      wantString: true,
      keyType: "value",
    },
    {
      id: "gemsStoneType",
      label: "Gemstone type",
      value: formData.gemsStoneType,
      type: "select",
      data: gemsStoneTypes,
      wantString: true,
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData.outletName,
      type: "select",
      data: outlets,
      wantString: true,
    },
    {
      id: "jewellerySmith",
      label: "Jewellery smith",
      value: formData.jewellerySmith,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.jewellerySmithDefaultValue,
      secondId: "jewellerySmithDefaultValue",
    },
    {
      id: "interestFrom",
      label: "Interest from",
      value: formData.interestFrom,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.interestFromDefaultValue,
      secondId: "interestFromDefaultValue",
    },
    {
      id: "purchaseDateFrom",
      label: "Purchase date (from)",
      value: formData.purchaseDateFrom,
      type: "date",
    },
    {
      id: "purchaseDateTo",
      label: "Purchase date (to)",
      value: formData.purchaseDateTo,
      type: "date",
    },
    {
      id: "sellingDateFrom",
      label: "Selling date (from)",
      value: formData.sellingDateFrom,
      type: "date",
    },
    {
      id: "sellingDateTo",
      label: "Selling date (to)",
      value: formData.sellingDateTo,
      type: "date",
    },
    {
      id: "availability",
      label: "Availability",
      value: formData.availability,
      type: "select",
      data: availabilityData,
      wantString: true,
    },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
    },
    {
      id: "jewelleryOwner",
      label: "Jewellery owner",
      value: formData.jewelleryOwner,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.jewelleryOwnerDefaultValue,
      secondId: "jewelleryOwnerDefaultValue",
      hide: !(formData.inventoryType === "service_item"),
    },
    {
      id: "jewelleryOwner",
      label: "Jewellery owner",
      value: formData.jewelleryOwner,
      type: "select",
      data: jewelleryOwners,
      hide: !(formData.inventoryType === "private_item"),
    },
    // { id: "saleStatus", label: "Sale status", value: formData.saleStatus, type: "select", data: saleStatus, wantString: true },
    // { id: "purchaseStartDate", label: "Purchase date", value: formData.purchaseStartDate, type: "date" },
    // { id: "sellingStartDate", label: "Selling date", value: formData.sellingStartDate, type: "date" },
    // { id: "creatingStartDate", label: "Created date", value: formData.creatingStartDate, type: "date" },
  ];

  useEffect(() => {
    handleGetAttributes();
    if (variable?.jewelleryFilterActionData) {
      let _data = variable?.jewelleryFilterActionData;
      setFormData({
        sku: _data?.sku || "",
        itemName: _data?.itemName || "",
        jewelleryType: _data?.jewelleryType || "",
        outletName: _data?.outletName || "",
        inventoryType: _data?.inventoryType || "",
        jewellerySmith: _data?.jewellerySmith || "",
        jewellerySmithDefaultValue: _data?.jewellerySmithDefaultValue || "",
        jewelleryOwner: _data?.jewelleryOwner || "",
        jewelleryOwnerDefaultValue: _data?.jewelleryOwnerDefaultValue || "",
        interestFrom: _data?.interestFrom || "",
        interestFromDefaultValue: _data?.interestFromDefaultValue || "",
        gemsSizeFrom: _data?.gemsSizeFrom || "",
        gemsSizeTo: _data?.gemsSizeTo || "",
        gemsSizeUnit: _data?.gemsSizeUnit || "pcs_per_cts",
        diamondsSizeFrom: _data?.diamondsSizeFrom || "",
        diamondsSizeTo: _data?.diamondsSizeTo || "",
        diamondsSizeUnit: _data?.diamondsSizeUnit || "pcs_per_cts",
        availability: _data?.availability || "",
        gemsStoneType: _data?.gemsStoneType || "",
        purchaseDateFrom: _data?.purchaseDateFrom || "",
        purchaseDateTo: _data?.purchaseDateTo || "",
        sellingDateFrom: _data?.sellingDateFrom || "",
        sellingDateTo: _data?.sellingDateTo || "",
      });
    }
    actionData(getMethod, "owners").then((result) => {
      let temp: any = [];
      if (result && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({
            key: item.ownerId,
            label: item.fullName,
            customKey: true,
          });
        });
      }
      setJewelleryOwners(temp);
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleGetAttributes = async () => {
    handelGetPersons("", setPersons);
    actionData(getMethod, gemsAvgSizesRoute).then((result) => {
      let sizes: any = [];
      let sizesByPcs: any = [];
      result.data.forEach((item: any) => {
        if (item.unit === "pcs_per_cts") {
          sizesByPcs.push(item);
        } else {
          sizes.push(item);
        }
      });
      setSizesByPcs(sizesByPcs);
      setSizes(sizes);
    });
    actionData(getMethod, jewelleryTypeFilterRoute()).then((result) => {
      setJewelleryTypes(result.data);
    });
    actionData(getMethod, "gemstones-type/filter").then((result) => {
      setGemsStoneTypes(result.data);
    });
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleClearFilter = () => {
    setFormData({
      sku: "",
      itemName: "",
      jewelleryType: "",
      outletName: "",
      inventoryType: "",
      jewellerySmith: "",
      jewellerySmithDefaultValue: "",
      jewelleryOwner: "",
      jewelleryOwnerDefaultValue: "",
      interestFrom: "",
      interestFromDefaultValue: "",
      gemsSizeFrom: "",
      gemsSizeTo: "",
      gemsSizeUnit: "pcs_per_cts",
      diamondsSizeFrom: "",
      diamondsSizeTo: "",
      diamondsSizeUnit: "pcs_per_cts",
      gemsStoneType: "",
      availability: "",
      purchaseDateFrom: "",
      purchaseDateTo: "",
      sellingDateFrom: "",
      sellingDateTo: "",
    });

    setPage({ ...page, current: 0 });
    handleClose(setShow);
    handleGetData(0, "shop_inventory");
    setVariable({
      ...variable,
      jewelleryFilterActionData: {},
      isJewelleryFilter: false,
      inventoryJewelleryType: "shop_inventory",
    });
  };

  const filterAction = () => {
    let _data: any = {};

    for (let [key, value] of Object.entries(formData)) {
      if (value !== null && value !== "" && value !== undefined) {
        if (key === "diamondsSizeUnit") {
          if (formData.diamondsSizeFrom || formData.diamondsSizeTo)
            _data[key] = value;
        } else if (key === "gemsSizeUnit") {
          if (formData.gemsSizeFrom || formData.gemsSizeTo) _data[key] = value;
        } else {
          _data[key] = value;
        }
      }
    }

    handleClose(setShow);
    setVariable({
      ...variable,
      jewelleryFilterActionData: _data,
      isJewelleryFilter: true,
      inventoryJewelleryType: "",
    });
    handleGetDataByFilter(0, _data);
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => handleClose(setShow)}
      className="jewellery-filter"
    >
      <Offcanvas.Header closeButton className="bg-grad">
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-4">
        <Row>
          <Col md={2}>
            <div
              className={cn(
                "jewellery-type-card ",
                formData.jewelleryType === "" && "bg-grad"
              )}
              onClick={() => setFormData({ ...formData, jewelleryType: "" })}
            >
              <span
                className={
                  formData.jewelleryType === "" ? "text-white" : "text-primary"
                }
              >
                {" "}
                <IconGem size={24} />
              </span>
              <small className={"mt-2 jewellery-type-label"}>All</small>
            </div>
          </Col>
          {jewelleryTypes.length > 0 &&
            jewelleryTypes.map((item: any, index: number) => (
              <Col key={index} md={2}>
                <div
                  className={cn(
                    "jewellery-type-card ",
                    formData.jewelleryType === item?.label && "bg-grad"
                  )}
                  onClick={() =>
                    setFormData({ ...formData, jewelleryType: item?.label })
                  }
                >
                  <div className="jewellery-type-img">
                    <img
                      src={`images/jewellery_types/${item?.label.toLowerCase()}.svg`}
                      alt="Thumbnail"
                      className={
                        formData.jewelleryType === item?.label
                          ? "fill-white"
                          : "fill-muted"
                      }
                    />
                  </div>
                  <small className={"mt-2 jewellery-type-label"}>
                    {item?.label}
                  </small>
                  {/* <small className={"jewellery-type-label"}>{item?.itemCount}</small> */}
                </div>
              </Col>
            ))}
        </Row>

        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
          handleInputChange={handleInputChange}
        />
        <Row>
          <Col>
            <Button
              className="w-100 btn-wh bg-danger"
              onClick={handleClearFilter}
            >
              Clear filter
            </Button>
          </Col>
          <Col>
            <Button className="w-100 btn-wh bg-grad" onClick={filterAction}>
              Search
            </Button>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Filter;
