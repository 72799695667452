import React from "react";
import { useEffect, useState, useContext } from "react";
import { Badge, Button, Image } from "react-bootstrap";
import { Layout, TableLayout } from "../../components";
import {
  RES_API_URL,
  getDataService,
  getReceivableRoute,
} from "../../services/api";
import { PageContext } from "../../context";
import { getNumber, handleDateFormat, numberFormat } from "../../services";
import { inventoryTypes } from "../../data";
import { useReactToPrint } from "react-to-print";
import { IconPrint } from "../../components/widgets/Icons";
import ListPrint from "./cpn/ListPrint";

function Index() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useContext(PageContext);

  const handleGetData = (skip: number) => {
    getDataService(
      getReceivableRoute(skip),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (skip: number) => {
    handleGetData(skip);
  };

  // print
  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  return (
    <>
      <Layout title={"Receivable"}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="fw-light text-start text-success mb-0">Receivable</h5>

          <div className="d-flex align-items-center">
            {page.total ? (
              <span>
                <small className="text-muted">Result : </small>
                {numberFormat(page.total)}
              </span>
            ) : (
              <></>
            )}

            <Button className="text-white bg-grad ms-3" onClick={PrintAction}>
              <IconPrint /> <span className="ms-2">Print</span>
            </Button>
          </div>
        </div>

        <div className="d-none">
          <ListPrint data={data} ref={componentRef} />
        </div>

        <TableLayout
          loading={loading}
          data={data}
          page={page}
          setPage={setPage}
          handleGetData={handleGetData}
          isFilter
          handleChangePage={handleChangePage}
        >
          <thead className="bg-primary text-white">
            <tr>
              <th className="ps-4">No.</th>
              <th style={{ width: "130px" }}>Invoice no.</th>
              <th>Item</th>
              <th>Customer</th>
              <th className="text-center">Net price</th>
              <th className="text-center">Paid</th>
              <th className="text-center">Receivable</th>
              <th className="text-center">Discount</th>
              <th className="text-center">Currency</th>
              <th style={{ width: "120px" }}>Selling date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item: any, index: number) => (
              <tr key={index}>
                <td>
                  <span className="flex-center">
                    {getNumber(index, page?.current)}
                  </span>
                </td>
                <td>{item?.invoiceNumber}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className=" object-fit me-3">
                      {item?.jewellery_images?.[0]?.url ? (
                        <Image
                          src={RES_API_URL + item?.jewellery_images?.[0]?.url}
                          alt=""
                          width={80}
                          height={80}
                          className="rounded"
                        />
                      ) : (
                        <Image
                          alt=""
                          src={"images/no-image.png"}
                          width={80}
                          height={80}
                          className="rounded"
                        />
                      )}
                    </div>
                    <div>
                      <span className="d-block">{item?.itemName}</span>
                      <small className="text-muted mt-1 d-block">
                        {item?.sku}
                      </small>
                      <Badge className="bg-success mt-2">
                        {
                          inventoryTypes.filter(
                            (type: any) => type.key === item.inventoryType
                          )[0]?.label
                        }
                      </Badge>
                    </div>
                  </div>
                </td>
                <td>{item?.fullName}</td>
                <td className="text-end">{numberFormat(item?.netPrice)}</td>
                <td className="text-end">
                  {numberFormat(item?.totalPaidAmount)}
                </td>
                <td className="text-end">
                  {numberFormat(item?.receivableAmount)}
                </td>
                <td className="text-end">
                  {numberFormat(item?.discountValue)}
                </td>
                <td className="">{item?.currency}</td>
                <td className="text-center">
                  {handleDateFormat(item.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </TableLayout>
      </Layout>
    </>
  );
}

export default Index;
