import { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { FileWithPath, useDropzone } from "react-dropzone";
import swal from "sweetalert";
import { NoData, ImageLoading } from "../../../../components";
import { IconDelete, IconUpload } from "../../../../components/widgets/Icons";
import {
  dataService,
  deleteMethod,
  deleteUploadTwo,
  postMethod,
  responseService,
  setUpload,
} from "../../../../services/api";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Images = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<any>(
    product ? (product.images ? product.images : []) : []
  );
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );
        setUpload(tempFormData).then((data) => {
          let tempFiles: any = [];
          uploadedFiles?.forEach((item: any) => tempFiles.push(item));
          data?.forEach((item: any) => tempFiles.push(item));
          setUploadedFiles(tempFiles);
          setLoading(false);

          let _formData = {
            fileName: data[0].fileName,
            url: data[0].url,
            mimeType: data[0].mimeType,
            size: data[0].size,
            thumbnail: false,
          };

          dataService(
            postMethod,
            _formData,
            [],
            `cali_gemstones/${product.id}/images`
          ).then((result) => {
            handleResponse(result);
          });
        });
      }, 500);
    },
  });

  const handleResponse = (result: any) => {
    responseService(result, handleGetData);
  };

  const handleDelete = (fileName: string, i: any) => {
    const filesArray = [...uploadedFiles];

    if (i !== -1) {
      filesArray.splice(i, 1);
      setUploadedFiles(filesArray);
    }

    deleteUploadTwo(fileName).then((data) => {
      dataService(
        deleteMethod,
        { fileName: fileName },
        [],
        `remove/cali_gemstones/images`
      ).then((result) => {
        handleResponse(result);
      });
    });
  };

  const toggle = (fileName: string, id: number) => {
    swal({
      text: `Are you sure to confirm this image?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        handleDelete(fileName, id);
      }
    });
  };

  // const thumbs = uploadedFiles.map((file: any, i: number) => (
  //     <section className="image-area rounded" key={file.name}>
  //         <img src={file.preview}
  //             alt={file.name} />
  //         <button className="btn remove-image bg-danger p-2" onClick={() => toggle(i)} type="button"><IconDelete /></button>
  //     </section>
  // ))

  return (
    <div className="bg-card mb-4">
      <section className="flex-between mb-3">
        <h6>Images</h6>
        <div {...getRootProps({ className: "" })}>
          <input {...getInputProps()} />
          <div className="d-flex justify-content-center">
            <Button
              className="text-white bg-grad"
              disabled={Boolean(product?.childGemstones?.length)}
            >
              <IconUpload /> <span className="ms-2">Upload images</span>
            </Button>
          </div>
        </div>
      </section>

      <div className="row mt-4">
        {uploadedFiles.length > 0 ? (
          uploadedFiles.map((item: any, index: number) => (
            <div key={index} className="col-3">
              <div className="image-area rounded">
                <Image src={`${RES_API_URL}${item.url}`} alt={"no_found"} />
                <button
                  type="button"
                  className="btn remove-image p-2"
                  onClick={() => toggle(item.fileName, index)}
                  disabled={Boolean(product?.childGemstones?.length)}
                >
                  <IconDelete />
                </button>
              </div>
            </div>
          ))
        ) : (
          <>{!loading && <NoData noImage title="image" />}</>
        )}

        {loading && (
          <section className="image-area rounded">
            <ImageLoading />
          </section>
        )}
      </div>
    </div>
  );
};

export default Images;
