import React from "react";
import { Table } from "react-bootstrap";
import { QRView, ThumbsnailImage } from "..";
import { inventoryTypes } from "../../data";
import {
  groupByDiamonds,
  groupByGemstones,
  handleDateTimeFormat,
  numberFormat,
} from "../../services";
import Logo from "../common/Logo";

const DetailPdf = React.forwardRef((props: any, ref: any) => {
  let product = props.data;
  let gold =
    product && product?.gold && product?.gold.length > 0
      ? product?.gold[0]
      : {};
  let inventoryTypeLabel = inventoryTypes.filter(
    (item: any) => item.key === product?.inventoryType
  )[0]?.label;
  let gemstoneFilterItems =
    product && product?.gemstones && product?.gemstones.length > 0
      ? groupByGemstones(product?.gemstones)
      : [];
  let diamondFilterItems =
    product && product?.diamonds && product?.diamonds.length > 0
      ? groupByDiamonds(product?.diamonds)
      : [];

  const getTotalGoldCost = () => {
    let total = 0;
    if (product?.gold && product?.gold.length > 0) {
      product?.gold.forEach((item: any) => {
        total = total + parseInt(item.totalGoldCost || 0);
      });
    }
    return total;
  };

  const getTotalGemCost = () => {
    let total = 0;
    if (product?.gemstones && product?.gemstones.length > 0) {
      product?.gemstones.forEach((item: any) => {
        total = total + parseInt(item.totalGemsCost || 0);
      });
    }
    return total;
  };

  const getTotalDiamondCost = () => {
    let total = 0;
    if (product?.diamonds && product?.diamonds.length > 0) {
      product?.diamonds.forEach((item: any) => {
        total = total + parseInt(item.totalDiamondsCost || 0);
      });
    }
    return total;
  };

  const getAllTotalCost = () => {
    return (
      getTotalGoldCost() +
      getTotalGemCost() +
      getTotalDiamondCost() +
      parseInt(product?.price?.otherCost || 0)
    );
  };

  const details = [
    { label: "SKU", value: product?.sku, fontWeight: "bold" },
    // { label: "Outlet", value: product?.outletName || "-", color: "print-color-red" },
    { label: "Jewellery type", value: product?.jewelleryType || "-" },
    { label: "Jewellery style", value: product?.jewelleryStyle || "-" },
    {
      label: "Jewellery weight",
      value: product?.jewelleryWeight
        ? `${product?.jewelleryWeight} ${product?.jewelleryWeightUnit}`
        : "-",
    },
    {
      label: "Bangle width",
      value: product?.width || "-",
      hide: !(product?.jewelleryType === "Bangles"),
    },
    {
      label: "Ring width",
      value: product?.width || "-",
      hide: !(product?.jewelleryType === "Rings"),
    },
    {
      label: "Bracelet length",
      value: product?.length || "-",
      hide: !(product?.jewelleryType === "Bracelets"),
    },
    {
      label: "Necklace length",
      value: product?.length || "-",
      hide: !(product?.jewelleryType === "Necklaces"),
    },
    // { label: "Inventory type", value: inventoryTypeLabel || "-" },
    // { label: "Sale status", value: product?.saleStatus === "in_stock" ? <Badge>In Stock</Badge> : <Badge className="bg-danger">Sold Out</Badge> },
    { label: "Description", value: product?.description || "-" },
    {
      label: "Purchase date",
      value: product?.purchaseDate
        ? handleDateTimeFormat(product?.purchaseDate)
        : "-",
      secondLabel: "Selling date",
      secondValue: product?.sellingDate
        ? handleDateTimeFormat(product?.sellingDate)
        : "-",
    },
    // { label: "All total cost", value: getAllTotalCost() > 0 ? `${numberFormat(getAllTotalCost())}  ${product?.price?.currency || "MMK"}` : "-" },
    // { label: "Selling price", value: product?.price?.sellingPrice ? `${numberFormat(product?.price?.sellingPrice)}  ${product?.price?.currency || "MMK"}` : "-" },
    {
      label: "Selling date",
      value: product?.sellingDate
        ? handleDateTimeFormat(product?.sellingDate)
        : "-",
    },
  ];

  const goldFields = [
    { label: "Material type used", value: gold?.materialTypeUsed || "-" },
    { label: "Material purity", value: gold?.materialPurity || "-" },
    { label: "Material colour", value: gold?.materialColor || "-" },
    { label: "Jewellery smith", value: gold?.jewellery_smith?.fullName || "-" },
    {
      label: "Total gold weight",
      value: gold?.totalGoldWeight
        ? `${gold?.totalGoldWeight} ${gold?.totalGoldWeightUnit}`
        : "-",
    },
    {
      label: "Goldsmith loss",
      value: gold?.goldSmithLoss
        ? `${gold?.goldSmithLoss} ${gold?.totalGoldWeightUnit}`
        : "-",
    },
    {
      label: "Burmese gold unit",
      value: gold?.goldSmithLoss ? `${gold?.goldSmithLossNote}` : "-",
      color: "print-color-gold",
    },
    {
      label: "Total gold cost",
      value: gold?.totalGoldCost
        ? ` ${numberFormat(gold?.totalGoldCost)} ${
            product?.price?.currency || "MMK"
          }`
        : "-",
    },
  ];

  const prices = [
    {
      label: "Invested gold price rate",
      value: product?.price?.investedGoldPriceRate
        ? `${numberFormat(product?.price?.investedGoldPriceRate)}  ${
            product?.price?.currency || "MMK"
          }`
        : "-",
    },
    {
      label: "Invested USD rate",
      value: product?.price?.investedUSDRate
        ? `${numberFormat(product?.price?.investedUSDRate)}  ${
            product?.price?.currency || "MMK"
          }`
        : "-",
    },
    {
      label: "Interest from",
      value: product?.price?.interest_from?.fullName || "-",
    },
    { label: "Interest rate %", value: product?.price?.interestRate || "-" },
    {
      label: "Total diamonds cost",
      value:
        product?.diamonds.length > 0
          ? `${numberFormat(getTotalDiamondCost())}  ${
              product?.price?.currency || "MMK"
            }`
          : "-",
    },
    {
      label: "Total gemstones cost",
      value:
        product?.gemstones.length > 0
          ? `${numberFormat(getTotalGemCost())}  ${
              product?.price?.currency || "MMK"
            }`
          : "-",
    },
    {
      label: "Jewellery making cost",
      value: product?.price?.otherCost
        ? `${numberFormat(product?.price?.otherCost)}  ${
            product?.price?.currency || "MMK"
          }`
        : "-",
    },
    {
      label: "All total cost",
      value:
        getAllTotalCost() > 0
          ? `${numberFormat(getAllTotalCost())}  ${
              product?.price?.currency || "MMK"
            }`
          : "-",
    },
    // { label: "Selling price", value: product?.price?.sellingPrice ? `${numberFormat(product?.price?.sellingPrice)}  ${product?.price?.currency || "MMK"}` : "-" },
  ];

  return (
    <section ref={ref} className="print-container">
      <div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center">
            {/* <img src="images/logo.png" alt="Thumbnail" width={60} /> */}
            <Logo />

            <div className="flex-fill inv-title ms-3">
              <h6 className="mb-0 fw-bold">{product?.itemName}</h6>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="me-2">
              <small className="print-text d-block mb-1">
                {handleDateTimeFormat(product?.createdAt)}
              </small>
              <small className="print-text-lg">{inventoryTypeLabel}</small>
            </div>

            <QRView text={product?.sku} width={50} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-3">
            <div className="print-thumnail">
              <ThumbsnailImage item={product} />
            </div>
            {product?.price?.sellingPrice && (
              <div className="p-3 rounded-3 bg-light text-center mt-4">
                <h6 className="fw-bold mb-0 print-color-red print-text-price">
                  {numberFormat(product?.price?.sellingPrice)}{" "}
                  {product?.price?.currency || "MMK"}
                </h6>
              </div>
            )}
          </div>
          <div className="col-9">
            <Table responsive striped className="print-table mb-3 border">
              <tbody>
                {details.map(
                  (item: any, index: number) =>
                    !item.hide && (
                      <tr key={index}>
                        <td className={item.color || ""}>{item.label}</td>
                        <td className={item.color || ""}>{item.value}</td>
                        {/* {item.secondLabel && <td>{item.secondLabel}</td>}
                                        {item.secondValue && <td>{item.secondValue}</td>} */}
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {product && product?.gold && product?.gold.length > 0 && (
              <div>
                <h6 className="fw-bold print-text-lg mb-3">Gold Information</h6>
                <Table responsive className="print-table border" striped>
                  <tbody>
                    {goldFields.map((item: any, index: number) => (
                      <tr key={index}>
                        <td style={{ width: "50%" }}>{item.label}</td>
                        <td
                          style={{ width: "50%" }}
                          className={item.color || ""}
                        >
                          {item.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <div className="col">
            <h6 className="fw-bold print-text-lg mb-3">Price Information</h6>
            <Table responsive className="print-table border" striped>
              <tbody>
                {prices.map((item: any, index: number) => (
                  <tr key={index}>
                    <td style={{ width: "50%" }} className={item.color || ""}>
                      {item.label}
                    </td>
                    <td style={{ width: "50%" }} className={item.color || ""}>
                      {item.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>

        {product?.notes && (
          <div className="p-2 rounded-3 bg-light mt-4">
            <small className="fw-bold text-muted print-text d-block">
              Remarks
            </small>
            <small className="wrap-text print-text">{product?.notes}</small>
          </div>
        )}
        <footer />

        <div className="print-second-container">
          <h6 className="fw-bold print-text-lg mb-2">Gemstones</h6>
          {gemstoneFilterItems.length > 0 &&
            gemstoneFilterItems.map((filterItem: any, index: number) => (
              <div key={index}>
                <h6 className="print-text mb-2">{filterItem.gemsStockType}</h6>
                {filterItem.gemsStockType === "Lot" ? (
                  <Table responsive className="print-table" striped bordered>
                    <thead>
                      <tr>
                        <th>Gems stone type</th>
                        <th>Bought from</th>
                        <th>Carrier</th>
                        <th>Gems size</th>
                        <th>No. of pcs</th>
                        <th>Weight</th>
                        <th>Unit</th>
                        <th>Price per cts</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterItem?.data.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{item.gemsStoneType || "-"}</td>
                          <td>{item.gems_bought_from?.fullName || "-"}</td>
                          <td>{item.gems_carrier?.fullName || "-"}</td>
                          <td>
                            <span className="flex-end">
                              {item.gemsSize || "-"}{" "}
                              {item.gemsSize
                                ? item.gemsSizeUnit === "avg_size"
                                  ? "ct."
                                  : "pcs/ct."
                                : ""}
                            </span>
                          </td>
                          <td>
                            <span className="flex-end">
                              {item.gemsQuantity || "-"}
                            </span>
                          </td>
                          <td>
                            <span className="flex-end">
                              {item.gemsWeight ? `${item.gemsWeight}` : "-"}
                            </span>
                          </td>
                          <td>{item.gemsWeightUnit}</td>
                          <td>
                            <span className="flex-end">
                              {item.gemsPricePerCts
                                ? `${numberFormat(item.gemsPricePerCts)}`
                                : "-"}
                            </span>
                          </td>
                          <td>
                            <span className="flex-end">
                              {item.totalGemsCost
                                ? ` ${numberFormat(item.totalGemsCost)}`
                                : "-"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <></>
                )}

                {filterItem.gemsStockType === "Single" ? (
                  <Table responsive className="print-table" striped bordered>
                    <thead>
                      <tr>
                        <th>Gems stone type</th>
                        <th>Bought from</th>
                        <th>Carrier</th>
                        <th>Weight</th>
                        <th>Unit</th>
                        <th>Price per cts</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterItem?.data.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{item.gemsStoneType || "-"}</td>
                          <td>{item.gems_bought_from?.fullName || "-"}</td>
                          <td>{item.gems_carrier?.fullName || "-"}</td>
                          <td>
                            <span className="flex-end">
                              {item.gemsWeight ? `${item.gemsWeight}` : "-"}
                            </span>
                          </td>
                          <td>{item.gemsWeightUnit}</td>
                          <td>
                            <span className="flex-end">
                              {item.gemsPricePerCts
                                ? `${numberFormat(item.gemsPricePerCts)}`
                                : "-"}
                            </span>
                          </td>
                          <td>
                            <span className="flex-end">
                              {item.totalGemsCost
                                ? ` ${numberFormat(item.totalGemsCost)}`
                                : "-"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <></>
                )}

                {filterItem.gemsStockType === "Pair" ? (
                  <Table responsive className="print-table" striped bordered>
                    <thead>
                      <tr>
                        <th>Gems stone type</th>
                        <th>Bought from</th>
                        <th>Carrier</th>
                        <th>Pcs 1 weight</th>
                        <th>Pcs 2 weight</th>
                        <th>Unit</th>
                        <th>Price per cts</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterItem?.data.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{item.gemsStoneType || "-"}</td>
                          <td>{item.gems_bought_from?.fullName || "-"}</td>
                          <td>{item.gems_carrier?.fullName || "-"}</td>
                          <td>
                            <span className="flex-end">
                              {item.gemsPcsOneWeight
                                ? `${item.gemsPcsOneWeight}`
                                : "-"}
                            </span>
                          </td>
                          <td>
                            <span className="flex-end">
                              {item.gemsPcsTwoWeight
                                ? `${item.gemsPcsTwoWeight}`
                                : "-"}
                            </span>
                          </td>
                          <td>{item.gemsWeightUnit}</td>
                          <td>
                            <span className="flex-end">
                              {item.gemsPricePerCts
                                ? `${numberFormat(item.gemsPricePerCts)}`
                                : "-"}
                            </span>
                          </td>
                          <td>
                            <span className="flex-end">
                              {item.totalGemsCost
                                ? ` ${numberFormat(item.totalGemsCost)}`
                                : "-"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <></>
                )}
              </div>
            ))}

          {product && product?.diamonds && product?.diamonds.length > 0 && (
            <div className="mb-2 mt-4">
              <h6 className="fw-bold print-text-lg mb-2">Diamonds</h6>
              {diamondFilterItems.length > 0 &&
                diamondFilterItems.map((filterItem: any, index: number) => (
                  <div key={index}>
                    <h6 className="print-text mb-2">
                      {filterItem.diamondsStockType}
                    </h6>
                    {filterItem.diamondsStockType === "Lot" ? (
                      <Table
                        responsive
                        className="print-table"
                        striped
                        bordered
                      >
                        <thead>
                          <tr>
                            <th>Bought from</th>
                            <th>Carrier</th>
                            <th>Diamonds size</th>
                            <th>No. of pcs</th>
                            <th>Weight</th>
                            <th>Unit</th>
                            <th>Price per cts</th>
                            <th>Total cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterItem?.data.map((item: any, index: number) => (
                            <tr key={index}>
                              <td>
                                {item.diamonds_bought_from?.fullName || "-"}
                              </td>
                              <td>{item.diamonds_carrier?.fullName || "-"}</td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsSize || "-"}{" "}
                                  {item.diamondsSize
                                    ? item.diamondsSizeUnit === "avg_size"
                                      ? "ct."
                                      : "pcs/ct."
                                    : ""}
                                </span>
                              </td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsQuantity || "-"}
                                </span>
                              </td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsWeight
                                    ? `${item.diamondsWeight}`
                                    : "-"}
                                </span>
                              </td>
                              <td>{item.diamondsWeightUnit}</td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsPricePerCts
                                    ? `${numberFormat(
                                        item.diamondsPricePerCts
                                      )}`
                                    : "-"}
                                </span>
                              </td>
                              <td>
                                <span className="flex-end">
                                  {item.totalDiamondsCost
                                    ? ` ${numberFormat(item.totalDiamondsCost)}`
                                    : "-"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <></>
                    )}

                    {filterItem.diamondsStockType === "Single" ? (
                      <Table
                        responsive
                        className="print-table"
                        striped
                        bordered
                      >
                        <thead>
                          <tr>
                            <th>Bought from</th>
                            <th>Carrier</th>
                            <th>Weight</th>
                            <th>Unit</th>
                            <th>Price per cts</th>
                            <th>Total cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterItem?.data.map((item: any, index: number) => (
                            <tr key={index}>
                              <td>
                                {item.diamonds_bought_from?.fullName || "-"}
                              </td>
                              <td>{item.diamonds_carrier?.fullName || "-"}</td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsWeight
                                    ? `${item.diamondsWeight}`
                                    : "-"}
                                </span>
                              </td>
                              <td>{item.diamondsWeightUnit}</td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsPricePerCts
                                    ? `${numberFormat(
                                        item.diamondsPricePerCts
                                      )}`
                                    : "-"}
                                </span>
                              </td>
                              <td>
                                <span className="flex-end">
                                  {item.totalDiamondsCost
                                    ? ` ${numberFormat(item.totalDiamondsCost)}`
                                    : "-"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <></>
                    )}

                    {filterItem.diamondsStockType === "Pair" ? (
                      <Table
                        responsive
                        className="print-table"
                        striped
                        bordered
                      >
                        <thead>
                          <tr>
                            <th>Bought from</th>
                            <th>Carrier</th>
                            <th>Pcs 1 weight</th>
                            <th>Pcs 2 weight</th>
                            <th>Unit</th>
                            <th>Price per cts</th>
                            <th>Total cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterItem?.data.map((item: any, index: number) => (
                            <tr key={index}>
                              <td>
                                {item.diamonds_bought_from?.fullName || "-"}
                              </td>
                              <td>{item.diamonds_carrier?.fullName || "-"}</td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsPcsOneWeight
                                    ? `${item.diamondsPcsOneWeight}`
                                    : "-"}
                                </span>
                              </td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsPcsTwoWeight
                                    ? `${item.diamondsPcsTwoWeight}`
                                    : "-"}
                                </span>
                              </td>
                              <td>{item.diamondsWeightUnit}</td>
                              <td>
                                <span className="flex-end">
                                  {item.diamondsPricePerCts
                                    ? `${numberFormat(
                                        item.diamondsPricePerCts
                                      )}`
                                    : "-"}
                                </span>
                              </td>
                              <td>
                                <span className="flex-end">
                                  {item.totalDiamondsCost
                                    ? ` ${numberFormat(item.totalDiamondsCost)}`
                                    : "-"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default DetailPdf;
