import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  IconAdd,
  IconArrowLeftRight,
  IconDelete,
  IconEdit,
} from "../../../../components/widgets/Icons";
import {
  dateFormat2,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import {
  actionData,
  currencyRoute,
  dataService,
  deleteService,
  gemsRoute,
  getMethod,
  postMethod,
  responseService,
} from "../../../../services/api";
import { CustomInput, CustomModalService, NoData } from "../../../../components";

const Prices = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const price = product?.price;
  const weight = product?.stages[0]?.weight
  const gemsPcsOneWeight = product?.stages[0]?.pcsOneWeight
  const gemsPcsTwoWeight = product?.stages[0]?.pcsTwoWeight

  const [currencies, setCurrencies] = useState([]);
  const [formData, setFormData] = useState<any>({
    sharedInvestorsQty: null,
    interestFrom: "",
    interestRate: "",
    investedUSDRate: "",
    currency: "",
    buyingPrice: "",
    otherCost: "",
    paymentDueDate: "",
    sellingPrice: "",
    sellingPricePerCts: "",
    basedFormula: "rate",
  });

  const numberOfInventories: any = [];

  [...Array(10)].forEach((_: any, i: any) => {
    numberOfInventories.push({ label: i + 1, value: i + 1 });
  });

  const fields = [
    {
      id: "interestFrom",
      label: "Interest from",
      value: formData.interestFrom,
      hide: true,
    },
    {
      id: "interestRate",
      label: "Interest rate",
      value: formData.interestRate,
      hide: true,
    },
    {
      id: "investedUSDRate",
      label: "Invested USD rate",
      value: formData.investedUSDRate,
      inputType: "number",
      wantString: true,
    },
    {
      id: "currency",
      label: "Currency",
      value: formData.currency,
      type: "select",
      data: currencies,
      wantString: true,
    },
    {
      id: "sharedInvestorsQty",
      label: "Number of investors",
      value: formData.sharedInvestorsQty,
      type: "select",
      data: numberOfInventories,
    },
    {
      id: "otherCost",
      label: "Other cost",
      value: formData.otherCost,
      inputType: "number",
    },
    {
      id: "buyingPrice",
      label: "Buying price",
      value: formData.buyingPrice,
      inputType: "number",
      wantString: true,
    },
    {
      id: "paymentDueDate",
      label: "Payment due date",
      value: handleDateFormat(formData?.paymentDueDate, dateFormat2),
      type: "date",
    },
  ];

  useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, currencyRoute).then((result) => {
      const temp: any = [];
      result.data?.map((d: any) =>
        temp.push({
          label: d?.value,
          value: d?.value,
        })
      );
      setCurrencies(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = () => {
    setFormData({
      sharedInvestorsQty: price?.sharedInvestorsQty || "",
      interestFrom: price?.interestFrom || "",
      interestRate: price?.interestRate || "",
      investedUSDRate: price?.investedUSDRate || "",
      currency: price?.currency || "",
      buyingPrice: price?.buyingPrice || "",
      otherCost: price?.otherCost || "",
      paymentDueDate: price?.paymentDueDate || "",
      sellingPrice: price?.sellingPrice || "",
      sellingPricePerCts: price?.sellingPricePerCts || ""
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    setLoading(true);
    formData.sellingPrice = product?.stockType === 'Pair' && formData.sellingPricePerCts !== "" ? Math.floor(
      ((gemsPcsOneWeight
        ? parseFloat(gemsPcsOneWeight)
        : 0) +
        (gemsPcsTwoWeight
          ? parseFloat(gemsPcsTwoWeight)
          : 0)) *
      (formData.sellingPricePerCts)
    ) : formData.sellingPricePerCts !== "" ? Math.floor(
      (weight) * (formData.sellingPricePerCts)
    ) : formData.sellingPrice
    formData.sellingPricePerCts = formData?.sellingPricePerCts === '' ? null : formData?.sellingPricePerCts
    formData.paymentDueDate =
      formData.paymentDueDate &&
      new Date(formData.paymentDueDate).toISOString();
    dataService(
      method,
      formData,
      [],
      `${gemsRoute(
        method === postMethod ? product.id : product?.price?.id
      )}/price`
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleDelete = () => {
    deleteService(
      "prices",
      null,
      `gemstones/${product?.price?.id}/price`,
      handleResponse
    );
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const totalCost = [

    {
      label: "Buying price",
      value: price?.buyingPrice
        ? `${price?.currency} ${numberFormat(price?.buyingPrice)}`
        : "-",
    },
    {
      label: "Selling price per cts",
      value: price?.sellingPricePerCts
        ? `${price?.currency} ${numberFormat(price?.sellingPricePerCts)}`
        : "-",
    },
    {
      label: "Selling price",
      value: price?.sellingPrice
        ? `${price?.currency} ${numberFormat(price?.sellingPrice)}`
        : "-",
    },
    {
      label: "Payment due date",
      value: price?.paymentDueDate
        ? handleDateFormat(price?.paymentDueDate)
        : "-",
    },
  ];

  const prices = [
    // { label: "Interest from", value: price?.interestFrom || "-" },
    // { label: "Interest rate", value: price?.interestRate || "-" },
    {
      label: "Other cost",
      value: price?.otherCost
        ? `${price?.currency} ${numberFormat(price?.otherCost)}`
        : "-",
    },
    {
      label: "Invested rate (USD)",
      value: price?.investedUSDRate
        ? `${numberFormat(price?.investedUSDRate)}`
        : "-",
    },
    { label: "Currency", value: price?.currency || "-" },
    { label: "Number of investors", value: price?.sharedInvestorsQty || "-" },
  ];

  return (
    <section className="bg-card my-4">
      <section className="flex-between mb-4">
        <h6>Prices</h6>
        <div>
          <Button
            className="text-white bg-grad"
            disabled={Boolean(product?.childGemstones?.length)}
            onClick={showForm}
          >
            {product?.price?.id ? (
              <>
                <IconEdit /> <span className="ms-2">Edit prices</span>
              </>
            ) : (
              <>
                <IconAdd /> <span className="ms-2">Add prices</span>
              </>
            )}
          </Button>
          {product?.price?.id && (
            <Button
              className="text-white bg-danger ms-2"
              onClick={handleDelete}
            >
              <IconDelete /> <span className="ms-2">Delete prices</span>
            </Button>
          )}
        </div>
      </section>

      {product?.price?.id ? (
        <Row>
          <Col>
            <Table responsive striped className="mb-0">
              <tbody>
                {prices.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="ps-4 text-muted ">{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table responsive striped className="mb-0">
              <tbody>
                {totalCost.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="ps-4 text-muted ">{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <NoData noImage />
      )}

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={product?.price?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        lg
      >
        <Row className="align-items-center">
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"sellingPrircePerCts"}
                label={"Selling price per cts"}
                value={formData.sellingPricePerCts}
                handleChange={(e: any) => {
                  if (product?.stockType === "Pair") {
                    setFormData({
                      ...formData,
                      sellingPricePerCts: e.target.value,
                      sellingPrice: Math.floor(
                        ((gemsPcsOneWeight
                          ? parseFloat(gemsPcsOneWeight)
                          : 0) +
                          (gemsPcsTwoWeight
                            ? parseFloat(gemsPcsTwoWeight)
                            : 0)) *
                        (e.target.value || 0)
                      ),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      sellingPricePerCts: e.target.value,
                      sellingPrice: Math.floor(
                        (weight || 0) * (e.target.value || 0)
                      ),
                    });
                  }
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"sellingPrice"}
                label={"Selling Price"}
                value={formData.sellingPrice}
                handleChange={(e: any) => {
                  if (product?.stockType === "Pair") {
                    setFormData({
                      ...formData,
                      sellingPrice: e.target.value,
                      sellingPricePerCts: parseInt(
                        e.target.value === 0
                          ? formData.sellingPricePerCts
                          : (e.target.value || 0) /
                          ((gemsPcsOneWeight
                            ? parseFloat(gemsPcsOneWeight)
                            : 0) +
                            (gemsPcsTwoWeight
                              ? parseFloat(gemsPcsTwoWeight)
                              : 0))
                      ),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      sellingPrice: e.target.value,
                      sellingPricePerCts: parseInt(
                        e.target.value === 0
                          ? formData.sellingPricePerCts
                          : (e.target.value || 0) / (weight || 0)
                      ),
                    });
                  }
                }}
                inputType={"number"}
              />
            )}
          </Col>
          <Col>
            <span className="flex-center">
              <Button
                className="bg-grad rounded-circle flex-center shadow-sm align-items-center"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    basedFormula:
                      formData.basedFormula === "rate" ? "total" : "rate",
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </span>
          </Col>
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"sellingPrice"}
                disable
                label={"Selling Price"}
                value={formData.sellingPrice}
                handleChange={null}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"sellingPricePerCts"}
                label={"Selling price per cts"}
                value={formData.sellingPricePerCts}
                disable
                handleChange={null}
                inputType={"number"}
              />
            )}
          </Col>
        </Row>

      </CustomModalService>

    </section>
  );
};

export default Prices;
