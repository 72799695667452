import React from "react";
import { inventoryTypes } from "../../data";
import { Image, Table } from "react-bootstrap";
import { handleDateFormat, numberFormat } from "../../services";
import Logo from "../common/Logo";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const JewelleryInventoryDetailsPdf = React.forwardRef(
  (props: any, ref: any) => {
    let inventoryType = props.inventoryType;
    let data = props.data;
    let pageCurrent = props.pageCurrent;
    const paginate = 8;
    let pageTotal = Math.ceil(data.length / paginate);
    const pages: any = [];

    for (let i = 0; i < pageTotal; i++) {
      pages.push({ id: i });
    }

    const ReportLists = ({ current }: { current: number }) => {
      return (
        <>
          <div className="flex-between mb-2">
            <div className="d-flex align-items-center">
              <Logo />
              {/* <Image src="images/logo.png" width={50} /> */}
              <div className="ms-2">
                <h5 className="text-primary fw-bold mb-0">
                  {process.env.REACT_APP_APP_TITLE}
                </h5>
                <small className="text-primary ">
                  Natural Gems & Jewellery
                </small>
              </div>
            </div>
            <div>
              <small className="flex-end mb-1">
                Date : {handleDateFormat(new Date())}
              </small>
              <small>
                Jewellery inventory report (
                {
                  inventoryTypes.filter(
                    (item: any) => item.key === inventoryType
                  )[0]?.label
                }
                )
              </small>
            </div>
          </div>

          <Table responsive striped bordered className="mb-0 print-table">
            <thead className="bg-primary text-white">
              <tr>
                <th rowSpan={2}>No.</th>
                <th rowSpan={2}>Item</th>
                <th colSpan={4}>Cost</th>
                <th rowSpan={2}>All total cost</th>
                <th rowSpan={2}>Selling price</th>
                <th rowSpan={2}>Currency</th>
                <th rowSpan={2}>Created at</th>
              </tr>
              <tr>
                <th>Gems</th>
                <th>Diamond</th>
                <th>Gold</th>
                <th>Jewellery</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .slice(current * paginate, current * paginate + paginate)
                  .map((item: any, index: any) => (
                    <tr key={index}>
                      <td>
                        <span className="flex-center">
                          {index + 1 + pageCurrent * 80}
                        </span>{" "}
                      </td>
                      <td>
                        <span className="d-flex align-items-center">
                          <div className="thumbnail-list-image me-2">
                            {item?.images ? (
                              <Image
                                src={`${RES_API_URL}${item?.images?.url}`}
                                alt="no_found"
                              />
                            ) : (
                              <Image src={"images/no-image.png"} />
                            )}
                          </div>
                          <div>
                            <div>{item?.itemName}</div>
                            <small className="text-muted my-2 d-block">
                              {item?.sku}
                            </small>
                          </div>
                        </span>
                      </td>
                      <td>
                        <span className="flex-end">
                          {item?.cost?.gemstones
                            ? `${numberFormat(item?.cost?.gemstones)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="flex-end">
                          {item?.cost?.diamonds
                            ? `${numberFormat(item?.cost?.diamonds)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="flex-end">
                          {item?.cost?.gold
                            ? `${numberFormat(item?.cost?.gold)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="flex-end">
                          {item?.cost?.jewellery
                            ? `${numberFormat(item?.cost?.jewellery)}`
                            : "-"}
                        </span>
                      </td>
                      {/* <td><span className="flex-end">{item?.gold ? `${item?.gold?.totalGoldWeight} ${item?.gold?.goldSmithLossNote ? `, ${item?.gold?.goldSmithLossNote}` : ""}` : '-'}</span></td> */}
                      <td>
                        <span className="flex-end">
                          {item?.cost?.total
                            ? `${numberFormat(item?.cost?.total)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="flex-end">
                          {item?.price?.sellingPrice
                            ? `${numberFormat(item?.price?.sellingPrice)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="flex-start">
                          {item?.currency || "MMK"}
                        </span>
                      </td>
                      <td>
                        <span className="flex-center">
                          {handleDateFormat(item?.createdAt)}
                        </span>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </>
      );
    };

    return (
      <section ref={ref} className="print-container">
        {pages.map((item: number, index: number) => (
          <div key={index}>
            <ReportLists current={index} />
            {item !== pages.length - 1 && <footer />}
          </div>
        ))}
      </section>
    );
  }
);

export default JewelleryInventoryDetailsPdf;
