import React from "react";
import { inventoryTypes } from "../../data";
import { Badge, Table } from "react-bootstrap";
import {
  handleDateFormat,
  netPrice,
  numberFormat,
  totalAmount,
} from "../../services";
import Logo from "../common/Logo";
// const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const GemSaleDetailsPdf = React.forwardRef((props: any, ref: any) => {
  let inventoryType = props.inventoryType;
  let data = props.data;
  // let pageCurrent = props.pageCurrent
  const paginate = 8;
  let pageTotal = Math.ceil(data.length / paginate);
  const pages: any = [];

  for (let i = 0; i < pageTotal; i++) {
    pages.push({ id: i });
  }

  const ReportLists = ({ current }: { current: number }) => {
    return (
      <>
        <div className="flex-between mb-2">
          <div className="d-flex align-items-center">
            {/* <Image src="images/logo.png" width={50} /> */}
            <Logo />
            <div className="ms-2">
              <h5 className="text-primary fw-bold mb-0">
                {process.env.REACT_APP_APP_TITLE}
              </h5>
              <small className="text-primary ">Natural Gems & Jewellery</small>
            </div>
          </div>
          <div>
            <small className="flex-end mb-1">
              Date : {handleDateFormat(new Date())}
            </small>
            <small>
              Calibrated gem sale report (
              {
                inventoryTypes.filter(
                  (item: any) => item.key === inventoryType
                )[0]?.label
              }
              )
            </small>
          </div>
        </div>

        <Table responsive striped bordered className="mb-0 print-table">
          <thead className="bg-primary text-white">
            <tr>
              {/* <th>No.</th> */}
              <th>Invoice no.</th>
              <th>Customer name</th>
              <th>Amount</th>
              <th>Payment</th>
              <th>Selling date</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                ?.slice(current * paginate, current * paginate + paginate)
                ?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.invoiceNumber}</td>
                    <td>{item?.cali_gems_buyer?.fullName}</td>
                    <td>
                      <span className="flex-end">
                        {item?.sale_cali_gemstone_items?.[0]?.currency}{" "}
                        {numberFormat(
                          totalAmount(
                            netPrice(
                              item?.sale_cali_gemstone_items
                            )?.toString(),
                            item
                          )
                        )}
                      </span>
                    </td>
                    <td className="text-center">
                      <Badge bg="success">{item?.paymentStatus}</Badge>
                    </td>
                    <td>
                      {item?.createdAt
                        ? handleDateFormat(item?.createdAt)
                        : "-"}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <section ref={ref} className="print-container">
      {pages.map((item: number, index: number) => (
        <div key={index}>
          <ReportLists current={index} />
          {item !== pages.length - 1 && <footer />}
        </div>
      ))}
    </section>
  );
});

export default GemSaleDetailsPdf;
