import React, { useContext, useEffect, useState } from "react";
import { Lang, Layout } from "../../../components";
import { PageContext } from "../../../context/Page";
import {
  actionData,
  customRouteTwo,
  gemstonesDetailRoute,
  gemstonesFilterRoute,
  gemstonesRoute,
  getDataService,
  limit,
  postMethod,
} from "../../../services/api";
import { Count, Data, Header } from "./cpn";
import { getStoreUser } from "../../../services";

const Index = () => {
  const [countData, setCountData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = React.useState("");
  const [type, setType] = React.useState("all");
  const [inventoryType, setInventoryType] = React.useState("shop_inventory");
  const [page, setPage] = useContext(PageContext);
  const handleGetDataByFilter = (skip: number, _formData: any) => {
    setLoading(true);
    actionData(postMethod, `${gemstonesFilterRoute}/${page.current}/${limit}`, _formData).then(
      (result) => {
        setData(result.data);
        setPage({
          current: skip,
          total: result.count,
          sorting: _formData.orderBy,
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    );
  };


  const handleGetCountData = (skip: number) => {
    getDataService(
      gemstonesDetailRoute,
      setLoading,
      setCountData,
      setPage,
      skip
    );
  };

  const handleGetData = (
    skip: number,
    type: any,
    inventoryType: any,
    keyword: any
  ) => {
    getDataService(
      customRouteTwo(
        gemstonesRoute,
        skip,
        type,
        keyword,
        getStoreUser()?.userId,
        inventoryType
      ),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  useEffect(() => {
    handleGetCountData(page.current);
    handleGetData(page.current, type, inventoryType, "");
    // eslint-disable-next-line
  }, []);

  const handleResponse = () => {
    handleGetCountData(page.current);
    handleGetData(page.current, type, inventoryType, "");
  };

  return (
    <Layout title={<Lang label="gems" />}>
      <Count data={countData} />
      <Header
        handleGetDataByFilter={handleGetDataByFilter}
        type={type}
        inventoryType={inventoryType}
        keyword={keyword}
        handleSetKeyword={setKeyword}
        handleSetType={setType}
        handleSetInventoryType={setInventoryType}
        handleGetData={(v: any, inv: any, k: any) =>
          handleGetData(page.current, v, inv, k)
        }
      />
      <Data
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={(page: any) =>
          handleGetData(page, type, inventoryType, keyword)
        }
        handleResponse={handleResponse}
      />
    </Layout>
  );
};

export default Index;
