import React from "react";

const GemLabelPdf = React.forwardRef((props: any, ref: any) => {
  return (
    <section ref={ref} className="print-labels-sticker mt-3">
      {props.items &&
        props.items?.map((item: any, index: number) => (
          <div className="label-card-container ps-3" key={index}>
            <div className="d-flex flex-column justify-content-start">
              <h6 className="card-label">{item?.sku}</h6>
              <h6 className="card-label">
                {item?.stoneType}{" "}
                {item?.stockType ? `(${item?.stockType})` : ""}
              </h6>
              {/* <h6 className="card-label">
                               
                            </h6> */}
              {item?.stages?.length > 0 ? (
                <h6 className="card-label">
                  {item?.stages?.[item?.stages?.length - 1]?.dimension &&
                    `${
                      item?.stages?.[item?.stages?.length - 1]?.dimension
                    } `}{" "}
                  {item?.stockType === "Lots" && (
                    <>
                      {item?.stages?.[item?.stages?.length - 1]?.numberOfPcs &&
                        `${
                          item?.stages?.[item?.stages?.length - 1]?.numberOfPcs
                        } Pcs ,`}
                    </>
                  )}
                  {item?.stockType === "Pair" ? (
                    <>
                      {`${
                        item?.stages?.[item?.stages?.length - 1]?.pcsOneWeight
                      } ${
                        item?.stages?.[item?.stages?.length - 1]?.weightUnit
                      }, ${
                        item?.stages?.[item?.stages?.length - 1]?.pcsOneWeight
                      } ${
                        item?.stages?.[item?.stages?.length - 1]?.weightUnit
                      }`}
                    </>
                  ) : (
                    <>
                      {item?.stages?.[item?.stages?.length - 1]?.weight &&
                        `${item?.stages?.[item?.stages?.length - 1]?.weight} ${
                          item?.stages?.[item?.stages?.length - 1]?.weightUnit
                        }`}
                    </>
                  )}
                </h6>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
    </section>
  );
});

export default GemLabelPdf;
