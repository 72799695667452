import { Button, Col, Form, FormCheck, Row, Table } from "react-bootstrap";
import {
  IconAdd,
  IconArrowLeftRight,
} from "../../../../components/widgets/Icons";
import {
  CustomInput,
  CustomModal,
  CustomSelectInputGroup,
  FormFieldService,
  NoData,
  TableRowButtons,
} from "../../../../components";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../context/Lang";
import {
  groupByGemstones,
  handleClose,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import {
  actionData,
  gemsStoneTypeRoute,
  gemsWeightUnitRoute,
  getMethod,
  responseService,
  deleteService,
  gemsAvgSizesRoute,
  dataService,
  postMethod,
  jewelleryRoute,
  getPersons,
} from "../../../../services/api";

const Gemstones = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [persons, setPersons] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gemsSizesByPcs, setGemsSizesByPcs] = useState([]);
  const [gemsSizes, setGemsSizes] = useState([]);
  const [gemsStoneTypes, setGemsStoneTypes] = useState([]);
  const [weightUnits, setWeigthUnits] = useState([]);
  const productFilterItems =
    product && product?.gemstones && product?.gemstones.length > 0
      ? groupByGemstones(product?.gemstones)
      : [];

  const [formData, setFormData] = useState<any>({
    gemstonesId: "",
    gemsStoneType: "",
    gemsBoughtFrom: null,
    gemsBoughtFromDefaultValue: "",
    gemsCarrier: null,
    gemsCarrierDefaultValue: "",
    gemsStockType: "Lot",
    gemsQuantity: null,
    gemsWeight: "",
    gemsPcsOneWeight: "",
    gemsPcsTwoWeight: "",
    gemsWeightUnit: "carat",
    gemsSize: null,
    gemsSizeUnit: "pcs_per_cts",
    gemsPricePerCts: "",
    totalGemsCost: "",
    basedFormula: "rate",
  });
  const stockTypes = [{ label: "Lot" }, { label: "Single" }, { label: "Pair" }];

  const required = ["gemsStoneType"];

  const fields = [
    {
      id: "gemsStoneType",
      label: "Gemstone type *",
      value: formData.gemsStoneType,
      type: "select",
      data: gemsStoneTypes,
      wantString: true,
    },
    {
      id: "gemsBoughtFrom",
      label: "Bought from",
      value: formData.gemsBoughtFrom,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.gemsBoughtFromDefaultValue,
    },
    {
      id: "gemsCarrier",
      label: "Carrier",
      value: formData.gemsCarrier,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.gemsCarrierDefaultValue,
    },
    {
      id: "gemsStockType",
      label: "Stock type",
      value: formData.gemsStockType,
      type: "select",
      data: stockTypes,
      wantString: true,
      disableInititalSelect: true,
    },
  ];

  useEffect(() => {
    handleGetGemstones();
    // eslint-disable-next-line
  }, []);

  const handleGetGemstones = async () => {
    getPersonsService("");
    actionData(getMethod, gemsAvgSizesRoute).then((result) => {
      let sizes: any = [];
      let sizesByPcs: any = [];
      result.data.forEach((item: any) => {
        if (item.unit === "pcs_per_cts") {
          sizesByPcs.push(item);
        } else {
          sizes.push(item);
        }
      });
      setGemsSizesByPcs(sizesByPcs);
      setGemsSizes(sizes);
    });
    actionData(getMethod, gemsStoneTypeRoute).then((result) => {
      setGemsStoneTypes(result.data);
    });
    actionData(getMethod, gemsWeightUnitRoute).then((result) => {
      setWeigthUnits(result.data);
    });
  };

  const getPersonsService = async (keyword?: string) => {
    actionData(getMethod, getPersons(0, keyword)).then((result) => {
      let temp: any = [];

      result.data.forEach((data: any) => {
        temp.push({
          value: data.id,
          label: `${data.fullName} (${data.phone})`,
          fullName: data.fullName,
          phone: data.phone,
          phoneOther: data.phoneOther,
          email: data.email,
          address: data.address,
        });
      });

      setPersons(temp);
    });
  };

  const handleInputChange = (newValue: string) => {
    getPersonsService(newValue);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    if (evt.target.id === "gemsStockType") {
      if (evt.target.value === "Lot") {
        setFormData({
          ...formData,
          gemsStockType: evt.target.value,
          gemsQuantity: null,
          gemsSize: null,
          gemsWeight: "",
          gemsPcsOneWeight: "",
          gemsPcsTwoWeight: "",
        });
      } else if (evt.target.value === "Single") {
        setFormData({
          ...formData,
          gemsStockType: evt.target.value,
          gemsQuantity: 1,
          gemsSize: null,
          gemsWeight: "",
          gemsPcsOneWeight: "",
          gemsPcsTwoWeight: "",
        });
      } else {
        setFormData({
          ...formData,
          gemsStockType: evt.target.value,
          gemsQuantity: null,
          gemsSize: null,
          gemsWeight: "",
          gemsPcsOneWeight: "",
          gemsPcsTwoWeight: "",
        });
      }
    } else {
      inputChangeService(evt, formData, setFormData, wantString);
    }
  };

  const showForm = (item?: any) => {
    setFormData({
      gemstonesId: item?.id || "",
      gemsStoneType: item?.gemsStoneType || "",
      gemsBoughtFrom: item?.gems_bought_from?.id || null,
      gemsBoughtFromDefaultValue: item?.gems_bought_from?.fullName || "",
      gemsCarrier: item?.gems_carrier?.id || null,
      gemsCarrierDefaultValue: item?.gems_carrier?.fullName || "",
      gemsStockType: item?.gemsStockType || "Lot",
      gemsQuantity: item?.gemsQuantity || null,
      gemsWeight: item?.gemsWeight || "",
      gemsPcsOneWeight: item?.gemsPcsOneWeight || "",
      gemsPcsTwoWeight: item?.gemsPcsTwoWeight || "",
      gemsWeightUnit: item?.gemsWeightUnit || "carat",
      gemsSize: item?.gemsSize || null,
      gemsSizeUnit: item?.gemsSizeUnit || "pcs_per_cts",
      gemsPricePerCts: item?.gemsPricePerCts || "",
      totalGemsCost: item?.totalGemsCost || "",
      basedFormula: item?.basedFormula || "rate",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    let postRoute = `${jewelleryRoute(product.id)}/gemstones`;
    let putRoute = `jewellery/gemstones/${formData.gemstonesId}`;

    setLoading(true);
    dataService(
      method,
      formData,
      required,
      method === postMethod ? postRoute : putRoute
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.gemsStoneType,
      null,
      `jewellery/gemstones/${item?.id}`,
      handleResponse
    );
  };

  return (
    <Col md={12}>
      <section className="bg-card mb-4">
        <section className="flex-between mb-3">
          <h6 className="fw-bold">Gemstones</h6>
          <Button className="text-white bg-grad" onClick={showForm}>
            <IconAdd /> <span className="ms-2">{translate("createNew")}</span>
          </Button>
        </section>

        {product && product?.gemstones && product?.gemstones.length > 0 ? (
          <>
            {productFilterItems.length > 0 &&
              productFilterItems.map((filterItem: any, index: number) => (
                <div key={index}>
                  <h6 className="text-muted mb-3 mt-4">
                    {filterItem.gemsStockType}
                  </h6>
                  {filterItem.gemsStockType === "Lot" ? (
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Gems stone type</th>
                          <th>Bought from</th>
                          <th>Carrier</th>
                          <th className="text-warning">Avg size</th>
                          <th>Gems size</th>
                          <th>No. of pcs</th>
                          <th>Weight</th>
                          <th>Unit</th>
                          <th>Price per cts</th>
                          <th>Total cost</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filterItem?.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.gemsStoneType || "-"}</td>
                            <td>{item.gems_bought_from?.fullName || "-"}</td>
                            <td>{item.gems_carrier?.fullName || "-"}</td>
                            <td>
                              <span className="flex-center text-warning">
                                {item.gemsAvgSize || "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.gemsSize || "-"}{" "}
                                {item.gemsSize
                                  ? item.gemsSizeUnit === "avg_size"
                                    ? "ct."
                                    : "pcs/ct."
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.gemsQuantity || "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.gemsWeight ? `${item.gemsWeight}` : "-"}
                              </span>
                            </td>
                            <td>{item.gemsWeightUnit}</td>
                            <td>
                              <span className="flex-end">
                                {item.gemsPricePerCts
                                  ? `${numberFormat(item.gemsPricePerCts)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.totalGemsCost
                                  ? ` ${numberFormat(item.totalGemsCost)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <TableRowButtons
                                item={item}
                                showForm={showForm}
                                handleDelete={handleDelete}
                                flex="flex-center"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}

                  {filterItem.gemsStockType === "Single" ? (
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Gems stone type</th>
                          <th>Bought from</th>
                          <th>Carrier</th>
                          <th>Weight</th>
                          <th>Unit</th>
                          <th>Price per cts</th>
                          <th>Total cost</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filterItem?.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.gemsStoneType || "-"}</td>
                            <td>{item.gems_bought_from?.fullName || "-"}</td>
                            <td>{item.gems_carrier?.fullName || "-"}</td>
                            <td>
                              <span className="flex-end">
                                {item.gemsWeight ? `${item.gemsWeight}` : "-"}
                              </span>
                            </td>
                            <td>{item.gemsWeightUnit}</td>
                            <td>
                              <span className="flex-end">
                                {item.gemsPricePerCts
                                  ? `${numberFormat(item.gemsPricePerCts)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.totalGemsCost
                                  ? ` ${numberFormat(item.totalGemsCost)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <TableRowButtons
                                item={item}
                                showForm={showForm}
                                handleDelete={handleDelete}
                                flex="flex-center"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}

                  {filterItem.gemsStockType === "Pair" ? (
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Gems stone type</th>
                          <th>Bought from</th>
                          <th>Carrier</th>
                          <th>Pcs 1 weight</th>
                          <th>Pcs 2 weight</th>
                          <th>Unit</th>
                          <th>Price per cts</th>
                          <th>Total cost</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filterItem?.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.gemsStoneType || "-"}</td>
                            <td>{item.gems_bought_from?.fullName || "-"}</td>
                            <td>{item.gems_carrier?.fullName || "-"}</td>
                            <td>
                              <span className="flex-end">
                                {item.gemsPcsOneWeight
                                  ? `${item.gemsPcsOneWeight}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.gemsPcsTwoWeight
                                  ? `${item.gemsPcsTwoWeight}`
                                  : "-"}
                              </span>
                            </td>
                            <td>{item.gemsWeightUnit}</td>
                            <td>
                              <span className="flex-end">
                                {item.gemsPricePerCts
                                  ? `${numberFormat(item.gemsPricePerCts)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.totalGemsCost
                                  ? ` ${numberFormat(item.totalGemsCost)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <TableRowButtons
                                item={item}
                                showForm={showForm}
                                handleDelete={handleDelete}
                                flex="flex-center"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
          </>
        ) : (
          <NoData noImage />
        )}
      </section>

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.gemstonesId}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          formData={formData}
          setFormData={setFormData}
        />
        {/* parseFloat(((inStock / total) * 100).toFixed(2).replace(/\.?0+$/, '')) */}
        {formData.gemsStockType === "Lot" && (
          <Row>
            <Col>
              <Form.Group className="input-form-control mb-4">
                <section className="flex-between">
                  <Form.Label>
                    {formData.gemsSizeUnit === "avg_size"
                      ? "Gems size"
                      : "Pcs per cts"}
                  </Form.Label>
                  <FormCheck
                    id="switch"
                    className="switch"
                    type="switch"
                    label={"Gems size"}
                    checked={formData.gemsSizeUnit === "avg_size"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        gemsSizeUnit:
                          formData.gemsSizeUnit === "avg_size"
                            ? "pcs_per_cts"
                            : "avg_size",
                      })
                    }
                  />
                </section>
                <Form.Control
                  as="select"
                  aria-label="Select"
                  id={"gemsSize"}
                  onChange={(e: any) => {
                    if (formData.gemsSizeUnit === "avg_size") {
                      let resultGemsWeight =
                        e.target.value && formData.gemsQuantity
                          ? parseFloat(
                              (e.target.value * formData.gemsQuantity)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                          : formData.gemsWeight;
                      setFormData({
                        ...formData,
                        gemsSize: e.target.value && parseFloat(e.target.value),
                        gemsWeight: resultGemsWeight,
                        totalGemsCost:
                          formData.basedFormula === "rate"
                            ? (resultGemsWeight
                                ? parseFloat(resultGemsWeight)
                                : 0) *
                              (formData.gemsPricePerCts
                                ? parseInt(formData.gemsPricePerCts)
                                : 0)
                            : formData.totalGemsCost,
                        gemsPricePerCts:
                          formData.basedFormula === "total"
                            ? parseInt(
                                resultGemsWeight === 0
                                  ? formData.gemsPricePerCts
                                  : (formData.totalGemsCost || 0) /
                                      (resultGemsWeight
                                        ? parseFloat(resultGemsWeight)
                                        : 0)
                              )
                            : formData.gemsPricePerCts,
                      });
                    } else {
                      let resultGemsWeight =
                        e.target.value && formData.gemsQuantity
                          ? parseFloat(
                              ((1 / e.target.value) * formData.gemsQuantity)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                          : formData.gemsWeight;
                      setFormData({
                        ...formData,
                        gemsSize: e.target.value && parseFloat(e.target.value),
                        gemsWeight: resultGemsWeight,
                        totalGemsCost:
                          formData.basedFormula === "rate"
                            ? (resultGemsWeight
                                ? parseFloat(resultGemsWeight)
                                : 0) *
                              (formData.gemsPricePerCts
                                ? parseInt(formData.gemsPricePerCts)
                                : 0)
                            : formData.totalGemsCost,
                        gemsPricePerCts:
                          formData.basedFormula === "total"
                            ? parseInt(
                                resultGemsWeight === 0
                                  ? formData.gemsPricePerCts
                                  : (formData.totalGemsCost || 0) /
                                      (resultGemsWeight
                                        ? parseFloat(resultGemsWeight)
                                        : 0)
                              )
                            : formData.gemsPricePerCts,
                      });
                    }
                  }}
                  value={formData.gemsSize}
                >
                  <option value="">Select</option>
                  {formData.gemsSizeUnit === "avg_size" ? (
                    <>
                      {gemsSizes.map((item: any, index: number) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      {gemsSizesByPcs.map((item: any, index: number) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <CustomInput
                id={"gemsQuantity"}
                label={"Number of pcs"}
                value={formData.gemsQuantity}
                handleChange={(e: any) => {
                  if (formData.gemsSizeUnit === "avg_size") {
                    let resultGemsWeight =
                      e.target.value && formData.gemsSize
                        ? parseFloat(
                            (formData.gemsSize * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                        : formData.gemsWeight;
                    setFormData({
                      ...formData,
                      gemsQuantity: e.target.value && parseInt(e.target.value),
                      gemsWeight: resultGemsWeight,
                      totalGemsCost:
                        formData.basedFormula === "rate"
                          ? (resultGemsWeight
                              ? parseFloat(resultGemsWeight)
                              : 0) *
                            (formData.gemsPricePerCts
                              ? parseInt(formData.gemsPricePerCts)
                              : 0)
                          : formData.totalGemsCost,
                      gemsPricePerCts:
                        formData.basedFormula === "total"
                          ? parseInt(
                              resultGemsWeight === 0
                                ? formData.gemsPricePerCts
                                : (formData.totalGemsCost || 0) /
                                    (resultGemsWeight
                                      ? parseFloat(resultGemsWeight)
                                      : 0)
                            )
                          : formData.gemsPricePerCts,
                    });
                  } else {
                    let resultGemsWeight =
                      e.target.value && formData.gemsSize
                        ? parseFloat(
                            ((1 / formData.gemsSize) * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                        : formData.gemsWeight;
                    setFormData({
                      ...formData,
                      gemsQuantity: e.target.value && parseInt(e.target.value),
                      gemsWeight: resultGemsWeight,
                      totalGemsCost:
                        formData.basedFormula === "rate"
                          ? (resultGemsWeight
                              ? parseFloat(resultGemsWeight)
                              : 0) *
                            (formData.gemsPricePerCts
                              ? parseInt(formData.gemsPricePerCts)
                              : 0)
                          : formData.totalGemsCost,
                      gemsPricePerCts:
                        formData.basedFormula === "total"
                          ? parseInt(
                              resultGemsWeight === 0
                                ? formData.gemsPricePerCts
                                : (formData.totalGemsCost || 0) /
                                    (resultGemsWeight
                                      ? parseFloat(resultGemsWeight)
                                      : 0)
                            )
                          : formData.gemsPricePerCts,
                    });
                  }
                }}
                inputType={"number"}
              />
            </Col>
          </Row>
        )}

        {formData.gemsStockType !== "Pair" && (
          <CustomSelectInputGroup
            label={"Gemstone weight *"}
            id={"gemsWeight"}
            secondId={"gemsWeightUnit"}
            value={formData.gemsWeight}
            secondValue={formData.gemsWeightUnit}
            handleChange={(e: any) =>
              setFormData({
                ...formData,
                gemsWeight: e.target.value,
                totalGemsCost:
                  formData.basedFormula === "rate"
                    ? Math.floor(
                        (e.target.value ? parseFloat(e.target.value) : 0) *
                          (formData.gemsPricePerCts
                            ? parseInt(formData.gemsPricePerCts)
                            : 0)
                      )
                    : formData.totalGemsCost,
                gemsPricePerCts:
                  formData.basedFormula === "total"
                    ? parseInt(
                        e.target.value === 0
                          ? formData.gemsPricePerCts
                          : (formData.totalGemsCost || 0) /
                              (e.target.value ? parseFloat(e.target.value) : 0)
                      )
                    : formData.gemsPricePerCts,
              })
            }
            // handleChange={(e: any) => setFormData({ ...formData, gemsWeight: e.target.value, gemsPricePerCts: parseInt(e.target.value === 0 ? formData.gemsPricePerCts : (formData.totalGemsCost || 0) / (e.target.value ? parseFloat(e.target.value) : 0)), totalGemsCost: (e.target.value ? parseFloat(e.target.value) : 0) * (formData.gemsPricePerCts || 0) })}
            secondHandleChange={handleChange}
            inputType={"select-group"}
            wantString={true}
            data={weightUnits}
          />
        )}

        {formData.gemsStockType === "Pair" && (
          <Row>
            <Col>
              <CustomSelectInputGroup
                label={"Pcs 1 weight *"}
                id={"gemsPcsOneWeight"}
                secondId={"gemsWeightUnit"}
                value={formData.gemsPcsOneWeight}
                secondValue={formData.gemsWeightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    gemsPcsOneWeight: e.target.value,
                    totalGemsCost:
                      formData.basedFormula === "rate"
                        ? Math.floor(
                            ((e.target.value ? parseFloat(e.target.value) : 0) +
                              (formData.gemsPcsTwoWeight
                                ? parseFloat(formData.gemsPcsTwoWeight)
                                : 0)) *
                              (formData.gemsPricePerCts
                                ? parseInt(formData.gemsPricePerCts)
                                : 0)
                          )
                        : formData.totalGemsCost,
                    gemsPricePerCts:
                      formData.basedFormula === "total"
                        ? parseInt(
                            e.target.value === 0
                              ? formData.gemsPricePerCts
                              : (formData.totalGemsCost || 0) /
                                  (e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0)
                          ) +
                          (formData.gemsPcsTwoWeight
                            ? parseFloat(formData.gemsPcsTwoWeight)
                            : 0)
                        : formData.gemsPricePerCts,
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
            <Col>
              <CustomSelectInputGroup
                label={"Pcs 2 weight *"}
                id={"gemsPcsTwoWeight"}
                secondId={"gemsWeightUnit"}
                value={formData.gemsPcsTwoWeight}
                secondValue={formData.gemsWeightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    gemsPcsTwoWeight: e.target.value,
                    totalGemsCost:
                      formData.basedFormula === "rate"
                        ? Math.floor(
                            ((e.target.value ? parseFloat(e.target.value) : 0) +
                              (formData.gemsPcsOneWeight
                                ? parseFloat(formData.gemsPcsOneWeight)
                                : 0)) *
                              (formData.gemsPricePerCts
                                ? parseInt(formData.gemsPricePerCts)
                                : 0)
                          )
                        : formData.totalGemsCost,
                    gemsPricePerCts:
                      formData.basedFormula === "total"
                        ? parseInt(
                            e.target.value === 0
                              ? formData.gemsPricePerCts
                              : (formData.totalGemsCost || 0) /
                                  (e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0)
                          ) +
                          (formData.gemsPcsOneWeight
                            ? parseFloat(formData.gemsPcsOneWeight)
                            : 0)
                        : formData.gemsPricePerCts,
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
          </Row>
        )}

        <Row className="align-items-center">
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"gemsPricePerCts"}
                label={"Gems price per cts"}
                value={formData.gemsPricePerCts}
                handleChange={(e: any) => {
                  if (formData.gemsStockType === "Pair") {
                    setFormData({
                      ...formData,
                      gemsPricePerCts: e.target.value,
                      totalGemsCost: Math.floor(
                        ((formData.gemsPcsOneWeight
                          ? parseFloat(formData.gemsPcsOneWeight)
                          : 0) +
                          (formData.gemsPcsTwoWeight
                            ? parseFloat(formData.gemsPcsTwoWeight)
                            : 0)) *
                          (e.target.value || 0)
                      ),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      gemsPricePerCts: e.target.value,
                      totalGemsCost: Math.floor(
                        (formData.gemsWeight || 0) * (e.target.value || 0)
                      ),
                    });
                  }
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"totalGemsCost"}
                label={"Total gems cost"}
                value={formData.totalGemsCost}
                handleChange={(e: any) => {
                  if (formData.gemsStockType === "Pair") {
                    setFormData({
                      ...formData,
                      totalGemsCost: e.target.value,
                      gemsPricePerCts: parseInt(
                        e.target.value === 0
                          ? formData.gemsPricePerCts
                          : (e.target.value || 0) /
                              ((formData.gemsPcsOneWeight
                                ? parseFloat(formData.gemsPcsOneWeight)
                                : 0) +
                                (formData.gemsPcsTwoWeight
                                  ? parseFloat(formData.gemsPcsTwoWeight)
                                  : 0))
                      ),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      totalGemsCost: e.target.value,
                      gemsPricePerCts: parseInt(
                        e.target.value === 0
                          ? formData.gemsPricePerCts
                          : (e.target.value || 0) / (formData.gemsWeight || 0)
                      ),
                    });
                  }
                }}
                inputType={"number"}
              />
            )}
          </Col>
          <Col>
            <span className="flex-center">
              <Button
                className="bg-grad rounded-circle flex-center shadow-sm align-items-center"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    basedFormula:
                      formData.basedFormula === "rate" ? "total" : "rate",
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </span>
          </Col>
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"totalGemsCost"}
                disable
                label={"Total gems cost"}
                value={formData.totalGemsCost}
                handleChange={null}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"gemsPricePerCts"}
                label={"Gems price per cts"}
                value={formData.gemsPricePerCts}
                disable
                handleChange={null}
                inputType={"number"}
              />
            )}
          </Col>
        </Row>
      </CustomModal>
    </Col>
  );
};

export default Gemstones;
