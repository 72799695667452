import React, { useContext } from "react";
import { Col, Table } from "react-bootstrap";
import { QRView, Thumbsnail } from "../../../../components";
import { LangContext } from "../../../../context";
import {
  getDiscountAmountByPercentage,
  handleDateFormat,
  numberFormat,
  totalAmount,
} from "../../../../services";
import InvoiceHeader from "../../../../components/pdf/InvoiceHeader";

const Invoice = ({
  data,
  showDataCheck,
}: {
  data: any;
  showDataCheck: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  const totalGemsWeight = () => {
    let total = 0;
    if (data?.jewellery?.gemstones && data?.jewellery?.gemstones?.length > 0) {
      data?.jewellery?.gemstones.forEach((item: any) => {
        if (item.gemsStockType === "Lot" && showDataCheck.showGemsLotWeight) {
          total += parseFloat(item.gemsWeight);
        }
        if (item.gemsStockType === "Pair" && showDataCheck.showGemsPairWeight) {
          total +=
            parseFloat(item.gemsPcsOneWeight) +
            parseFloat(item.gemsPcsTwoWeight);
        }
        if (
          item.gemsStockType === "Single" &&
          showDataCheck.showGemsSingleWeight
        ) {
          total += parseFloat(item.gemsWeight);
        }
      });
    }

    return total.toFixed(3).replace(/\.?0+$/, "");
  };

  return (
    <div className="bg-card">
      {/* <section className="text-center mb-4 bg-black">
        <img
          src="images/mr.spinel.png"
          className="p-5 pb-4"
          alt="no_found"
          width={300}
        />
        <span className="d-block pb-4 text-white-50">
          <small>78 street, Bet; 34 x 35 street, Mandalay | </small>{" "}
          <small>Ph : 09-43200898 , 09-799075549 , 09-425291390</small>
        </span>
      </section> */}
      <InvoiceHeader />

      <div className="flex-between align-items-center">
        <div style={{ width: "50%" }}>
          <span className="d-block mb-2">
            <small className="text-muted">Customer</small> :{" "}
            <small>{data?.buyer?.fullName || "-"}</small>
          </span>
          <span className="d-block mb-2">
            <small className="text-muted">Phone</small> :{" "}
            <small>{data?.buyer?.phone || "-"}</small>
          </span>
          {data?.buyer?.address && (
            <span className="d-block mb-2">
              <small className="text-muted">{translate("address")}</small> :{" "}
              <small>{data?.buyer?.address || "-"} </small>
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          <QRView text={data?.invoiceNumber} width={50} />
          <div className="ms-3 d-flex flex-column align-items-end">
            <span className="d-block mb-2 mt-1">
              <small>{data?.invoiceNumber}</small>
            </span>
            <span className="d-block mb-2">
              <small className="text-muted">{translate("date")}</small> :{" "}
              <small>{handleDateFormat(data?.createdAt)}</small>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <h6 className="fw-bold border text-center p-2">SALE INVOICE</h6>
      </div>

      <Table responsive className="mt-3" bordered striped>
        <thead>
          <tr>
            <th>Item</th>
            <th>Weight (grams)</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {data?.jewellery?.itemName}{" "}
              {data?.jewellery?.gold?.materialPurity &&
                `(${data?.jewellery?.gold?.materialPurity})`}
            </td>
            <td>
              <span className="d-flex justify-content-center">
                {data?.jewellery?.jewelleryWeight}
              </span>
            </td>
            <td>
              <span className={data?.isGift ? "flex-center" : "flex-end"}>
                {data?.isGift ? "Gift item" : numberFormat(data?.netPrice)}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <div>
            <div className="receit-item-image flex-center">
              <Thumbsnail images={data?.jewellery?.images} />
            </div>
            <small className="xxs-label text-muted d-block mt-2 text-center">
              {data?.jewellery?.sku}
            </small>
          </div>
          <div className="ms-3">
            {totalGemsWeight() !== "0" ? (
              <small className="d-block mb-2">
                <span className="text-muted">Gems weight : </span>
                {totalGemsWeight()}{" "}
                {data?.jewellery?.weight?.totalGemsWeightUnit}
              </small>
            ) : (
              <></>
            )}
            {data?.jewellery?.weight?.totalDiamondsWeight ? (
              <small className="d-block mb-2">
                <span className="text-muted">Diamonds weight : </span>
                {data?.jewellery?.weight?.totalDiamondsWeight}{" "}
                {data?.jewellery?.weight?.totalDiamondsWeightUnit}
              </small>
            ) : (
              <></>
            )}
            {data?.jewellery?.gold?.totalGoldWeight ? (
              <small className="d-block mb-4">
                <span className="text-muted">Gold weight : </span>
                {data?.jewellery?.gold?.totalGoldWeight}{" "}
                {data?.jewellery?.gold?.totalGoldWeightUnit}
              </small>
            ) : (
              <></>
            )}
          </div>
        </div>
        {data?.isGift ? (
          <></>
        ) : (
          <Col md={4} className="me-2">
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">
                Discount{" "}
                {data?.discountType === "by_percentage" &&
                  `(${data?.discountValue}%)`}
              </small>
              <small>
                {data?.discountValue && data?.discountValue !== "0" && "-"}{" "}
                {data?.discountValue
                  ? numberFormat(
                      data?.discountType === "by_percentage"
                        ? getDiscountAmountByPercentage(data?.netPrice, data)
                        : data?.discountValue
                    )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top">
              <small className="text-muted d-block">
                Total ({data?.currency})
              </small>
              <small>{numberFormat(totalAmount(data?.netPrice, data))}</small>
            </div>
          </Col>
        )}
      </div>

      {data?.saleNotes && showDataCheck.showSaleNotes && (
        <span className="d-block">
          <small className="text-muted">Notes </small> :{" "}
          <small>{data?.saleNotes}</small>
        </span>
      )}

      <small className="text-danger text-center d-block mb-3 mt-5">
        {translate("receipt-tip")}
      </small>
      <small className="text-muted text-center d-block">
        {translate("thankForYourPurchase")}
      </small>
    </div>
  );
};

export default Invoice;
