import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import { CustomInput, FormFieldService, LoadingButtonLabel } from "../../../components";
import { CartContext } from "../../../context/Cart";
import {
  dateFormat2,
  disableScroll,
  getDiscountAmountByPercentage,
  handleClose,
  handleDateFormat,
  inputChangeService,
  numberFormat,
  totalAmount,
} from "../../../services";
import { actionData, getMethod } from "../../../services/api";
import CartItem from "./CartItem";

const SaleConfirmModal = ({
  show,
  loading,
  setShow,
  handleSaleConfirm,
  person,
  formData,
  setFormData,
}: {
  show: boolean;
  loading: boolean;
  setShow: any;
  handleSaleConfirm: any;
  person: any;
  formData: any;
  setFormData: any;
}) => {
  const [cart] = useContext(CartContext);
  const paymentSeries = [
    { key: "down_payment", label: "Down Payment", customKey: true },
    { key: "advanced", label: "Advanced", customKey: true },
  ];
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isBackDate, setIsBackDate] = useState(false);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    let temp: any = [];
    actionData(getMethod, "payment/methods").then((result) => {
      if (result.data && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({ label: item.bankName, value: item.id, keyType: "value" });
        });
      }
      setPaymentMethods(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const fields = [
    {
      id: "paymentMethodId",
      label: "Payment method *",
      value: formData.paymentMethodId,
      type: "select",
      data: paymentMethods,
      colCount: 6,
    },
    {
      id: "paymentSeries",
      label: "Payment series *",
      value: formData.paymentSeries,
      type: "select",
      data: paymentSeries,
      wantString: true,
      colCount: 6,
      disableMargin: true,
    },
    // { id: "amount", label: "Paid amount *", value: formData.amount, inputType: "number", wantString: true, colCount: 12, disableMargin: true },
  ];

  return (
    <Modal show={show} onHide={() => handleClose(setShow)} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{cart?.item?.itemName}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-2">
          <Row className="mb-4">
            <Col>
              <div className="mb-4">
                <span className="d-block mb-2">
                  <small className="text-muted">Customer name</small> :{" "}
                  <small>{person?.fullName}</small>
                </span>
                <span className="d-block mb-2">
                  <small className="text-muted">Phone</small> :{" "}
                  <small>{person?.phone}</small>
                </span>
                {person?.address && (
                  <span className="d-block mb-2">
                    <small className="text-muted">Address</small> :{" "}
                    <small>{person?.address || "-"} </small>
                  </span>
                )}
              </div>
              <CartItem hideRemoveButton isGift={formData.isGift} />

              {!formData.isGift ? (
                <div className="flex-end">
                  <div className="w-50 mt-4">
                    <div className="flex-between mb-2 pb-2">
                      <small className="text-muted d-block">Net price</small>
                      <small>{numberFormat(cart?.netPrice)}</small>
                    </div>
                    <div className="flex-between mb-2 pb-2">
                      <small className="text-muted d-block">
                        Discount{" "}
                        {formData.discountType === "by_percentage" &&
                          `(${formData.discountValue}%)`}
                      </small>
                      <small>
                        {formData.discountValue &&
                          formData.discountValue !== "0" &&
                          "-"}{" "}
                        {formData.discountValue
                          ? numberFormat(
                              formData.discountType === "by_percentage"
                                ? getDiscountAmountByPercentage(
                                    cart?.netPrice,
                                    formData
                                  )
                                : formData.discountValue
                            )
                          : "-"}
                      </small>
                    </div>
                    <div className="flex-between pt-2 border-top">
                      <small className="text-muted d-block">Total</small>
                      <small>
                        {numberFormat(totalAmount(cart?.netPrice, formData))}
                      </small>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <Row>
                {!formData.isGift &&
                (cart?.item?.inventoryType === "ordered_item" ||
                  cart?.item?.inventoryType === "service_item") ? (
                  <Col md={6}>
                    <CustomInput
                      id={"serviceFee"}
                      label={"Service fee"}
                      value={formData.serviceFee}
                      handleChange={(e: any) =>
                        setFormData({ ...formData, serviceFee: e.target.value })
                      }
                      inputType={"number"}
                    />
                  </Col>
                ) : (
                  <></>
                )}

                <Col md={6}>
                  {!formData.isGift ? (
                    <Form.Group className="input-form-control mb-3">
                      <div className="flex-between">
                        <Form.Label>{`Discount ${
                          formData.discountType === "by_amount"
                            ? "amount"
                            : "(%)"
                        }`}</Form.Label>

                        <FormCheck
                          id="switch"
                          className="switch"
                          type="switch"
                          label={"%"}
                          checked={formData.discountType === "by_percentage"}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              discountType:
                                formData.discountType === "by_amount"
                                  ? "by_percentage"
                                  : "by_amount",
                              discountValue: "",
                            })
                          }
                        />
                      </div>

                      <Form.Control
                        type={"number"}
                        id={"discountValue"}
                        value={formData.discountValue}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            discountValue: e.target.value,
                          })
                        }
                        onWheel={disableScroll}
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                </Col>

                <Col md={formData.isGift ? 12 : 6}>
                  <Form.Group className="mb-2 input-form-control">
                    <div className="flex-between align-items-center">
                      <Form.Label>Date</Form.Label>
                      <Form.Check
                        type="checkbox"
                        label="Back date"
                        className="cursor"
                        checked={isBackDate}
                        onChange={(e) => setIsBackDate(!isBackDate)}
                      />
                    </div>
                    <input
                      type="date"
                      className="form-control input-form-control"
                      id={"createdAt"}
                      value={handleDateFormat(formData.createdAt, dateFormat2)}
                      max={handleDateFormat(new Date(), dateFormat2)}
                      onChange={handleChange}
                      disabled={!isBackDate}
                    />
                  </Form.Group>
                </Col>

                <Col
                  md={
                    !formData.isGift &&
                    (cart?.item?.inventoryType === "ordered_item" ||
                      cart?.item?.inventoryType === "service_item")
                      ? 6
                      : 12
                  }
                >
                  <CustomInput
                    id={"saleNotes"}
                    label={"Sale note"}
                    value={formData.saleNotes}
                    handleChange={(e: any) =>
                      setFormData({ ...formData, saleNotes: e.target.value })
                    }
                    inputType={"textarea"}
                  />
                </Col>
              </Row>

              {!formData.isGift ? (
                <div className="p-4 bg-light rounded">
                  <FormFieldService
                    fields={fields}
                    handleChange={handleChange}
                    colCount={12}
                  />

                  <CustomInput
                    id={"amount"}
                    label={"Paid amount *"}
                    value={formData.amount}
                    handleChange={(e: any) => {
                      setFormData({ ...formData, amount: e.target.value });
                      // if (e.target.value <= cart?.netPrice) {
                      //     setFormData({ ...formData, amount: e.target.value })
                      // } else {
                      //     toast.error("Paid amount is greater than net price!")
                      // }
                    }}
                    inputType={"number"}
                  />
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-success" onClick={() => handleClose(setShow)}>
          Cancel
        </Button>
        <Button className="btn btn-grad ms-3" onClick={handleSaleConfirm}
          disabled={loading}
        >
          <LoadingButtonLabel label="Sale Confirm" disabled={loading} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleConfirmModal;
