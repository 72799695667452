import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  IconAdd,
  IconClose,
  IconGrid,
  IconList,
  IconBoxs,
  // IconPrint,
  IconSearch,
  IconFilter,
} from "../../../../components/widgets/Icons";
import { VariableContext } from "../../../../context/Variable";
import {
  actionData,
  getMethod,
  stockTypesRoute,
} from "../../../../services/api";
import { inventoryTypes } from "../../../../data";
import { handleShow } from "../../../../services";
import GemFilter from "./Filter";

interface HeaderProps {
  type: string;
  inventoryType: string;
  keyword: string;
  handleSetKeyword: (data: any) => void;
  handleSetType: (data: any) => void;
  handleSetInventoryType: (data: any) => void;
  handleGetDataByFilter: any;
  handleGetData: (v: any, inv: any, k: any) => void;
}

const Header: React.FC<HeaderProps> = ({
  type,
  inventoryType,
  keyword,
  handleSetKeyword,
  handleSetType,
  handleGetDataByFilter,
  handleSetInventoryType,
  handleGetData,
}) => {
  const history = useHistory();
  const [stockTypes, setStockTypes] = React.useState([]);
  const [variable, setVariable] = useContext(VariableContext);
  const [show, setShow] = useState(false);

  const handleGetStockTypeOptions = () => {
    actionData(getMethod, stockTypesRoute).then((result) => {
      setStockTypes(result.data);
    });
  };

  React.useEffect(() => {
    handleGetStockTypeOptions();
  }, []);

  const handleSearch = (e: any) => {
    if (e.keyCode === 13) {
      handleGetData(type, inventoryType, keyword);
    }
  };

  const handleSelectChange = (selectType: any, value: any) => {
    if (selectType === "type") {
      handleSetType(value);
      handleGetData(value, inventoryType, keyword);
    } else {
      handleSetInventoryType(value);
      handleGetData(type, value, keyword);
    }
  };

  return (
    <section className="d-flex justify-content-between mb-4">
      <Col md="3">
        <InputGroup className="search-input">
          <InputGroup.Text className="bg-grad text-white">
            <IconSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            value={keyword}
            onChange={(e) => handleSetKeyword(e.target.value)}
            onKeyDown={(e) => handleSearch(e)}
          />
          {keyword && (
            <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>}>
              <InputGroup.Text
                className="bg-danger cursor"
                onClick={() => {
                  handleSetKeyword("");
                  handleGetData(type, inventoryType, "");
                }}
              >
                <IconClose />
              </InputGroup.Text>
            </OverlayTrigger>
          )}
        </InputGroup>
      </Col>

      <section className="d-flex ">
        <div className="me-2">
          <Form.Select
            defaultValue={type}
            onChange={(e: any) => handleSelectChange("type", e.target.value)}
          >
            <option value="all">All</option>
            {stockTypes?.map((item: any, index: number) => (
              <option key={index} value={item?.label?.toLowerCase()}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div>

        <div className="me-2">
          <Form.Select
            defaultValue={inventoryType}
            onChange={(e: any) =>
              handleSelectChange("inventory", e.target.value)
            }
          >
            {inventoryTypes?.map((item: any, index: number) => (
              <option key={index} value={item?.key}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div>

        <div>
          <OverlayTrigger overlay={<Tooltip>Add gems</Tooltip>}>
            <Button
              className="bg-grad me-2"
              onClick={() => history.push("/add-gems")}
            >
              <IconAdd />
            </Button>
          </OverlayTrigger>
          <Button className="bg-grad me-2" onClick={() => handleShow(setShow)}>
            <IconFilter />
          </Button>
        </div>


        <div>
          <OverlayTrigger overlay={<Tooltip>Split gem</Tooltip>}>
            <Button
              className="bg-grad me-2"
              onClick={() => history.push("/split-gem")}
            >
              <IconBoxs />
            </Button>
          </OverlayTrigger>
        </div>

        {/* <div>
          <OverlayTrigger overlay={<Tooltip>Print labels</Tooltip>}>
            <Button
              className="bg-grad me-2"
              onClick={() => history.push("/gems-labels")}
            >
              <IconPrint />
            </Button>
          </OverlayTrigger>
        </div> */}

        <div>
          <ButtonGroup>
            <OverlayTrigger overlay={<Tooltip>Grid view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, isList: false })}
                className={
                  variable?.isList
                    ? "bg-white text-primary"
                    : "text-white bg-grad"
                }
              >
                <IconGrid />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>List view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, isList: true })}
                className={
                  variable?.isList
                    ? "text-white bg-grad"
                    : "bg-white text-primary"
                }
              >
                <IconList />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      </section>
      <GemFilter
        show={show}
        setShow={setShow}
        handleGetData={handleGetData}
        handleGetDataByFilter={handleGetDataByFilter}
      />
    </section>
  );
};

export default Header;
