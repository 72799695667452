import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { inventoryTypes } from "../../../../data";
import { handleDateFormat } from "../../../../services";
import InvoiceImage from "../../../../components/pdf/InvoiceImage";

const Summary = ({
  jewelleryCount,
  inventoryType,
  weightCards,
  costCards,
}: {
  jewelleryCount: string;
  inventoryType: string;
  weightCards: any;
  costCards: any;
}) => {
  return (
    <div className="bg-card">
      {/* <div className="d-flex flex-column align-items-center mb-4 bg-black">
        <Image src="images/mr.spinel.png" width={300} />
      </div> */}

      <InvoiceImage />

      <div className="flex-between mb-3 align-items-center">
        <label className="fw-bold ">Jewellery inventory report (summary)</label>
        <br />
        <div className="d-flex justify-content-between">
          <div>
            <small className="flex-end mb-1">
              {" "}
              Report date :{" "}
              <label className="fw-bold ms-1">
                {" "}
                {handleDateFormat(new Date())}
              </label>
            </small>
            <small className="flex-end mb-1">
              Inventory type :{" "}
              {
                inventoryTypes.filter(
                  (item: any) => item.key === inventoryType
                )[0]?.label
              }
            </small>
            <small className="flex-end">Jewelleries : {jewelleryCount}</small>
          </div>
        </div>
      </div>

      <Row>
        <Col md={6}>
          <Table responsive striped className="mb-0 border">
            <tbody>
              <tr>
                <td>
                  {" "}
                  <span className="fw-bold mb-2">Weights</span>
                </td>
                <td>
                  <span className="fw-bold flex-end">In stock</span>
                </td>
                <td>
                  <span className="fw-bold flex-end">On hold</span>
                </td>
                <td>
                  <span className="fw-bold flex-end">Sold out</span>
                </td>
              </tr>
              {weightCards.map((item: any, index: number) => (
                <tr key={index}>
                  <td className=" text-muted ">{item.label}</td>
                  <td>
                    <h6 className="fw-bold flex-end">{item.availableValue}</h6>
                  </td>
                  <td>
                    <h6 className="fw-bold flex-end">{item.onHoldValue}</h6>
                  </td>
                  <td>
                    <h6 className="fw-bold flex-end">
                      {item.unavailableValue}
                    </h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col md={6}>
          <Table responsive striped className="mb-0 border">
            <tbody>
              <tr>
                <td>
                  {" "}
                  <span className="fw-bold mb-2">Total Cost</span>
                </td>
                <td>
                  <span className="fw-bold flex-end">In stock (Cost)</span>
                </td>
                <td>
                  <span className="fw-bold flex-end">On hold (Cost)</span>
                </td>
              </tr>
              {costCards.map((item: any, index: number) => (
                <tr key={index}>
                  <td className=" text-muted ">{item.label}</td>
                  <td>
                    <h6 className="fw-bold flex-end">
                      <small>{item.inStockValue}</small>
                    </h6>
                  </td>
                  <td>
                    <h6 className="fw-bold flex-end">
                      <small>{item.onHoldValue}</small>
                    </h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
