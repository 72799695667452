import { Link } from "react-router-dom";

function getYear() {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  return currentYear;
}

function Footer() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <hr className="hr-style" />
          <ul className="navbar-nav">
            <div className="d-flex justify-content-center">
              <li className="nav-item active">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item mx-4">
                <Link to="/privacy-policy" className="nav-link">
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/terms-and-conditions" className="nav-link">
                  Terms & Conditions
                </Link>
              </li>
            </div>
          </ul>
          <div className="col-8 mx-auto text-center text-muted py-3 copy-right">
            Copyright &copy; {getYear()}{" "}
            <Link
              to={{ pathname: "https://hbsenterprise.com" }}
              target="_blank"
            >
              HBS Co., Ltd.
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
