import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Lang, Layout } from "../../components";
import {
  IconCreditCard,
  IconDelete,
  IconNoti,
  IconPayment,
  IconPeople,
  IconPalette,
  IconScissor,
  IconDiamond,
} from "../../components/widgets/Icons";

const Index = () => {
  const history = useHistory();

  const menus = [
    {
      label: "Payment methods",
      description: "Manage payment methods",
      route: "payment-methods",
      icon: <IconCreditCard size={32} />,
    },
    {
      label: "Change prices",
      description: "Update prices based on inflaction",
      route: "change-prices",
      icon: <IconPayment size={32} />,
    },
    {
      label: "Change Rates",
      description: "Update prices based on market rate",
      route: "change-rates",
      icon: <IconPayment size={32} />,
    },
  ];

  const userMenus = [
    {
      label: "Users",
      description: "Manage system users",
      route: "users",
      icon: <IconPeople size={32} />,
    },
  ];

  const otherMenus = [
    {
      label: "Gem colors",
      description: "Manage gem colors",
      route: "gem-colors",
      icon: <IconPalette size={32} />,
    },
    // {
    //   label: "Gem shape and cut",
    //   description: "Manage gem shape and cut",
    //   route: "gem-shape-and-cut",
    //   icon: <IconPalette size={32} />,
    // },
    {
      label: "Calibrated gem colors",
      description: "Manage calibrated gem colors",
      route: "calibrated-gem-colors",
      icon: <IconPalette size={32} />,
    },
    {
      label: "Stone types",
      description: "Manage stone type",
      route: "stone-types",
      icon: <IconDiamond size={32} />,
    },
    {
      label: "Shape and Cut",
      description: "Manage shape and cut",
      route: "shape-and-cut",
      icon: <IconScissor size={32} />,
    },
  ];

  const trashMenus = [
    {
      label: <Lang label="jewelleries" />,
      description: "Deleted jewellery lists",
      route: "jewellery-trash",
      icon: <IconDelete size={32} />,
    },
    {
      label: "Jewellery sets",
      description: "Deleted jewellery set lists",
      route: "jewellery-set-trash",
      icon: <IconDelete size={32} />,
    },
    {
      label: <Lang label="gems" />,
      description: "Deleted gems lists",
      route: "gems-trash",
      icon: <IconDelete size={32} />,
    },
    {
      label: <Lang label="calibratedGems" />,
      description: "Deleted calibrated gems lists",
      route: "calibrated-gems-trash",
      icon: <IconDelete size={32} />,
    },
  ];

  const notiMenus = [
    {
      label: "Email notification",
      description: "Configure email notification",
      route: "email-notification",
      icon: <IconNoti size={32} />,
    },
  ];

  return (
    <Layout title="Configuration">
      <h6>Payments and Price Management</h6>
      <Row className="pt-2 mb-4">
        {menus.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <div
              className="config-card bg-card mb-4 d-flex cursor align-items-center"
              onClick={() => history.push(item.route)}
            >
              {item.icon}
              <div className="ms-4">
                <h6>{item.label}</h6>
                <small className="text-muted">{item.description}</small>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <h6>User Management</h6>
      <Row className="pt-2 mb-4">
        {userMenus.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <div
              className="config-card bg-card mb-4 d-flex cursor align-items-center"
              onClick={() => history.push(item.route)}
            >
              {item.icon}
              <div className="ms-4">
                <h6>{item.label}</h6>
                <small className="text-muted">{item.description}</small>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <h6>Others</h6>
      <Row className="pt-2 mb-4">
        {otherMenus.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <div
              className="config-card bg-card mb-4 d-flex cursor align-items-center"
              onClick={() => history.push(item.route)}
            >
              {item.icon}
              <div className="ms-4">
                <h6>{item.label}</h6>
                <small className="text-muted">{item.description}</small>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <h6>Trash bin</h6>
      <Row className="pt-2">
        {trashMenus.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <div
              className="config-card bg-card mb-4 d-flex cursor align-items-center"
              onClick={() => history.push(item.route)}
            >
              {item.icon}
              <div className="ms-4">
                <h6>{item.label}</h6>
                <small className="text-muted">{item.description}</small>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <h6>Notification</h6>
      <Row className="pt-2">
        {notiMenus.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <div
              className="config-card bg-card mb-4 d-flex cursor align-items-center"
              onClick={() => history.push(item.route)}
            >
              {item.icon}
              <div className="ms-4">
                <h6>{item.label}</h6>
                <small className="text-muted">{item.description}</small>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Layout>
  );
};

export default Index;
