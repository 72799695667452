import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InfoCard } from "../../../../components";
import { IconDiamond } from "../../../../components/widgets/Icons";

interface countProps {
  data: any;
}

const Count: React.FC<countProps> = ({ data }) => {
  const [state, setState] = useState("in_stock");

  const cards = [
    { id: "in_stock", label: "In stock", value: 0, color: "primary" },
    { id: "consignment", label: "Consignments", value: 0, color: "success" },
    { id: "sold_out", label: "Sold out", value: 0, color: "info" },
    { id: "all", label: "All gems", value: 0, color: "warning" },
  ];

  const icon = <IconDiamond size={24} />;

  return (
    <Row className="mb-4">
      <Col md={3}>
        <InfoCard
          id={cards[0].id}
          label={cards[0].label}
          value={data?.inStock}
          color={cards[0].color}
          state={state}
          icon={icon}
          cardAction={() => setState(cards[0].id)}
        />
      </Col>

      <Col md={3}>
        <InfoCard
          id={cards[1].id}
          label={cards[1].label}
          value={data?.consignments}
          color={cards[1].color}
          state={state}
          icon={icon}
          cardAction={() => setState(cards[1].id)}
        />
      </Col>

      <Col md={3}>
        <InfoCard
          id={cards[2].id}
          label={cards[2].label}
          value={data?.soldoutGemstonesCount}
          color={cards[2].color}
          state={state}
          icon={icon}
          cardAction={() => setState(cards[2].id)}
        />
      </Col>

      <Col md={3}>
        <InfoCard
          id={cards[3].id}
          label={cards[3].label}
          value={data?.all}
          color={cards[3].color}
          state={state}
          icon={icon}
          cardAction={() => setState(cards[3].id)}
        />
      </Col>
    </Row>
  );
};

export default Count;
