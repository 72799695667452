import { Image, Table } from "react-bootstrap";
import { inventoryTypes } from "../../../../data";
import { handleDateFormat, numberFormat } from "../../../../services";
import Logo from "../../../../components/common/Logo";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Details = ({
  data,
  pageCurrent,
  inventoryType,
}: {
  data: any;
  pageCurrent: number;
  inventoryType: string;
}) => {
  return (
    <div className="bg-card">
      <div className="flex-between mb-2">
        <div className="d-flex align-items-center">
          <Logo />
          {/* <Image src="images/logo.png" width={50} /> */}
          <div className="ms-2">
            <h5 className="text-primary fw-bold mb-0">
              {process.env.REACT_APP_APP_TITLE}
            </h5>
            <small className="text-primary ">Natural Gems & Jewellery</small>
          </div>
        </div>
        <div>
          <small className="flex-end mb-1">
            Date : {handleDateFormat(new Date())}
          </small>
          <small>
            Jewellery sale report (
            {
              inventoryTypes.filter(
                (item: any) => item.key === inventoryType
              )[0]?.label
            }
            )
          </small>
        </div>
      </div>

      <Table responsive striped bordered className="mb-0 report-table">
        <thead className="bg-primary text-white">
          <tr>
            {/* <th>No.</th> */}
            <th>Selling date</th>
            <th>Item</th>
            <th>Invoice</th>
            <th>Selling price</th>
            <th>All total cost</th>
            <th>Actual income</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              {/* <td><span className="flex-center">{index + 1 + pageCurrent * 80}</span> </td> */}
              <td>
                <span className="flex-center">{item?.sellingDate}</span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div className="thumbnail-list-image me-2">
                    {item?.image ? (
                      <Image
                        src={`${RES_API_URL}${item?.image?.url}`}
                        alt="no_found"
                        width={100}
                        height={100}
                      />
                    ) : (
                      <Image
                        src={"images/no-image.png"}
                        width={100}
                        height={100}
                      />
                    )}
                  </div>
                  <div>
                    <div>{item?.itemName}</div>
                    <small className="text-muted my-2 d-block">
                      {item?.sku}
                    </small>
                  </div>
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div>
                    <div>{item?.customerName}</div>
                    <small className="text-muted my-2 d-block">
                      {item?.invoiceNumber}
                    </small>
                  </div>
                </span>
              </td>
              <td>
                <span className="flex-end">
                  {item?.sellingPrice
                    ? `${numberFormat(item?.sellingPrice)}`
                    : "-"}
                </span>
              </td>
              <td>
                <span className="flex-end">
                  {item?.totalCost ? `${numberFormat(item?.totalCost)}` : "-"}
                </span>
              </td>
              <td>
                <span className="flex-end">
                  {item?.actualIncome
                    ? `${numberFormat(item?.actualIncome)}`
                    : "-"}
                </span>
              </td>
              <td>
                <span className="flex-start">{item?.currency}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Details;
