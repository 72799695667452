import { useContext } from "react";
import { Button, Col, Image, Modal, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { InfoTable, Thumbsnail } from "../../../../components";
import { IconCart } from "../../../../components/widgets/Icons";
import { POSDetailLoading } from "../../../../components/widgets/Loading";
import { handleClose, numberFormat } from "../../../../services";
import { SetCartContext } from "../../../../context";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const SetItemModel = ({
  show,
  setShow,
  item,
  loading,
  setInfo,
}: {
  show: boolean;
  setShow: any;
  item: any;
  loading: boolean;
  setInfo: any;
}) => {
  // let gold = item && item?.gold && item?.gold.length > 0 ? item?.gold[0] : {}
  const [setCart, setSetCart] = useContext(SetCartContext);

  // const inventoryTypeLabel = inventoryTypes.filter((inventory: any) => inventory.key === item?.inventoryType)[0]?.label

  //For pdf
  // const componentRef = useRef(null);
  // const reactToPrintContent = useCallback(() => {
  //     return componentRef.current;
  // }, []);

  // const PrintAction = useReactToPrint({
  //     content: reactToPrintContent,
  // });

  const data = [
    { label: "Set name", value: item?.itemName },
    { label: "SKU", value: item?.sku },
    {
      label: "Selling price",
      value: setInfo?.sellingPrice
        ? `${setInfo?.currency} ${numberFormat(setInfo?.sellingPrice)}`
        : "",
    },
    // { label: "Jewellery weight", value: "7.45 grams" || "-" },
    // { label: "Jewellery for", value: "Women" || "-" },
  ];

  const otherData = [
    { label: "Included", value: setInfo?.jewelleryInclued || "-" },
    { label: "Jewellery count", value: setInfo?.jewelleryCount || "-" },
    { label: "Note", value: setInfo?.note || "-" },
  ];

  // const totalDiamondsQuantity = () => {
  //     let total = 0;
  //     if (item && item?.diamonds && item?.diamonds.length > 0) {
  //         item?.diamonds.forEach((diamond: any) => {
  //             if (diamond.diamondsStockType === "Lot") total += diamond.diamondsQuantity
  //         })
  //     }
  //     return total;
  // }

  // const totalGemsQuantity = () => {
  //     let total = 0;
  //     if (item && item?.gemstones && item?.gemstones.length > 0) {
  //         item?.gemstones.forEach((gemstone: any) => {
  //             if (gemstone.gemsStockType === "Lot") total += gemstone.gemsQuantity
  //         })
  //     }
  //     return total;
  // }

  const handleAddToCart = () => {
    if (setCart?.item?.itemName) {
      toast.error("Already added item!");
    } else {
      setSetCart({
        ...setCart,
        item: item,
        netPrice: setInfo?.sellingPrice,
        currency: setInfo?.currency,
      });
    }
    handleClose(setShow);
  };

  return (
    <Modal show={show} onHide={() => handleClose(setShow)} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{!loading && item?.itemName}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <POSDetailLoading />
        ) : (
          <div className="p-2">
            <Row className="mb-4">
              <Col md={2}>
                <div className="pos-detail-image">
                  {item?.setsImage ? (
                    <Image
                      src={`${RES_API_URL}${item?.setsImage?.url}`}
                      alt="no_found"
                    />
                  ) : (
                    <Image src={"images/no-image.png"} />
                  )}
                </div>
              </Col>
              <InfoTable data={data} />
              <InfoTable data={otherData} />
            </Row>

            <Table responsive striped bordered className="mb-0 bg-light border">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Item</th>
                  <th>Gems (carat)</th>
                  <th>Diamonds (carat)</th>
                  <th>Gold (gram)</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {item?.setItems?.map((subItem: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <span className="flex-center">{index + 1}</span>
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <div className="thumbnail-list-image me-2">
                          <Thumbsnail images={subItem?.images} />
                        </div>
                        <div>
                          <div>{subItem?.itemName}</div>
                          <small className="text-muted mt-2">
                            {subItem?.sku}
                          </small>
                        </div>
                      </span>
                    </td>
                    <td>
                      <span className="flex-end">
                        {subItem.gemstones.totalWeight}
                      </span>
                    </td>
                    <td>
                      <span className="flex-end">
                        {subItem.diamonds.totalWeight}
                      </span>
                    </td>
                    <td>
                      <span className="flex-end">
                        {subItem.gold.totalGoldWeight}
                      </span>
                    </td>
                    <td>
                      <span className="flex-end">
                        {item?.currency} {numberFormat(subItem?.sellingPrice)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-success" onClick={() => handleClose(setShow)}>
          Cancel
        </Button>

        <Button className="btn btn-grad ms-3" onClick={handleAddToCart}>
          <IconCart /> <span className="ms-2">Add to cart</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetItemModel;
