import { Accordion, Col, Row } from "react-bootstrap";

const CustomerInfoAccordion = ({ person }: { person?: any }) => {
  return (
    <>
      {person && (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="pos-customer-info-header">
              Customer Info
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={6}>
                  <small className="text-muted d-block">Full name</small>
                  <small>{person?.fullName}</small>
                </Col>
                <Col md={6}>
                  <small className="text-muted d-block">Phone</small>
                  <small>{person?.phone}</small>
                </Col>
                <Col md={12} className="mt-3">
                  <small className="text-muted d-block">Address</small>
                  <small>{person?.address || "-"}</small>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

export default CustomerInfoAccordion;
