import { useContext, useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router-dom";
import {
  IconClockHistory,
  IconDelete,
  // IconEdit,
  IconFile,
} from "../../../../components/widgets/Icons";
import { PageContext } from "../../../../context/Page";
import {
  getNumber,
  handleClose,
  handleShow,
  numberFormat,
} from "../../../../services";
import { moveToTrashService } from "../../../../services/api";
import { Checkbox } from "../../../../components";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  itemName?: string;
  image?: string;
}

export const List = ({
  item,
  handleResponse,
  handleCheckClick,
  isCheckData,
  index,
  pageCurrent,
}: {
  item: any;
  handleResponse: any;
  handleCheckClick: any,
  isCheckData: any,
  index: number;
  pageCurrent: number;
}) => {
  const history = useHistory();
  const [page, setPage] = useContext(PageContext);
  const [target, setTarget] = useState<Target>();
  const [show, setShow] = useState(false);

  const goDetail = () => {
    history.push(`/gems-detail?id=${item.id}`,
    );

    setPage({ current: 0, total: 0, sorting: page.sorting });
  };

  const handleDelete = () => {
    moveToTrashService(
      item?.itemName,
      null,
      `trash/gemstones/${item?.id}`,
      handleResponse
    );
  };

  const thumbsnailImage = (item: any) => {
    let tempImages = item.images;
    let isExist = false;
    let tempDiv = (
      <img
        src="images/no-image.png"
        alt="Thumbnail"
        width={80}
        height={80}
        className="object-fit rounded my-2"
      />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="Thumbnail"
              width={80}
              height={80}
              className="object-fit rounded cursor my-2"
              onClick={() => {
                setTarget({ itemName: item?.itemName, image: img.url });
                handleShow(setShow);
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="Thumbnail"
            width={80}
            height={80}
            className="rounded cursor my-2"
            onClick={() => {
              setTarget({ itemName: item?.itemName, image: tempImages[0].url });
              handleShow(setShow);
            }}
          />
        );
      }
    }

    return tempDiv;
  };

  return (
    <>
      {show && (
        <Lightbox
          mainSrc={`${RES_API_URL}${target?.image}`}
          imageTitle={`${item?.itemName} (${item?.sku})`}
          imageCaption={`${item?.jewelleryType} / ${item?.jewelleryWeight} ${item?.jewelleryWeightUnit
            } / ${item?.currency} ${numberFormat(item?.sellingPrice)}`}
          onCloseRequest={() => handleClose(setShow)}
        />
      )}

      <td className="">
        <span className="flex-center">
          <Checkbox
            key={index}
            type="checkbox"
            name={item.sku}
            id={item.id}
            handleClick={(e: any) => handleCheckClick(e, item)}
            isChecked={isCheckData && isCheckData!.includes(item.id)}
          />
          <span className="ms-3">{getNumber(index, pageCurrent)}</span>
        </span>{" "}

      </td >
      <td>
        <div className="d-flex align-items-center">
          <div className="me-3">{thumbsnailImage(item)}</div>
          <div className="d-flex flex-column">
            <div className="d-block">{item.itemName}</div>
            <div className="d-flex mb-3">
              {item?.availability && !item?.isSoldout && (
                <Badge bg="primary">In stock</Badge>
              )}
              {!item?.availability && !item?.isSoldout && (
                <Badge bg="success">Consignment</Badge>
              )}
              {item?.isSoldout && <Badge bg="danger">Sold out</Badge>}
              {item?.childGemstones?.length > 0 && (
                <Badge bg="info" className="ms-1">
                  Splited
                </Badge>
              )}
            </div>
          </div>
        </div>
      </td>
      <td>{item.sku}</td>
      <td>
        <span className="d-block text-muted">
          {item?.stoneType} {item?.stockType ? `(${item?.stockType})` : ""}
        </span>
        <span className="d-block text-muted">
          {item?.stages?.length > 0 && (
            <>
              {item?.stages?.[item?.stages?.length - 1]?.dimension &&
                `${item?.stages?.[item?.stages?.length - 1]?.dimension}, `}

              {item?.stages?.[item?.stages?.length - 1]?.numberOfPcs &&
                `${item?.stages?.[item?.stages?.length - 1]?.numberOfPcs
                } Pcs, `}

              {item?.stages?.[item?.stages?.length - 1]?.weight &&
                `${item?.stages?.[item?.stages?.length - 1]?.weight} ${item?.stages?.[item?.stages?.length - 1]?.weightUnit
                }`}
            </>
          )}
        </span>
        <span className="d-block text-muted">
          {item?.stages?.length > 0 && (
            <>
              {item?.stages?.[item?.stages?.length - 1]?.color &&
                `${item?.stages?.[item?.stages?.length - 1]?.color}, `}

              {item?.stages?.[item?.stages?.length - 1]?.clarity &&
                `${item?.stages?.[item?.stages?.length - 1]?.clarity}`}
            </>
          )}
        </span>
        <span className="text-muted">
          {item?.miningLocation && `Mine: ${item?.miningLocation}`}
        </span>
      </td>
      {/* <td>{item.origin}</td> */}
      {/* <td>{item.stockType}</td> */}
      {/* <td>{item.stoneType}</td> */}
      {/* <td><span className="flex-end">{item.weight} {item.weightUnit}</span></td> */}
      <td>
        <span className="flex-end">
          {item.price?.sellingPrice
            ? `${item?.price?.currency} ${numberFormat(
              item?.price?.sellingPrice
            )}`
            : "-"}
        </span>
      </td>
      <td>
        <span className="d-flex justify-content-end">
          <OverlayTrigger overlay={<Tooltip>Gems stages</Tooltip>}>
            <Button
              className="text-white btn-sm bg-grad me-2"
              onClick={() =>
                history.push({
                  pathname: "/gems-stages",
                  state: { item: item },
                })
              }
            >
              <IconClockHistory size={18} />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
            <Button className="text-white btn-sm bg-grad" onClick={goDetail}>
              <IconFile />
            </Button>
          </OverlayTrigger>

          {/* <OverlayTrigger overlay={<Tooltip>Edit gems</Tooltip>}>
            <Button
              className="text-white btn-sm bg-grad mx-2"
              disabled={Boolean(item?.childGemstones?.length)}
              onClick={() =>
                history.push({
                  pathname: "/edit-gems",
                  state: { item: item },
                })
              }
            >
              <IconEdit />
            </Button>
          </OverlayTrigger> */}

          <OverlayTrigger overlay={<Tooltip>Move to trash</Tooltip>}>
            <Button
              className="ms-2 bg-danger btn-sm"
              disabled={Boolean(item?.childGemstones?.length)}
              onClick={handleDelete}
            >
              <IconDelete />
            </Button>
          </OverlayTrigger>
        </span>
      </td>
    </>
  );
};
