import { Button, Col, Table } from "react-bootstrap";
import { IconAdd } from "../../../../components/widgets/Icons";
import {
  CustomModalService,
  NoData,
  TableRowButtons,
} from "../../../../components";
import {
  handelGetPersons,
  handleClose,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../context/Lang";
import {
  actionData,
  dataService,
  deleteService,
  getMethod,
  jewelleryRoute,
  jewelleryWeightUnitRoute,
  materialColorRoute,
  materialPurityRoute,
  materialRoute,
  postMethod,
  responseService,
} from "../../../../services/api";

const Gold = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [persons, setPersons] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [materialPurity, setMaterialPurity] = useState([]);
  const [materialColors, setMaterialColors] = useState([]);
  const [weightUnits, setWeigthUnits] = useState([]);
  const [formData, setFormData] = useState({
    goldId: "",
    materialTypeUsed: "",
    materialPurity: "",
    materialColor: "",
    jewellerySmith: null,
    jewellerySmithDefaultValue: "",
    totalGoldWeight: "",
    totalGoldWeightUnit: "grams",
    goldSmithLoss: "",
    goldSmithLossNote: "",
    totalGoldCost: "",
  });
  const required = ["materialTypeUsed", "materialPurity", "totalGoldWeight"];

  const fields = [
    {
      id: "materialTypeUsed",
      label: "Material type used *",
      value: formData.materialTypeUsed,
      type: "select",
      data: materials,
      wantString: true,
    },
    {
      id: "materialPurity",
      label: "Material purity *",
      value: formData.materialPurity,
      type: "select",
      data: materialPurity,
      wantString: true,
    },
    {
      id: "materialColor",
      label: "Material color *",
      value: formData.materialColor,
      type: "select",
      data: materialColors,
      wantString: true,
    },
    {
      id: "jewellerySmith",
      label: "Jewellery smith",
      value: formData.jewellerySmith,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.jewellerySmithDefaultValue,
    },
    {
      id: "totalGoldWeight",
      label: "Total gold raw weight",
      value: formData.totalGoldWeight,
      inputType: "number",
      type: "select-group",
      secondId: "totalGoldWeightUnit",
      secondValue: formData.totalGoldWeightUnit,
      data: weightUnits,
      wantString: true,
    },
    {
      id: "goldSmithLoss",
      label: "Gold smith loss",
      value: formData.goldSmithLoss,
      inputType: "number",
      wantString: true,
    },
    {
      id: "goldSmithLossNote",
      label: "Gold smith loss note",
      value: formData.goldSmithLossNote,
    },
    {
      id: "totalGoldCost",
      label: "Total gold cost",
      value: formData.totalGoldCost,
      inputType: "number",
      wantString: true,
    },
  ];

  useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    handelGetPersons("", setPersons);
    actionData(getMethod, materialRoute).then((result) => {
      setMaterials(result.data);
    });
    actionData(getMethod, materialPurityRoute).then((result) => {
      setMaterialPurity(result.data);
    });
    actionData(getMethod, materialColorRoute).then((result) => {
      setMaterialColors(result.data);
    });
    actionData(getMethod, jewelleryWeightUnitRoute).then((result) => {
      setWeigthUnits(result.data);
    });
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = (item?: any) => {
    setFormData({
      goldId: item?.id || "",
      materialTypeUsed: item?.materialTypeUsed || "",
      materialPurity: item?.materialPurity || "",
      materialColor: item?.materialColor || "",
      jewellerySmith: item?.jewellery_smith?.id || "",
      jewellerySmithDefaultValue: item?.jewellery_smith?.fullName || "",
      totalGoldWeight: item?.totalGoldWeight || "",
      totalGoldWeightUnit: item?.totalGoldWeightUnit || "grams",
      goldSmithLoss: item?.goldSmithLoss || "",
      goldSmithLossNote: item?.goldSmithLossNote || "",
      totalGoldCost: item?.totalGoldCost || "",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    let postRoute = `${jewelleryRoute(product.id)}/gold`;
    let putRoute = `jewellery/gold/${formData.goldId}`;
    setLoading(true);
    dataService(
      method,
      formData,
      required,
      method === postMethod ? postRoute : putRoute
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.materialTypeUsed,
      null,
      `jewellery/gold/${item?.id}`,
      handleResponse
    );
  };

  return (
    <Col md={12}>
      <section className="bg-card mb-4">
        <section className="flex-between mb-3">
          <h6>Gold</h6>
          {product && product?.gold && product?.gold.length === 0 && (
            <Button className="text-white bg-grad" onClick={showForm}>
              <IconAdd /> <span className="ms-2">{translate("createNew")}</span>
            </Button>
          )}
        </section>

        {product && product?.gold && product?.gold.length > 0 ? (
          <>
            <Table responsive className="mb-2" striped bordered>
              <thead>
                <tr>
                  <th>Material type used</th>
                  <th>Material purity</th>
                  <th>Material color</th>
                  <th>Jewellery smith</th>
                  <th>Total gold weight</th>
                  <th>Gold smith loss</th>
                  <th>Total cost</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {product?.gold.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.materialTypeUsed || "-"}</td>
                    <td>{item.materialPurity || "-"}</td>
                    <td>{item.materialColor || "-"}</td>
                    <td>{item.jewellery_smith?.fullName || "-"}</td>
                    <td>
                      <span className="flex-end">
                        {item.totalGoldWeight
                          ? `${item.totalGoldWeight} ${item.totalGoldWeightUnit}`
                          : "-"}
                      </span>
                    </td>
                    <td>
                      {item.goldSmithLoss
                        ? `${item.goldSmithLoss} ${item.totalGoldWeightUnit}${
                            item.goldSmithLossNote
                              ? ` - ${item.goldSmithLossNote}`
                              : ""
                          }`
                        : "-"}
                    </td>
                    <td>
                      <span className="flex-end">
                        {item.totalGoldCost
                          ? ` ${numberFormat(item.totalGoldCost)}`
                          : "-"}
                      </span>
                    </td>
                    <td>
                      <TableRowButtons
                        item={item}
                        showForm={showForm}
                        handleDelete={handleDelete}
                        flex="flex-center"
                      />{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <NoData noImage />
        )}
      </section>

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.goldId}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        lg
      />
    </Col>
  );
};

export default Gold;
