import {
  Button,
  Col,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  IconAdd,
  IconClose,
  IconImage,
  IconUpload,
} from "../../../../components/widgets/Icons";
import {
  CustomInput,
  CustomModal,
  CustomSelectInputGroup,
  FormFieldService,
  ImageLoading,
  NoData,
  TableRowButtons,
} from "../../../../components";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../context/Lang";
import {
  csvToArr,
  handleClose,
  handleShow,
  inputChangeService,
} from "../../../../services";
import {
  actionData,
  dataService,
  deleteImage,
  deleteMethod,
  deleteService,
  deleteUploadTwo,
  gemsAvgSizesRoute,
  gemsClarityRoute,
  gemsColorRoute,
  gemsRoute,
  gemsShapeAndCutRoute,
  gemsTreatmentRoute,
  gemsWeightUnitRoute,
  getMethod,
  postMethod,
  responseService,
  setUpload,
} from "../../../../services/api";
import { FileWithPath, useDropzone } from "react-dropzone";
import Lightbox from "react-image-lightbox";
import React from "react";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  gemstonesStageId?: string;
  fileName?: string;
  image?: string;
}

const Stages = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [weightUnits, setWeigthUnits] = useState([]);
  const [gemsSizesByPcs, setGemsSizesByPcs] = useState([]);
  const [gemsSizes, setGemsSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [clarities, setClarities] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showDeleteImage, setShowDeleteImage] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [target, setTarget] = useState<Target>();

  const [formData, setFormData] = useState<any>({
    gemstonesStageId: "",
    numberOfPcs: null,
    dimension: "",
    weight: "",
    weightUnit: "grams",
    color: "",
    clarity: "",
    shapeAndCut: "",
    treatment: [],
    stageStaus: "Initial",
    pcsOneWeight: "",
    pcsTwoWeight: "",
    size: "",
    sizeUnit: "pcs_per_cts",
  });

  // const required = ["numberOfPcs", "weight"];

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );

        setUpload(tempFormData).then((data) => {
          let tempFiles: any = [];
          uploadedFiles?.forEach((item: any) => tempFiles.push(item));
          data?.forEach((item: any) => tempFiles.push(item));
          setUploadedFiles(tempFiles);
          setImageLoading(false);
          dataService(
            postMethod,
            { ...data[0], thumbnail: false },
            [],
            `gemstonesStage/${target?.gemstonesStageId}/images`
          ).then((result) => {
            handleResponse(result);
          });
        });
      }, 500);
    },
  });

  const fields = [
    // {
    //   id: "numberOfPcs",
    //   label: "Number Of pcs *",
    //   value: formData.numberOfPcs,
    //   inputType: "number",
    // },
    { id: "dimension", label: "Dimension", value: formData.dimension },
    // {
    //   id: "weight",
    //   label: "Weight *",
    //   value: formData.weight,
    //   inputType: "number",
    //   type: "select-group",
    //   secondId: "weightUnit",
    //   secondValue: formData.weightUnit,
    //   data: weightUnits,
    //   wantString: true,
    // },
    {
      id: "color",
      label: "Color",
      value: formData.color,
      type: "select",
      data: colors,
      wantString: true,
    },
    {
      id: "clarity",
      label: "Clarity",
      value: formData.clarity,
      type: "select",
      data: clarities,
      wantString: true,
    },
    {
      id: "shapeAndCut",
      label: "Shape and cut",
      value: formData.shapeAndCut,
      type: "select",
      data: shapes,
      wantString: true,
    },
    // {
    //   id: "treatment",
    //   label: "Treatment",
    //   value: formData.treatment,
    //   type: "select",
    //   data: treatments,
    //   wantString: true,
    // },
  ];

  useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, gemsWeightUnitRoute).then((result) => {
      setWeigthUnits(result.data);
    });

    actionData(getMethod, gemsColorRoute).then((result) => {
      setColors(result.data);
    });

    actionData(getMethod, gemsClarityRoute).then((result) => {
      setClarities(result.data);
    });

    actionData(getMethod, gemsShapeAndCutRoute).then((result) => {
      setShapes(result.data);
    });

    actionData(getMethod, gemsTreatmentRoute).then((result) => {
      setTreatments(result.data);
    });

    actionData(getMethod, gemsAvgSizesRoute).then((result) => {
      let sizes: any = [];
      let sizesByPcs: any = [];
      result.data.forEach((item: any) => {
        if (item.unit === "pcs_per_cts") {
          sizesByPcs.push(item);
        } else {
          sizes.push(item);
        }
      });
      setGemsSizesByPcs(sizesByPcs);
      setGemsSizes(sizes);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleChangeTwo = (evt: any, wantString?: boolean) => {
    const value = evt.target.value;
    if (value === "Lots") {
      setFormData({
        ...formData,
        numberOfPcs: null,
        size: null,
        weightUnit: value,
        pcsOneWeight: "",
        pcsTwoWeight: "",
      });
    } else if (value === "Loose" || value === "Single") {
      setFormData({
        ...formData,
        numberOfPcs: 1,
        size: null,
        weightUnit: value,
        pcsOneWeight: "",
        pcsTwoWeight: "",
      });
    } else {
      setFormData({
        ...formData,
        numberOfPcs: null,
        size: null,
        weightUnit: value,
        pcsOneWeight: "",
        pcsTwoWeight: "",
      });
    }
  };

  const handleCheckboxChange = (evt: any) => {
    let temp: any = [...formData?.treatment];
    const checked = evt.target.checked;
    const value = evt.target.value;

    if (checked) {
      temp.push(value);
      setFormData({ ...formData, treatment: temp });
    } else {
      temp = temp.filter((v: any) => v !== value);
    }

    setFormData({ ...formData, treatment: temp });
  };

  const showForm = (item?: any) => {
    setFormData({
      gemstonesStageId: item?.id || null,
      numberOfPcs: item?.numberOfPcs || null,
      dimension: item?.dimension || "",
      weight: item?.weight || "",
      weightUnit: item?.weightUnit || "",
      color: item?.color || "",
      clarity: item?.clarity || "",
      shapeAndCut: item?.shapeAndCut || "",
      treatment: csvToArr(item?.treatment, ", "),
      stageStaus: "Initial",
      pcsOneWeight: item?.pcsOneWeight || "",
      pcsTwoWeight: item?.pcsTwoWeight || "",
      size: item?.size || "",
      sizeUnit: item?.sizeUnit || "pcs_per_cts",
    });

    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    let required: any = [];

    if (product.stockType !== "Pair") {
      required = ["weight"];

    } else {
      required = ["pcsOneWeight", "pcsTwoWeight"];
      formData.numberOfPcs = 2;
      formData.weight = parseFloat(formData.pcsOneWeight) + parseFloat(formData?.pcsTwoWeight);
    }

    const _formData = {
      ...formData,
      treatment: formData?.treatment?.join(", "),
      pcsOneWeight: formData?.pcsOneWeight
        ? Number(formData?.pcsOneWeight)
        : null,
      pcsTwoWeight: formData?.pcsTwoWeight
        ? Number(formData?.pcsTwoWeight)
        : null,
    };

    setLoading(true);
    dataService(
      method,
      _formData,
      required,
      `${gemsRoute(
        method === postMethod ? product.id : formData.gemstonesStageId
      )}/stage`
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      "this stage",
      null,
      `${gemsRoute(item.id)}/stage`,
      handleResponse
    );
  };

  const toggle = (fileName: string, id: number) => {
    setTarget({ id: id, fileName: fileName });
    handleShow(setShowDeleteImage);
  };

  const showAddImageForm = (item?: any) => {
    let tempImages = item.images;
    if (tempImages.length > 0) {
      setUploadedFiles(tempImages);
    } else {
      setUploadedFiles([]);
    }
    setTarget({ gemstonesStageId: item.id });
    handleShow(setShowAddImage);
  };

  const uploadedThumbs = uploadedFiles.map((file: any, i) => (
    <div className="image-area col-6 rounded" key={file.fileName}>
      <img
        src={`${RES_API_URL}${file.url}`}
        className="thumb-img"
        alt={file.fileName}
        key={file.fileName}
      />
      <button
        className="btn remove-image-2"
        onClick={() => toggle(file.fileName, i)}
        type="button"
      >
        <IconClose />
      </button>
    </div>
  ));

  const stageImage = (item: any) => {
    let tempImages = item.images;

    let tempDiv: any = (
      <section className="product-card-small-image cursor">
        <Image src="images/no-image.png" alt="Thumbnail" />
      </section>
    );

    if (tempImages.length > 0) {
      let imageUrl = tempImages[0].url;
      tempDiv = (
        <section className="product-card-small-image cursor">
          <Image
            src={`${RES_API_URL}${imageUrl}`}
            onClick={() => {
              setTarget({ image: tempImages[0].url });
              handleShow(setShowImage);
            }}
          />
        </section>
      );
    }

    return tempDiv;
  };

  const handleDeleteImage = async () => {
    deleteUploadTwo(target?.fileName).then((data) => {
      deleteImage(`remove/gemstonesStage/images`, target?.fileName).then(
        (res) => {
          dataService(
            deleteMethod,
            { fileName: target?.fileName },
            [],
            `remove/gemstonesStage/images`
          ).then((result) => {
            handleResponse(result);
          });
        }
      );
    });
  };

  console.log(product);

  return (
    <Col md={12}>
      {showImage && (
        <Lightbox
          mainSrc={`${RES_API_URL}${target?.image}`}
          imageTitle={product?.itemName}
          // imageCaption={`${translate('color')} - ${target?.colorName}`}
          onCloseRequest={() => handleClose(setShowImage)}
        />
      )}

      <section className="bg-card mb-4">
        <section className="flex-between mb-3">
          <h6>Stages</h6>
          <Button
            className="text-white bg-grad"
            disabled={product?.childGemstones?.length}
            onClick={showForm}
          >
            <IconAdd /> <span className="ms-2">{translate("createNew")}</span>
          </Button>
        </section>

        {product && product?.stages && product?.stages.length > 0 ? (
          <>
            <Table responsive className="mb-2" striped>
              <thead>
                <tr>
                  <th>
                    <span className="flex-center">Image</span>
                  </th>

                  <th>Dimension</th>

                  {product?.stockType === "Lots" && (
                    <React.Fragment>
                      <th>Gems size</th>
                      <th className="text-center">No. of pcs</th>
                    </React.Fragment>
                  )}

                  {product?.stockType !== "Pair" && <th>Weight</th>}

                  {product?.stockType === "Pair" && (
                    <React.Fragment>
                      <th className="text-center">Pcs 1 weight</th>
                      <th className="text-center">Pcs 2 weight</th>
                      <th>Unit</th>
                    </React.Fragment>
                  )}

                  <th>Color</th>
                  <th>Clarity</th>
                  <th>Shape and cut</th>
                  <th>Treatment</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {product?.stages.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <span className="flex-center">
                        {item.images ? stageImage(item) : "-"}
                      </span>
                    </td>
                    <td>{item.dimension || "-"}</td>

                    {product?.stockType === "Lots" && (
                      <React.Fragment>
                        <td>
                          <span className="">
                            {item.size || "-"}{" "}
                            {item.size
                              ? item.sizeUnit === "avg_size"
                                ? "ct."
                                : "pcs/ct."
                              : ""}
                          </span>
                        </td>
                        <td>
                          <span className="flex-center">
                            {item.numberOfPcs || "-"}
                          </span>
                        </td>
                      </React.Fragment>
                    )}

                    {product?.stockType !== "Pair" && (
                      <td>
                        {item.weight} {item.weightUnit}
                      </td>
                    )}

                    {product?.stockType === "Pair" && (
                      <React.Fragment>
                        <td>
                          <span className="flex-center">
                            {item.pcsOneWeight ? `${item.pcsOneWeight}` : "-"}
                          </span>
                        </td>
                        <td>
                          <span className="flex-center">
                            {item.pcsTwoWeight ? `${item.pcsTwoWeight}` : "-"}
                          </span>
                        </td>
                        <td>{item.weightUnit}</td>
                      </React.Fragment>
                    )}

                    <td>{item.color || "-"}</td>
                    <td>{item.clarity || "-"}</td>
                    <td>{item.shapeAndCut || "-"}</td>
                    <td>{item.treatment || "-"}</td>
                    <td>
                      <span className="flex-end">
                        <Button
                          className="btn-sm bg-grad ms-2"
                          disabled={Boolean(product?.childGemstones?.length)}
                          onClick={() => showAddImageForm(item)}
                        >
                          <IconImage />
                        </Button>
                        <TableRowButtons
                          item={item}
                          showForm={showForm}
                          handleDelete={handleDelete}
                          disabled={Boolean(product?.childGemstones?.length)}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <NoData noImage />
        )}
      </section>

      <CustomModal
        show={show}
        handleSubmit={handleSubmit}
        handleClose={() => handleClose(setShow)}
        id={formData?.gemstonesStageId}
        loading={loading}
        lg
      >
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Stock type</Form.Label>
              <Form.Control disabled value={product?.stockType} />
            </Form.Group>
          </Col>

          {product?.stockType === "Lots" && (
            <React.Fragment>
              <Col md={6}>
                <Form.Group className="input-form-control mb-4">
                  <section className="flex-between">
                    <Form.Label>
                      {formData?.sizeUnit === "avg_size"
                        ? "Gems size"
                        : "Pcs per cts"}
                    </Form.Label>
                    <FormCheck
                      id="switch"
                      className="switch"
                      type="switch"
                      label={"Gems size"}
                      checked={formData?.sizeUnit === "avg_size"}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          sizeUnit:
                            formData?.sizeUnit === "avg_size"
                              ? "pcs_per_cts"
                              : "avg_size",
                        })
                      }
                    />
                  </section>

                  <Form.Control
                    as="select"
                    aria-label="Select"
                    id={"gemsSize"}
                    onChange={(e: any) => {
                      if (formData?.sizeUnit === "avg_size") {
                        let resultGemsWeight =
                          e.target.value && formData?.numberOfPcs
                            ? parseFloat(
                              (e.target.value * formData?.numberOfPcs)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                            : formData?.weight;
                        setFormData({
                          ...formData,
                          size: e.target.value && parseFloat(e.target.value),
                          weight: resultGemsWeight,
                        });
                      } else {
                        let resultGemsWeight =
                          e.target.value && formData?.numberOfPcs
                            ? parseFloat(
                              ((1 / e.target.value) * formData?.numberOfPcs)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                            : formData?.weight;
                        setFormData({
                          ...formData,
                          size: e.target.value && parseFloat(e.target.value),
                          weight: resultGemsWeight,
                        });
                      }
                    }}
                    value={formData?.size}
                  >
                    <option value="">Select</option>
                    {formData?.sizeUnit === "avg_size" ? (
                      <>
                        {gemsSizes.map((item: any, index: number) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {gemsSizesByPcs.map((item: any, index: number) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6}>
                <CustomInput
                  id={"gemsQuantity"}
                  label={"Number of pcs"}
                  value={formData?.numberOfPcs}
                  handleChange={(e: any) => {
                    if (formData?.sizeUnit === "avg_size") {
                      let resultGemsWeight =
                        e.target.value && formData?.size
                          ? parseFloat(
                            (formData?.size * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                          : formData?.weight;
                      setFormData({
                        ...formData,
                        numberOfPcs: e.target.value && parseInt(e.target.value),
                        weight: resultGemsWeight,
                      });
                    } else {
                      let resultGemsWeight =
                        e.target.value && formData?.size
                          ? parseFloat(
                            ((1 / formData?.size) * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                          : formData?.weight;
                      setFormData({
                        ...formData,

                        numberOfPcs: e.target.value && parseInt(e.target.value),
                        weight: resultGemsWeight,
                      });
                    }
                  }}
                  inputType={"number"}
                />
              </Col>
            </React.Fragment>
          )}

          {product?.stockType !== "Pair" && (
            <Col md={6}>
              <CustomSelectInputGroup
                label={"Gemstone weight *"}
                id={"weight"}
                secondId={"weightUnit"}
                value={formData?.weight}
                secondValue={formData?.weightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    weight: e.target.value,
                  })
                }
                // handleChange={(e: any) => setFormData({ ...formData, gemsWeight: e.target.value, gemsPricePerCts: parseInt(e.target.value === 0 ? formData.gemsPricePerCts : (formData.totalGemsCost || 0) / (e.target.value ? parseFloat(e.target.value) : 0)), totalGemsCost: (e.target.value ? parseFloat(e.target.value) : 0) * (formData.gemsPricePerCts || 0) })}
                secondHandleChange={handleChangeTwo}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
          )}
        </Row>

        {product?.stockType === "Pair" && (
          <Row>
            <Col md={6}>
              <CustomSelectInputGroup
                label={"Pcs 1 weight *"}
                id={"pcsOneWeight"}
                secondId={"weightUnit"}
                value={formData?.pcsOneWeight}
                secondValue={formData?.weightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    pcsOneWeight: e.target.value,
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>

            <Col md={6}>
              <CustomSelectInputGroup
                label={"Pcs 2 weight *"}
                id={"pcsTwoWeight"}
                secondId={"weightUnit"}
                value={formData?.pcsTwoWeight}
                secondValue={formData?.weightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    pcsTwoWeight: e.target.value,
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
          </Row>
        )}

        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
        />

        <Form.Group>
          <Form.Label className="d-block">Treatment</Form.Label>
          {treatments?.map((item: any, index: number) => (
            <Form.Check
              key={index}
              inline
              label={item?.label}
              name="treatment[]"
              type="checkbox"
              id={`treatment-${index}`}
              value={item?.label}
              checked={formData?.treatment?.includes(item?.label)}
              onChange={handleCheckboxChange}
            />
          ))}
        </Form.Group>
      </CustomModal>

      {/* <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.gemstonesStageId}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        lg
      /> */}

      <Modal
        show={showAddImage}
        onHide={() => handleClose(setShowAddImage)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-4">{uploadedThumbs}</div>

          <div {...getRootProps({ className: " mb-3" })}>
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center">
              {imageLoading ? (
                <ImageLoading />
              ) : (
                <Button className="text-white bg-grad mt-3">
                  <IconUpload /> Upload image
                </Button>
              )}
            </div>
          </div>
          {/* {uploadedFiles.length > 0 ? (
            <div className="d-flex justify-content-center  mt-4">
              {uploadedThumbs}
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center  mt-4">
                {uploadedThumbs}
              </div>

              <div {...getRootProps({ className: " mb-3" })}>
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center">
                  {imageLoading ? (
                    <ImageLoading />
                  ) : (
                    <Button className="text-white bg-grad mt-3">
                      <IconUpload /> Upload image
                    </Button>
                  )}
                </div>
              </div>
            </>
          )} */}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button className='bg-success' onClick={() => handleClose(setShowAddImage)}>
                        Cancel
                    </Button>
                    <Button className='bg-grad' disabled={loading}>
                        <LoadingButtonLabel label="Save" disabled={loading} />
                    </Button>
                </Modal.Footer> */}
      </Modal>

      <Modal
        show={showDeleteImage}
        onHide={() => handleClose(setShowDeleteImage)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this image?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => handleClose(setShowDeleteImage)}
          >
            Cancel
          </Button>
          <Button className="bg-danger" onClick={handleDeleteImage}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default Stages;
