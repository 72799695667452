import { useContext, useEffect, useState } from "react";
import { NavDropdown, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Lang } from "..";
import { VariableContext } from "../../context";
import { logout } from "../../services";
import { actionData, getMethod } from "../../services/api";
import { IconLock, IconPerson, IconSignOut } from "./Icons";
// import { IconLogout, IconAccount, IconLock } from "./Icons";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

function AvatarDropdown() {
  const [showmenu, setShowmenu] = useState(false);
  const history = useHistory();
  const [variable, setVariable] = useContext(VariableContext);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    actionData(getMethod, `user/${user?.userId}`).then((result) => {
      setVariable({
        ...variable,
        fullName: result?.data?.fullName,
        avatar: result?.data?.avatar,
      });
    });
  };

  const handleLogout = () => {
    logout();
    history.push("/");
  };

  return (
    <div className="avatar-dropdown">
      <NavDropdown
        title={
          <div className="d-flex">
            <span className="header-avatar">
              {" "}
              <Image
                src={
                  variable?.avatar?.fileName
                    ? `${RES_API_URL}/assets/avatar/${variable?.avatar?.fileName}`
                    : "images/avatar.png"
                }
              />{" "}
            </span>
            <div className="ms-3">
              <p className="fw-bold mb-1 header-avatar-name">
                {variable?.fullName}
              </p>
              <small className="text-muted">{user ? user?.userRole : ""}</small>
            </div>
          </div>
        }
        show={showmenu}
        onClick={() => history.push("/profile")}
        // onMouseEnter={() => setShowmenu(true)}
        onMouseLeave={() => setShowmenu(false)}
      >
        <div className="p-2 d-flex align-items-center flex-column object-fit">
          <Image
            src={
              variable?.avatar?.fileName
                ? `${RES_API_URL}/assets/avatar/${variable?.avatar?.fileName}`
                : "images/avatar.png"
            }
          />
          <p className="fw-bold mt-2 mb-1 header-avatar-name">
            {variable?.fullName}
          </p>
          <small className="text-muted">{user ? user?.userRole : ""}</small>
        </div>
        <NavDropdown.Item href="/profile" className="nav-link">
          <IconPerson />{" "}
          <span className="text-muted ms-2">
            <Lang label="profile" />
          </span>
        </NavDropdown.Item>
        <NavDropdown.Item href="/change-password" className="nav-link">
          <IconLock />{" "}
          <span className="text-muted ms-2">
            <Lang label="changePassword" />
          </span>
        </NavDropdown.Item>
        <NavDropdown.Item className="nav-link" onClick={() => handleLogout()}>
          <IconSignOut />{" "}
          <span className="text-muted ms-2">
            <Lang label="signOut" />
          </span>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default AvatarDropdown;
