import { useContext, useEffect, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { FormFieldService } from "../../../components";
import { PageContext, VariableContext } from "../../../context";
import { inventoryTypes, priceFromData, priceToData } from "../../../data";
import { handleClose, inputChangeService } from "../../../services";
import {
  actionData,
  getMethod,
  jewelleryTypeFilterRoute,
} from "../../../services/api";

const Filter = ({
  show,
  setShow,
  handleGetData,
  handleGetDataByFilter,
}: {
  show: boolean;
  setShow: any;
  handleGetData: any;
  handleGetDataByFilter: any;
}) => {
  const [jewelleryTypes, setJewelleryTypes] = useState([]);
  const [gemsStoneTypes, setGemsStoneTypes] = useState([]);
  const [page, setPage] = useContext(PageContext);
  const [variable, setVariable] = useContext(VariableContext);

  const [formData, setFormData] = useState({
    itemName: "",
    sku: "",
    jewelleryType: "",
    gemsStoneType: "",
    inventoryType: "",
    sellingPriceFrom: "",
    sellingPriceTo: "",
  });

  const handlePriceToData = () => {
    let temp: any = [];
    priceToData.forEach((item: any) => {
      if (parseInt(item.value) > parseInt(formData.sellingPriceFrom))
        temp.push(item);
    });
    return temp;
  };

  const fields = [
    { id: "itemName", label: "Item name", value: formData.itemName },
    { id: "sku", label: "SKU", value: formData.sku },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes.filter((i: any) => i?.key !== "ordered_item"),
      wantString: true,
    },
    {
      id: "jewelleryType",
      label: "Jewellery type",
      value: formData.jewelleryType,
      type: "select",
      data: jewelleryTypes,
      wantString: true,
    },
    {
      id: "gemsStoneType",
      label: "Gemstone type",
      value: formData.gemsStoneType,
      type: "select",
      data: gemsStoneTypes,
      wantString: true,
    },
    {
      id: "sellingPriceFrom",
      label:
        formData.sellingPriceFrom === "<1000000" ||
        formData.sellingPriceFrom === ">500000000"
          ? "Price"
          : "Price from",
      value: formData.sellingPriceFrom,
      type: "select",
      data: priceFromData,
      wantString: true,
      keyType: "value",
    },
    {
      id: "sellingPriceTo",
      label: "Price to",
      value: formData.sellingPriceTo,
      type: "select",
      data: handlePriceToData(),
      wantString: true,
      keyType: "value",
      hide:
        formData.sellingPriceFrom === "<1000000" ||
        formData.sellingPriceFrom === ">500000000",
    },
  ];

  useEffect(() => {
    handleGetAttribute();
    if (variable?.jewelleryPOSFilterActionData) {
      let _data = variable?.jewelleryPOSFilterActionData;
      setFormData({
        itemName: _data?.itemName || "",
        sku: _data?.sku || "",
        inventoryType: _data?.inventoryType || "shop_inventory",
        jewelleryType: _data?.jewelleryType || "",
        gemsStoneType: _data?.gemsStoneType || "",
        sellingPriceFrom: _data?.sellingPriceFrom || "",
        sellingPriceTo: _data?.sellingPriceTo || "",
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleGetAttribute = async () => {
    actionData(getMethod, jewelleryTypeFilterRoute()).then((result) => {
      setJewelleryTypes(result.data);
    });
    actionData(getMethod, "gemstones-type/filter").then((result) => {
      setGemsStoneTypes(result.data);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const filterAction = () => {
    let _data: any = {};

    for (let [key, value] of Object.entries(formData)) {
      if (value !== null && value !== "" && value !== undefined) {
        _data[key] = value;
      }
    }

    if (_data.sellingPriceFrom === "<1000000") {
      _data.sellingPriceFrom = "0";
      _data.sellingPriceTo = "1000000";
    }

    if (_data.sellingPriceFrom === ">500000000") {
      _data.sellingPriceFrom = "500000000";
    }

    handleClose(setShow);
    handleGetDataByFilter(0, _data);
    setVariable({
      ...variable,
      jewelleryPOSFilterActionData: _data,
      isJewelleryPOSFilter: true,
    });
  };

  const handleClearFilter = () => {
    setFormData({
      itemName: "",
      sku: "",
      inventoryType: "",
      jewelleryType: "",
      gemsStoneType: "",
      sellingPriceFrom: "",
      sellingPriceTo: "",
    });

    setPage({ ...page, current: 0 });
    handleClose(setShow);
    handleGetData(0, page.sorting);
    setVariable({
      ...variable,
      jewelleryPOSFilterActionData: {},
      isJewelleryPOSFilter: false,
    });
  };

  return (
    <Offcanvas show={show} onHide={() => handleClose(setShow)} placement="end">
      <Offcanvas.Header closeButton className="bg-grad">
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-4">
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={12}
        />
        <Row>
          <Col>
            <Button className="w-100 btn-wh bg-grad" onClick={filterAction}>
              Search
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100 btn-wh bg-danger"
              onClick={handleClearFilter}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Filter;
