// import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InfoTable, ThumbsnailImage } from "..";
// import JewelleryDetailInfo from "./JewelleryDetailInfo";

const GemInfo = ({ gem }: { gem: any }) => {
  // const [show, setShow] = useState(false);

  const data = [
    { label: "Name", value: gem?.itemName },
    { label: "SKU", value: gem?.sku },
    { label: "Stock type", value: gem?.stockType || "-" },
    { label: "Stone type", value: gem?.stoneType || "-" },
    {
      label: "gem weight",
      value: gem?.gemWeight ? `${gem?.gemWeight} ${gem?.gemWeightUnit}` : "-",
    },
    {
      label: "Number of Pcs",
      value: gem?.stages?.[gem?.stages?.length - 1]?.numberOfPcs || "-",
    },
    { label: "Currency", value: gem?.price?.currency || "-" },
  ];

  return (
    <div>
      {/* <JewelleryDetailInfo show={show} setShow={setShow} /> */}

      <Row>
        <Col md={4}>
          <div className="detail-item-image">
            <ThumbsnailImage item={gem} />
          </div>
          {/* <div className="text-center">
                        <Button className="bg-grad mt-4" onClick={() => setShow(true)}><IconFile /> <span className="ms-2">More detail</span></Button>
                    </div> */}
        </Col>
        <InfoTable data={data} />
      </Row>
    </div>
  );
};

export default GemInfo;
