import { Button, Col, Table } from "react-bootstrap";
import {
  CustomInput,
  CustomModal,
  FormFieldService,
  Lang,
  NoData,
  TableRowButtons,
} from "../../../../components";
import { useEffect, useState } from "react";
import {
  dateFormat2,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
  totalBalance,
} from "../../../../services";
import {
  actionData,
  dataService,
  deleteService,
  getMethod,
  postMethod,
  putMethod,
  responseService,
} from "../../../../services/api";
import { IconAdd } from "../../../../components/widgets/Icons";
import { paymentSeries } from "../../../../data";
import { toast } from "react-toastify";

const Payments = ({
  detail,
  handleGetData,
}: {
  detail: any;
  handleGetData: any;
}) => {
  const data = detail?.paymentTrx;
  const [show, setShow] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const saleCancelled = detail?.sale_cali_gemstone_items?.find(
    (i: any) => i?.isCancel
  );

  const [formData, setFormData] = useState<any>({
    paymentId: "",
    paymentMethodId: "",
    paymentSeries: "Advanced",
    paymentDate: new Date(),
    amount: "",
  });

  const fields = [
    {
      id: "paymentMethodId",
      label: "Payment method *",
      value: formData.paymentMethodId,
      type: "select",
      data: paymentMethods,
    },
    {
      id: "paymentSeries",
      label: "Payment series *",
      value: formData.paymentSeries,
      type: "select",
      data: paymentSeries,
      wantString: true,
    },
    {
      id: "paymentDate",
      label: "Payment date",
      value: handleDateFormat(formData.paymentDate, dateFormat2),
      type: "date",
    },
  ];

  const required = ["paymentMethodId", "paymentSeries", "amount"];

  useEffect(() => {
    handleGetPaymentMethds();
    // eslint-disable-next-line
  }, []);

  const handleGetPaymentMethds = () => {
    let temp: any = [];
    actionData(getMethod, "payment/methods").then((result) => {
      if (result.data && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({ label: item.bankName, value: item.id, keyType: "value" });
        });
      }
      setPaymentMethods(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    if (evt.target.value === "final") {
      setFormData({
        ...formData,
        paymentSeries: evt.target.value,
        amount: totalBalance(detail),
      });
    } else {
      inputChangeService(evt, formData, setFormData, wantString);
    }
  };

  const showForm = (item?: any) => {
    setFormData({
      paymentId: item?.paymentId || "",
      paymentMethodId: item?.paymentMethodId || "",
      paymentSeries: item?.paymentSeries || "Advanced",
      paymentDate: item?.paymentDate || new Date(),
      amount: item?.amount || "",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    let postRoute = `payment/cali_gemstones/${detail.id}`;
    let putRoute = `payment/cali_gemstones/${formData?.paymentId}`;

    if (formData.paymentDate && formData.amount && formData.paymentMethodId) {
      formData.amount = Number(formData?.amount);
      formData.paymentDate = formData.paymentDate
        ? new Date(formData?.paymentDate).toISOString()
        : null;

      setLoading(true);
      dataService(
        !formData?.paymentId ? postMethod : putMethod,
        formData,
        required,
        method === postMethod ? postRoute : putRoute,
        true
      ).then((result) => {
        responseService(
          result,
          handleGetData,
          handleClose(setShow),
          null,
          null,
          setLoading
        );
      });
    } else {
      toast.error("Please provide required data!");
    }
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      "this payment",
      null,
      `payment/cali_gemstones/${item?.paymentId}`,
      handleResponse
    );
  };

  return (
    <Col md={12}>
      <section className="bg-card mb-4">
        <section className="flex-between mb-3">
          <h6 className="fw-bold">Payment history</h6>
          {!saleCancelled ? (
            <Button className="text-white bg-grad" onClick={showForm}>
              <IconAdd />{" "}
              <span className="ms-2">
                <Lang label="createNew" />
              </span>
            </Button>
          ) : (
            <></>
          )}
        </section>

        {data && data.length > 0 ? (
          <>
            <Table responsive striped bordered>
              <thead>
                <tr className="text-center">
                  <th>Payment series</th>
                  <th>Paid amount</th>
                  <th>Payment method</th>
                  <th>Bank name</th>
                  <th>Payment date</th>
                  {!saleCancelled && <th />}
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <span className="flex-center">
                        {paymentSeries.filter(
                          (paymentSerie: any) =>
                            paymentSerie.key === item?.paymentSeries
                        )[0]?.label || "-"}
                      </span>
                    </td>
                    <td>
                      <span className="flex-end">
                        {detail?.currency} {numberFormat(item?.amount)}
                      </span>
                    </td>
                    <td>
                      <span className="flex-center">
                        {item?.paymentMethod || "-"}
                      </span>
                    </td>
                    <td>
                      <span className="flex-center">
                        {item?.bankName || "-"}
                      </span>
                    </td>
                    <td>
                      <span className="flex-center">
                        {handleDateFormat(item?.paymentDate)}
                      </span>
                    </td>
                    {!saleCancelled && (
                      <td>
                        <TableRowButtons
                          item={item}
                          showForm={showForm}
                          handleDelete={handleDelete}
                          flex="flex-center"
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <NoData noImage />
        )}
      </section>

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.paymentId}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={12}
        />

        <CustomInput
          id={"amount"}
          label={"Paid amount *"}
          value={formData.amount}
          handleChange={(e: any) => {
            setFormData({ ...formData, amount: e.target.value });
            // if (e.target.value <= totalBalance(detail)) {
            //     setFormData({ ...formData, amount: e.target.value })
            // } else {
            //     toast.error(`Paid amount is greater than balance amount - ${numberFormat(totalBalance(detail))} MMK!`)
            // }
          }}
          inputType={"number"}
        />
      </CustomModal>
    </Col>
  );
};

export default Payments;
