import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import {
  Layout,
  TableLayout,
  CustomModalService,
  GoBack,
} from "../../components";
import { IconEdit } from "../../components/widgets/Icons";
import { PageContext } from "../../context/Page";
import {
  getNumber,
  handleClose,
  handleDateTimeFormat,
  handleShow,
  inputChangeService,
} from "../../services";
import {
  actionData,
  customRoute,
  dataService,
  gemsStoneTypeRoute,
  getDataService,
  getMethod,
  jewelleryTypeRoute,
  responseService,
} from "../../services/api";

const ChangePrices = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);
  const [jewelleryTypes, setJewelleryTypes] = useState([]);
  const [gemsStoneTypes, setGemsStoneTypes] = useState([]);
  const [formData, setFormData] = useState({
    inventoryType: "All",
    inventorySubType: "All",
    action: "Increase",
    inflationRate: "",
  });

  const required = ["inflationRate"];

  const inventoryTypes = [
    { label: "All" },
    { label: "Jewellery" },
    { label: "Gems" },
  ];
  const actionTypes = [{ label: "Increase" }, { label: "Decrease" }];

  const fields = [
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "inventorySubType",
      label: "Inventory sub type",
      value: formData.inventorySubType,
      type: "select",
      disableInititalSelect: true,
      data:
        formData.inventoryType === "Jewellery"
          ? jewelleryTypes
          : gemsStoneTypes,
      wantString: true,
      hide: formData.inventoryType === "All",
    },
    {
      id: "action",
      label: "Action",
      value: formData.action,
      type: "select",
      data: actionTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "inflationRate",
      label: "Inflation rate (%) *",
      value: formData.inflationRate,
      inputType: "number",
    },
  ];

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService(
      customRoute("price/changes", skip),
      setLoading,
      setData,
      setPage,
      skip
    );
    actionData(getMethod, jewelleryTypeRoute).then((result) => {
      let temp: any = [{ label: "All" }];
      result.data.forEach((data: any) => {
        temp.push(data);
      });
      setJewelleryTypes(temp);
    });
    actionData(getMethod, gemsStoneTypeRoute).then((result) => {
      let temp: any = [{ label: "All" }];
      result.data.forEach((data: any) => {
        temp.push(data);
      });
      setGemsStoneTypes(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    if (evt.target.id === "inventoryType") {
      setFormData({
        ...formData,
        inventoryType: evt.target.value,
        inventorySubType: "All",
      });
    } else {
      inputChangeService(evt, formData, setFormData, wantString);
    }
  };

  const showForm = () => {
    setFormData({
      inventoryType: "All",
      inventorySubType: "All",
      action: "Increase",
      inflationRate: "",
    });
    handleShow(setShow);
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    swal({
      // title: `Are you sure to delete?`,
      text: `Are you sure to change prices!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setLoading(true);
        dataService(method, formData, required, "price/change").then(
          (result) => {
            handleResponse(result);
          }
        );
      }
    });
  };

  return (
    <Layout title="Change prices">
      <div className="flex-between mb-4">
        <GoBack label="Configuration" />
        <div className="d-flex align-items-center">
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconEdit /> <span className="ms-2">Update price</span>
          </Button>
        </div>
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Inventory type</th>
            <th>Inventory sub type</th>
            <th>Action type</th>
            <th>Rate (%)</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => (
            <tr key={index}>
              <td className="text-center">{getNumber(index, page.current)}</td>
              <td>
                <span className="flex-center">{item.inventoryType}</span>
              </td>
              <td>
                <span className="flex-center">{item.inventorySubType}</span>
              </td>
              <td>
                <span className="flex-center">{item.action}</span>
              </td>
              <td>
                <span className="flex-center">{item.inflationRate} %</span>
              </td>
              <td>
                <span className="flex-center">
                  {handleDateTimeFormat(item.createdAt)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={null}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
        customTitle="Update prices"
        lg
      />
    </Layout>
  );
};

export default ChangePrices;
