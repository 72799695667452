import { useContext, useEffect, useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Lang, Layout, SearchInput, TableLayout } from "../../../components";
import { IconDelete, IconFile } from "../../../components/widgets/Icons";
import { PageContext } from "../../../context/Page";
import {
  getNumber,
  handleDateFormat,
  netPrice,
  numberFormat,
  totalAmount,
} from "../../../services";
import {
  deleteService,
  responseService,
  actionData,
  postMethod,
  getRouteWithPaginate,
  getDataService,
  posSalesCalibratedGemRoute,
} from "../../../services/api";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useContext(PageContext);
  const history = useHistory();
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService(
      getRouteWithPaginate(posSalesCalibratedGemRoute, skip),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleGetSearchData = (skip: number, searchString: string) => {
    setLoading(true);
    actionData(
      postMethod,
      getRouteWithPaginate(posSalesCalibratedGemRoute, skip),
      {
        keyword: searchString,
      }
    ).then((result) => {
      setData(result.data);
      setPage &&
        setPage({
          current: skip,
          total: result.count,
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  //Sale print function
  // const componentRef = useRef(null);
  // const reactToPrintContent = useCallback(() => {
  //     return componentRef.current;
  // }, []);
  // const PrintAction = useReactToPrint({
  //     content: reactToPrintContent,
  // });
  const allItemsAreGifts = (item: any) => item?.every((item: any) => item.isGift);
  const handleResponse = (result: any) => {
    responseService(result, handleGetData, null, null, null, setLoading);
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.invoiceNumber,
      { sellerId: user?.userId },
      `${posSalesCalibratedGemRoute}/${item?.id}`,
      handleResponse
    );
  };

  const handleSerachEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      if (e.target.value) {
        handleGetSearchData(0, e.target.value);
      } else {
        handleGetData(0);
        setSearchString("");
      }
    }
  };

  const handleChangePage = (skip: number) => {
    searchString
      ? handleGetSearchData(skip, searchString)
      : handleGetData(skip);
  };

  return (
    <Layout title="Calibrated gems sales">
      <div className="mb-4 flex-between">
        <SearchInput
          handleSerachEnterAction={handleSerachEnterAction}
          searchString={searchString}
          setSearchString={setSearchString}
          handleClearSearch={() => {
            handleGetData(0);
            setSearchString("");
          }}
        />

        {/* Sale print component */}
        {/* <section className="d-none"><ListPrint ref={componentRef} data={data} /></section> */}

        <div className="d-flex align-items-center">
          {page.total ? (
            <span>
              <small className="text-muted">Result : </small>
              {numberFormat(page.total)}
            </span>
          ) : (
            <></>
          )}
          {/* <Button className="text-white bg-grad ms-3" onClick={PrintAction}><IconPrint /> <span className="ms-2">Print</span></Button> */}
        </div>
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
        isFilter
        handleChangePage={handleChangePage}
      >
        <thead>
          <tr className="">
            <th className="text-center">
              <Lang label="no." />
            </th>
            <th>Invoice no.</th>
            <th>
              <Lang label="customer" />
            </th>
            <th>Amount</th>
            <th className="text-center">Status</th>
            <th>Selling date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => (
            <tr key={index}>
              <td className="text-center">{getNumber(index, page?.current)}</td>
              <td>
                <span className="d-block">{item.invoiceNumber}</span>
                {allItemsAreGifts(item?.sale_cali_gemstone_items) && <Badge bg="primary">Gifted</Badge>}
                {item?.sale_cali_gemstone_items?.find(
                  (i: any) => i?.isCancel
                ) && <Badge bg="danger">Cancelled</Badge>}
              </td>
              <td>{item?.cali_gems_buyer?.fullName}</td>
              <td>
                <span className="flex-end">
                  {item?.sale_cali_gemstone_items?.[0]?.currency}{" "}
                  {numberFormat(
                    totalAmount(
                      netPrice(item?.sale_cali_gemstone_items)?.toString(),
                      item
                    )
                  )}
                </span>
              </td>
              <td className="text-center">
                <Badge bg="success">{item?.paymentStatus}</Badge>
              </td>
              <td>
                <span className="flex-center">
                  {handleDateFormat(item.createdAt)}
                </span>
              </td>
              <td>
                <span className="flex-center">
                  <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                    <Button
                      className="btn-sm bg-grad"
                      onClick={() =>
                        history.push({
                          pathname: "/calibrated-gems-sale-detail",
                          state: { item: item },
                        })
                      }
                    >
                      <IconFile />
                    </Button>
                  </OverlayTrigger>

                  {!item?.sale_cali_gemstone_items?.find(
                    (i: any) => i?.isCancel
                  ) && (
                      <OverlayTrigger overlay={<Tooltip>Delete sale</Tooltip>}>
                        <Button
                          className="ms-2 bg-danger btn-sm"
                          onClick={() => handleDelete(item)}
                        >
                          <IconDelete />
                        </Button>
                      </OverlayTrigger>
                    )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>
    </Layout>
  );
};

export default Index;
