import { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FileWithPath, useDropzone } from "react-dropzone";
import {
  actionData,
  deleteMethod,
  getMethod,
  postMethod,
  putMethod,
} from "../../services/api";
import Skeleton from "react-loading-skeleton";
import { FormFieldService, Layout } from "../../components";
import { IconCamera, IconPerson } from "../../components/widgets/Icons";
import { deleteAvatar, uploadAvatar } from "../../services/api";
import { VariableContext } from "../../context";
import { handleClose, handleShow, inputChangeService } from "../../services";
import { personData } from "../../data";
import { DetailLoading } from "../../components/widgets/Loading";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  fileName?: string;
  colorName?: string;
  image?: string;
}

function Profile() {
  const history = useHistory();
  const tempUser: any = localStorage.getItem("user");
  const [loading, setLoading] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const user = tempUser && JSON.parse(tempUser);
  const [variable, setVariable] = useContext(VariableContext);
  const [show, setShow] = useState(false);
  const [fileName, setFileName] = useState("");
  const [target, setTarget] = useState<Target>();
  const [formData, setFormData] = useState<any>(personData);
  const [countries, setCounties] = useState([]);
  const [regions, setRegions] = useState([]);

  const fields = [
    { id: "fullName", label: "Full name *", value: formData.fullName },
    { id: "email", label: "Email", value: formData.email },
    { id: "phone", label: "Phone", value: formData.phone },
    { id: "address", label: "Address", value: formData.address },
  ];

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    setLoading(true);
    actionData(getMethod, `user/${user?.userId}`).then((result: any) => {
      let userData = result.data;
      setFormData({
        ...formData,
        id: user?.userId,
        avatar: userData?.avatar || "",
        fullName: userData?.fullName || "",
        phone: userData?.phone || "",
        email: userData?.email || "",
        address: userData?.address || "",
        city: userData?.city || "",
        countryId: userData?.countryId || "",
        stateId: userData?.stateId || "",
      });
      setVariable({
        ...variable,
        fullName: userData?.fullName,
        avatar: userData?.avatar,
      });
      setFileName(userData?.avatar?.fileName || "");

      setTimeout(() => {
        setLoading(false);
      }, 1000);

      handleGetRegions(userData?.countryId || "");
    });
    actionData(getMethod, `countries`).then((result: any) => {
      setCounties(result.data);
    });
  };

  const handleSelectCountry = (evt: any) => {
    const value = evt.target.value;
    setFormData({ ...formData, countryId: value ? parseInt(value) : "" });
    handleGetRegions(value);
  };

  const handleGetRegions = async (countryId: string) => {
    actionData(getMethod, `states/` + countryId).then((result) => {
      setRegions(result.data);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleSubmit = () => {
    actionData(putMethod, "profile/" + user?.userId, formData).then(
      (result) => {
        if (result.success) {
          toast.success("Successfully profile updated!");
          handleGetData();
        } else {
          toast.error(result.message);
        }
      }
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageUploadLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );
        uploadAvatar(tempFormData).then((data) => {
          let _formData = {
            userId: user?.userId,
            avatar: data[0],
          };

          actionData(postMethod, "avatar/user", _formData).then(
            (result: any) => {
              if (result?.id || result?.success) {
                toast.success("Successfully avatar updated!");
                handleGetData();
              }
            }
          );

          setImageUploadLoading(false);
        });
      }, 1000);
    },
  });

  const handleDeleteUploaded = async (file: any, i: any) => {
    setImageUploadLoading(true);
    deleteAvatar({ file: file }).then((data) => {
      setFileName("");
      let _formData = {
        fileName: file,
      };

      actionData(deleteMethod, "avatar", _formData).then((result: any) => {
        if (result?.id || result?.success) {
          toast.success("Successfully deleted!");
          handleGetData();
        }
      });

      setImageUploadLoading(false);
      handleClose(setShow);
    });
  };

  const toggle = (fileName: string, id: number) => {
    setTarget({ id: id, fileName: fileName });
    handleShow(setShow);
  };

  return (
    <Layout title="Profile">
      {loading ? (
        <DetailLoading />
      ) : (
        <div className="bg-card">
          <div className="d-flex flex-column justify-content-center align-items-center my-4">
            {!imageUploadLoading ? (
              <div className="position-relative">
                <div className="avatar">
                  {fileName ? (
                    <img
                      src={`${RES_API_URL}/assets/avatar/${fileName}`}
                      className="thumb-img"
                      alt={fileName}
                    />
                  ) : (
                    <IconPerson size={80} />
                  )}
                </div>

                {!fileName && (
                  <div className="btn-upload">
                    <div {...getRootProps({ className: " mb-3" })}>
                      <input {...getInputProps()} />
                      <IconCamera />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Skeleton height={150} width={150} borderRadius={100} />
              </div>
            )}

            {fileName && (
              <small
                className="mt-3 cursor text-danger"
                onClick={() => toggle(fileName, 0)}
              >
                Remove avatar
              </small>
            )}
          </div>

          <FormFieldService
            fields={fields}
            handleChange={handleChange}
            handleInputChange={null}
            formData={formData}
            setFormData={setFormData}
          />

          <Row>
            <Col md={6}>
              <Form.Group className="mb-4 input-form-control">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  aria-label="Select"
                  className="mb-4"
                  id="countryId"
                  value={formData.countryId}
                  onChange={handleSelectCountry}
                >
                  <option value="">Select</option>
                  {countries.map((item: any, index: number) => (
                    <option value={item.id} key={index}>
                      {item.countryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-4 input-form-control">
                <Form.Label>Region / State</Form.Label>
                <Form.Control
                  as="select"
                  aria-label="Select"
                  className="mb-4"
                  id="stateId"
                  value={formData.stateId}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {regions.map((item: any, index: number) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-4 input-form-control">
                <Form.Label>City</Form.Label>
                <Form.Control
                  className="mb-4"
                  id="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex">
            <Button className="bg-grad" onClick={handleSubmit}>
              Save
            </Button>
            <Button
              className="bg-success ms-2"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      <Modal show={show} onHide={() => handleClose(setShow)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete avatar?</Modal.Body>
        <Modal.Footer>
          <Button className="bg-success" onClick={() => handleClose(setShow)}>
            Cancel
          </Button>
          <Button
            className="bg-danger"
            onClick={() => handleDeleteUploaded(target?.fileName, target?.id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default Profile;
