import React, { useContext } from "react";
import { Table, Image } from "react-bootstrap";
import { QRView, Thumbsnail } from "..";
import {
  getDiscountAmountByPercentage,
  handleDateFormat,
  numberFormat,
  totalAmount,
} from "../../services";
import { LangContext } from "../../context";
import InvoiceHeader from "./InvoiceHeader";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const SetInvoicePdf = React.forwardRef((props: any, ref: any) => {
  let data = props.data;
  let showDataCheck = props.showDataCheck;
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <div ref={ref} className="print-container">
      {/* <section className="text-center mb-2">
        <img src="images/mr.spinel.png" alt="no_found" width={250} />
        <span className="d-block my-2 print-text">
          <small>78 street, Bet; 34 x 35 street, Mandalay | </small>{" "}
          <small>Ph : 09-43200898 , 09-799075549 , 09-425291390</small>
        </span>
      </section> */}
      <InvoiceHeader />

      <div className="flex-between align-items-center">
        <div style={{ width: "50%" }}>
          <span className="d-block mb-2 print-text">
            <small className="text-muted">Customer</small> :{" "}
            <small>{data?.buyer?.fullName || ""}</small>
          </span>
          <span className="d-block mb-2 print-text">
            <small className="text-muted">Phone</small> :{" "}
            <small>{data?.buyer?.phone || ""}</small>
          </span>
          {data?.buyer?.address && (
            <span className="d-block mb-2 print-text">
              <small className="text-muted">{translate("address")}</small> :{" "}
              <small>{data?.buyer?.address || "-"} </small>
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          <QRView text={data?.invoiceNumber} width={40} />
          <div className="ms-3 d-flex flex-column align-items-end">
            <span className="d-block mb-2 print-text mt-1">
              <small>{data?.invoiceNumber}</small>
            </span>
            <span className="d-block mb-2 print-text">
              <small className="text-muted">{translate("date")}</small> :{" "}
              <small>{handleDateFormat(data?.createdAt)}</small>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <span className="fw-bold border text-center p-2 print-text">
          SALE INVOICE
        </span>
      </div>

      <Table responsive striped bordered className="print-table mt-4 mb-3">
        <thead>
          <tr>
            <th>No.</th>
            <th style={{ width: "18%" }}>Code</th>
            <th>Item</th>
            {showDataCheck.showGemsWeight && <th>Gem (carat)</th>}
            <th>Diamond (carat)</th>
            <th>Gold (grams)</th>
            <th>Weight (grams)</th>
            <th>Amount (MMK)</th>
          </tr>
        </thead>
        <tbody>
          {data?.sets.jewellery.map((subItem: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">{index + 1}</span>
              </td>
              <td>
                <span className="flex-center print-text">
                  <small>{subItem?.sku}</small>
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div className="d-flex flex-column align-items-center">
                    <div className="thumbnail-list-image-sm">
                      <Thumbsnail images={subItem?.images} />
                    </div>
                  </div>
                </span>
              </td>
              {showDataCheck.showGemsWeight && (
                <td>
                  <span className="flex-end">
                    {subItem?.weight?.totalGemsWeight}
                  </span>
                </td>
              )}
              <td>
                <span className="flex-end">
                  {subItem?.weight?.totalDiamondsWeight}
                </span>
              </td>
              <td>
                <span className="flex-end">
                  {subItem?.gold?.totalGoldWeight}
                </span>
              </td>
              <td>
                <span className="flex-end">{subItem?.jewelleryWeight}</span>
              </td>
              <td>
                <span className="flex-end">
                  {numberFormat(subItem?.sellingPrice)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div className="thumbnail-list-image flex-center">
            {data?.sets?.images ? (
              <Image
                src={`${RES_API_URL}${data?.sets?.images?.url}`}
                alt="no_found"
              />
            ) : (
              <Image src={"images/no-image.png"} />
            )}
          </div>
          <div className="ms-3">
            <span className="d-block mb-2 print-text">
              <small>{data?.sets?.itemName}</small>
            </span>
            <span className="d-block mb-1 print-text">
              <small className="text-muted">{data?.sets?.sku}</small>
            </span>
            {data?.sets?.jewelleryIncluded ? (
              <span className="print-text d-block">
                <small className="text-muted">Jewellery included : </small>
                <small>{data?.sets?.jewelleryIncluded}</small>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>

        {data?.isGift ? (
          <></>
        ) : (
          <div className="me-2 w-25">
            <div className="flex-between mb-2 pb-2 ">
              <small className="text-muted d-block print-text ">Subtotal</small>
              <small className="fw-bold print-text ">
                {numberFormat(data?.netPrice)}
              </small>
            </div>
            <div className="flex-between mb-2 pb-2 ">
              <small className="text-muted d-block print-text ">
                Discount{" "}
                {data?.discountType === "by_percentage" &&
                  `(${data?.discountValue}%)`}
              </small>
              <small className="fw-bold print-text ">
                {data?.discountValue && data?.discountValue !== "0" && "-"}{" "}
                {data?.discountValue
                  ? numberFormat(
                      data?.discountType === "by_percentage"
                        ? getDiscountAmountByPercentage(data?.netPrice, data)
                        : data?.discountValue
                    )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top ">
              <small className="text-muted d-block print-text ">Total</small>
              <small className="fw-bold print-text ">
                {numberFormat(totalAmount(data?.netPrice, data))}
              </small>
            </div>
          </div>
        )}
      </div>

      {data?.saleNotes && showDataCheck.showSaleNotes && (
        <span className="d-block print-text mt-5">
          <small className="text-muted">Notes </small> :{" "}
          <small>{data?.saleNotes}</small>
        </span>
      )}

      <small className="text-danger print-text text-center d-block mb-3 mt-5">
        {translate("receipt-tip")}
      </small>
      <small className="text-muted print-text text-center d-block">
        {translate("thankForYourPurchase")}
      </small>
    </div>
  );
});

export default SetInvoicePdf;
