import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  IconAdd,
  IconClose,
  IconGrid,
  IconList,
  // IconBoxs,
  // IconPrint,
  IconSearch,
} from "../../../../components/widgets/Icons";
import { VariableContext } from "../../../../context/Variable";
import { inventoryTypes } from "../../../../data";
import { actionData, getMethod } from "../../../../services/api";
// import { actionData, getMethod } from "../../../../services/api";

interface HeaderProps {
  type: string;
  keyword: string;
  stoneType: string;
  shapeAndCut: string;
  inventoryType: string;
  handleSetKeyword: (data: any) => void;
  handleSetType: (data: any) => void;
  handleStoneType: (data: any) => void;
  handleShapeAndCut: (data: any) => void;
  handleSetInventoryType: (data: any) => void;
  handleGetData: (v: any, inv: any, k: any, st: any, sc: any) => void;
}

const Header: React.FC<HeaderProps> = ({
  type,
  inventoryType,
  keyword,
  stoneType,
  shapeAndCut,
  handleSetKeyword,
  handleSetType,
  handleStoneType,
  handleShapeAndCut,
  handleSetInventoryType,
  handleGetData,
}) => {
  const history = useHistory();
  const [stoneTypes, setStoneTypes] = React.useState([]);
  const [shapes, setShapes] = React.useState([]);
  const [variable, setVariable] = useContext(VariableContext);

  const handleGetStoneTypeOptions = () => {
    actionData(getMethod, "cali-gems-stone-type").then((result) => {
      setStoneTypes(result?.data);
    });
  };

  const handleGetShapesOptions = () => {
    actionData(getMethod, `cali-gems-shape-and-cut`).then((result) => {
      setShapes(result.data);
    });
  };

  React.useEffect(() => {
    handleGetStoneTypeOptions();
    handleGetShapesOptions();
  }, []);

  const handleSearch = (e: any) => {
    if (e.keyCode === 13) {
      handleGetData(type, inventoryType, keyword, stoneType, shapeAndCut);
    }
  };

  const handleSelectChange = (value: any) => {
    handleSetInventoryType(value);
    handleGetData(type, value, keyword, stoneType, shapeAndCut);
  };

  const handleStoneTypeChange = (value: any) => {
    handleStoneType(value);
    handleGetData(type, inventoryType, keyword, value, shapeAndCut);
  };

  const handleShapeAndCutChange = (value: any) => {
    handleShapeAndCut(value);
    handleGetData(type, inventoryType, keyword, stoneType, value);
  };

  // const handleSelectChange = (value: any) => {
  //   handleSetType(value);
  //   handleGetData(value, keyword);
  // };

  return (
    <section className="d-flex justify-content-between mb-4">
      <Col md="3">
        <InputGroup className="search-input">
          <InputGroup.Text className="bg-grad text-white">
            <IconSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            value={keyword}
            onChange={(e) => handleSetKeyword(e.target.value)}
            onKeyDown={(e) => handleSearch(e)}
          />
          {keyword && (
            <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>}>
              <InputGroup.Text
                className="bg-danger cursor"
                onClick={() => {
                  handleSetKeyword("");
                  handleGetData(
                    type,
                    inventoryType,
                    "",
                    stoneType,
                    shapeAndCut
                  );
                }}
              >
                <IconClose />
              </InputGroup.Text>
            </OverlayTrigger>
          )}
        </InputGroup>
      </Col>

      <Col md={9} className="d-flex justify-content-end align-items-center">
        <div className="me-2">
          <Form.Select
            defaultValue={stoneType}
            onChange={(e: any) => handleStoneTypeChange(e.target.value)}
          >
            <option value="">Stone Types</option>
            {stoneTypes?.map((item: any, index: number) => (
              <option key={index} value={item?.label}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div>

        <div className="me-2">
          <Form.Select
            defaultValue={shapeAndCut}
            onChange={(e: any) => handleShapeAndCutChange(e.target.value)}
          >
            <option value="">Shape and cut</option>
            {shapes?.map((item: any, index: number) => (
              <option key={index} value={item?.label}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div>

        {/* <div className="me-2">
          <Form.Select
            defaultValue={type}
            onChange={(e: any) => handleSelectChange(e.target.value)}
          >
            <option value="all">All</option>
            {stockTypes?.map((item: any, index: number) => (
              <option key={index} value={item?.label?.toLowerCase()}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div> */}

        <div className="me-2">
          <Form.Select
            defaultValue={inventoryType}
            onChange={(e: any) => handleSelectChange(e.target.value)}
          >
            {inventoryTypes?.map((item: any, index: number) => (
              <option key={index} value={item?.key}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div>

        <div>
          <OverlayTrigger overlay={<Tooltip>Add calibrated gems</Tooltip>}>
            <Button
              className="bg-grad me-2"
              onClick={() => history.push("/add-calibrated-gems")}
            >
              <IconAdd />
            </Button>
          </OverlayTrigger>
        </div>

        {/* <div>
          <OverlayTrigger overlay={<Tooltip>Split calibrated gems</Tooltip>}>
            <Button
              className="bg-grad me-2"
              onClick={() => history.push("/split-calibrated-gems")}
            >
              <IconBoxs />
            </Button>
          </OverlayTrigger>
        </div> */}

        {/* <div>
          <OverlayTrigger overlay={<Tooltip>Print labels</Tooltip>}>
            <Button
              className="bg-grad me-2"
              onClick={() => history.push("/calibrated-gems-labels")}
            >
              <IconPrint />
            </Button>
          </OverlayTrigger>
        </div> */}

        <div>
          <ButtonGroup>
            <OverlayTrigger overlay={<Tooltip>Grid view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, isList: false })}
                className={
                  variable?.isList
                    ? "bg-white text-primary"
                    : "text-white bg-grad"
                }
              >
                <IconGrid />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>List view</Tooltip>}>
              <Button
                onClick={() => setVariable({ ...variable, isList: true })}
                className={
                  variable?.isList
                    ? "text-white bg-grad"
                    : "bg-white text-primary"
                }
              >
                <IconList />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      </Col>
    </section>
  );
};

export default Header;
