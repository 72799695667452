import React from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import {
  actionData,
  gemsAvgSizesRoute,
  gemsClarityRoute,
  gemsColorRoute,
  gemsShapeAndCutRoute,
  gemsTreatmentRoute,
  gemsWeightUnitRoute,
  getMethod,
} from "../../../../services/api";
import {
  CustomInput,
  CustomSelectInputGroup,
  FormFieldService,
} from "../../../../components";
import { toast } from "react-toastify";

interface StageProps {
  formData: any;
  setFormData: (data: any) => void;
}

const Stage: React.FC<StageProps> = ({ formData, setFormData }) => {
  const [gemsSizesByPcs, setGemsSizesByPcs] = React.useState([]);
  const [gemsSizes, setGemsSizes] = React.useState([]);
  const [weightUnits, setWeigthUnits] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [clarities, setClarities] = React.useState([]);
  const [shapes, setShapes] = React.useState([]);
  const [treatments, setTreatments] = React.useState([]);
  const fields = [
    // {
    //   id: "numberOfPcs",
    //   label: "Number Of pcs *",
    //   value: formData?.stage?.numberOfPcs,
    //   inputType: "number",
    // },
    { id: "dimension", label: "Dimension", value: formData?.stage?.dimension },
    // {
    //   id: "weight",
    //   label: "Weight *",
    //   value: formData?.stage?.weight,
    //   inputType: "number",
    //   type: "select-group",
    //   secondId: "weightUnit",
    //   secondValue: formData?.stage?.weightUnit,
    //   data: weightUnits,
    //   wantString: true,
    // },
    {
      id: "color",
      label: "Color",
      value: formData?.stage?.color,
      type: "select",
      data: colors,
      wantString: true,
    },
    {
      id: "clarity",
      label: "Clarity",
      value: formData?.stage?.clarity,
      type: "select",
      data: clarities,
      wantString: true,
    },
    {
      id: "shapeAndCut",
      label: "Shape and cut",
      value: formData?.stage?.shapeAndCut,
      type: "select",
      data: shapes,
      wantString: true,
    },
    // {
    //   id: "treatment",
    //   label: "Treatment",
    //   value: formData.treatment,
    //   type: "select",
    //   data: treatments,
    //   wantString: true,
    // },
  ];

  React.useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, gemsWeightUnitRoute).then((result) => {
      setWeigthUnits(result.data);
    });

    actionData(getMethod, gemsColorRoute).then((result) => {
      setColors(result.data);
    });

    actionData(getMethod, gemsClarityRoute).then((result) => {
      setClarities(result.data);
    });

    actionData(getMethod, gemsShapeAndCutRoute).then((result) => {
      setShapes(result.data);
    });

    actionData(getMethod, gemsTreatmentRoute).then((result) => {
      setTreatments(result.data);
    });

    actionData(getMethod, gemsAvgSizesRoute).then((result) => {
      let sizes: any = [];
      let sizesByPcs: any = [];
      result.data.forEach((item: any) => {
        if (item.unit === "pcs_per_cts") {
          sizesByPcs.push(item);
        } else {
          sizes.push(item);
        }
      });
      setGemsSizesByPcs(sizesByPcs);
      setGemsSizes(sizes);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.type === "text" ? target.value : target.value;
    }

    setFormData({
      ...formData,
      stage: {
        ...formData.stage,
        [name]: value,
      },
    });
  };

  const handleChangeTwo = (evt: any, wantString?: boolean) => {
    const value = evt.target.value;
    if (value === "Lots") {
      setFormData({
        ...formData,
        stage: {
          ...formData.stage,
          numberOfPcs: null,
          size: null,
          weightUnit: value,
          pcsOneWeight: "",
          pcsTwoWeight: "",
        },
      });
    } else if (value === "Loose" || value === "Single") {
      setFormData({
        ...formData,
        stage: {
          ...formData.stage,
          numberOfPcs: 1,
          size: null,
          weightUnit: value,
          pcsOneWeight: "",
          pcsTwoWeight: "",
        },
      });
    } else {
      setFormData({
        ...formData,
        stage: {
          ...formData.stage,
          numberOfPcs: null,
          size: null,
          weightUnit: value,
          pcsOneWeight: "",
          pcsTwoWeight: "",
        },
      });
    }
  };

  const handleCheckboxChange = (evt: any) => {
    let temp: any = [...formData?.stage?.treatment];
    const checked = evt.target.checked;
    const value = evt.target.value;

    if (checked) {
      temp.push(value);
      setFormData({
        ...formData,
        stage: {
          ...formData.stage,
          treatment: temp,
        },
      });
    } else {
      temp = temp.filter((v: any) => v !== value);
    }

    setFormData({
      ...formData,
      stage: {
        ...formData.stage,
        treatment: temp,
      },
    });
  };

  const handleNext = () => {
    let error = true;
    const stage: any = formData?.stage;

    if (
      (formData?.gemInfo?.stockType === "Loose" ||
        formData?.gemInfo?.stockType === "Single" ||
        formData?.gemInfo?.stockType === "Lots") &&
      stage?.weight
    ) {
      error = false;
    }

    if (
      formData?.gemInfo?.stockType === "Pair" &&
      stage?.pcsOneWeight &&
      stage?.pcsTwoWeight
    ) {
      stage.weight = parseFloat(stage?.pcsOneWeight) + parseFloat(stage?.pcsTwoWeight);
      error = false;
    }

    if (!error) {
      setFormData({
        ...formData,
        step: 3,
      });
    } else {
      toast.error("Please fill data!");
    }
    console.log(stage);

  };

  return (
    <div className={`${formData?.step === 2 ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>Stage information (Step 2)</div>
      </div>

      <div className="bg-card mt-3">
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Stock type</Form.Label>
              <Form.Control disabled value={formData?.gemInfo?.stockType} />
            </Form.Group>
          </Col>

          {formData.gemInfo?.stockType === "Lots" && (
            <React.Fragment>
              <Col md={6}>
                <Form.Group className="input-form-control mb-4">
                  <section className="flex-between">
                    <Form.Label>
                      {formData.sizeUnit === "avg_size"
                        ? "Gems size"
                        : "Pcs per cts"}
                    </Form.Label>
                    <FormCheck
                      id="switch"
                      className="switch"
                      type="switch"
                      label={"Gems size"}
                      checked={formData.stage?.sizeUnit === "avg_size"}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          stage: {
                            ...formData?.stage,
                            sizeUnit:
                              formData.stage?.sizeUnit === "avg_size"
                                ? "pcs_per_cts"
                                : "avg_size",
                          },
                        })
                      }
                    />
                  </section>

                  <Form.Control
                    as="select"
                    aria-label="Select"
                    id={"gemsSize"}
                    onChange={(e: any) => {
                      if (formData.stage?.sizeUnit === "avg_size") {
                        let resultGemsWeight =
                          e.target.value && formData.stage?.numberOfPcs
                            ? parseFloat(
                              (e.target.value * formData.stage?.numberOfPcs)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                            : formData.stage?.weight;
                        setFormData({
                          ...formData,
                          stage: {
                            ...formData?.stage,
                            size: e.target.value && parseFloat(e.target.value),
                            weight: resultGemsWeight,
                          },
                        });
                      } else {
                        let resultGemsWeight =
                          e.target.value && formData.stage?.numberOfPcs
                            ? parseFloat(
                              (
                                (1 / e.target.value) *
                                formData.stage?.numberOfPcs
                              )
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                            : formData.stage?.weight;
                        setFormData({
                          ...formData,
                          stage: {
                            ...formData?.stage,
                            size: e.target.value && parseFloat(e.target.value),
                            weight: resultGemsWeight,
                          },
                        });
                      }
                    }}
                    value={formData?.stage?.size}
                  >
                    <option value="">Select</option>
                    {formData?.stage?.sizeUnit === "avg_size" ? (
                      <>
                        {gemsSizes.map((item: any, index: number) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {gemsSizesByPcs.map((item: any, index: number) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6}>
                <CustomInput
                  id={"gemsQuantity"}
                  label={"Number of pcs"}
                  value={formData?.stage?.numberOfPcs}
                  handleChange={(e: any) => {
                    if (formData?.stage?.sizeUnit === "avg_size") {
                      let resultGemsWeight =
                        e.target.value && formData.stage?.size
                          ? parseFloat(
                            (formData.stage?.size * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                          : formData.stage?.weight;
                      setFormData({
                        ...formData,
                        stage: {
                          ...formData?.stage,
                          numberOfPcs:
                            e.target.value && parseInt(e.target.value),
                          weight: resultGemsWeight,
                        },
                      });
                    } else {
                      let resultGemsWeight =
                        e.target.value && formData.stage?.size
                          ? parseFloat(
                            ((1 / formData.stage?.size) * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                          : formData.stage?.weight;
                      setFormData({
                        ...formData,
                        stage: {
                          ...formData?.stage,
                          numberOfPcs:
                            e.target.value && parseInt(e.target.value),
                          weight: resultGemsWeight,
                        },
                      });
                    }
                  }}
                  inputType={"number"}
                />
              </Col>
            </React.Fragment>
          )}

          {formData.gemInfo?.stockType !== "Pair" && (
            <Col md={6}>
              <CustomSelectInputGroup
                label={"Gemstone weight *"}
                id={"weight"}
                secondId={"weightUnit"}
                value={formData?.stage?.weight}
                secondValue={formData?.stage?.weightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    stage: {
                      ...formData?.stage,
                      weight: e.target.value,
                    },
                  })
                }
                // handleChange={(e: any) => setFormData({ ...formData, gemsWeight: e.target.value, gemsPricePerCts: parseInt(e.target.value === 0 ? formData.gemsPricePerCts : (formData.totalGemsCost || 0) / (e.target.value ? parseFloat(e.target.value) : 0)), totalGemsCost: (e.target.value ? parseFloat(e.target.value) : 0) * (formData.gemsPricePerCts || 0) })}
                secondHandleChange={handleChangeTwo}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
          )}
        </Row>

        {formData.gemInfo?.stockType === "Pair" && (
          <Row>
            <Col md={6}>
              <CustomSelectInputGroup
                label={"Pcs 1 weight *"}
                id={"pcsOneWeight"}
                secondId={"weightUnit"}
                value={formData?.stage?.pcsOneWeight}
                secondValue={formData?.stage?.weightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    stage: {
                      ...formData?.stage,
                      pcsOneWeight: e.target.value,
                    },
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>

            <Col md={6}>
              <CustomSelectInputGroup
                label={"Pcs 2 weight *"}
                id={"pcsTwoWeight"}
                secondId={"weightUnit"}
                value={formData?.stage?.pcsTwoWeight}
                secondValue={formData?.stage?.weightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    stage: {
                      ...formData?.stage,
                      pcsTwoWeight: e.target.value,
                    },
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
          </Row>
        )}

        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
        />

        <Form.Group>
          <Form.Label className="d-block">Treatment</Form.Label>
          {treatments?.map((item: any, index: number) => (
            <Form.Check
              key={index}
              inline
              label={item?.label}
              name="treatment[]"
              type="checkbox"
              id={`treatment-${index}`}
              value={item?.label}
              checked={formData?.stage?.treatment?.includes(item?.label)}
              onChange={handleCheckboxChange}
            />
          ))}
        </Form.Group>
      </div>

      <div className="mt-3 d-flex justify-content-between">
        <Button
          className="text-white bg-grad px-4"
          onClick={() => setFormData({ ...formData, step: 1 })}
        >
          Previous
        </Button>

        <Button className="text-white bg-grad px-4" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default Stage;
