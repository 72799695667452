import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormFieldService, GoBack, Layout, LoadingButtonLabel } from "../../../components";
import { inventoryStatusForAddForm, inventoryTypes } from "../../../data";
import {
  getStoreUser,
  handelGetPersons,
  inputChangeService,
} from "../../../services";
import {
  actionData,
  dataService,
  getMethod,
  jewelleryForRoute,
  jewelleryRoute,
  jewelleryTypeRoute,
  jewelleryWeightUnitRoute,
  postMethod,
  responseForRoutes,
} from "../../../services/api";

const Index = () => {
  const history = useHistory();
  // const [owners, setOwners] = useState([])
  // const [brokers, setBrokers] = useState([])
  const [loading, setLoading] = useState(false);
  const [genders, setGenders] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [jewelleryTypes, setJewelleryTypes] = useState([]);
  const [jewelleryWeightUnits, setJewelleryWeightUnits] = useState([]);
  const [jewelleryOwners, setJewelleryOwners] = useState([]);
  const [persons, setPersons] = useState([]);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  const [formData, setFormData] = useState({
    jewelleryFor: "",
    itemName: "",
    jewelleryType: "",
    jewelleryStyle: "",
    jewelleryWeight: "",
    jewelleryWeightUnit: "grams",
    width: "",
    length: "",
    outletName: "Mandalay",
    inventoryType: "shop_inventory",
    jewelleryOwner: null,
    jewelleryOwnerDefaultValue: "",
    // gemsOwnerId: null,
    // gemsBrokerId: null,
    // diamondsOwnerId: null,
    // diamondsBrokerId: null,
    published: true,
    purchaseDate: "",
    description: "",
    notes: "",
    statusCode: "received",
    enteredBy: user.ownerId,
    userLoginId: getStoreUser()?.userId,
    forSale: false,
  });

  const required = ["itemName", "jewelleryType", "jewelleryWeight"];

  const fields = [
    { id: "itemName", label: "Item name *", value: formData.itemName },
    {
      id: "jewelleryType",
      label: "Jewellery type *",
      value: formData.jewelleryType,
      type: "select",
      data: jewelleryTypes,
      wantString: true,
    },
    {
      id: "jewelleryWeight",
      label: "Jewellery weight *",
      value: formData.jewelleryWeight,
      inputType: "number",
      type: "select-group",
      secondId: "jewelleryWeightUnit",
      secondValue: formData.jewelleryWeightUnit,
      data: jewelleryWeightUnits,
      wantString: true,
    },
    {
      id: "jewelleryFor",
      label: "Jewellery for",
      value: formData.jewelleryFor,
      type: "select",
      data: genders,
      wantString: true,
    },
    {
      id: "jewelleryStyle",
      label: "Jewellery style",
      value: formData.jewelleryStyle,
    },
    {
      id: "width",
      label: "Bangle width",
      value: formData.width,
      jewelleryType: "Bangles",
      hide: !(formData.jewelleryType === "Bangles"),
    },
    {
      id: "width",
      label: "Ring width",
      value: formData.width,
      jewelleryType: "Rings",
      hide: !(formData.jewelleryType === "Rings"),
    },
    {
      id: "length",
      label: "Bracelet length",
      value: formData.length,
      jewelleryType: "Bracelets",
      hide: !(formData.jewelleryType === "Bracelets"),
    },
    {
      id: "length",
      label: "Necklace length",
      value: formData.length,
      jewelleryType: "Necklaces",
      hide: !(formData.jewelleryType === "Necklaces"),
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData.outletName,
      type: "select",
      data: outlets,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "statusCode",
      label: "Inventory status",
      value: formData.statusCode,
      type: "select",
      data: inventoryStatusForAddForm,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "jewelleryOwner",
      label: "Jewellery owner",
      value: formData.jewelleryOwner,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.jewelleryOwnerDefaultValue,
      hide: !(formData.inventoryType === "service_item"),
    },
    {
      id: "jewelleryOwner",
      label: "Jewellery owner",
      value: formData.jewelleryOwner,
      type: "select",
      data: jewelleryOwners,
      hide: !(formData.inventoryType === "private_item"),
    },
    { id: "description", label: "Description", value: formData.description },
    {
      id: "purchaseDate",
      label: "Purchase date",
      value: formData.purchaseDate,
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: formData.notes,
      inputType: "textarea",
    },
  ];

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    handelGetPersons("", setPersons);
    actionData(getMethod, "owners").then((result) => {
      let temp: any = [];
      if (result && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({
            key: item.ownerId,
            label: item.fullName,
            customKey: true,
          });
        });
      }
      setJewelleryOwners(temp);
    });

    actionData(getMethod, jewelleryForRoute).then((result) => {
      setGenders(result.data);
    });

    actionData(getMethod, jewelleryTypeRoute).then((result) => {
      setJewelleryTypes(result.data);
    });

    actionData(getMethod, jewelleryWeightUnitRoute).then((result) => {
      setJewelleryWeightUnits(result.data);
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
      }
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    if (evt.target.id === "jewelleryType") {
      setFormData({
        ...formData,
        jewelleryType: evt.target.value,
        width: "",
        length: "",
      });
    } else {
      inputChangeService(evt, formData, setFormData, wantString);
    }
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const handleSubmit = () => {
    formData.forSale =
      formData?.inventoryType === "private_item" ? formData?.forSale : false;
    console.log(formData);

    formData.purchaseDate =
      formData.purchaseDate && new Date(formData.purchaseDate).toISOString();
    setTimeout(() => {
      setLoading(true);
      dataService(postMethod, formData, required, jewelleryRoute()).then(
        (result) => {
          if (result.success) {
            responseForRoutes(result, history, "jewelleries");
          }
          else {
            setLoading(false)
          }
        }
      );
    }, 800);
  };

  return (
    <Layout title="Add jewellery">
      <div className="flex-between mb-4">
        <GoBack label="Jewelleries" />
        <div className="d-flex align-items-center">
          {formData?.inventoryType === "private_item" && (
            <Form.Group className="border-none">
              <Form.Check
                type="checkbox"
                label="For sale"
                id="forSale"
                checked={formData?.forSale}
                onChange={(e: any) =>
                  setFormData({ ...formData, forSale: e.target.checked })
                }
              />
            </Form.Group>
          )}

          <Form.Group className="mx-4 border-none">
            <Form.Check
              type="checkbox"
              label="Publish jewellery"
              id="published"
              checked={formData?.published}
              onChange={handleChange}
            />
          </Form.Group>
          <Button className="text-white bg-grad px-4" onClick={handleSubmit}
            disabled={loading}
          >
            <LoadingButtonLabel label="Save" disabled={loading} />
          </Button>
        </div>
      </div>

      <FormFieldService
        fields={fields}
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
        colCount={4}
        bg
        handleInputChange={handleInputChange}
      />
    </Layout>
  );
};

export default Index;
