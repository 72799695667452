import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import Logo from "../../../../components/common/Logo";
import { QRView, ThumbsnailImage } from "../../../../components";
import { numberFormat } from "../../../../services";
import { inventoryTypes } from "../../../../data";

const POSDetailPdf = React.forwardRef((props: any, ref: any) => {
  let product = props.data;

  const inventoryTypeLabel = inventoryTypes.filter(
    (inventory: any) => inventory.key === product?.inventoryType
  )[0]?.label;

  const details = [
    { label: "SKU", value: product?.sku },
    { label: "Stock type", value: product?.stockType || "-" },
    { label: "Stone style", value: product?.stoneType || "-" },
    { label: "Stages", value: product?.stages?.length || "-" },
    {
      label: "Buying state",
      value: product?.buyingState || "-",
    },
    {
      label: "Mining location",
      value: product?.miningLocation || "-",
    },
    { label: "Inventory type", value: inventoryTypeLabel || "-" },
    { label: "Description", value: product?.description || "-" },
  ];

  return (
    <section ref={ref} className="print-container">
      <div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <Logo />

            <div className="flex-fill inv-title ms-3">
              <h6 className="mb-0 fw-bold">{product?.itemName}</h6>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <QRView text={product?.sku} width={50} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-3">
            <div className="print-thumnail">
              <ThumbsnailImage item={product} />
            </div>
            {product?.price?.sellingPrice && (
              <div className="p-3 rounded-3 bg-light text-center mt-4">
                <h6 className="fw-bold mb-0 print-color-red print-text-price">
                  {numberFormat(product?.price?.sellingPrice)}{" "}
                  {product?.price?.currency || "MMK"}
                </h6>
              </div>
            )}
          </div>
          <div className="col-9">
            <Table responsive striped className="print-table mb-3 border">
              <tbody>
                {details.map(
                  (item: any, index: number) =>
                    !item.hide && (
                      <tr key={index}>
                        <td className={item.color || ""}>{item.label}</td>
                        <td className={item.color || ""}>{item.value}</td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </div>
        </div>

        <Row className="mb-3">
          {product && product?.stages && product?.stages.length > 0 && (
            <Col>
              <h6 className="fw-bold print-text-lg mb-2">Gemstones stages</h6>
              <Table
                responsive
                className="print-table text-center"
                striped
                bordered
              >
                <thead>
                  <tr>
                    <th>Color</th>
                    <th>Clarity</th>
                    <th>Shape and cut</th>
                    <th>Dimension</th>
                    <th>Treatment</th>
                    <th>No. of pcs</th>
                    <th>Weight ({product?.stages[0].weightUnit})</th>
                  </tr>
                </thead>
                <tbody>
                  {product?.stages.map((gems: any, index: number) => (
                    <tr key={index}>
                      <td>{gems?.color}</td>
                      <td>{gems?.clarity}</td>
                      <td>{gems?.shapeAndCut}</td>
                      <td>{gems?.dimension}</td>
                      <td>{gems?.treatment}</td>
                      <td>{gems?.numberOfPcs}</td>
                      <td>{gems?.weight}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      </div>
    </section>
  );
});

export default POSDetailPdf;
