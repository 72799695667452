import React, { useContext } from "react";
import { Badge, Table } from "react-bootstrap";
import { PageContext } from "../../../context";
import { getNumber, handleDateFormat, numberFormat } from "../../../services";
import { inventoryTypes } from "../../../data";
import Logo from "../../../components/common/Logo";

const ListPrint = React.forwardRef((props: any, ref: any) => {
  const data = props.data;
  const [page] = useContext(PageContext);

  return (
    <section ref={ref}>
      <style type="text/css" media="print">
        {`
          @page {
            size: A4 Landscape;
            margin: 0.45in 0.5in;
          }
        `}
      </style>

      <div className="flex-between mb-2">
        <div className="d-flex align-items-center">
          {/* <Image src="images/logo.png" width={50} /> */}
          <Logo />
          <div className="ms-2">
            <h5 className="text-primary fw-bold mb-0">
              {process.env.REACT_APP_APP_TITLE}
            </h5>
            <small className="text-primary ">Natural Gems & Jewellery</small>L
          </div>
        </div>
        <div>
          <small className="flex-end mb-1">
            Date : {handleDateFormat(new Date())}
          </small>
          <small>Receivable</small>
        </div>
      </div>

      <Table className="mb-0 print-table" bordered striped>
        <thead className="bg-primary text-white">
          <tr>
            <th className="ps-4">No.</th>
            <th>Invoice no.</th>
            <th>Item</th>
            <th>Customer</th>
            <th className="text-center">Net price</th>
            <th className="text-center">Paid</th>
            <th className="text-center">Receivable</th>
            <th className="text-center">Discount</th>
            <th style={{ width: "120px" }}>Selling date</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <td>{item?.invoiceNumber}</td>
              <td>
                <span className="d-block">{item?.itemName}</span>
                <small className="text-muted mt-1 d-block">{item?.sku}</small>
                <Badge className="bg-success mt-2">
                  {
                    inventoryTypes.filter(
                      (type: any) => type.key === item.inventoryType
                    )[0]?.label
                  }
                </Badge>
              </td>
              <td>{item?.fullName}</td>
              <td className="text-end">{numberFormat(item?.netPrice)}</td>
              <td className="text-end">
                {numberFormat(item?.totalPaidAmount)}
              </td>
              <td className="text-end">
                {numberFormat(item?.receivableAmount)}
              </td>
              <td className="text-end">{numberFormat(item?.discountValue)}</td>
              <td className="text-center">
                {handleDateFormat(item.createdAt)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
});

export default ListPrint;
