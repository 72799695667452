import { useState } from "react";
import { ThumbsnailImage } from "../../../../components";
import { handleShow, numberFormat } from "../../../../services";
import { gemsRoute, getDataService } from "../../../../services/api";
import ItemModal from "../cpn/ItemModal";

const ItemCard = ({ item }: { item: any }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const handelDetailAction = () => {
    getDataService(gemsRoute(item.id), setLoading, setData, null, 0);
    handleShow(setShow);
  };

  return (
    <>
      <div className="pos-item mb-4" onClick={handelDetailAction}>
        <div className="pos-item-image">
          <ThumbsnailImage item={item} />
        </div>
        <div
          className="p-3 d-flex flex-column justify-content-between"
          // style={{ height: "11rem" }}
        >
          <span className="d-block">
            <small className="d-block xs-label mb-2 text-truncate">
              {item?.itemName}
            </small>
            <small className="xs-label text-muted d-block fw-bold mb-1">
              {item?.sku}
            </small>
            <span className="d-block text-muted xs-label">
              {item?.stoneType} {item?.stockType ? `(${item?.stockType})` : ""}
            </span>
            {item?.gemstones_stage?.length > 0 && (
              <span className="d-block text-muted xs-label">
                {item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                  ?.dimension &&
                  `${
                    item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                      ?.dimension
                  }, `}

                {item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                  ?.numberOfPcs &&
                  `${
                    item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                      ?.numberOfPcs
                  } Pcs, `}

                {item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                  ?.weight &&
                  `${
                    item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                      ?.weight
                  } ${
                    item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                      ?.weightUnit
                  }`}
              </span>
            )}
            {item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
              ?.color && (
              <span className="d-block text-muted xs-label">
                {item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                  ?.color &&
                  `${
                    item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                      ?.color
                  }, `}

                {item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                  ?.clarity &&
                  `${
                    item?.gemstones_stage?.[item?.gemstones_stage?.length - 1]
                      ?.clarity
                  }`}
              </span>
            )}
            {item?.miningLocation && (
              <span className="text-muted xs-label">
                Mine: {item?.miningLocation}
              </span>
            )}
          </span>
          <small className="d-block fw-bold mt-2">
            {item?.price?.sellingPrice &&
              `${item?.price?.currency} ${numberFormat(
                item?.price?.sellingPrice
              )}`}
          </small>
          {/* <small className="xs-label d-block mb-1 text-muted">
            {item?.jewelleryType}
          </small> */}
        </div>
      </div>

      <ItemModal
        show={show}
        setShow={setShow}
        item={data}
        gem={item}
        loading={loading}
      />
    </>
  );
};

export default ItemCard;
