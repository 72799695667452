import { useState } from "react";
import { GoBack, Layout } from "../../../components";
import CaliGem from "./cpn/CaliGem";
import Stage from "./cpn/Stage";
import Price from "./cpn/Price";
import { Form } from "react-bootstrap";

const Index = () => {
  const [formData, setFormData] = useState({
    step: 1,
    published: true,
    forSale: false,
    caliGem: {
      itemName: "",
      sku: "",
      stoneType: "",
      stockType: "",
      buyingState: "",
      miningLocation: "Bangkok",
      outletName: "",
      caliGemsOwnerId: null,
      caliGemsBrokerId: null,
      published: true,
      purchaseDate: "",
      notes: "",
      inventoryType: "shop_inventory",
      userLoginId: null,
    },
    stage: {
      // gemstonesStageId: "",
      numberOfPcs: 1,
      dimension: "",
      weight: "",
      weightUnit: "carat",
      color: "",
      clarity: "",
      shapeAndCut: "",
      treatment: [],
      stageStaus: "Initial",
    },
    price: {
      sharedInvestorsQty: null,
      interestFrom: "",
      interestRate: "",
      investedUSDRate: null,
      currency: "",
      otherCost: null,
      paymentDueDate: null,
      buyingPriceFomula: "total",
      buyingPricePerCts: null,
      buyingPrice: "",
      sellingPriceFomula: "total",
      sellingPricePerCts: null,
      sellingPrice: "",
    },
  });

  return (
    <Layout title="Add calibrated gem">
      <div className="flex-between mb-4">
        <GoBack label="Calibrated gems" />

        <div className=" d-flex align-items-center">
          {formData?.caliGem?.inventoryType === "private_item" && (
            <Form.Group className="border-none me-3">
              <Form.Check
                type="checkbox"
                label="For sale"
                id="forSale"
                checked={formData?.forSale}
                onChange={(e: any) =>
                  setFormData({ ...formData, forSale: e.target.checked })
                }
              />
            </Form.Group>
          )}

          <Form.Group className="border-none">
            <Form.Check
              type="checkbox"
              label="Publish gems"
              id="published"
              checked={formData?.published}
              onChange={(e: any) =>
                setFormData({ ...formData, published: e.target.checked })
              }
            />
          </Form.Group>
        </div>
      </div>

      <div>
        <CaliGem formData={formData} setFormData={setFormData} />
        <Stage formData={formData} setFormData={setFormData} />
        <Price formData={formData} setFormData={setFormData} />
      </div>
    </Layout>
  );
};

export default Index;
