import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormFieldService, GoBack, Layout } from "../../../components";
import { DetailLoading } from "../../../components/widgets/Loading";
import { inventoryStatusForAddForm, inventoryTypes } from "../../../data";
import {
  dateFormat2,
  getSearchParam,
  getStoreUser,
  handelGetPersons,
  handleDateFormat,
  inputChangeService,
} from "../../../services";
import {
  actionData,
  dataService,
  getMethod,
  jewelleryForRoute,
  jewelleryRoute,
  jewelleryWeightUnitRoute,
  putMethod,
  responseForRoutes,
} from "../../../services/api";

const Index = () => {
  const history = useHistory();
  const itemId = getSearchParam('id');
  const [genders, setGenders] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [jewelleryWeightUnits, setJewelleryWeightUnits] = useState([]);
  const [persons, setPersons] = useState([]);
  const [jewelleryOwners, setJewelleryOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    jewelleryFor: "",
    itemName: "",
    jewelleryType: "",
    jewelleryStyle: "",
    jewelleryWeight: "",
    jewelleryWeightUnit: "grams",
    jewelleryOwner: null,
    jewelleryOwnerDefaultValue: "",
    width: "",
    length: "",
    outletName: "Mandalay",
    inventoryType: "shop_inventory",
    published: true,
    purchaseDate: "",
    description: "",
    statusCode: "received",
    notes: "",
    userLoginId: getStoreUser()?.userId,
    forSale: false,
  });
  const required = ["itemName", "jewelleryType", "jewelleryWeight"];

  const fields = [
    { id: "itemName", label: "Item name *", value: formData.itemName },
    {
      id: "jewelleryWeight",
      label: "Jewellery weight *",
      value: formData.jewelleryWeight,
      inputType: "number",
      type: "select-group",
      secondId: "jewelleryWeightUnit",
      secondValue: formData.jewelleryWeightUnit,
      data: jewelleryWeightUnits,
      wantString: true,
    },
    {
      id: "jewelleryFor",
      label: "Jewellery for",
      value: formData.jewelleryFor,
      type: "select",
      data: genders,
      wantString: true,
    },
    {
      id: "jewelleryStyle",
      label: "Jewellery style",
      value: formData.jewelleryStyle,
    },
    {
      id: "width",
      label: "Bangle width",
      value: formData.width,
      jewelleryType: "Bangles",
      hide: !(formData.jewelleryType === "Bangles"),
    },
    {
      id: "width",
      label: "Ring width",
      value: formData.width,
      jewelleryType: "Rings",
      hide: !(formData.jewelleryType === "Rings"),
    },
    {
      id: "length",
      label: "Bracelet length",
      value: formData.length,
      jewelleryType: "Bracelets",
      hide: !(formData.jewelleryType === "Bracelets"),
    },
    {
      id: "length",
      label: "Necklace length",
      value: formData.length,
      jewelleryType: "Necklaces",
      hide: !(formData.jewelleryType === "Necklaces"),
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData.outletName,
      type: "select",
      data: outlets,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "statusCode",
      label: "Inventory status",
      value: formData.statusCode,
      type: "select",
      data: inventoryStatusForAddForm,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "jewelleryOwner",
      label: "Jewellery owner",
      value: formData.jewelleryOwner,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.jewelleryOwnerDefaultValue,
      hide: !(formData.inventoryType === "service_item"),
    },
    {
      id: "jewelleryOwner",
      label: "Jewellery owner",
      value: formData.jewelleryOwner,
      type: "select",
      data: jewelleryOwners,
      hide: !(formData.inventoryType === "private_item"),
    },
    { id: "description", label: "Description", value: formData.description },
    {
      id: "purchaseDate",
      label: "Purchase date",
      value: handleDateFormat(formData.purchaseDate, dateFormat2),
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: formData.notes,
      inputType: "textarea",
    },
  ];

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    setLoading(true);
    handelGetPersons("", setPersons);
    actionData(getMethod, "owners").then((result) => {
      let temp: any = [];
      if (result && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({
            key: item.ownerId,
            label: item.fullName,
            customKey: true,
          });
        });
      }
      setJewelleryOwners(temp);
    });

    actionData(getMethod, jewelleryForRoute).then((result) => {
      setGenders(result.data);
    });

    actionData(getMethod, jewelleryWeightUnitRoute).then((result) => {
      setJewelleryWeightUnits(result.data);
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
      }
    });

    actionData(getMethod, jewelleryRoute(itemId)).then((result) => {
      if (result?.data) {
        let _data = result.data;

        setFormData({
          ...formData,
          jewelleryFor: _data.jewelleryFor || "",
          itemName: _data.itemName || "",
          jewelleryType: _data.jewelleryType || "",
          jewelleryStyle: _data.jewelleryStyle || "",
          jewelleryWeight: _data.jewelleryWeight || "",
          jewelleryWeightUnit: _data.jewelleryWeightUnit || "grams",
          jewelleryOwner: _data?.jewellery_owner?.id || "",
          jewelleryOwnerDefaultValue:
            _data?.jewellery_owner?.fullName || "grams",
          width: _data.width || "",
          length: _data.length || "",
          outletName: _data.outletName || "Mandalay",
          inventoryType: _data.inventoryType || "shop_inventory",
          published: _data.published,
          purchaseDate: _data.purchaseDate || "",
          description: _data.description || "",
          statusCode:
            _data.jewelleryInventory?.length > 0
              ? _data.jewelleryInventory[0]?.statusCode
              : "received",
          notes: _data.notes || "",
          userLoginId: _data.userLoginId || getStoreUser()?.userId,
          forSale: _data.forSale || false,
        });
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    if (evt.target.id === "jewelleryType") {
      setFormData({
        ...formData,
        jewelleryType: evt.target.value,
        width: "",
        length: "",
      });
    } else {
      inputChangeService(evt, formData, setFormData, wantString);
    }
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const handleSubmit = () => {
    formData.forSale =
      formData?.inventoryType === "private_item" ? formData?.forSale : false;
    formData.purchaseDate =
      formData.purchaseDate && new Date(formData.purchaseDate).toISOString();

    if (
      formData.inventoryType === "shop_inventory" ||
      formData.inventoryType === "ordered_item"
    ) {
      formData.jewelleryOwner = null;
    }
    dataService(
      putMethod,
      formData,
      required,
      `${jewelleryRoute()}/${itemId}`
    ).then((result) => {
      responseForRoutes(result, history, "jewelleries");
    });
  };

  return (
    <Layout title="Edit jewellery">
      {loading ? (
        <DetailLoading />
      ) : (
        <>
          <div className="flex-between mb-4">
            <GoBack label="Jewelleries" />
            <div className="d-flex align-items-center">
              {formData?.inventoryType === "private_item" && (
                <Form.Group className="border-none">
                  <Form.Check
                    type="checkbox"
                    label="For sale"
                    id="forSale"
                    checked={formData?.forSale}
                    onChange={(e: any) =>
                      setFormData({ ...formData, forSale: e.target.checked })
                    }
                  />
                </Form.Group>
              )}

              <Form.Group className="mx-4 border-none">
                <Form.Check
                  type="checkbox"
                  label="Publish jewellery"
                  id="published"
                  checked={formData?.published}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button
                className="text-white bg-grad px-4"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>

          <FormFieldService
            fields={fields}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            colCount={4}
            bg
            handleInputChange={handleInputChange}
          />
        </>
      )}
    </Layout>
  );
};

export default Index;
