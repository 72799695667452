import { useState } from "react";
import { Badge, Col, Image, Row, Table } from "react-bootstrap";
import { handleDateFormat, numberFormat } from "../../../../services";
import Lightbox from "react-image-lightbox";
import { inventoryTypes } from "../../../../data";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

function Info({ product }: { product: any }) {
  const images = product?.images;
  const [imageView, setImageView] = useState({ photoIndex: 0, isOpen: false });
  const handleCloseImageModal = () =>
    setImageView({ ...imageView, isOpen: false });

  const details = [
    { label: "Name", value: product?.itemName },
    { label: "SKU", value: product?.sku },
    { label: "Stock type", value: product?.stockType || "-" },
    { label: "Stone type", value: product?.stoneType || "-" },
    { label: "Buying state", value: product?.buyingState || "-" },
    {
      label: "Gemstones owner",
      value: product?.gemstonesOwner
        ? `${product?.gemstonesOwner?.fullName} (${product?.gemstonesOwner?.phone})`
        : "-",
    },
    {
      label: "Gemstones broker",
      value: product?.gemstonesBroker
        ? `${product?.gemstonesBroker?.fullName} (${product?.gemstonesBroker?.phone})`
        : "-",
    },
    { label: "Mining location", value: product?.miningLocation || "-" },
  ];

  const otherDetails = [
    // { label: "Treatment", value: product?.treatment || "-" },
    // { label: "Origin", value: product?.origin || "-" },
    { label: "Outlet (Location)", value: product?.outletName || "-" },
    {
      label: "Inventory type",
      value:
        inventoryTypes?.find((i: any) => i?.key === product?.inventoryType)
          ?.label || "N/A",
    },
    { label: "Published", value: product?.published ? "Yes" : "No" },
    {
      label: product?.inventoryType === "private_item" ? "For sale" : "",
      value: product?.forSale ? "Yes" : "No",
    },
    {
      label: "Sale status",
      value: !product?.isSoldout ? (
        <Badge>In Stock</Badge>
      ) : (
        <Badge className="bg-danger">Sold Out</Badge>
      ),
    },
    // { label: "Shared owners qty", value: product?.sharedOwnersQty || "-" },
    {
      label: "Purchase date",
      value: product?.purchaseDate
        ? handleDateFormat(product?.purchaseDate)
        : "-",
    },
    {
      label: "Selling date",
      value: product?.sellingDate
        ? handleDateFormat(product?.sellingDate)
        : "-",
    },
    { label: "Created date", value: handleDateFormat(product?.createdAt) },
  ];

  // const product?Image = () => {
  //     let tempImages = product??.product?Colors
  //     let tempDiv = <Image src={'images/noimage.png'} />;
  //     if (tempImages.length > 0) {
  //         tempDiv = <Image src={`${RES_API_URL}${tempImages[0]}`} alt={tempImages[0]}
  //             className="cursor"
  //             onClick={() => {
  //                 setImageView({
  //                     ...imageView,
  //                     photoIndex: 0,
  //                     isOpen: true
  //                 })
  //             }}
  //         />
  //     }
  //     return tempDiv;
  // }

  const thumbsnailImage = (item: any) => {
    let tempImages = item.images;
    let isExist = false;
    let tempDiv = (
      <img src="images/no-image.png" alt="Thumbnail" className="float-right" />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="no img"
              height={250}
              className="object-fit float-right cursor"
              onClick={() => {
                setImageView({
                  ...imageView,
                  photoIndex: 0,
                  isOpen: true,
                });
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="no img"
            height={150}
            className="object-fit float-right cursor"
            onClick={() => {
              setImageView({
                ...imageView,
                photoIndex: 0,
                isOpen: true,
              });
            }}
          />
        );
      }
    }

    return tempDiv;
  };

  return (
    <section className="bg-card my-4">
      {imageView.isOpen && (
        <Lightbox
          mainSrc={`${RES_API_URL}${images[imageView.photoIndex].url}`}
          nextSrc={`${RES_API_URL}${
            images[(imageView.photoIndex + 1) % images.length].url
          }`}
          prevSrc={`${RES_API_URL}${
            images[(imageView.photoIndex + images.length - 1) % images.length]
              .url
          }`}
          imageTitle={`${product?.itemName} (${product?.sku})`}
          imageCaption={`${product?.stockType} / ${
            product?.stoneType
          } / ${numberFormat(product?.price?.sellingPrice)} ${
            product?.price?.currency
          }`}
          onCloseRequest={handleCloseImageModal}
          onMovePrevRequest={() =>
            setImageView({
              ...imageView,
              photoIndex:
                (imageView.photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            setImageView({
              ...imageView,
              photoIndex: (imageView.photoIndex + 1) % images.length,
            })
          }
        />
      )}

      <Row>
        <Col md={3}>
          <section className="detail-image object-fit">
            {product && thumbsnailImage(product)}
          </section>

          <Row>
            {product && product?.images && product?.images.length > 0 && (
              <>
                {product?.images.map((item: any, index: number) => (
                  <Col md={3} key={index} className="mt-3">
                    <section className="product-card-small-image cursor">
                      <Image
                        src={`${RES_API_URL}${item.url}`}
                        onClick={() => {
                          setImageView({
                            ...imageView,
                            photoIndex: index,
                            isOpen: true,
                          });
                          // setImageView({
                          //     ...imageView,
                          //     photoIndex: index,
                          //     isOpen: true
                          // })
                        }}
                      />
                    </section>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Col>
        <Col>
          <Table responsive striped className="mb-0">
            <tbody>
              {details.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="ps-4 text-muted ">{item.label}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table responsive striped className="mb-0">
            <tbody>
              {otherDetails.map(
                (item: any, index: number) =>
                  item?.label && (
                    <tr key={index}>
                      <td className="ps-4 text-muted ">{item.label}</td>
                      <td>{item.value}</td>
                    </tr>
                  )
              )}
            </tbody>
          </Table>

          {product?.notes && (
            <div className="p-2 rounded-3 bg-light mt-4">
              <small className="fw-bold text-muted">Remarks</small>
              <br />
              <small className="wrap-text">{product?.notes}</small>
            </div>
          )}
        </Col>
      </Row>
    </section>
  );
}

export default Info;
