import React from "react";
import { Table } from "react-bootstrap";
import { inventoryTypes } from "../../data";
import { handleDateFormat } from "../../services";
import InvoiceImage from "./InvoiceImage";

const JewelleryInventorySummaryPdf = React.forwardRef(
  (props: any, ref: any) => {
    let inventoryType = props.inventoryType;
    let weightCards = props.weightCards;
    let costCards = props.costCards;
    let jewelleryCount = props.jewelleryCount;

    return (
      <section ref={ref} className="print-container">
        {/* <div className="d-flex flex-column align-items-center mb-4 bg-black">
          <Image src="images/mr.spinel.png" width={300} />
        </div> */}
        <InvoiceImage />

        <div className="flex-between mb-3 align-items-center">
          <label className="ms-1 fw-bold ">
            Jewellery inventory report (summary)
          </label>
          <div className="d-flex justify-content-end">
            <div>
              <small className="flex-end mb-1">
                {" "}
                Report date :{" "}
                <label className="fw-bold ms-1">
                  {" "}
                  {handleDateFormat(new Date())}
                </label>
              </small>
              <small className="flex-end mb-1">
                Inventory type :{" "}
                <label className="fw-bold ms-1">
                  {" "}
                  {
                    inventoryTypes.filter(
                      (item: any) => item.key === inventoryType
                    )[0]?.label
                  }
                </label>
              </small>
              <small className="flex-end">Jewelleries : {jewelleryCount}</small>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Table responsive striped className="mb-0 border">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <span className="fw-bold mb-2">Weights</span>
                  </td>
                  <td>
                    <span className="fw-bold flex-end">In stock</span>
                  </td>
                  <td>
                    <span className="fw-bold flex-end">On hold</span>
                  </td>
                  <td>
                    <span className="fw-bold flex-end">Sold out</span>
                  </td>
                </tr>
                {weightCards.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className=" text-muted ">{item.label}</td>
                    <td>
                      <h6 className="fw-bold flex-end">
                        {item.availableValue}
                      </h6>
                    </td>
                    <td>
                      <h6 className="fw-bold flex-end">{item.onHoldValue}</h6>
                    </td>
                    <td>
                      <h6 className="fw-bold flex-end">
                        {item.unavailableValue}
                      </h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col">
            <Table responsive striped className="mb-0 border">
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <span className="fw-bold mb-2">Total Cost</span>
                  </td>
                  <td>
                    <span className="fw-bold flex-end">In stock (Cost)</span>
                  </td>
                  <td>
                    <span className="fw-bold flex-end">On hold (Cost)</span>
                  </td>
                </tr>
                {costCards.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className=" text-muted ">{item.label}</td>
                    <td>
                      <h6 className="fw-bold flex-end">
                        <small>{item.inStockValue}</small>
                      </h6>
                    </td>
                    <td>
                      <h6 className="fw-bold flex-end">
                        <small>{item.onHoldValue}</small>
                      </h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    );
  }
);

export default JewelleryInventorySummaryPdf;
