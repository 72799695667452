import React from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IconFile } from "../../../../components/widgets/Icons";

interface ChildGemProps {
  product: any;
  handleGetData: any;
}

const ChildGem: React.FC<ChildGemProps> = ({ product, handleGetData }) => {
  const history = useHistory();

  const goDetail = (item: any) => {
    history.go(-1);
    setTimeout(() => {
      history.push({
        pathname: "/gems-detail",
        state: { item: item },
      });
    }, 10);
  };

  return (
    <section className="bg-card my-4">
      <h6>Child gem stone</h6>

      <Table responsive striped className="mb-0">
        <thead>
          <tr>
            <td>Name</td>
            <td>SKU</td>
            <td>Stone type</td>
            <td>Stock type</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {product?.childGemstones.map((item: any, index: number) => (
            <tr key={index}>
              <td className="ps-4 text-muted ">{item?.itemName}</td>
              <td>{item?.sku}</td>
              <td>{item.stoneType}</td>
              <td>{item.stockType}</td>
              <td>
                <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                  <Button
                    className="text-white btn-sm bg-grad me-2"
                    onClick={() => goDetail(item)}
                  >
                    <IconFile />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
};

export default ChildGem;
