import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { actionData, getMethod, getPersons } from "./api";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
//Date Variable
const defaultTimeZone = "Asia/Yangon";
//const dateTimeFormat = 'hh:mm A - DD-MM-YYYY'
const dateTimeFormat = "YYYY-MM-DD hh:mm A";
const dateFormat = "DD-MM-YYYY";
const dateFormat2 = "YYYY-MM-DD";

const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;
const USER = "user";

export { dateFormat2 };

export const handleClose: any = (setShow: any) => setShow(false);
export const handleShow = (setShow: any) => setShow(true);

export const getNumber = (index: number, skip: number) => {
  return index + 1 + skip * limit;
};

export const handleDateFormat = (date: any, format?: string) => {
  return dayjs(date)
    .tz(defaultTimeZone)
    .format(format || dateFormat);
};

export const handleDateTimeFormat = (date: any) => {
  return date ? dayjs(date).tz(defaultTimeZone).format(dateTimeFormat) : "-";
};

export function numberFormat(x: any) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
}

export function toFloat(x: any) {
  return x
    ? parseFloat(x)
        .toFixed(3)
        .replace(/\.?0+$/, "")
    : 0;
}

export const login = (user: any) => {
  localStorage.setItem(USER, user);
};

export const logout = () => {
  localStorage.removeItem(USER);
};

export const getStoreUser = () => {
  const tempUser = localStorage.getItem(USER);
  const user = tempUser && JSON.parse(tempUser);
  return user;
};

export const isLogin = () => {
  if (localStorage.getItem(USER)) {
    return true;
  }
  return false;
};

//For Number Input
export const disableScroll = (e: any) => {
  e.currentTarget.blur();
};

export const handleKeyDown = (event: any) => {
  if (event.key.toLowerCase() === "enter") {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const inputChangeService = (
  evt: any,
  formData: any,
  setFormData: any,
  wantString?: boolean
) => {
  const target = evt.target;
  const name = target.id;
  let value;

  if (target.type === "checkbox") {
    value = target.checked;
  } else if (target.type === "number" || target.type === "select-one") {
    if (target.value === "") {
      value = "";
    } else {
      value = target.value;
      if (wantString) {
        value = target.value;
      } else {
        value = parseFloat(target.value);
      }
    }
  } else {
    value = target.type === "text" ? target.value : target.value;
  }

  setFormData({
    ...formData,
    [name]: value,
  });
};

export const getPersonsService = async (
  keyword?: string,
  setOwners?: any,
  setBrokers?: any
) => {
  actionData(getMethod, getPersons(0, keyword)).then((result) => {
    let tempOwner: any = [];
    let tempBroker: any = [];

    result.data.forEach((data: any) => {
      tempOwner.push({
        value: data?.id,
        // value: data?.owners?.id,
        label: `${data.fullName} ${data?.phone ? `(${data?.phone})` : ""}`,
        fullName: data.fullName,
        phone: data.phone,
        phoneOther: data.phoneOther,
        email: data.email,
        address: data.address,
      });

      tempBroker.push({
        value: data?.id,
        // value: data?.owners?.id,
        label: `${data.fullName} ${data?.phone ? `(${data?.phone})` : ""}`,
        fullName: data.fullName,
        phone: data.phone,
        phoneOther: data.phoneOther,
        email: data.email,
        address: data.address,
      });
    });

    setOwners && setOwners(tempOwner);
    setBrokers && setBrokers(tempBroker);
  });
};

export const handelGetPersons = async (keyword: string, setPersons: any) => {
  actionData(getMethod, getPersons(0, keyword)).then((result) => {
    let temp: any = [];

    result.data.forEach((data: any) => {
      temp.push({
        value: data.id,
        label: `${data.fullName} (${data.phone})`,
        fullName: data.fullName,
        phone: data.phone,
        phoneOther: data.phoneOther,
        email: data.email,
        address: data.address,
      });
    });

    setPersons(temp);
  });
};

// export const getTotalCost = (item: any) => {
//   let total = 0
//   if (item && item.length > 0) {
//     item?.gemstones.forEach((item: any) => {
//       total = total + parseInt(item.totalGemsCost || 0)
//     })
//   }
//   return total !== 0 ? `(${numberFormat(total)} MMK)` : ''
// }

export const groupByGemstones = (items: any) => {
  let tempArray: any = [];

  items.forEach((product: any) => {
    const { gemsStockType } = product;

    let exist = false;

    if (tempArray.length > 0) {
      tempArray.forEach((item: any) => {
        if (item.gemsStockType === gemsStockType) {
          exist = true;
        }
      });
    }

    if (!exist) {
      let tempObject = {
        gemsStockType: gemsStockType,
        data: items.filter(
          (data: any) => data.gemsStockType === product.gemsStockType
        ),
      };
      tempArray.push(tempObject);
    }
  });

  return tempArray;
};

export const groupByDiamonds = (items: any) => {
  let tempArray: any = [];

  items.forEach((product: any) => {
    const { diamondsStockType } = product;

    let exist = false;

    if (tempArray.length > 0) {
      tempArray.forEach((item: any) => {
        if (item.diamondsStockType === diamondsStockType) {
          exist = true;
        }
      });
    }

    if (!exist) {
      let tempObject = {
        diamondsStockType: diamondsStockType,
        data: items.filter(
          (data: any) => data.diamondsStockType === product.diamondsStockType
        ),
      };
      tempArray.push(tempObject);
    }
  });

  return tempArray;
};

export const showPersonForm = (
  setFormData?: any,
  setShow?: any,
  item?: any
) => {
  setFormData({
    id: item?.id || "",
    fullName: item?.fullName || "",
    phone: item?.phone || "",
    phoneOther: item?.phoneOther || "",
    email: item?.email || "",
    address: item?.address || "",
    note: item?.note || "",
  });
  handleShow(setShow);
};

export const getDiscountAmountByPercentage = (
  netPrice: string,
  formData: any
) => {
  let amount: any = 0;
  if (formData.discountValue) {
    let discountPercent = parseFloat(formData.discountValue);
    if (netPrice) {
      amount = (parseInt(netPrice) / 100) * discountPercent;
    }
    amount = parseInt(amount);
  } else {
    amount = 0;
  }
  return amount;
};

export const totalAmount = (netPrice: string, formData: any) => {
  let amount: any = parseInt(netPrice);
  if (netPrice && formData?.discountValue) {
    amount =
      amount -
      (formData?.discountType === "by_percentage"
        ? getDiscountAmountByPercentage(netPrice, formData)
        : parseInt(formData?.discountValue));
  }
  return formData?.isGift ? 0 : amount;
};

export const totalPaidAmount = (data: any) => {
  let amount: any = 0;
  if (data && data?.length > 0) {
    data?.forEach((item: any) => {
      amount += parseInt(item?.amount || 0);
    });
  }
  return amount;
};

export const totalBalance = (data: any) => {
  return totalAmount(data?.netPrice, data) - totalPaidAmount(data?.paymentTrx);
};

export const netPrice = (items: any) => {
  if (items?.length) {
    const discount = items?.reduce((acc: number, cur: any) => {
      return (
        acc +
        (cur?.isGift
          ? 0
          : cur?.discountType === "by_amount"
          ? Number(cur?.discountValue)
          : (parseInt(cur?.sellingPrice || cur?.price) / 100) *
            cur?.discountValue)
      );
    }, 0);

    const total: any = items?.reduce((acc: number, cur: any) => {
      return acc + (cur?.isGift ? 0 : Number(cur?.sellingPrice || cur?.price));
    }, 0);

    return total - discount;
  }

  return 0;
};

export const totalBalanceTwo = (data: any) => {
  return (
    netPrice(data?.sale_cali_gemstone_items) - totalPaidAmount(data?.paymentTrx)
  );
};

export const defineAccess = (array: any) => {
  return array.some((item: any) => item === userLevel());
};

export const userLevel = () => {
  let role = getStoreUser()?.roleName;
  let level = 4;
  if (role === "Owner") level = 1;
  if (role === "Admin") level = 2;
  if (role === "Manager") level = 3;
  if (role === "Operator") level = 4;
  return level;
};

export const csvToArr = (stringVal: any, splitter: any) => {
  let formedArr: any = [];

  if (stringVal) {
    formedArr = stringVal?.trim().split(splitter);
  }

  return formedArr;
};

export const getSearchParam = (key: any) => {
  const params: any = new URLSearchParams(window.location.search);
  const param = params.get(key) ?? null;
  return param;
};
