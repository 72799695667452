import { useContext, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  IconAdd,
  IconFilter,
  IconGrid,
  IconList,
} from "../../../../components/widgets/Icons";
import { VariableContext } from "../../../../context/Variable";
import { handleShow } from "../../../../services";
import Filter from "./Filter";
import { inventoryTypes } from "../../../../data";

const Header = ({
  result,
  selectCount,
  outlets,
  outletName,
  handleGetDataByFilter,
  isAvailable,
  inventoryType,
  setInventoryType,
  handleChangeAvailable,
  handleGetData,
  setOutletName,
}: {
  outletName: any;
  outlets: any;
  result: number;
  selectCount?: number;
  handleGetDataByFilter: any;
  isAvailable: boolean;
  inventoryType: any;
  setInventoryType: any;
  handleChangeAvailable: any;
  setOutletName: (d: any) => void;
  handleGetData: (v: any, o: any) => void;
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const [variable, setVariable] = useContext(VariableContext);

  const handleSelectChange = (value: any) => {
    setInventoryType(value);
    handleGetData(value, outletName);
    setVariable({
      ...variable,
      inventoryJewelleryType: value,
      jewelleryFilterActionData: {},
      isJewelleryFilter: false,
    });
  };

  return (
    <section className="d-flex justify-content-between mb-4">
      <Col md={6}>
        <div className="d-flex align-items-center">
          <ButtonGroup className="me-3">
            <Button
              onClick={() => handleChangeAvailable(true)}
              className={
                isAvailable
                  ? "text-white bg-grad"
                  : "bg-white text-primary border "
              }
            >
              In stock
            </Button>
            <Button
              onClick={() => handleChangeAvailable(false)}
              className={
                isAvailable
                  ? "bg-white text-primary border "
                  : "text-white  bg-danger "
              }
            >
              Sold out
            </Button>
          </ButtonGroup>

          <Col md={5}>
            <InputGroup>
              <InputGroup.Text className="bg-grad">Outlet</InputGroup.Text>
              <Form.Select
                value={outletName}
                onChange={(e: any) => {
                  setOutletName(e.target.value);
                  handleGetData(inventoryType, e.target.value);
                }}
              >
                <option value="">All</option>
                {outlets?.map((item: any, index: number) => (
                  <option value={item?.name} key={index}>{item?.name}</option>
                ))}
              </Form.Select>
            </InputGroup>
          </Col>
          {/* {result ? (
            <span className="ms-3">
              <small className="text-muted">
                {variable?.isJewelleryFilter ? "Filter result" : "Result"} :{" "}
              </small>
              {numberFormat(result)}
            </span>
          ) : (
            <></>
          )} */}
        </div>
      </Col>

      <section className=" d-flex align-items-center">
        {selectCount ? (
          <span>
            {selectCount} <small className="text-muted me-3">selected</small>
          </span>
        ) : (
          <></>
        )}

        <div className="me-2">
          <Form.Select
            value={inventoryType}
            onChange={(e: any) => handleSelectChange(e.target.value)}
          >
            {inventoryTypes?.map((item: any, index: number) => (
              <option key={index} value={item?.key}>
                {item?.label}
              </option>
            ))}
          </Form.Select>
        </div>

        <OverlayTrigger overlay={<Tooltip>Add jewellery</Tooltip>}>
          <Button
            className="bg-grad me-2"
            onClick={() => history.push("/add-jewellery")}
          >
            <IconAdd />{" "}
          </Button>
        </OverlayTrigger>

        <Button className="bg-grad me-2" onClick={() => handleShow(setShow)}>
          <IconFilter />
        </Button>

        {/* <OverlayTrigger overlay={<Tooltip>Print labels</Tooltip>}>
                    <Button className="bg-grad me-2" onClick={() => history.push("/jewellery-labels")}><IconPrint /></Button>
                </OverlayTrigger> */}

        <ButtonGroup>
          <OverlayTrigger overlay={<Tooltip>Grid view</Tooltip>}>
            <Button
              onClick={() => setVariable({ ...variable, isList: false })}
              className={
                variable?.isList
                  ? "bg-white text-primary"
                  : "text-white bg-grad"
              }
            >
              <IconGrid />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>List view</Tooltip>}>
            <Button
              onClick={() => setVariable({ ...variable, isList: true })}
              className={
                variable?.isList
                  ? "text-white bg-grad"
                  : "bg-white text-primary"
              }
            >
              <IconList />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </section>

      <Filter
        show={show}
        setShow={setShow}
        handleGetData={handleGetData}
        handleGetDataByFilter={handleGetDataByFilter}
      />
    </section>
  );
};

export default Header;
