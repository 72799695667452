import { useContext } from "react";
import {
  Accordion,
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  Form,
} from "react-bootstrap";
import { CustomToggle, ThumbsnailImage } from "../../../components";
import { IconEdit } from "../../../components/widgets/Icons";
import { CartContext } from "../../../context/Cart";
import { disableScroll, numberFormat } from "../../../services";

const CartItem = ({
  hideRemoveButton = false,
  isGift = false,
}: {
  hideRemoveButton?: boolean;
  isGift?: boolean;
}) => {
  const [cart, setCart] = useContext(CartContext);

  const removeItem = (item: any) => {
    setCart({ ...cart, item: {} });
  };

  return (
    <>
      {cart?.item?.itemName ? (
        <div className="bg-light p-4 rounded">
          <Row>
            <Col md={4}>
              <div className="cart-item-image">
                <ThumbsnailImage item={cart?.item} />
              </div>
              {!hideRemoveButton && (
                <div className="text-center mt-3 cursor">
                  <small className="text-danger" onClick={removeItem}>
                    Remove item
                  </small>
                </div>
              )}
            </Col>
            <Col>
              <label className="d-block fw-bold mb-3">
                {cart?.item?.itemName}
              </label>
              <small className="xs-label text-muted d-block mb-2">
                {cart?.item?.sku}
              </small>

              {cart?.item?.weight?.totalGemsWeight ? (
                <small className="d-block mb-2">
                  <span className="text-muted">Gems weight : </span>
                  {cart?.item?.weight?.totalGemsWeight}{" "}
                  {cart?.item?.weight?.totalGemsWeightUnit}
                </small>
              ) : (
                <></>
              )}
              {cart?.item?.weight?.totalDiamondsWeight ? (
                <small className="d-block mb-2">
                  <span className="text-muted">Diamonds weight : </span>
                  {cart?.item?.weight?.totalDiamondsWeight}{" "}
                  {cart?.item?.weight?.totalDiamondsWeightUnit}
                </small>
              ) : (
                <></>
              )}
              {cart?.item?.gold ? (
                <small className="d-block mb-4">
                  <span className="text-muted">Gold weight : </span>
                  {cart?.item?.gold[0]?.totalGoldWeight}{" "}
                  {cart?.item?.gold[0]?.totalGoldWeightUnit}{" "}
                  {cart?.item?.gold[0]?.goldSmithLossNote
                    ? `, ${cart?.item?.gold[0]?.goldSmithLossNote}`
                    : ""}
                </small>
              ) : (
                <></>
              )}

              {isGift ? (
                <p className="text-muted">Gift item</p>
              ) : (
                <Accordion defaultActiveKey="" className="mt-1">
                  <div className="d-flex align-items-center">
                    <p className="fw-bold mb-0 me-2">
                      <span className="text-muted">Net price : </span>{" "}
                      {cart?.item?.price?.currency}{" "}
                      {numberFormat(cart?.netPrice)}
                    </p>

                    <CustomToggle eventKey="0">
                      <OverlayTrigger
                        overlay={<Tooltip>Edit net price</Tooltip>}
                      >
                        <span className="text-muted cursor">
                          <IconEdit />
                        </span>
                      </OverlayTrigger>
                    </CustomToggle>
                  </div>
                  <Accordion.Collapse eventKey="0" className="border-none">
                    <div className="d-flex align-items-center mt-3">
                      <Col md={9}>
                        <Form.Group className="input-form-control">
                          <Form.Control
                            type={"number"}
                            onWheel={disableScroll}
                            value={cart?.netPrice}
                            onChange={(e) =>
                              setCart({ ...cart, netPrice: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              )}
            </Col>
          </Row>

          {/* <Table responsive striped className="mb-0 mt-4 border">
                        <tbody>
                            {details.map((item: any, index: number) => (!item.hide) && (
                                <tr key={index}>
                                    <td className=" text-muted " style={{ width: "30%" }}>{item.label}</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> */}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CartItem;
