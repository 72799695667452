import { useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import {
  actionData,
  getGemSuggest,
  getMethod,
  gemsRoute,
  postMethod,
} from "../../services/api";
import { CustomSuggestionSelect } from "../widgets/CustomInput";
import { DivLoading } from "../widgets/Loading";
import GemInfo from "./GemInfo";

const GemSelect = ({
  gemStonesId,
  setGemStronesId,
  noBg = false,
  inventoryType = "",
}: {
  gemStonesId?: any;
  setGemStronesId: any;
  noBg?: boolean;
  inventoryType?: string;
}) => {
  const [gems, setGems] = useState([]);
  const [gem, setGem] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    handelGemSuggest("");
    // eslint-disable-next-line
  }, []);

  const handelGemSuggest = async (keyword: string) => {
    let formData = {
      keyword: keyword,
      sort: "desc",
      availability: "Available",
      inventoryType: inventoryType || "",
    };
    actionData(postMethod, getGemSuggest(), formData).then((result) => {
      let temp: any = [];

      result?.data?.map(
        (data: any) =>
          ["Lots", "Pair", "Single", "Loose"]?.includes(data?.stockType) &&
          temp.push({
            ...data,
            value: data.id,
            label: `${data.itemName} (${data.sku})`,
            sku: data.sku,
            itemName: data.itemName,
          })
      );
      setGems(temp);
    });
  };

  const handleInputChange = (newValue: string) => {
    handelGemSuggest(newValue);
  };

  const handleOnChange = useCallback(
    (data: any) => {
      if (data) {
        setLoading(true);
        actionData(getMethod, gemsRoute(data?.value)).then((result) => {
          setGem(result?.data);
          setGemStronesId(result?.data?.id);
          setTimeout(() => {
            setLoading(false);
          }, 800);
        });
      } else {
        setGem(data);
        setGemStronesId("");
      }
    },
    [setGemStronesId]
  );

  useEffect(() => {
    if (gemStonesId) {
      handleOnChange({ value: gemStonesId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gemStonesId]);

  return (
    <section className={noBg ? "" : "bg-card"}>
      <CustomSuggestionSelect
        handleInputChange={handleInputChange}
        handleOnChange={handleOnChange}
        value={gemStonesId}
        id={""}
        label={""}
        data={gems}
        showLabel={false}
        placeholder={`Search by sku or name`}
        disableMargin
      />

      {isLoading ? (
        <div className="mt-4">
          <DivLoading />
        </div>
      ) : (
        <div>
          {gem ? (
            <div className="mt-4">
              <GemInfo gem={gem} />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <Image src="images/search.png" width={170} />
              <small className="text-muted">
                Please find gem by sku code or item name!
              </small>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default GemSelect;
