import React, { useContext } from "react";
import { Col, Image, Table } from "react-bootstrap";
import { QRView, Thumbsnail } from "../../../../components";
import { LangContext } from "../../../../context";
import {
  getDiscountAmountByPercentage,
  handleDateFormat,
  numberFormat,
  totalAmount,
} from "../../../../services";
import InvoicceHeader from "../../../../components/pdf/InvoiceHeader";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Invoice = ({
  data,
  showDataCheck,
}: {
  data: any;
  showDataCheck: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <div className="bg-card">
      <InvoicceHeader />
      {/* <section className="text-center mb-4">
        <img
          src="images/mr.spinel.png"
          className="p-5 pb-4"
          alt="no_found"
          width={300}
        />
        <span className="d-block pb-4 text-white-50">
          <small>78 street, Bet; 34 x 35 street, Mandalay | </small>{" "}
          <small>Ph : 09-43200898 , 09-799075549 , 09-425291390</small>
        </span>
      </section> */}

      <div className="flex-between align-items-center">
        <div style={{ width: "50%" }}>
          <span className="d-block mb-2">
            <small className="text-muted">Customer</small> :{" "}
            <small>{data?.buyer?.fullName || "-"}</small>
          </span>
          <span className="d-block mb-2">
            <small className="text-muted">Phone</small> :{" "}
            <small>{data?.buyer?.phone || "-"}</small>
          </span>
          {data?.buyer?.address && (
            <span className="d-block mb-2">
              <small className="text-muted">{translate("address")}</small> :{" "}
              <small>{data?.buyer?.address || "-"} </small>
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          <QRView text={data?.invoiceNumber} width={50} />
          <div className="ms-3 d-flex flex-column align-items-end">
            <span className="d-block mb-2 mt-1">
              <small>{data?.invoiceNumber}</small>
            </span>
            <span className="d-block mb-2">
              <small className="text-muted">{translate("date")}</small> :{" "}
              <small>{handleDateFormat(data?.createdAt)}</small>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <h6 className="fw-bold border text-center p-2">SALE INVOICE</h6>
      </div>

      <Table responsive striped bordered className="bg-light border mt-3 mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th style={{ width: "18%" }}>Code</th>
            <th>Item</th>
            {showDataCheck.showGemsWeight && <th>Gem (carat)</th>}
            <th>Diamond (carat)</th>
            <th>Gold (grams)</th>
            <th>Weight (grams)</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {data?.sets?.jewellery.map((subItem: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">{index + 1}</span>
              </td>
              <td>
                <span className="flex-center">
                  <small>{subItem?.sku}</small>
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div className="thumbnail-list-image">
                    <Thumbsnail images={subItem?.images} />
                  </div>
                </span>
              </td>
              {showDataCheck.showGemsWeight && (
                <td>
                  <span className="flex-end">
                    {subItem?.weight?.totalGemsWeight}
                  </span>
                </td>
              )}
              <td>
                <span className="flex-end">
                  {subItem?.weight?.totalDiamondsWeight}
                </span>
              </td>
              <td>
                <span className="flex-end">
                  {subItem?.gold?.totalGoldWeight}
                </span>
              </td>
              <td>
                <span className="flex-end">{subItem?.jewelleryWeight}</span>
              </td>
              <td>
                <span className="flex-end">
                  {numberFormat(subItem?.sellingPrice)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mb-4 mt-4">
        <div className="d-flex align-items-center">
          <div className="receit-item-image">
            {data?.sets?.images ? (
              <Image
                src={`${RES_API_URL}${data?.sets?.images?.url}`}
                alt="no_found"
              />
            ) : (
              <Image src={"images/no-image.png"} />
            )}
          </div>
          <div className="ms-3">
            <small className="xs-label d-block mb-2 fw-bold">
              {data?.sets?.itemName}
            </small>
            <small className="xs-label d-block mb-2 text-muted">
              {data?.sets?.sku}
            </small>
            {data?.sets?.jewelleryIncluded ? (
              <small className="xs-label d-block mb-2">
                <span className="text-muted">Jewellery included : </span>
                {data?.sets?.jewelleryIncluded}
              </small>
            ) : (
              <></>
            )}
          </div>
        </div>

        {data?.isGift ? (
          <></>
        ) : (
          <Col md={4} className="me-2">
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">Subtotal</small>
              <small>{numberFormat(data?.netPrice)}</small>
            </div>
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">
                Discount{" "}
                {data?.discountType === "by_percentage" &&
                  `(${data?.discountValue}%)`}
              </small>
              <small>
                {data?.discountValue && data?.discountValue !== "0" && "-"}{" "}
                {data?.discountValue
                  ? numberFormat(
                      data?.discountType === "by_percentage"
                        ? getDiscountAmountByPercentage(data?.netPrice, data)
                        : data?.discountValue
                    )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top">
              <small className="text-muted d-block">
                Total ({data?.currency})
              </small>
              <small>{numberFormat(totalAmount(data?.netPrice, data))}</small>
            </div>
          </Col>
        )}
      </div>

      {data?.saleNotes && showDataCheck.showSaleNotes && (
        <span className="d-block">
          <small className="text-muted">Notes </small> :{" "}
          <small>{data?.saleNotes}</small>
        </span>
      )}

      <small className="text-danger text-center d-block mb-3 mt-5">
        {translate("receipt-tip")}
      </small>
      <small className="text-muted text-center d-block">
        {translate("thankForYourPurchase")}
      </small>
    </div>
  );
};

export default Invoice;
