import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { Lang, Layout } from "../../../components";
import {
  JewelleryInventoryDetailsPdf,
  JewelleryInventorySummaryPdf,
} from "../../../components/pdf";
import { IconPrint } from "../../../components/widgets/Icons";
import {
  DetailLoading,
  TableLoading,
} from "../../../components/widgets/Loading";
import { PgComponent } from "../../../components/widgets/Pagination";
import { LangContext, PageContext, VariableContext } from "../../../context";
import { numberFormat } from "../../../services";
import {
  actionData,
  getDataService,
  getJewelleyInventoryReportRoute,
  getMethod,
} from "../../../services/api";
import Details from "./cpn/Details";
import Summary from "./cpn/Summary";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

function Index() {
  const [detailData, setDetailData] = useState([]);
  const [status, setStatus] = useState("summary");
  const [inventory, setInventory] = useState<any>();
  const [inventoryWeight, setInventoryWeigh] = useState<any>();
  const [inventoryOnHoldWeight, setInventoryOnHoldWeigh] = useState<any>();
  const [inventoryUnavailableWeight, setInventoryUnavailableWeigh] =
    useState<any>();
  const [inventoryCost, setInventoryCost] = useState<any>();
  const [variable] = useContext(VariableContext);
  const [loading, setLoading] = useState(false);
  const [inventoryType, setInventoryType] = useState("shop_inventory");
  const [page, setPage] = useContext(PageContext);
  const tempUser: any = localStorage.getItem("user");
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const user = tempUser && JSON.parse(tempUser);

  //For Summary Pdf
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);
  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  //For Details Pdf
  const detailsComponentRef = useRef(null);
  const detailsReactToPrintContent = useCallback(() => {
    return detailsComponentRef.current;
  }, []);
  const DetailsPrintAction = useReactToPrint({
    content: detailsReactToPrintContent,
  });

  // const weightCards = [
  //     { label: "Jewelleries", value: numberFormat(inventory?.jewellery?.totalCount || 0), color: "primary" },
  //     { label: "Gemstone weight (carat)", value: numberFormat((inventoryWeight?.totalGemsWeight || 0) + inventoryUnavailableWeight?.totalGemsWeight || 0), color: "primary" },
  //     { label: "Diamond weight (carat)", value: numberFormat(((inventoryWeight?.totalDiamondsWeight || 0) + inventoryUnavailableWeight?.totalDiamondsWeight || 0).toFixed(2)), color: "success" },
  //     { label: "Gold raw weight (grams)", value: numberFormat((inventoryWeight?.totalGoldWeight || 0) + inventoryUnavailableWeight?.totalGoldWeight || 0), color: "info" },
  //     { label: "Gold smith loss weight (grams)", value: numberFormat((inventoryWeight?.totalGoldSmithLossWeight) || 0 + inventoryUnavailableWeight?.totalGoldSmithLossWeight || 0), color: "warning" },
  // ]

  const weightCards = [
    {
      label: "Gemstone (carat)",
      availableValue: numberFormat(inventoryWeight?.totalGemsWeight || 0),
      onHoldValue: numberFormat(inventoryOnHoldWeight?.totalGemsWeight || 0),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalGemsWeight || 0
      ),
    },
    {
      label: "Diamond (carat)",
      availableValue: numberFormat(inventoryWeight?.totalDiamondsWeight || 0),
      onHoldValue: numberFormat(
        inventoryOnHoldWeight?.totalDiamondsWeight || 0
      ),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalDiamondsWeight || 0
      ),
    },
    {
      label: "Gold raw (grams)",
      availableValue: numberFormat(inventoryWeight?.totalGoldWeight || 0),
      onHoldValue: numberFormat(inventoryOnHoldWeight?.totalGoldWeight || 0),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalGoldWeight || 0
      ),
    },
    {
      label: "Gold smith loss (grams)",
      availableValue: numberFormat(
        inventoryWeight?.totalGoldSmithLossWeight || 0
      ),
      onHoldValue: numberFormat(
        inventoryOnHoldWeight?.totalGoldSmithLossWeight || 0
      ),
      unavailableValue: numberFormat(
        inventoryUnavailableWeight?.totalGoldSmithLossWeight || 0
      ),
    },
  ];

  // const costCards = [
  //     { label: "Total jewellery cost", value: numberFormat(inventoryCost?.totalJewelleryCost || 0), color: "primary" },
  //     { label: "Total gems cost", value: numberFormat(inventoryCost?.totalGemsCost || 0), color: "success" },
  //     { label: "Total diamonds cost", value: numberFormat(inventoryCost?.totalDiamondsCost || 0), color: "info" },
  //     { label: "Total gold cost", value: numberFormat(inventoryCost?.totalGoldCost || 0), color: "warning" },
  //     { label: "All total cost (MMK)", value: numberFormat(inventoryCost?.allTotalCost || 0), color: "primary" },
  // ]

  const costCards = [
    {
      label: "Jewellery",
      inStockValue: numberFormat(
        inventoryCost?.instock?.totalJewelleryCost || 0
      ),
      color: "primary",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalJewelleryCost || 0),
    },
    {
      label: "Gems",
      inStockValue: numberFormat(inventoryCost?.instock?.totalGemsCost || 0),
      color: "success",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalGemsCost || 0),
    },
    {
      label: "Diamond",
      inStockValue: numberFormat(
        inventoryCost?.instock?.totalDiamondsCost || 0
      ),
      color: "info",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalDiamondsCost || 0),
    },
    {
      label: "Gold",
      inStockValue: numberFormat(inventoryCost?.instock?.totalGoldCost || 0),
      color: "warning",
      onHoldValue: numberFormat(inventoryCost?.onhold?.totalGoldCost || 0),
    },
    {
      label: "All total",
      inStockValue: numberFormat(inventoryCost?.instock?.allTotalCost || 0),
      color: "primary",
      onHoldValue: numberFormat(inventoryCost?.onhold?.allTotalCost || 0),
    },
  ];

  useEffect(() => {
    handleGetData("shop_inventory");
    handleGetDetailData(page.current, "shop_inventory");
    // eslint-disable-next-line
  }, []);

  const handleGetDetailData = (skip: number, invType?: string) => {
    getDataService(
      getJewelleyInventoryReportRoute(
        skip,
        invType || variable?.inventoryJewelleryType,
        invType === "private_item"
          ? user?.userRole !== "Owner"
            ? `&ownerId=${user?.ownerId}`
            : ""
          : "",
        "80"
      ),
      setLoading,
      setDetailData,
      setPage,
      skip
    );
  };

  const handleGetData = async (inventoryType: string) => {
    // user?.userRole != 'Owner'
    let ownerId =
      inventoryType === "private_item"
        ? user?.userRole !== "Owner"
          ? `&ownerId=${user?.ownerId}`
          : ""
        : "";
    setLoading(true);
    actionData(
      getMethod,
      `count/inventory?inventoryType=${inventoryType}${ownerId}`
    ).then((result) => {
      setInventory(result.data);
      setInventoryType(inventoryType);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });

    actionData(
      getMethod,
      `cost/inventory?inventoryType=${inventoryType}${ownerId}`
    ).then((result) => {
      setInventoryCost(result.data);
    });

    actionData(
      getMethod,
      `weight/inventory?inventoryType=${inventoryType}${ownerId}`
    ).then((result) => {
      setInventoryWeigh(result.data.inStock);
      setInventoryOnHoldWeigh(result.data.onHold);
      setInventoryUnavailableWeigh(result.data.soldOut);
    });
  };

  return (
    <Layout title="Jewellery inventory report">
      <style type="text/css" media="print">
        {"@page { size: landscape; }"}
      </style>
      <div className="flex-between mb-4">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text className="bg-grad">
              <Lang label="inventoryType" />
            </InputGroup.Text>
            <Form.Control
              as="select"
              aria-label="Select"
              onChange={(e) => {
                handleGetData(e.target.value);
                handleGetDetailData(0, e.target.value);
              }}
            >
              <option value="shop_inventory">{translate("shopItems")}</option>
              <option value="service_item">{translate("serviceItems")}</option>
              <option value="ordered_item">{translate("orderedItems")}</option>
              <option value="private_item">{translate("privateItems")}</option>
            </Form.Control>
          </InputGroup>
        </Col>

        <div className="d-flex">
          <ButtonGroup className="me-3">
            <Button
              onClick={() => setStatus("summary")}
              className={
                status === "summary"
                  ? "text-white bg-grad"
                  : "bg-white text-primary border "
              }
            >
              <Lang label="summary" />
            </Button>
            <Button
              onClick={() => setStatus("details")}
              className={
                status === "summary"
                  ? "bg-white text-primary border "
                  : "text-white  bg-grad "
              }
            >
              <Lang label="detail" />
            </Button>
          </ButtonGroup>

          <div style={{ display: "none" }}>
            <JewelleryInventorySummaryPdf
              ref={componentRef}
              jewelleryCount={numberFormat(
                inventory?.jewellery?.inStock?.count || 0
              )}
              inventoryType={inventoryType}
              weightCards={weightCards}
              costCards={costCards}
            />
          </div>
          <div style={{ display: "none" }}>
            <JewelleryInventoryDetailsPdf
              ref={detailsComponentRef}
              data={detailData}
              inventoryType={inventoryType}
              pageCurrent={page.current}
            />
          </div>

          <OverlayTrigger overlay={<Tooltip>Print report</Tooltip>}>
            <Button
              className="btn btn-grad"
              onClick={() =>
                status === "summary" ? PrintAction() : DetailsPrintAction()
              }
            >
              <IconPrint />
            </Button>
          </OverlayTrigger>
        </div>
      </div>

      {status === "summary" ? (
        <>
          {loading ? (
            <DetailLoading />
          ) : (
            <Summary
              jewelleryCount={numberFormat(
                inventory?.jewellery?.inStock?.count || 0
              )}
              inventoryType={inventoryType}
              weightCards={weightCards}
              costCards={costCards}
            />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <TableLoading />
          ) : (
            <Details
              pageCurrent={page.current}
              data={detailData}
              inventoryType={inventoryType}
            />
          )}
        </>
      )}

      {status !== "summary" ? (
        <div className="mt-4">
          {page && page.total > limit && (
            <PgComponent
              currentPage={page.current + 1 || 0}
              fromCount={page.current || 0}
              total={page.total || 0}
              paginationLimit={80}
              pageInfo={page}
              setPage={setPage}
              handleGetData={handleGetDetailData}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
}

export default Index;
