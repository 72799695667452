import React from "react";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import {
  IconAdd,
  IconDelete,
  IconEdit,
} from "../../../../components/widgets/Icons";
import { CustomModal, NoData } from "../../../../components";
import {
  actionData,
  dataService,
  deleteService,
  getPersons,
  responseService,
} from "../../../../services/api";
import { CustomSuggestionSelect } from "../../../../components/widgets/CustomInput";
import { numberFormat } from "../../../../services";
import { toast } from "react-toastify";

interface InvestorsProps {
  product: any;
  handleGetData: () => void;
}

const Investors: React.FC<InvestorsProps> = ({ product, handleGetData }) => {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [investorOptions, setInvestorOptions] = React.useState([]);
  const price = product?.price;

  const defaultValue = {
    gemstonesId: null,
    investorId: null,
    investmentType:
      product?.gemstonesInvestors?.[0]?.investmentType || "By amount",
    investmentAmount: "",
    investmentPercentage: "",
  };

  const [formData, setFormData] = React.useState<any>(defaultValue);

  const handleGetPersonData = (keyword: any) => {
    actionData("GET", getPersons(0, keyword)).then((result) => {
      let temp: any = [];

      result.data.forEach((data: any) => {
        temp.push({
          ...data,
          value: data?.id,
          label: `${data.fullName} (${data.phone})`,
        });
      });

      setInvestorOptions(temp);
    });
  };

  React.useEffect(() => {
    handleGetPersonData("");
  }, []);

  const showForm = (item?: any, index?: any) => {
    setShow(true);

    if (item?.person?.id) {
      setIsEdit(true);
      setIndex(index);
      handleGetPersonData(item?.person?.fullName);
    } else {
      setIndex(0);
      setIsEdit(false);
    }

    setFormData(item || defaultValue);
  };

  const closeForm = () => {
    setShow(false);
    setIsEdit(false);
    handleGetPersonData("");
    setFormData(defaultValue);
  };

  const handleSwitchChange = (status: boolean) => {
    const _newData = { ...formData };
    _newData.investmentType = status ? "By percentage" : "By amount";
    _newData.investmentAmount = "";
    _newData.investmentPercentage = "";
    setFormData(_newData);
  };

  const handleInputChange = (value: string) => {
    const _newData = { ...formData };

    if (_newData?.investmentType === "By amount") {
      _newData.investmentAmount = value;
      _newData.investmentPercentage = "";
    } else {
      _newData.investmentAmount = "";
      _newData.investmentPercentage = value;
    }

    setFormData(_newData);
  };

  const handleSetPerson = (value: any) => {
    const _newData = { ...formData };
    _newData.investorId = value?.id;
    setFormData(_newData);
  };

  const handleInputOnChange = (value: any) => {
    handleGetPersonData(value);
  };

  const handleSubmit = () => {
    const fd = formData;
    const productInvestors = product?.gemstonesInvestors;
    const investorExist = productInvestors?.find(
      (g: any) => g?.investorId === fd?.investorId
    );

    const investmentType =
      product?.gemstonesInvestors?.[0]?.investmentType ||
      formData?.investmentType
        ? formData?.investmentType
        : "By amount";

    const value = 0;
    const totalAmount =
      productInvestors?.reduce(
        (a: any, c: any) => a + Number(c?.investmentAmount),
        value
      ) - (isEdit ? Number(productInvestors?.[index]?.investmentAmount) : 0);

    const totalPercentage =
      productInvestors?.reduce(
        (a: any, c: any) => a + Number(c?.investmentPercentage),
        value
      ) -
      (isEdit ? Number(productInvestors?.[index]?.investmentPercentage) : 0);

    if (fd?.investorId && (fd?.investmentAmount || fd?.investmentPercentage)) {
      if (!isEdit && investorExist) {
        toast.error("This investor has already added!");
      } else {
        if (fd?.investmentType !== investmentType) {
          toast.error("Please provide valid investment type!");
        } else {
          const logicOne =
            investmentType === "By amount" &&
            totalAmount + Number(fd?.investmentAmount) >
              Number(price?.buyingPrice);

          const logicTwo =
            investmentType === "By percentage" &&
            totalPercentage + Number(fd?.investmentPercentage) > 100;

          if (logicOne) {
            toast.error("Total invenstment amount is more than buying price!");
          }

          if (logicTwo) {
            toast.error("Total invenstment percentage is more than 100%!");
          }

          if (!logicOne && !logicTwo) {
            if (
              isEdit
                ? productInvestors?.length - 1
                : productInvestors?.length + 1 === price?.sharedInvestorsQty
            ) {
              const logicThree =
                investmentType === "By amount" &&
                totalAmount + Number(fd?.investmentAmount) !==
                  Number(price?.buyingPrice);

              const logicFour =
                investmentType === "By percentage" &&
                totalPercentage + Number(fd?.investmentPercentage) !== 100;

              if (logicThree) {
                toast.error("Please provide total invenstment amount!");
              }

              if (logicFour) {
                toast.error("Please provide total invenstment percentage!");
              }

              if (!logicThree && !logicFour) {
                handleSubmitData();
              }
            } else {
              handleSubmitData();
            }
          }
        }
      }
    } else {
      toast.error("Please provide valid data!");
    }
  };

  const handleSubmitData = () => {
    setLoading(true);
    const _newFormData = {
      gemstonesId: product?.id,
      investorId: formData?.investorId,
      investmentType: formData?.investmentType,
      investmentAmount: formData?.investmentAmount || "0",
      investmentPercentage: formData?.investmentPercentage || "0",
    };

    if (isEdit) {
      handeUpdate(_newFormData);
    } else {
      handeCreate(_newFormData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const handeCreate = (_data: any) => {
    dataService("POST", _data, [], `gems-investor`, true).then((result) => {
      handleResponse(result);
    });
  };

  const handeUpdate = (_data: any) => {
    dataService("PUT", _data, [], `gems-investor/${formData?.id}`, true).then(
      (result) => {
        handleResponse(result);
      }
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.person?.fullName,
      null,
      `gems-investor/${item?.id}`,
      handleResponse
    );
  };

  const handleResponse = (result: any) => {
    responseService(result, handleGetData, closeForm(), null, null, setLoading);
  };

  return (
    <div className="bg-card my-4">
      <div className="flex-between">
        <h6>Investors</h6>
        <div>
          {product?.gemstonesInvestors?.length !==
            price?.sharedInvestorsQty && (
            <Button
              className="text-white bg-grad"
              onClick={() => showForm(null)}
            >
              <IconAdd /> <span className="ms-2">Add investor</span>
            </Button>
          )}
        </div>
      </div>

      {product?.gemstonesInvestors?.length > 0 ? (
        <div className="">
          <Table responsive striped className="mb-0">
            <thead>
              <tr>
                <td className="text-center">No.</td>
                <td className="">Name</td>
                <td className="">Invenstment</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {product?.gemstonesInvestors?.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="">{`${item?.person?.fullName} (${item?.person?.phone})`}</td>
                  <td className="">
                    {item?.investmentType === "By amount"
                      ? `${price?.currency} ${numberFormat(
                          item?.investmentAmount
                        )}`
                      : `${item?.investmentPercentage}%`}
                  </td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                      <Button
                        disabled={
                          product?.gemstonesInvestors?.length ===
                          price?.sharedInvestorsQty
                        }
                        className="text-white btn-sm bg-grad"
                        onClick={() => showForm(item, index)}
                      >
                        <IconEdit />
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                      <Button
                        className="ms-2 text-white btn-sm bg-danger"
                        onClick={() => handleDelete(item)}
                      >
                        <IconDelete />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <NoData noImage />
      )}

      <CustomModal
        show={show}
        loading={loading}
        id={null}
        customTitle={!isEdit ? "Add new investor" : "Edit investor"}
        handleSubmit={handleSubmit}
        handleClose={closeForm}
      >
        <div className="mb-2">
          <CustomSuggestionSelect
            id={formData?.investorId}
            value={formData?.investorId}
            label="Investors name *"
            data={investorOptions}
            defaulLabel={formData.defaultLabel}
            handleOnChange={(e: any) => handleSetPerson(e)}
            handleInputChange={handleInputOnChange}
          />

          <Form.Group>
            <div className="d-flex justify-content-between">
              <div>
                <Form.Label>
                  <span className="me-1">Investment </span>
                  {formData?.investmentType === "By amount"
                    ? "amount"
                    : "(%)"}{" "}
                  *
                </Form.Label>
              </div>
              <div>
                <Form.Check
                  label="%"
                  type="switch"
                  id={`inevst-switch`}
                  checked={formData?.investmentType !== "By amount"}
                  onChange={(e: any) => handleSwitchChange(e.target.checked)}
                />
              </div>
            </div>
            <Form.Control
              style={{ padding: "0.7rem 0.75rem" }}
              value={
                formData?.investmentType === "By amount"
                  ? formData?.investmentAmount
                  : formData?.investmentPercentage
              }
              onChange={(e: any) => handleInputChange(e.target.value)}
            />
          </Form.Group>
        </div>
        <span className="text-muted small">
          Buying price:
          <span className="ms-1">{`${price?.currency} ${numberFormat(
            price?.buyingPrice
          )}`}</span>
        </span>
      </CustomModal>
    </div>
  );
};

export default Investors;
