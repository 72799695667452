import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { NoData } from "../../../components";
import { IconFilter, IconQRScan } from "../../../components/widgets/Icons";
import { GridLoading } from "../../../components/widgets/Loading";
import { PgComponent } from "../../../components/widgets/Pagination";
import { PageContext, VariableContext } from "../../../context";
import { handleShow, numberFormat } from "../../../services";
import {
  actionData,
  calibratedGemsRoute,
  getDataService,
  getCalibratedGemsRoute,
  getPOSCalibratedGemsRoute,
  postMethod,
} from "../../../services/api";
import ItemCard from "./cpn/ItemCard";
import ItemModel from "./cpn/ItemModal";
import Filter from "./cpn/Filter";

const PosItem = () => {
  const [data, setData] = useState([]);
  const [gem, setGem] = useState([]);
  const [search, setSearch] = useState("");
  const [detail, setDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useContext(PageContext);
  const [variable] = useContext(VariableContext);

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService(
      getPOSCalibratedGemsRoute(skip),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleGetDataByFilter = (skip: number, _formData: any) => {
    setLoading(true);
    actionData(postMethod, getPOSCalibratedGemsRoute(skip), _formData).then(
      (result) => {
        setData(result.data);
        setPage({
          current: skip,
          total: result.count,
          sorting: _formData.orderBy,
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    );
  };

  const handleEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      let formData = { sku: e.target.value.trim() };

      actionData(postMethod, getCalibratedGemsRoute(0, true), formData).then(
        (result) => {
          if (result && result.data && result.data.length > 0) {
            setGem(result.data[0]);
            handelDetailAction(result.data[0].id);
          } else {
            toast.error("No data result!");
          }
        }
      );
    }
  };

  const handelDetailAction = (id: string) => {
    getDataService(calibratedGemsRoute(id), setLoading, setDetail, null, 0);
    handleShow(setShowDetail);
    setTimeout(() => {
      setSearch("");
    }, 500);
  };

  const handleChangePage = (skip: number) => {
    variable?.isJewelleryPOSFilter
      ? handleGetDataByFilter(skip, variable?.jewelleryPOSFilterActionData)
      : handleGetData(skip);
  };

  return (
    <div className="pt-4 px-4 pos-item-view">
      <div className="flex-between mb-4">
        <Col md={8}>
          <InputGroup className="input-form-control">
            <InputGroup.Text className="bg-grad text-white">
              <IconQRScan />
            </InputGroup.Text>
            <Form.Control
              placeholder="Scan qrcode or type sku.."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => handleEnterAction(e)}
              autoFocus
            />
          </InputGroup>
        </Col>

        <div className="d-flex align-items-center">
          {page.total ? (
            <span>
              <small className="text-muted">Result : </small>
              {numberFormat(page.total)}
            </span>
          ) : (
            <></>
          )}

          <Button className="bg-grad ms-3" onClick={() => handleShow(setShow)}>
            <IconFilter />
          </Button>
        </div>
      </div>

      {loading ? (
        <GridLoading rowCount={3} />
      ) : (
        <Row className="mb-4">
          {data?.length > 0 ? (
            <>
              {data?.map((item: any, index: number) => (
                <Col md={4} key={index}>
                  <ItemCard item={item} />
                </Col>
              ))}

              {page && (
                <PgComponent
                  currentPage={page.current + 1 || 0}
                  fromCount={page.current || 0}
                  total={page.total || 0}
                  pageInfo={page}
                  setPage={setPage}
                  handleGetData={handleGetData}
                  disablePageInput
                  isFilter={variable?.isJewelleryPOSFilter}
                  handleChangePage={handleChangePage}
                />
              )}
            </>
          ) : (
            <NoData noImage />
          )}
        </Row>
      )}

      <ItemModel
        show={showDetail}
        setShow={setShowDetail}
        item={detail}
        gem={gem}
        loading={loading}
      />

      <Filter
        show={show}
        setShow={setShow}
        handleGetData={handleGetData}
        handleGetDataByFilter={handleGetDataByFilter}
      />
    </div>
  );
};

export default PosItem;
