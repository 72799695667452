import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";
import { IconAdd } from "../../../components/widgets/Icons";
import PersonModel from "../../../components/person/PersonModel";
import { handelGetPersons, showPersonForm } from "../../../services";
import CustomerInfoAccordion from "./CustomerInfoAccordion";

const CustomerInfo = ({
  person,
  setPerson,
}: {
  person: any;
  setPerson: any;
}) => {
  const [show, setShow] = useState(false);
  const [persons, setPersons] = useState([]);
  const [formData, setFormData] = useState<any>({
    id: "",
    fullName: "",
    phone: "",
    phoneOther: "",
    email: "",
    idType: "ord",
    avatar: "",
    billingCountryId: null,
    billingStateId: null,
    billingCity: "",
    billingAddress: "",
    shippingCountryId: null,
    shippingStateId: null,
    shippingCity: "",
    shippingAddress: "",
    dateOfBirth: "",
    note: "",
  });

  useEffect(() => {
    handelGetPersons("", setPersons);
  }, []);

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  return (
    <section className="mb-4">
      <PersonModel
        show={show}
        setShow={setShow}
        formData={formData}
        setFormData={setFormData}
      />
      <InputGroup className="mb-4">
        <section style={{ width: "85%" }}>
          <Select
            isClearable
            options={persons}
            onInputChange={handleInputChange}
            onChange={(value: any) => setPerson(value)}
            placeholder={"Select Customer"}
            classNamePrefix="react-select"
            className="react-select-container-half-radius"
            styles={{
              control: (base: any, state: any) => ({
                ...base,
                boxShadow: state.isFocused ? 0 : 0,
              }),
            }}
          />
        </section>
        <InputGroup.Text
          className="bg-grad text-white d-flex justify-content-center cursor"
          style={{ width: "15%" }}
          onClick={() => showPersonForm(setFormData, setShow)}
        >
          <span>
            <IconAdd />
          </span>
        </InputGroup.Text>
      </InputGroup>

      <CustomerInfoAccordion person={person} />
    </section>
  );
};

export default CustomerInfo;
