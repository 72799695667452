import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { LangContext } from "../../context/Lang";
import { PageContext } from "../../context/Page";
import { VariableContext } from "../../context/Variable";
import { defineAccess, logout } from "../../services";
import {
  IconDashboard,
  IconPeople,
  IconGear,
  IconPointOfSale,
  IconGem,
  IconSale,
  IconDiamond,
  IconPersonSquare,
  IconBoxs,
  IconArrowReturn,
  IconInventory,
  IconClipboardPulse,
  IconClipboardData,
  IconPerson,
  IconLock,
  IconSignOut,
  IconCalculator,
  IconPayment,
} from "../widgets/Icons";

const LeftSidebar = () => {
  const history = useHistory();
  const [variable, setVariable] = useContext(VariableContext);
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/")[1];
  const [page, setPage] = useContext(PageContext);

  const goPage = (route: string) => {
    //Reset page
    if (`/${splitLocation}` !== route) {
      setPage({ current: 0, total: 0, sorting: page.sorting });
      setVariable({
        ...variable,
        jewelleryFilterActionData: {},
        isJewelleryFilter: false,
        inventoryJewelleryType: "shop_inventory",
      });
    } else {
      window.location.reload();
    }

    history.push(route);
  };

  const menusOne = [
    {
      route: "dashboard",
      icon: <IconDashboard />,
      label: translate("dashboard"),
    },
  ];

  const menusTwo = [
    {
      route: "persons",
      icon: <IconPeople size={16} />,
      label: translate("persons"),
    },
    {
      route: "configuration",
      icon: <IconGear />,
      label: translate("configuration"),
    },
    { route: "profile", icon: <IconPerson />, label: translate("profile") },
    {
      route: "change-password",
      icon: <IconLock />,
      label: translate("changePassword"),
    },
  ];

  const handleLogout = () => {
    swal({
      text: `Are you sure to logout?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willLogout) => {
      if (willLogout) {
        logout();
        history.push("/");
      }
    });
  };

  return (
    <div className="sidebar">
      <Sidebar
        collapsed={variable?.isSidebarActive}
        breakPoint="xs"
        width="320px"
        color="#000"
        backgroundColor="#fff"
        rootStyles={{
          ".ps-submenu-content": {
            width: "unset",
          },
          ".ps-menuitem-root .ps-menu-label": {
            paddingRight: "45px",
          },
          "&.ps-collapsed": {
            ".ps-menu-button": {
              width: "100%",
            },
            ".ps-menuitem-root .ps-menu-label": {
              paddingRight: "0px",
            },
          },
        }}
      >
        <div className="sidebar-btn-wrapper border-bottom">
          <div className="sidebar-btn">
            <Image
              src={"images/logo.png"}
              height={25}
              className="sidebar-logo"
            />
            <span style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
              {process.env.REACT_APP_APP_TITLE}
            </span>
          </div>
        </div>

        <Menu>
          {defineAccess([1, 2]) &&
            menusOne.map((item: any, index: number) => (
              <MenuItem
                icon={item.icon}
                active={pathname === "/" + item.route}
                component={<Link to={item.route} />}
                key={index}
              >
                {item.label}
              </MenuItem>
            ))}

          {defineAccess([1, 2]) && (
            <SubMenu
              label={translate("inventory")}
              icon={<IconInventory />}
              defaultOpen={[
                "/jewelleries",
                "/jewellery-sets",
                "/gems",
                "/calibrated-gems",
              ].includes(pathname)}
            >
              <MenuItem
                icon={<IconGem size={16} />}
                active={pathname === "/jewelleries"}
                onClick={() => goPage(`/jewelleries`)}
              >
                {translate("jewelleries")}
              </MenuItem>
              <MenuItem
                icon={<IconBoxs />}
                active={pathname === "/jewellery-sets"}
                onClick={() => goPage(`/jewellery-sets`)}
              >
                {translate("jewellerySets")}
              </MenuItem>
              <MenuItem
                icon={<IconDiamond size={16} />}
                active={pathname === "/gems"}
                onClick={() => goPage(`/gems`)}
              >
                {translate("gems")}
              </MenuItem>
              <MenuItem
                icon={<IconDiamond size={16} />}
                active={pathname === "/calibrated-gems"}
                onClick={() => goPage(`/calibrated-gems`)}
                title={translate("calibratedGems")}
              >
                {translate("calibratedGems")}
              </MenuItem>
            </SubMenu>
          )}

          <SubMenu
            label={translate("pointOfSale")}
            icon={<IconPointOfSale />}
            defaultOpen={[
              "/jewellery-point-of-sale",
              "/jewellery-sets-pos",
              "/gems-point-of-sale",
              "/calibrated-gems-point-of-sale",
            ].includes(pathname)}
          >
            <MenuItem
              icon={<IconGem size={16} />}
              active={pathname === "/jewellery-point-of-sale"}
              onClick={() => goPage(`/jewellery-point-of-sale`)}
            >
              {translate("jewelleries")}
            </MenuItem>
            <MenuItem
              icon={<IconBoxs />}
              active={pathname === "/jewellery-sets-pos"}
              onClick={() => goPage(`/jewellery-sets-pos`)}
            >
              {translate("jewellerySets")}
            </MenuItem>
            <MenuItem
              icon={<IconDiamond size={16} />}
              active={pathname === "/gems-point-of-sale"}
              onClick={() => goPage(`/gems-point-of-sale`)}
            >
              {translate("gems")}
            </MenuItem>
            <MenuItem
              icon={<IconDiamond size={16} />}
              active={pathname === "/calibrated-gems-point-of-sale"}
              onClick={() => goPage(`/calibrated-gems-point-of-sale`)}
              title={translate("calibratedGems")}
            >
              {translate("calibratedGems")}
            </MenuItem>
          </SubMenu>

          {defineAccess([1, 2, 3]) && (
            <SubMenu
              label={translate("consignments")}
              icon={<IconPersonSquare size={16} />}
              defaultOpen={[
                "/jewellery-consignments",
                "/gems-consignments",
                "/calibrated-gems-consignments",
              ].includes(pathname)}
            >
              <MenuItem
                icon={<IconGem size={16} />}
                active={pathname === "/jewellery-consignments"}
                onClick={() => goPage(`/jewellery-consignments`)}
              >
                {translate("jewelleries")}
              </MenuItem>
              <MenuItem
                icon={<IconDiamond size={16} />}
                active={pathname === "/gems-consignments"}
                onClick={() => goPage(`/gems-consignments`)}
              >
                {translate("gems")}
              </MenuItem>
              <MenuItem
                icon={<IconDiamond size={16} />}
                active={pathname === "/calibrated-gems-consignments"}
                onClick={() => goPage(`/calibrated-gems-consignments`)}
                title={translate("calibratedGems")}
              >
                {translate("calibratedGems")}
              </MenuItem>
            </SubMenu>
          )}

          {defineAccess([1, 2, 3]) && (
            <SubMenu
              label={translate("sales")}
              icon={<IconSale size={16} />}
              defaultOpen={[
                "/jewellery-sales",
                "/jewellery-set-sales",
                "/gems-sales",
                "/calibrated-gems-sales",
              ].includes(pathname)}
            >
              <MenuItem
                icon={<IconGem size={16} />}
                active={pathname === "/jewellery-sales"}
                onClick={() => goPage(`/jewellery-sales`)}
              >
                {translate("jewelleries")}
              </MenuItem>
              <MenuItem
                icon={<IconBoxs />}
                active={pathname === "/jewellery-set-sales"}
                onClick={() => goPage(`/jewellery-set-sales`)}
              >
                {translate("jewellerySets")}
              </MenuItem>
              <MenuItem
                icon={<IconDiamond size={16} />}
                active={pathname === "/gems-sales"}
                onClick={() => goPage(`/gems-sales`)}
              >
                {translate("gems")}
              </MenuItem>
              <MenuItem
                icon={<IconDiamond size={16} />}
                active={pathname === "/calibrated-gems-sales"}
                onClick={() => goPage(`/calibrated-gems-sales`)}
                title={translate("calibratedGems")}
              >
                {translate("calibratedGems")}
              </MenuItem>
            </SubMenu>
          )}

          {defineAccess([1, 2, 3]) && (
            <SubMenu
              label={translate("returnedList")}
              icon={<IconArrowReturn />}
              defaultOpen={[
                "/jewellery-return-lists",
                "/jewellery-set-return-lists",
              ].includes(pathname)}
            >
              <MenuItem
                icon={<IconGem size={16} />}
                active={pathname === "/jewellery-return-lists"}
                onClick={() => goPage(`/jewellery-return-lists`)}
              >
                {translate("jewelleries")}
              </MenuItem>
              <MenuItem
                icon={<IconBoxs size={16} />}
                active={pathname === "/jewellery-set-return-lists"}
                onClick={() => goPage(`/jewellery-set-return-lists`)}
              >
                {translate("jewellerySets")}
              </MenuItem>
            </SubMenu>
          )}

          {defineAccess([1, 2, 3]) && (
            <SubMenu
              label={"Accounting"}
              icon={<IconCalculator />}
              defaultOpen={[
                "/payable",
                "/receivable",
                "/bad-debts",
                "/expense",
              ].includes(pathname)}
            >
              <MenuItem
                icon={<IconPayment size={16} />}
                active={pathname === "/payable"}
              >
                Payable
              </MenuItem>
              <MenuItem
                icon={<IconPayment size={16} />}
                active={pathname === "/receivable"}
                onClick={() => goPage("/receivable")}
              >
                Receivable
              </MenuItem>
              <MenuItem
                icon={<IconPayment size={16} />}
                active={pathname === "/bad-debts"}
              >
                Bad debts
              </MenuItem>
              <MenuItem
                icon={<IconPayment size={16} />}
                active={pathname === "/expense"}
              >
                Expense
              </MenuItem>
            </SubMenu>
          )}

          <SubMenu
            label={translate("inventoryReport")}
            icon={<IconClipboardData />}
            defaultOpen={[
              "/jewellery-inventory-reports",
              "/set-inventory-reports",
              "/gem-inventory-reports",
              "/calibrated-gem-inventory-reports",
            ].includes(pathname)}
          >
            <MenuItem
              icon={<IconGem size={16} />}
              active={pathname === "/jewellery-inventory-reports"}
              onClick={() => goPage(`/jewellery-inventory-reports`)}
            >
              {translate("jewelleries")}
            </MenuItem>
            <MenuItem
              icon={<IconBoxs />}
              active={pathname === "/set-inventory-reports"}
              onClick={() => goPage(`/set-inventory-reports`)}
            >
              {translate("jewellerySets")}
            </MenuItem>
            <MenuItem
              icon={<IconDiamond size={16} />}
              active={pathname === "/gem-inventory-reports"}
              onClick={() => goPage(`/gem-inventory-reports`)}
            >
              {translate("gems")}
            </MenuItem>
            <MenuItem
              icon={<IconDiamond size={16} />}
              active={pathname === "/calibrated-gem-inventory-reports"}
              onClick={() => goPage(`/calibrated-gem-inventory-reports`)}
              title={translate("calibratedGems")}
            >
              {translate("calibratedGems")}
            </MenuItem>
          </SubMenu>

          <SubMenu
            label={translate("saleReport")}
            icon={<IconClipboardPulse />}
            defaultOpen={[
              "/jewellery-sale-reports",
              "/set-sale-reports",
              "/gem-sale-reports",
              "/calibrated-gem-sale-reports",
            ].includes(pathname)}
          >
            <MenuItem
              icon={<IconGem size={16} />}
              active={pathname === "/jewellery-sale-reports"}
              onClick={() => goPage(`/jewellery-sale-reports`)}
            >
              {translate("jewelleries")}
            </MenuItem>
            <MenuItem
              icon={<IconBoxs />}
              active={pathname === "/set-sale-reports"}
              onClick={() => goPage(`/set-sale-reports`)}
            >
              {translate("jewellerySets")}
            </MenuItem>
            <MenuItem
              icon={<IconDiamond size={16} />}
              active={pathname === "/gem-sale-reports"}
              onClick={() => goPage(`/gem-sale-reports`)}
            >
              {translate("gems")}
            </MenuItem>
            <MenuItem
              icon={<IconDiamond size={16} />}
              active={pathname === "/calibrated-gem-sale-reports"}
              onClick={() => goPage(`/calibrated-gem-sale-reports`)}
              title={translate("calibratedGems")}
            >
              {translate("calibratedGems")}
            </MenuItem>
          </SubMenu>

          {defineAccess([1, 2]) &&
            menusTwo.map((item: any, index: number) => (
              <MenuItem
                icon={item.icon}
                active={pathname === item.route}
                onClick={() => goPage(`/${item.route}`)}
                key={index}
              >
                {item.label}
              </MenuItem>
            ))}

          <MenuItem icon={<IconSignOut />} onClick={() => handleLogout()}>
            {translate("signOut")}
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default LeftSidebar;
