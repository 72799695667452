import { Button, Col, Form, FormCheck, Row, Table } from "react-bootstrap";
import {
  IconAdd,
  IconArrowLeftRight,
} from "../../../../components/widgets/Icons";
import {
  CustomInput,
  CustomModal,
  CustomSelectInputGroup,
  FormFieldService,
  NoData,
  TableRowButtons,
} from "../../../../components";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../context/Lang";
import {
  groupByDiamonds,
  handleClose,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import {
  actionData,
  gemsWeightUnitRoute,
  getMethod,
  responseService,
  deleteService,
  dataService,
  postMethod,
  jewelleryRoute,
  getPersons,
  diamondAvgSizesRoute,
} from "../../../../services/api";

const Diamonds = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [persons, setPersons] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [diamondsSizesByPcs, setDiamondsSizesByPcs] = useState([]);
  const [diamondsSizes, setDiamondsSizes] = useState([]);
  const [weightUnits, setWeigthUnits] = useState([]);
  const productFilterItems =
    product && product?.diamonds && product?.diamonds.length > 0
      ? groupByDiamonds(product?.diamonds)
      : [];

  const [formData, setFormData] = useState<any>({
    diamondsId: "",
    diamondsBoughtFrom: null,
    diamondsBoughtFromDefaultValue: "",
    diamondsCarrier: null,
    diamondsCarrierDefaultValue: "",
    diamondsStockType: "",
    diamondsQuantity: null,
    diamondsWeight: "",
    diamondsPcsOneWeight: "",
    diamondsPcsTwoWeight: "",
    diamondsWeightUnit: "carat",
    diamondsSize: null,
    diamondsSizeUnit: "pcs_per_cts",
    diamondsPricePerCts: "",
    totalDiamondsCost: "",
    basedFormula: "rate",
  });
  const stockTypes = [{ label: "Lot" }, { label: "Single" }, { label: "Pair" }];

  const fields = [
    {
      id: "diamondsBoughtFrom",
      label: "Bought from",
      value: formData.diamondsBoughtFrom,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.diamondsBoughtFromDefaultValue,
    },
    {
      id: "diamondsCarrier",
      label: "Carrier",
      value: formData.diamondsCarrier,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.diamondsCarrierDefaultValue,
    },
    {
      id: "diamondsStockType",
      label: "Stock type",
      value: formData.diamondsStockType,
      type: "select",
      data: stockTypes,
      wantString: true,
      disableInititalSelect: true,
    },
  ];

  useEffect(() => {
    handleGetGemstones();
    // eslint-disable-next-line
  }, []);

  const handleGetGemstones = async () => {
    getPersonsService("");
    actionData(getMethod, diamondAvgSizesRoute).then((result) => {
      let sizes: any = [];
      let sizesByPcs: any = [];
      result.data.forEach((item: any) => {
        if (item.unit === "pcs_per_cts") {
          sizesByPcs.push(item);
        } else {
          sizes.push(item);
        }
      });
      setDiamondsSizesByPcs(sizesByPcs);
      setDiamondsSizes(sizes);
    });
    actionData(getMethod, gemsWeightUnitRoute).then((result) => {
      setWeigthUnits(result.data);
    });
  };

  const getPersonsService = async (keyword?: string) => {
    actionData(getMethod, getPersons(0, keyword)).then((result) => {
      let temp: any = [];

      result.data.forEach((data: any) => {
        temp.push({
          value: data.id,
          label: `${data.fullName} (${data.phone})`,
          fullName: data.fullName,
          phone: data.phone,
          phoneOther: data.phoneOther,
          email: data.email,
          address: data.address,
        });
      });

      setPersons(temp);
    });
  };

  const handleInputChange = (newValue: string) => {
    getPersonsService(newValue);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    if (evt.target.id === "diamondsStockType") {
      if (evt.target.value === "Lot") {
        setFormData({
          ...formData,
          diamondsStockType: evt.target.value,
          diamondsQuantity: null,
          diamondsSize: null,
          diamondsWeight: "",
          diamondsPcsOneWeight: "",
          diamondsPcsTwoWeight: "",
        });
      } else if (evt.target.value === "Single") {
        setFormData({
          ...formData,
          diamondsStockType: evt.target.value,
          diamondsQuantity: 1,
          diamondsSize: null,
          diamondsWeight: "",
          diamondsPcsOneWeight: "",
          diamondsPcsTwoWeight: "",
        });
      } else {
        setFormData({
          ...formData,
          diamondsStockType: evt.target.value,
          diamondsQuantity: null,
          diamondsSize: null,
          diamondsWeight: "",
          diamondsPcsOneWeight: "",
          diamondsPcsTwoWeight: "",
        });
      }
    } else {
      inputChangeService(evt, formData, setFormData, wantString);
    }
  };

  const showForm = (item?: any) => {
    setFormData({
      diamondsId: item?.id || "",
      diamondsBoughtFrom: item?.diamonds_bought_from?.id || null,
      diamondsBoughtFromDefaultValue:
        item?.diamonds_bought_from?.fullName || "",
      diamondsCarrier: item?.diamonds_carrier?.id || null,
      diamondsCarrierDefaultValue: item?.diamonds_carrier?.fullName || "",
      diamondsStockType: item?.diamondsStockType || "Lot",
      diamondsQuantity: item?.diamondsQuantity || null,
      diamondsWeight: item?.diamondsWeight || "",
      diamondsPcsOneWeight: item?.diamondsPcsOneWeight || "",
      diamondsPcsTwoWeight: item?.diamondsPcsTwoWeight || "",
      diamondsWeightUnit: item?.diamondsWeightUnit || "carat",
      diamondsSize: item?.diamondsSize || null,
      diamondsSizeUnit: item?.diamondsSizeUnit || "pcs_per_cts",
      diamondsPricePerCts: item?.diamondsPricePerCts || "",
      totalDiamondsCost: item?.totalDiamondsCost || "",
      basedFormula: item?.basedFormula || "rate",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    let postRoute = `${jewelleryRoute(product.id)}/diamonds`;
    let putRoute = `jewellery/diamonds/${formData.diamondsId}`;

    setLoading(true);
    dataService(
      method,
      formData,
      [],
      method === postMethod ? postRoute : putRoute
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      "this diamond",
      null,
      `jewellery/diamonds/${item?.id}`,
      handleResponse
    );
  };

  return (
    <Col md={12}>
      <section className="bg-card mb-4">
        <section className="flex-between mb-3">
          <h6 className="fw-bold">Diamonds</h6>
          <Button className="text-white bg-grad" onClick={showForm}>
            <IconAdd /> <span className="ms-2">{translate("createNew")}</span>
          </Button>
        </section>

        {product && product?.diamonds && product?.diamonds.length > 0 ? (
          <>
            {productFilterItems.length > 0 &&
              productFilterItems.map((filterItem: any, index: number) => (
                <div key={index}>
                  <h6 className="text-muted mb-3 mt-4">
                    {filterItem.diamondsStockType}
                  </h6>
                  {filterItem.diamondsStockType === "Lot" ? (
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Bought from</th>
                          <th>Carrier</th>
                          <th className="text-warning">Avg size</th>
                          <th>Diamonds size</th>
                          <th>No. of pcs</th>
                          <th>Weight</th>
                          <th>Unit</th>
                          <th>Price per cts</th>
                          <th>Total cost</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filterItem?.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>
                              {item.diamonds_bought_from?.fullName || "-"}
                            </td>
                            <td>{item.diamonds_carrier?.fullName || "-"}</td>
                            <td>
                              <span className="flex-center text-warning">
                                {item.diamondsAvgSize || "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsSize || "-"}{" "}
                                {item.diamondsSize
                                  ? item.diamondsSizeUnit === "avg_size"
                                    ? "ct."
                                    : "pcs/ct."
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsQuantity || "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsWeight
                                  ? `${item.diamondsWeight}`
                                  : "-"}
                              </span>
                            </td>
                            <td>{item.diamondsWeightUnit}</td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsPricePerCts
                                  ? `${numberFormat(item.diamondsPricePerCts)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.totalDiamondsCost
                                  ? ` ${numberFormat(item.totalDiamondsCost)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <TableRowButtons
                                item={item}
                                showForm={showForm}
                                handleDelete={handleDelete}
                                flex="flex-center"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}

                  {filterItem.diamondsStockType === "Single" ? (
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Bought from</th>
                          <th>Carrier</th>
                          <th>Weight</th>
                          <th>Unit</th>
                          <th>Price per cts</th>
                          <th>Total cost</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filterItem?.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>
                              {item.diamonds_bought_from?.fullName || "-"}
                            </td>
                            <td>{item.diamonds_carrier?.fullName || "-"}</td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsWeight
                                  ? `${item.diamondsWeight}`
                                  : "-"}
                              </span>
                            </td>
                            <td>{item.diamondsWeightUnit}</td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsPricePerCts
                                  ? `${numberFormat(item.diamondsPricePerCts)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.totalDiamondsCost
                                  ? ` ${numberFormat(item.totalDiamondsCost)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <TableRowButtons
                                item={item}
                                showForm={showForm}
                                handleDelete={handleDelete}
                                flex="flex-center"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}

                  {filterItem.diamondsStockType === "Pair" ? (
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Bought from</th>
                          <th>Carrier</th>
                          <th>Pcs 1 weight</th>
                          <th>Pcs 2 weight</th>
                          <th>Unit</th>
                          <th>Price per cts</th>
                          <th>Total cost</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filterItem?.data.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>
                              {item.diamonds_bought_from?.fullName || "-"}
                            </td>
                            <td>{item.diamonds_carrier?.fullName || "-"}</td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsPcsOneWeight
                                  ? `${item.diamondsPcsOneWeight}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsPcsTwoWeight
                                  ? `${item.diamondsPcsTwoWeight}`
                                  : "-"}
                              </span>
                            </td>
                            <td>{item.diamondsWeightUnit}</td>
                            <td>
                              <span className="flex-end">
                                {item.diamondsPricePerCts
                                  ? `${numberFormat(item.diamondsPricePerCts)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span className="flex-end">
                                {item.totalDiamondsCost
                                  ? ` ${numberFormat(item.totalDiamondsCost)}`
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <TableRowButtons
                                item={item}
                                showForm={showForm}
                                handleDelete={handleDelete}
                                flex="flex-center"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
          </>
        ) : (
          <NoData noImage />
        )}
      </section>

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.diamondsId}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          formData={formData}
          setFormData={setFormData}
        />
        {/* parseFloat(((inStock / total) * 100).toFixed(2).replace(/\.?0+$/, '')) */}
        {formData.diamondsStockType === "Lot" && (
          <Row>
            <Col>
              <Form.Group className="input-form-control mb-4">
                <section className="flex-between">
                  <Form.Label>
                    {formData.diamondsSizeUnit === "avg_size"
                      ? "Diamonds size"
                      : "Pcs per cts"}
                  </Form.Label>
                  <FormCheck
                    id="switch"
                    className="switch"
                    type="switch"
                    label={"Diamond size"}
                    checked={formData.diamondsSizeUnit === "avg_size"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        diamondsSizeUnit:
                          formData.diamondsSizeUnit === "avg_size"
                            ? "pcs_per_cts"
                            : "avg_size",
                      })
                    }
                  />
                </section>
                <Form.Control
                  as="select"
                  aria-label="Select"
                  id={"diamondsSize"}
                  onChange={(e: any) => {
                    if (formData.diamondsSizeUnit === "avg_size") {
                      let resultDiamondsWeight =
                        e.target.value && formData.diamondsWeight
                          ? parseFloat(
                              (e.target.value * formData.diamondsQuantity)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                          : formData.diamondsWeight;
                      setFormData({
                        ...formData,
                        diamondsSize:
                          e.target.value && parseFloat(e.target.value),
                        diamondsWeight: resultDiamondsWeight,
                        totalDiamondsCost:
                          formData.basedFormula === "rate"
                            ? Math.floor(
                                (resultDiamondsWeight
                                  ? parseFloat(resultDiamondsWeight)
                                  : 0) *
                                  (formData.diamondsPricePerCts
                                    ? parseInt(formData.diamondsPricePerCts)
                                    : 0)
                              )
                            : formData.totalDiamondsCost,
                        diamondsPricePerCts:
                          formData.basedFormula === "total"
                            ? parseInt(
                                resultDiamondsWeight === 0
                                  ? formData.diamondsPricePerCts
                                  : (formData.totalDiamondsCost || 0) /
                                      (resultDiamondsWeight
                                        ? parseFloat(resultDiamondsWeight)
                                        : 0)
                              )
                            : formData.diamondsPricePerCts,
                      });
                    } else {
                      let resultDiamondsWeight =
                        e.target.value && formData.diamondsWeight
                          ? parseFloat(
                              ((1 / e.target.value) * formData.diamondsQuantity)
                                .toFixed(3)
                                .replace(/\.?0+$/, "")
                            )
                          : formData.diamondsWeight;
                      setFormData({
                        ...formData,
                        diamondsSize:
                          e.target.value && parseFloat(e.target.value),
                        diamondsWeight: resultDiamondsWeight,
                        totalDiamondsCost:
                          formData.basedFormula === "rate"
                            ? Math.floor(
                                (resultDiamondsWeight
                                  ? parseFloat(resultDiamondsWeight)
                                  : 0) *
                                  (formData.diamondsPricePerCts
                                    ? parseInt(formData.diamondsPricePerCts)
                                    : 0)
                              )
                            : formData.totalDiamondsCost,
                        diamondsPricePerCts:
                          formData.basedFormula === "total"
                            ? parseInt(
                                resultDiamondsWeight === 0
                                  ? formData.diamondsPricePerCts
                                  : (formData.totalDiamondsCost || 0) /
                                      (resultDiamondsWeight
                                        ? parseFloat(resultDiamondsWeight)
                                        : 0)
                              )
                            : formData.diamondsPricePerCts,
                      });
                    }
                  }}
                  value={formData.diamondsSize}
                >
                  <option value="">Select</option>
                  {formData.diamondsSizeUnit === "avg_size" ? (
                    <>
                      {diamondsSizes.map((item: any, index: number) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      {diamondsSizesByPcs.map((item: any, index: number) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <CustomInput
                id={"diamondsQuantity"}
                label={"Number of pcs"}
                value={formData.diamondsQuantity}
                handleChange={(e: any) => {
                  if (formData.diamondsSizeUnit === "avg_size") {
                    let resultDiamondsWeight =
                      e.target.value && formData.diamondsSize
                        ? parseFloat(
                            (formData.diamondsSize * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                        : formData.diamondsWeight;
                    setFormData({
                      ...formData,
                      diamondsQuantity:
                        e.target.value && parseInt(e.target.value),
                      diamondsWeight: resultDiamondsWeight,
                      totalDiamondsCost:
                        formData.basedFormula === "rate"
                          ? Math.floor(
                              (resultDiamondsWeight
                                ? parseFloat(resultDiamondsWeight)
                                : 0) *
                                (formData.diamondsPricePerCts
                                  ? parseInt(formData.diamondsPricePerCts)
                                  : 0)
                            )
                          : formData.totalDiamondsCost,
                      diamondsPricePerCts:
                        formData.basedFormula === "total"
                          ? parseInt(
                              resultDiamondsWeight === 0
                                ? formData.diamondsPricePerCts
                                : (formData.totalDiamondsCost || 0) /
                                    (resultDiamondsWeight
                                      ? parseFloat(resultDiamondsWeight)
                                      : 0)
                            )
                          : formData.diamondsPricePerCts,
                    });
                  } else {
                    let resultDiamondsWeight =
                      e.target.value && formData.diamondsSize
                        ? parseFloat(
                            ((1 / formData.diamondsSize) * e.target.value)
                              .toFixed(3)
                              .replace(/\.?0+$/, "")
                          )
                        : formData.diamondsWeight;
                    setFormData({
                      ...formData,
                      diamondsQuantity:
                        e.target.value && parseInt(e.target.value),
                      diamondsWeight: resultDiamondsWeight,
                      totalDiamondsCost:
                        formData.basedFormula === "rate"
                          ? Math.floor(
                              (resultDiamondsWeight
                                ? parseFloat(resultDiamondsWeight)
                                : 0) *
                                (formData.diamondsPricePerCts
                                  ? parseInt(formData.diamondsPricePerCts)
                                  : 0)
                            )
                          : formData.totalDiamondsCost,
                      diamondsPricePerCts:
                        formData.basedFormula === "total"
                          ? parseInt(
                              resultDiamondsWeight === 0
                                ? formData.diamondsPricePerCts
                                : (formData.totalDiamondsCost || 0) /
                                    (resultDiamondsWeight
                                      ? parseFloat(resultDiamondsWeight)
                                      : 0)
                            )
                          : formData.diamondsPricePerCts,
                    });
                  }
                }}
                inputType={"number"}
              />
            </Col>
          </Row>
        )}

        {formData.diamondsStockType !== "Pair" && (
          <CustomSelectInputGroup
            label={"Diamonds weight *"}
            id={"diamondsWeight"}
            secondId={"diamondsWeightUnit"}
            value={formData.diamondsWeight}
            secondValue={formData.diamondsWeightUnit}
            handleChange={(e: any) =>
              setFormData({
                ...formData,
                diamondsWeight: e.target.value,
                totalDiamondsCost:
                  formData.basedFormula === "rate"
                    ? Math.floor(
                        (e.target.value ? parseFloat(e.target.value) : 0) *
                          (formData.diamondsPricePerCts
                            ? parseInt(formData.diamondsPricePerCts)
                            : 0)
                      )
                    : formData.totalDiamondsCost,
                diamondsPricePerCts:
                  formData.basedFormula === "total"
                    ? parseInt(
                        e.target.value === 0
                          ? formData.diamondsPricePerCts
                          : (formData.totalDiamondsCost || 0) /
                              (e.target.value ? parseFloat(e.target.value) : 0)
                      )
                    : formData.diamondsPricePerCts,
              })
            }
            // handleChange={(e: any) => setFormData({ ...formData, gemsWeight: e.target.value, gemsPricePerCts: parseInt(e.target.value === 0 ? formData.gemsPricePerCts : (formData.totalGemsCost || 0) / (e.target.value ? parseFloat(e.target.value) : 0)), totalGemsCost: (e.target.value ? parseFloat(e.target.value) : 0) * (formData.gemsPricePerCts || 0) })}
            secondHandleChange={handleChange}
            inputType={"select-group"}
            wantString={true}
            data={weightUnits}
          />
        )}

        {formData.diamondsStockType === "Pair" && (
          <Row>
            <Col>
              <CustomSelectInputGroup
                label={"Pcs 1 weight *"}
                id={"diamondsPcsOneWeight"}
                secondId={"diamondsWeightUnit"}
                value={formData.diamondsPcsOneWeight}
                secondValue={formData.diamondsWeightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    diamondsPcsOneWeight: e.target.value,
                    totalDiamondsCost:
                      formData.basedFormula === "rate"
                        ? Math.floor(
                            ((e.target.value ? parseFloat(e.target.value) : 0) +
                              (formData.diamondsPcsTwoWeight
                                ? parseFloat(formData.diamondsPcsTwoWeight)
                                : 0)) *
                              (formData.diamondsPricePerCts
                                ? parseInt(formData.diamondsPricePerCts)
                                : 0)
                          )
                        : formData.totalDiamondsCost,
                    diamondsPricePerCts:
                      formData.basedFormula === "total"
                        ? parseInt(
                            e.target.value === 0
                              ? formData.diamondsPricePerCts
                              : (formData.totalDiamondsCost || 0) /
                                  (e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0)
                          ) +
                          (formData.diamondsPcsTwoWeight
                            ? parseFloat(formData.diamondsPcsTwoWeight)
                            : 0)
                        : formData.diamondsPricePerCts,
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
            <Col>
              <CustomSelectInputGroup
                label={"Pcs 2 weight *"}
                id={"diamondsPcsTwoWeight"}
                secondId={"diamondsWeightUnit"}
                value={formData.diamondsPcsTwoWeight}
                secondValue={formData.diamondsWeightUnit}
                handleChange={(e: any) =>
                  setFormData({
                    ...formData,
                    diamondsPcsTwoWeight: e.target.value,
                    totalDiamondsCost:
                      formData.basedFormula === "rate"
                        ? Math.floor(
                            ((e.target.value ? parseFloat(e.target.value) : 0) +
                              (formData.diamondsPcsOneWeight
                                ? parseFloat(formData.diamondsPcsOneWeight)
                                : 0)) *
                              (formData.diamondsPricePerCts
                                ? parseInt(formData.diamondsPricePerCts)
                                : 0)
                          )
                        : formData.totalDiamondsCost,
                    diamondsPricePerCts:
                      formData.basedFormula === "total"
                        ? parseInt(
                            e.target.value === 0
                              ? formData.diamondsPricePerCts
                              : (formData.totalDiamondsCost || 0) /
                                  (e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0)
                          ) +
                          (formData.diamondsPcsOneWeight
                            ? parseFloat(formData.diamondsPcsOneWeight)
                            : 0)
                        : formData.diamondsPricePerCts,
                  })
                }
                secondHandleChange={handleChange}
                inputType={"select-group"}
                wantString={true}
                data={weightUnits}
              />
            </Col>
          </Row>
        )}

        <Row className="align-items-center">
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"diamondsPricePerCts"}
                label={"Diamonds price per cts"}
                value={formData.diamondsPricePerCts}
                handleChange={(e: any) => {
                  if (formData.diamondsStockType === "Pair") {
                    setFormData({
                      ...formData,
                      diamondsPricePerCts: e.target.value,
                      totalDiamondsCost:
                        Math.floor(
                          (formData.diamondsPcsOneWeight
                            ? parseFloat(formData.diamondsPcsOneWeight)
                            : 0) +
                            (formData.diamondsPcsTwoWeight
                              ? parseFloat(formData.diamondsPcsTwoWeight)
                              : 0)
                        ) * (e.target.value || 0),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      diamondsPricePerCts: e.target.value,
                      totalDiamondsCost: Math.floor(
                        (formData.diamondsWeight || 0) * (e.target.value || 0)
                      ),
                    });
                  }
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"totalDiamondsCost"}
                label={"Total diamonds cost"}
                value={formData.totalGemsCost}
                handleChange={(e: any) => {
                  if (formData.diamondsStockType === "Pair") {
                    setFormData({
                      ...formData,
                      totalDiamondsCost: e.target.value,
                      diamondsPricePerCts: parseInt(
                        e.target.value === 0
                          ? formData.diamondsPricePerCts
                          : (e.target.value || 0) /
                              ((formData.diamondsPcsOneWeight
                                ? parseFloat(formData.diamondsPcsOneWeight)
                                : 0) +
                                (formData.diamondsPcsTwoWeight
                                  ? parseFloat(formData.diamondsPcsTwoWeight)
                                  : 0))
                      ),
                    });
                  } else {
                    setFormData({
                      ...formData,
                      totalDiamondsCost: e.target.value,
                      diamondsPricePerCts: parseInt(
                        e.target.value === 0
                          ? formData.diamondsPricePerCts
                          : (e.target.value || 0) /
                              (formData.diamondsWeight || 0)
                      ),
                    });
                  }
                }}
                inputType={"number"}
              />
            )}
          </Col>
          <Col>
            <span className="flex-center">
              <Button
                className="bg-grad rounded-circle flex-center shadow-sm align-items-center"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    basedFormula:
                      formData.basedFormula === "rate" ? "total" : "rate",
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </span>
          </Col>
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"totalDiamondsCost"}
                disable
                label={"Total diamonds cost"}
                value={formData.totalDiamondsCost}
                handleChange={null}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"diamondsPricePerCts"}
                label={"Diamonds price per cts"}
                value={formData.diamondsPricePerCts}
                disable
                handleChange={null}
                inputType={"number"}
              />
            )}
          </Col>
        </Row>
      </CustomModal>
    </Col>
  );
};

export default Diamonds;
