import { Layout, TableLayout } from "./layouts/Layout";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Image,
  Modal,
  Table,
  useAccordionButton,
} from "react-bootstrap";
import Navbar from "./layouts/Navbar";
import Footer from "./layouts/Footer";
import { IconDelete, IconEdit, IconLeftArrow } from "./widgets/Icons";
import { useContext, useEffect } from "react";
import { postMethod, putMethod } from "../services/api";
import { LangContext } from "../context/Lang";
import { ImageLoading, LoadingButtonLabel } from "./widgets/Loading";
import {
  CustomInput,
  CustomSelectInput,
  DateInput,
  CustomSelectInputGroup,
  CustomeSuggestionSelect,
  SearchInput,
} from "./widgets/CustomInput";
import { PersonSelect, PersonModel } from "./person";
import FormFieldService from "./services/FormFieldService";
import QRCode from "qrcode";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;
var cn = require("classnames");

export {
  Layout,
  TableLayout,
  ImageLoading,
  LoadingButtonLabel,
  FormFieldService,
  CustomInput,
  CustomSelectInput,
  DateInput,
  CustomSelectInputGroup,
  CustomeSuggestionSelect,
  SearchInput,
  PersonSelect,
  PersonModel,
};

export const NoData = ({
  title = "data",
  noImage = false,
}: {
  title?: string;
  noImage?: boolean;
}) => {
  return (
    <section className="d-flex align-items-center flex-column my-4">
      {!noImage && (
        <Image
          src={"images/no_data.png"}
          width={200}
          className="rounded mb-4"
        />
      )}
      <p className="fs-6 mb-0 text-success">No {title} found!</p>
    </section>
  );
};

export const PublicLayout = ({ children }: { children?: any }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export const GoBack = ({ label }: { label: string }) => {
  const history = useHistory();
  return (
    <section className="cursor" onClick={() => history.goBack()}>
      <p className="mb-0">
        <IconLeftArrow /> {label}
      </p>
    </section>
  );
};

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);
  return null;
}

export const CustomModalService = ({
  show,
  children,
  handleSubmit,
  handleClose,
  id,
  loading,
  lg = false,
  setShow,
  fields,
  handleChange,
  handleInputChange,
  formData,
  setFormData,
  customTitle = "",
  xl = false,
}: {
  show: boolean;
  handleSubmit: any;
  children?: any,
  handleClose: any;
  id: any;
  loading: boolean;
  lg?: boolean;
  setShow: any;
  fields: any;
  handleChange: any;
  handleInputChange?: any;
  formData?: any;
  setFormData?: any;
  customTitle?: string;
  xl?: boolean;
}) => {
  return (
    <CustomModal
      show={show}
      handleClose={() => handleClose(setShow)}
      customTitle={customTitle}
      id={id}
      handleSubmit={handleSubmit}
      loading={loading}
      lg={lg}
      xl={xl}
    >
      <FormFieldService
        fields={fields}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
      />
      {children}
    </CustomModal>
  );
};

export const CustomModal = ({
  show,
  handleSubmit,
  handleClose,
  id,
  loading,
  lg,
  children,
  customTitle = "",
  xl,
}: {
  show: boolean;
  handleSubmit: any;
  handleClose: any;
  id: any;
  loading: boolean;
  lg?: boolean;
  children: any;
  customTitle?: string;
  xl?: boolean;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size={lg ? "lg" : xl ? "xl" : undefined}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {customTitle
            ? customTitle
            : id
              ? translate("editInfo")
              : translate("createNew")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button className="bg-success" onClick={handleClose}>
          Cancel
        </Button>
        {/* onClick={id ? handleSubmit(putMethod) : handleSubmit(postMethod)} */}
        <Button
          className="bg-grad"
          onClick={() => handleSubmit(id ? putMethod : postMethod)}
          disabled={loading}
        >
          <LoadingButtonLabel label="Save" disabled={loading} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const TableRowButtons = ({
  item,
  showForm,
  handleDelete,
  flex = "flex-end",
  disabled,
}: {
  item: any;
  showForm: any;
  handleDelete: any;
  flex?: string;
  disabled?: boolean;
}) => (
  <span className={flex}>
    <Button
      className="btn-sm bg-grad ms-2"
      disabled={disabled}
      onClick={() => showForm(item)}
    >
      <IconEdit />
    </Button>
    <Button
      className="bg-danger btn-sm ms-2"
      disabled={disabled}
      onClick={() => handleDelete(item)}
    >
      <IconDelete />
    </Button>
  </span>
);

export const Lang = ({ label }: { label: string }) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  return <>{translate(label)}</>;
};

export const InfoTable = ({ data, notes }: { data: any; notes?: string }) => (
  <Col>
    <Table responsive striped className="mb-0">
      <tbody>
        {data.map(
          (item: any, index: number) =>
            !item.hide && (
              <tr key={index}>
                <td className="text-muted" style={{ width: "40%" }}>
                  {item?.label}
                </td>
                <td style={{ width: "60%" }}>{item?.value}</td>
              </tr>
            )
        )}
      </tbody>
    </Table>

    {notes && (
      <div className="p-2 rounded-3 bg-light mt-4">
        <small className="fw-bold text-muted">Remarks</small>
        <br />
        <small className="wrap-text">{notes}</small>
      </div>
    )}
  </Col>
);

export const InfoCard = ({
  id,
  label,
  value,
  icon,
  color,
  state,
  cardAction,
  h6 = false,
  mb = false,
  hover = false,
}: {
  id: string;
  label: string;
  value: number;
  icon: any;
  color: string;
  state?: string;
  cardAction?: any;
  h6?: boolean;
  mb?: boolean;
  hover?: boolean;
}) => (
  <section
    className={cn(
      "info-count",
      state === id && "count-active",
      mb && "mb-4",
      hover && "info-count-hover"
    )}
    onClick={cardAction}
  >
    <div
      className={cn(
        "avatar-lg rounded-circle border",
        `bg-soft-${color}`,
        `border-${color}`
      )}
    >
      {icon}
    </div>

    <div className="text-end">
      <h5 className={cn("fw-bold mb-1", h6 && "h6")}>
        <span>{value || "-"}</span>
      </h5>
      <small className="text-muted">{label}</small>
    </div>
  </section>
);

export const ThumbsnailImage = ({ item }: { item: any }) => {
  let tempImages = item?.images;
  let isExist = false;
  let tempDiv = <Image src={"images/no-image.png"} />;

  if (tempImages && tempImages.length > 0) {
    tempImages.forEach((img: any) => {
      if (img.thumbnail) {
        isExist = true;

        tempDiv = <Image src={`${RES_API_URL}${img.url}`} alt="no_found" />;
      }
    });

    if (!isExist) {
      tempDiv = (
        <Image src={`${RES_API_URL}${tempImages[0].url}`} alt="no_found" />
      );
    }
  }

  return tempDiv;
};

export const Thumbsnail = ({ images }: { images: any }) => {
  let isExist = false;
  let tempDiv = <Image src={"images/no-image.png"} />;

  if (images && images.length > 0) {
    images.forEach((img: any) => {
      if (img.thumbnail) {
        isExist = true;

        tempDiv = <Image src={`${RES_API_URL}${img.url}`} alt="no_found" />;
      }
    });

    if (!isExist) {
      tempDiv = <Image src={`${RES_API_URL}${images[0].url}`} alt="no_found" />;
    }
  }

  return tempDiv;
};

export const CustomToggle = ({
  children,
  eventKey,
}: {
  children: any;
  eventKey: any;
}) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => { });

  return <div onClick={decoratedOnClick}>{children}</div>;
};

export const Checkbox = ({
  id,
  type,
  name,
  handleClick,
  isChecked,
}: {
  id: any;
  type: any;
  name: string;
  handleClick: any;
  isChecked: any;
}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
      className="form-check-input cursor"
    />
  );
};

export const QRView = ({ text, width }: { text: string; width: number }) => {
  let tempDiv = <></>;
  QRCode.toDataURL(text, function (err, url) {
    // if (err) console.log('error: ' + err)
    tempDiv = <Image src={url} alt="no_found" width={width} height={width} />;
  });
  return tempDiv;
};
