import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {
  FormFieldService,
  GoBack,
  Lang,
  Layout,
  PersonSelect,
} from "../../../components";
import { JewellerySelect } from "../../../components/jewellery";
import {
  getStoreUser,
  handleDateFormat,
  inputChangeService,
} from "../../../services";
import {
  consignmentJewellery,
  dataService,
  postMethod,
  putMethod,
  responseForRoutes,
} from "../../../services/api";
import { toast } from "react-toastify";

const Form = () => {
  const history = useHistory();
  const location: any = useLocation();
  const data = location.state?.data;
  const user = getStoreUser();
  const [person, setPerson] = useState<any>(data?.jewellery_consignee);
  const [jewelleryId, setJewelleryId] = useState(data?.jewelleryId);
  const [disabled, setDisabled] = useState(false);

  const [formData, setFormData] = useState({
    askingPrice: data?.askingPrice,
    brokerFee: data?.brokerFee,
    dealingPrice: data?.dealingPrice,
    returnAt: data?.returnAt,
    dealAt: data?.dealAt,
    notes: data?.consignmentNotes,
  });

  const fields = [
    {
      id: "askingPrice",
      label: "Asking price *",
      value: formData?.askingPrice,
    },
    {
      id: "dealingPrice",
      label: "Dealing Price",
      value: formData?.dealingPrice,
    },
    { id: "brokerFee", label: "Broker fee", value: formData?.brokerFee },
    {
      id: "dealAt",
      label: "Deal date",
      value: formData?.dealAt
        ? handleDateFormat(formData?.dealAt, "YYYY-MM-DD")
        : null,
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: formData?.notes,
      inputType: "textarea",
      disableMargin: true,
    },
  ];

  const required = ["askingPrice"];

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleSubmit = () => {
    if (jewelleryId && (person?.value || person?.id) && formData?.askingPrice) {
      setDisabled(true);
      const _formData = {
        jewelleryId: Number(jewelleryId),
        consignerId: user?.userId,
        consigneeId: Number(person?.value || person?.id),
        askingPrice: Number(formData?.askingPrice),
        brokerFee: Number(formData?.brokerFee),
        dealingPrice: Number(formData?.dealingPrice),
        consignmentStatus: "Pending",
        consignmentNotes: formData?.notes,
        createdAt: new Date().toISOString(),
        returnAt: null,
        dealAt: formData?.dealAt
          ? new Date(formData?.dealAt).toISOString()
          : null,
      };

      dataService(
        data ? putMethod : postMethod,
        _formData,
        required,
        data ? `${consignmentJewellery}/${data.id}` : consignmentJewellery,
        true
      ).then((result) => {
        responseForRoutes(result, history, "jewellery-consignments");
      });

      setTimeout(() => {
        setDisabled(false);
      }, 300);
    } else {
      toast.error("Please provide required data.");
    }
  };

  return (
    <Layout
      title={
        <span>
          {`${data ? "Edit" : "Add"} consignment`} (<Lang label="jewellery" />)
        </span>
      }
    >
      <div className="flex-between mb-4">
        <GoBack label="Jewellery consignments" />
        <Button
          className="text-white bg-grad px-4"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>

      <Row>
        <Col md={8}>
          <JewellerySelect
            jewelleryId={jewelleryId}
            setJewelleryId={setJewelleryId}
            inventoryType="shop_inventory"
          />

          <div className="mt-4">
            <PersonSelect
              person={person}
              setPerson={setPerson}
              defaultValue={person?.id || person?.value}
            />
          </div>
        </Col>

        <Col md={4}>
          <FormFieldService
            fields={fields}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            colCount={12}
            bg
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Form;
