import { useContext, useState } from "react";
import { Col, OverlayTrigger, Tooltip, Row, Form } from "react-bootstrap";
import { ThumbsnailImage } from "../../../../components";
import { IconEdit } from "../../../../components/widgets/Icons";
import { CartContext } from "../../../../context/Cart";
import { numberFormat } from "../../../../services";
import ItemPriceModal from "./ItemPriceModal";

const CartItem = ({
  hideGift = false,
  hideEditPrice = false,
  hideRemoveButton = false,
}: {
  hideGift?: boolean;
  hideEditPrice?: boolean;
  hideRemoveButton?: boolean;
}) => {
  const [cart, setCart] = useContext(CartContext);
  const [item, setItem] = useState<any>();
  const [show, setShow] = useState(false);

  const handleToggleModal = (value?: any) => {
    setShow(!show);
    setItem(value || null);
  };

  const discountAmont = (item: any) => {
    let total: any = item?.sellingPrice;
    let amount: any = item?.discountValue;

    if (item?.discountType === "by_percentage" && amount) {
      let discountPercent = parseFloat(amount);
      amount = (parseInt(total) / 100) * discountPercent;
    }

    // tempItem.discountValue = amount;
    return parseInt(amount) || 0;
  };

  const handleGiftItem = (status: boolean, index: number) => {
    const temp: any = [...cart?.items];
    temp[index].isGift = status;
    setCart({ ...cart, items: temp });
  };

  const removeItem = (index: any) => {
    const temp: any = [...cart?.items];
    temp.splice(index, 1);
    setCart({ ...cart, items: temp });
  };

  return (
    <div className="mb-n3">
      {cart?.items?.map((item: any, index: number) => (
        <div key={index} className="bg-light p-4 rounded mb-3">
          <Row>
            <Col md={4}>
              <div className="cart-item-image">
                <ThumbsnailImage item={item} />
              </div>
            </Col>
            <Col>
              <label className="d-block fw-bold mb-3">{item?.itemName}</label>
              <small className="xs-label text-muted d-block mb-2">
                {item?.sku}
              </small>

              <span className="d-block text-muted xs-label">
                {item?.stoneType}{" "}
                {item?.stockType ? `(${item?.stockType})` : ""}
              </span>

              {item?.cali_gemstones_stage?.length > 0 && (
                <span className="d-block text-muted xs-label">
                  {item?.cali_gemstones_stage?.[
                    item?.cali_gemstones_stage?.length - 1
                  ]?.dimension &&
                    `${
                      item?.cali_gemstones_stage?.[
                        item?.cali_gemstones_stage?.length - 1
                      ]?.dimension
                    }, `}

                  {item?.cali_gemstones_stage?.[
                    item?.cali_gemstones_stage?.length - 1
                  ]?.numberOfPcs &&
                    `${
                      item?.cali_gemstones_stage?.[
                        item?.cali_gemstones_stage?.length - 1
                      ]?.numberOfPcs
                    } Pcs, `}

                  {item?.cali_gemstones_stage?.[
                    item?.cali_gemstones_stage?.length - 1
                  ]?.weight &&
                    `${
                      item?.cali_gemstones_stage?.[
                        item?.cali_gemstones_stage?.length - 1
                      ]?.weight
                    } ${
                      item?.cali_gemstones_stage?.[
                        item?.cali_gemstones_stage?.length - 1
                      ]?.weightUnit
                    }`}
                </span>
              )}

              {item?.cali_gemstones_stage?.[
                item?.cali_gemstones_stage?.length - 1
              ]?.color && (
                <span className="d-block text-muted xs-label">
                  {item?.cali_gemstones_stage?.[
                    item?.cali_gemstones_stage?.length - 1
                  ]?.color &&
                    `${
                      item?.cali_gemstones_stage?.[
                        item?.cali_gemstones_stage?.length - 1
                      ]?.color
                    }, `}

                  {item?.cali_gemstones_stage?.[
                    item?.cali_gemstones_stage?.length - 1
                  ]?.clarity &&
                    `${
                      item?.cali_gemstones_stage?.[
                        item?.cali_gemstones_stage?.length - 1
                      ]?.clarity
                    }`}
                </span>
              )}

              {item?.miningLocation && (
                <span className="text-muted xs-label">
                  Mine: {item?.miningLocation}
                </span>
              )}

              {item?.isGift ? (
                <p className="text-muted mt-3 mb-0">Gift item</p>
              ) : (
                <>
                  <div className="d-flex align-items-center mt-3">
                    <p className="fw-bold mb-0 me-2">
                      <span className="text-muted">Net price : </span>{" "}
                      {item?.currency} {numberFormat(item?.sellingPrice)}
                    </p>

                    {!hideEditPrice && (
                      <OverlayTrigger
                        overlay={<Tooltip>Edit net price</Tooltip>}
                      >
                        <span
                          className="text-muted cursor"
                          onClick={() => handleToggleModal(item)}
                        >
                          <IconEdit />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>

                  {item?.discountValue && item?.discountValue !== "0" && (
                    <p className="fw-bold mb-0 me-2">
                      <span className="text-muted">Discount : </span>
                      <span className="text-danger">
                        - {item?.currency} {numberFormat(discountAmont(item))}
                      </span>
                    </p>
                  )}
                </>
              )}
            </Col>

            <Col xs={12} className="mt-3">
              <div className=" d-flex justify-content-between align-items-center">
                {!hideGift && (
                  <div>
                    <Form.Check
                      type="checkbox"
                      label="Gift item"
                      id={`giftItem-${index + 1}`}
                      className="cursor"
                      checked={item?.isGift}
                      onChange={(e) => handleGiftItem(e?.target.checked, index)}
                    />
                  </div>
                )}

                {!hideRemoveButton && (
                  <div className="text-center cursor">
                    <small
                      className="text-danger"
                      onClick={() => removeItem(index)}
                    >
                      Remove item
                    </small>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ))}

      <ItemPriceModal
        item={item}
        show={show}
        handleToggleModal={handleToggleModal}
      />
    </div>
  );
};

export default CartItem;
