import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { NoData } from "../../../../components";
import {
  GridLoading,
  TableLoading,
} from "../../../../components/widgets/Loading";
import { PgComponent } from "../../../../components/widgets/Pagination";
import { VariableContext } from "../../../../context/Variable";
import { Grid } from "./Grid";
import { List } from "./List";
import { useReactToPrint } from "react-to-print";
import { IconPrint } from "../../../../components/widgets/Icons";
import GemLabelPdf from "../../../../components/pdf/GemLabelPdf";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

const Data = ({
  loading,
  data,
  page,
  setPage,
  handleResponse,
  handleGetData,
}: {
  loading: boolean;
  data: any;
  page: any;
  setPage: any;
  handleResponse: any;
  handleGetData: any;
}) => {
  const [variable] = useContext(VariableContext);
  const [, setIsCheckAll] = useState(false);
  const [isCheckData, setCheckData] = useState<any[]>();
  const [printItems, setPrintItems] = useState<any[]>();
  const printComponentRef = useRef(null);

  const reactToPrintLabelContent = useCallback(() => {
    return printComponentRef.current;
  }, []);

  const PrintLabelAction = useReactToPrint({
    content: reactToPrintLabelContent,
  });

  useEffect(() => {
    setIsCheckAll(false);
    setCheckData([]);
    setPrintItems([]);
    // eslint-disable-next-line
  }, []);


  const handleClick = (evt: any, item: any) => {
    const { id, checked } = evt.target;

    let temp: any[] = [];
    if (checked) {
      isCheckData?.forEach((_item: any) => temp.push(_item));
      temp.push(parseInt(id));
    } else {
      isCheckData?.forEach((_item: any) => {
        if (_item !== parseInt(id)) {
          temp.push(_item);
        }
      });
    }

    setCheckData(temp);

    let tempPrintItems: any[] = [];
    printItems?.forEach((_item) => tempPrintItems.push(_item));

    if (tempPrintItems.length > 0) {
      let isExist = false;

      tempPrintItems.forEach((data, index) => {
        if (data.id === item.id) {
          tempPrintItems.splice(index, 1);
          isExist = true;
        }
      });

      if (!isExist) {
        tempPrintItems.push(item);
      }
    } else {
      tempPrintItems.push(item);
    }

    setPrintItems(tempPrintItems);
  };

  return (
    <div>
      <div style={{ display: "none" }}>
        <GemLabelPdf ref={printComponentRef} items={printItems} />
      </div>

      {printItems && printItems.length > 0 && (
        <div className="float-btns">
          {data.length > 0 && (
            <Button
              onClick={() => {
                PrintLabelAction();
                setIsCheckAll(false);
                setPrintItems([]);
                setCheckData([]);
              }}
              className="btn btn-grad me-2"
            >
              <IconPrint /> <span className="ps-2">Print labels</span>
            </Button>
          )}
        </div>
      )}

      {variable?.isList ? (
        <>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {data?.length > 0 ? (
                <Table responsive bordered className="mb-4" striped>
                  <thead>
                    <tr>
                      <th className="ps-4">#</th>
                      <th>Name</th>
                      <th>SKU</th>
                      {/* <th>Origin</th> */}
                      <th>Item info</th>
                      {/* <th><span className="flex-end">Weight</span></th> */}
                      <th>
                        <span className="flex-end">Price</span>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item: any, index: any) => (
                      <tr key={index}>
                        <List
                          item={item}
                          handleResponse={handleResponse}
                          index={index}
                          handleCheckClick={handleClick}
                          isCheckData={isCheckData}
                          pageCurrent={page.current}
                        />
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <NoData />
              )}
            </>
          )}
        </>
      ) : (
        <Row>
          {loading ? (
            <GridLoading />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item: any, index: number) => (
                    <Col md={6} key={index}>
                      <Grid item={item} handleResponse={handleResponse} />
                    </Col>
                  ))}
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Row>
      )}

      {page && page.total > limit && (
        <PgComponent
          currentPage={page.current + 1 || 0}
          fromCount={page.current || 0}
          total={page.total || 0}
          paginationLimit={limit}
          pageInfo={page}
          setPage={setPage}
          handleGetData={(page: any) => handleGetData(page)}
        />
      )}
    </div>
  );
};

export default Data;
