import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { FormFieldService, GoBack, Layout } from "../../../components";
import {
  dateFormat2,
  getPersonsService,
  getStoreUser,
  handleDateFormat,
  inputChangeService,
} from "../../../services";
import {
  actionData,
  buyingStateRoute,
  dataService,
  gemsRoute,
  getMethod,
  putMethod,
  // responseForRoutes,
  stockTypesRoute,
} from "../../../services/api";
import { inventoryTypes } from "../../../data";
import { toast } from "react-toastify";
import swal from "sweetalert";

const Index = () => {
  const history = useHistory();
  const location: any = useLocation();
  const item = location.state.item;
  const [owners, setOwners] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [buyingStates, setBuyingStates] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [formData, setFormData] = useState({
    itemName: item?.itemName || "",
    // sku: item?.sku || "",
    stoneType: item?.stoneType || "",
    stockType: item?.stockType || "",
    buyingState: item?.buyingState || "",
    miningLocation: item?.miningLocation || "",
    outletName: item?.outletName || "",
    gemsOwnerId: item?.gemstonesOwner?.id || null,
    gemsBrokerId: item?.gemstonesBroker?.id || null,
    inventoryType: item?.inventoryType || "shop_inventory",
    serviceItem: item?.serviceItem,
    published: item?.published,
    purchaseDate: item?.purchaseDate || "",
    notes: item?.notes || "",
    userLoginId: item?.userLoginId || "",
    forSale: item?.forSale || false,
  });

  const required = ["itemName", "stoneType"];
  const fields = [
    { id: "itemName", label: "Item name *", value: formData?.itemName },
    // { id: "sku", label: "SKU *", value: formData.sku },
    {
      id: "stockType",
      label: "Stock type",
      value: formData?.stockType,
      type: "select",
      data: stockTypes,
      wantString: true,
    },
    {
      id: "gemsOwnerId",
      label: "Gems owner",
      value: formData?.gemsOwnerId,
      type: "suggestion-select",
      data: owners,
      defaultLabel: `${item.gemstonesOwner?.fullName} (${item.gemstonesOwner?.phone})`,
    },
    {
      id: "gemsBrokerId",
      label: "Gems broker",
      value: formData?.gemsBrokerId,
      type: "suggestion-select",
      data: brokers,
      defaultLabel: `${item.gemstonesBroker?.fullName} (${item.gemstonesBroker?.phone})`,
    },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "buyingState",
      label: "Buying state",
      value: formData?.buyingState,
      type: "select",
      data: buyingStates,
      wantString: true,
    },
    {
      id: "miningLocation",
      label: "Mining location",
      value: formData?.miningLocation,
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData.outletName,
      type: "select",
      data: outlets,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "purchaseDate",
      label: "Purchase date",
      value: handleDateFormat(formData?.purchaseDate, dateFormat2),
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: formData?.notes,
      inputType: "textarea",
    },
  ];

  useEffect(() => {
    handleGetData();
    document.querySelector("#stockType")?.setAttribute("disabled", "true");
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    getPersonsService("", setOwners, setBrokers);

    actionData(getMethod, buyingStateRoute).then((result) => {
      setBuyingStates(result.data);
    });

    actionData(getMethod, stockTypesRoute).then((result) => {
      setStockTypes(result.data);
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result.data.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
      }
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleInputChange = (newValue: string) => {
    getPersonsService(newValue, setOwners, null);
    // getPersonsService(newValue, setOwners, setBrokers);
  };

  const handleSubmit = () => {
    formData.forSale =
      formData?.inventoryType === "private_item" ? formData?.forSale : false;
    formData.userLoginId = Number(getStoreUser()?.userId);
    formData.purchaseDate =
      formData.purchaseDate && new Date(formData.purchaseDate).toISOString();

    setDisabled(true);
    dataService(
      putMethod,
      formData,
      required,
      `${gemsRoute()}/${item.id}`
    ).then((result) => {
      // responseForRoutes(result, history, "gems");
      if (result?.success) {
        swal(result.message, {
          icon: "success",
          buttons: [false],
          timer: 1500,
        });
        history.goBack();
      } else {
        toast.error(result?.message);
        setDisabled(false);
      }
    });
  };

  return (
    <Layout title="Edit gems">
      <div className="flex-between mb-4">
        <GoBack label="Gems" />
        <div className="d-flex align-items-center">
          {/* <Form.Group>
            <Form.Check
              type="checkbox"
              label="Service item"
              id="serviceItem"
              checked={formData?.serviceItem}
              onChange={handleChange}
            />
          </Form.Group> */}
          {/* <Form.Group className="ms-4 border-none">
            <Form.Check
              type="checkbox"
              label="Publish gems"
              id="published"
              checked={formData?.published}
              onChange={handleChange}
            />
          </Form.Group> */}
          <div className=" d-flex align-items-center">
            {formData?.inventoryType === "private_item" && (
              <Form.Group className="border-none me-3">
                <Form.Check
                  type="checkbox"
                  label="For sale"
                  id="forSale"
                  checked={formData?.forSale}
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            <Form.Group className="border-none">
              <Form.Check
                type="checkbox"
                label="Publish gems"
                id="published"
                checked={formData?.published}
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <Button
            className="text-white bg-grad px-4 ms-4"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
      <FormFieldService
        fields={fields}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        colCount={4}
        bg
      />
    </Layout>
  );
};

export default Index;
