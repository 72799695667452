import { useContext, useEffect, useState } from "react";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  IconDelete,
  IconFile,
  IconRecycle,
} from "../../../components/widgets/Icons";
import { Layout, TableLayout, GoBack } from "../../../components";
import {
  actionData,
  deleteMethod,
  getDataService,
  putMethod,
} from "../../../services/api";
import { PageContext } from "../../../context";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getNumber } from "../../../services";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const Sets = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService(
      `trash/sets/${skip * limit}/${limit}?sort=desc`,
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleResponse = (result: any) => {
    if (result?.success || result?.id || result?.persons?.id) {
      toast.success(result?.message || "Successfully done!");
      handleGetData(0);
    } else {
      toast.error(result?.message);
      setLoading && setLoading(false);
    }
  };

  const handleRestore = (item: any) => {
    swal({
      text: `Are you sure to restore this item!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        actionData(putMethod, `restore/sets/${item?.id}`, null).then(
          (result) => {
            handleResponse(result);
          }
        );
      }
    });
  };

  const handleDelete = (item: any) => {
    swal({
      text: `Are you sure to delete this item permanently!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        actionData(deleteMethod, `set/${item.id}`, null).then((result) => {
          handleResponse(result);
        });
      }
    });
  };

  // const thumbsnailImage = (item: any) => {
  //     let tempImages = item.images
  //     let isExist = false
  //     let tempDiv = <img src="images/no-image.png" alt="Thumbnail" width={80} height={80} className="object-fit rounded my-2" />;

  //     if (tempImages && tempImages.length > 0) {
  //         tempImages.forEach((img: any) => {
  //             if (img.thumbnail) {
  //                 isExist = true

  //                 tempDiv = <img src={`${RES_API_URL}${img.url}`} alt="Thumbnail" width={80} height={80} className="object-fit rounded cursor my-2"
  //                 />
  //             }
  //         })

  //         if (!isExist) {
  //             tempDiv = <img src={`${RES_API_URL}${tempImages[0].url}`} alt="Thumbnail" width={80} height={80} className="rounded cursor my-2"
  //             />
  //         }
  //     }

  //     return tempDiv;
  // }

  return (
    <Layout title="Deleted jewellery set lists">
      <div className="mb-4">
        <GoBack label="Configuration" />
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Jewellery set item</th>
            <th>Included</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div className="thumbnail-list-image me-2">
                    {item?.images ? (
                      <Image
                        src={`${RES_API_URL}${item?.images?.url}`}
                        alt="no_found"
                      />
                    ) : (
                      <Image src={"images/no-image.png"} />
                    )}
                  </div>
                  <div>
                    <div>{item?.itemName}</div>
                    <small className="text-muted mt-2">{item?.sku}</small>
                  </div>
                </span>
              </td>
              <td>{item?.setsJewellery || "-"}</td>
              <td>
                <span className="flex-center">
                  <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                    <Button
                      className="btn-sm bg-grad"
                      onClick={() =>
                        history.push({
                          pathname: "/jewellery-set-details",
                          state: { item: item },
                        })
                      }
                    >
                      <IconFile />
                    </Button>
                  </OverlayTrigger>
                  <Button
                    className="bg-grad btn-sm ms-2"
                    onClick={() => handleRestore(item)}
                  >
                    {" "}
                    <IconRecycle />{" "}
                  </Button>
                  <Button
                    className="bg-danger btn-sm ms-2"
                    onClick={() => handleDelete(item)}
                  >
                    {" "}
                    <IconDelete />{" "}
                  </Button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>
    </Layout>
  );
};

export default Sets;
