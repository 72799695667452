import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { Thumbsnail } from "../../../../components";
import { LangContext } from "../../../../context";
import { handleDateFormat, numberFormat } from "../../../../services";

interface InvoiceProps {
  data: any;
  showDataCheck: any;
}

const Invoice: React.FC<InvoiceProps> = ({ data, showDataCheck }) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <div className="bg-card">
      <style type="text/css" media="print">
        {`
          .bg-card{
              box-shadow: none !important;
            }
        `}
      </style>

      <div className="flex-between align-items-center mb-4">
        <div>
          <img
            src="images/mr.spinel.png"
            className="p-4 bg-black"
            alt=""
            width={200}
          />
        </div>

        <div>
          <span className="d-block mb-2">
            <small className="text-muted">Broker</small> :{" "}
            <small>{data?.cali_gems_consignee?.fullName || "-"}</small>
          </span>
          <span className="d-block mb-2">
            <small className="text-muted">Phone</small> :{" "}
            <small>{data?.cali_gems_consignee?.phone || "-"}</small>
          </span>
          {data?.gems_consignee?.address && (
            <span className="d-block mb-2">
              <small className="text-muted">{translate("address")}</small> :{" "}
              <small>{data?.cali_gems_consignee?.address || "-"} </small>
            </span>
          )}
          <span className="d-block mb-2">
            <small className="text-muted">Date</small> :{" "}
            <small>{handleDateFormat(data?.createdAt)}</small>
          </span>
          <span className="d-block mb-2">
            <small className="text-muted">Status</small> :{" "}
            <small>{data?.consignmentStatus}</small>
          </span>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <h6 className="fw-bold border text-center px-4 py-2 text-uppercase">
          Calibrated gem consignment
        </h6>
      </div>

      <Table responsive className="mt-3" bordered striped>
        <thead>
          <tr>
            <th>Item</th>
            <th className="text-center">Stock Type</th>
            <th className="text-center">Stone Type</th>
            <th className="text-center">Dealing price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data?.consigned_gems?.itemName} </td>
            <td className="text-center">
              <span>{data?.consigned_gems?.stockType || "N/A"}</span>
            </td>
            <td className="text-center">
              <span>{data?.consigned_gems?.stoneType || "N/A"}</span>
            </td>
            <td className="text-center">
              <span>
                {data?.dealingPrice
                  ? `${data?.consigned_gems?.currency} ${numberFormat(
                    data?.dealingPrice
                  )}`
                  : "N/A"}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex align-items-start">
          <div>
            <div className="receit-item-image flex-center">
              <Thumbsnail images={data?.consigned_gems?.images} />
            </div>
            <small className="xxs-label text-muted d-block mt-2 text-center">
              {data?.consigned_gems?.sku}
            </small>
          </div>
          <div className="ms-3">
            <small className="d-block mb-2">
              <span className="text-muted">Mining location : </span>
              {`${data?.consigned_gems?.miningLocation || "N/A"}`}
            </small>
            <small className="d-block mb-2">
              <span className="text-muted">Note : </span>
              {`${data?.consigned_gems?.notes || "N/A"}`}
            </small>
          </div>
        </div>
      </div>

      {data?.consignmentNotes && (
        <span className="d-block">
          <small className="text-muted">Notes </small> :{" "}
          <small>{data?.consignmentNotes}</small>
        </span>
      )}

      <small className="text-danger text-center d-block mb-3 mt-5">
        {translate("receipt-tip")}
      </small>
      <small className="text-muted text-center d-block">
        {translate("thankForYourPurchase")}
      </small>
    </div>
  );
};

export default Invoice;
