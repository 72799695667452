import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  Layout,
  SearchInput,
  Lang,
  TableLayout,
  TableRowButtons,
  CustomModal,
  FormFieldService,
  ImageLoading,
  NoData,
} from "../../components";
import {
  IconAdd,
  IconCamera,
  IconClose,
  IconFile,
  IconGrid,
  IconImage,
  IconList,
  IconUpload,
} from "../../components/widgets/Icons";
import { PageContext } from "../../context/Page";
import { FileWithPath, useDropzone } from "react-dropzone";
import {
  getNumber,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../services";
import {
  actionData,
  dataService,
  deleteMethod,
  deleteUpload,
  getDataService,
  getRouteWithPaginate,
  moveToTrashService,
  postMethod,
  putMethod,
  responseService,
  setUpload,
} from "../../services/api";
import { GridLoading } from "../../components/widgets/Loading";
import { PgComponent } from "../../components/widgets/Pagination";
import { inventoryTypes, inventoryTypesForSet } from "../../data";
import { VariableContext } from "../../context";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

interface Target {
  id?: number;
  fileName?: string;
  colorName?: string;
  image?: string;
}

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [show, setShow] = useState(false);
  const [showImageModel, setShowImageModel] = useState(false);
  const [showDeleteModel, setShowDeleteMode] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [data, setData] = useState([]);
  const [inventoryType, setInventoryType] = useState("shop_inventory");
  const [page, setPage] = useContext(PageContext);
  const [variable, setVariable] = useContext(VariableContext);
  const [target, setTarget] = useState<Target>();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    itemName: "",
    inventoryType: "shop_inventory",
    notes: "",
    image: null,
  });
  const required = ["itemName"];

  const fields = [
    { id: "itemName", label: "Jewellery set name *", value: formData.itemName },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.inventoryType,
      type: "select",
      data: inventoryTypesForSet,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "notes",
      label: "Note",
      value: formData.notes,
      inputType: "textarea",
      disableMargin: true,
    },
  ];

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageUploadLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );
        setUpload(tempFormData).then((data) => {
          setUploadedFiles(data);
          setFormData({ ...formData, image: data[0] });
          setImageUploadLoading(false);

          if (formData.id) {
            let _formData = {
              setsId: formData.id,
              image: data[0],
            };

            dataService(postMethod, _formData, [], `sets/images`).then(
              (result) => {
                handleResponse(result);
                handleClose(setShowImageModel);
              }
            );
          }
        });
      }, 1000);
    },
  });

  useEffect(() => {
    setInventoryType(variable?.inventoryJewelleryType);
    handleGetData(page.current, variable?.inventoryJewelleryType);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number, type: any) => {
    const _newType = type || inventoryType;
    getDataService(
      getRouteWithPaginate("sets", skip, _newType),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleChangeInventoryType = (type: any) => {
    setInventoryType(type);
    handleGetData(0, type);
    setVariable({ ...variable, inventoryJewelleryType: type });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = (item?: any) => {
    setFormData({
      id: item.id || "",
      itemName: item.itemName || "",
      inventoryType: item.inventoryType || "",
      notes: item.notes || "",
      image: item.image || null,
    });

    if (item?.images) {
      let temp: any = [item?.images];
      setUploadedFiles(temp);
    } else {
      setUploadedFiles([]);
    }
    handleShow(setShow);
  };

  const showImage = (item?: any) => {
    if (item?.images) {
      let temp: any = [item?.images];
      setUploadedFiles(temp);
    } else {
      setUploadedFiles([]);
    }

    setFormData({
      id: item.id || "",
      itemName: item.itemName || "",
      inventoryType: item.inventoryType || "",
      notes: item.notes || "",
      image: null,
    });

    handleShow(setShowImageModel);
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    let putFormData = {
      itemName: formData.itemName,
      notes: formData.notes,
    };
    setLoading(true);
    dataService(
      method,
      method === postMethod ? formData : putFormData,
      required,
      method === postMethod ? "set" : `set/${formData.id}`,
      method === putMethod
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleGetSearchData = (skip: number, searchString: string) => {
    setLoading(true);
    actionData(postMethod, getRouteWithPaginate("sets", skip, inventoryType), {
      keyword: searchString,
    }).then((result) => {
      setData(result.data);
      setPage &&
        setPage({
          current: skip,
          total: result.count,
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleSerachEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      if (e.target.value) {
        handleGetSearchData(0, e.target.value);
      } else {
        handleGetData(0, inventoryType);
        setSearchString("");
      }
    }
  };

  const handleDeleteUploaded = async (file: any, i: any) => {
    deleteUpload({ file: file }).then((data) => {
      setUploadedFiles([]);
      setFormData({ ...formData, image: null });
      handleClose(setShowDeleteMode);

      if (formData.id) {
        actionData(deleteMethod, "sets/images", { fileName: file }).then(
          (result) => {
            handleClose(setShowImageModel);
            handleGetData(page.current, inventoryType);
          }
        );
      }
    });
  };

  const handleDelete = (item: any) => {
    moveToTrashService(
      item?.itemName,
      null,
      `trash/sets/${item?.id}`,
      handleResponse
    );
    // handleDeleteResponse(await actionData(deleteMethod, `set/${item.id}`, null), item?.images?.fileName)
  };

  const toggle = (fileName: string, id: number) => {
    setTarget({ id: id, fileName: fileName });
    handleShow(setShowDeleteMode);
  };

  const uploadedThumbs = uploadedFiles.map((file: any, i) => (
    <div className="image-area rounded" key={file.fileName}>
      <img
        src={`${RES_API_URL}${file.url}`}
        className="thumb-img"
        alt={file.fileName}
        key={file.fileName}
      />
      <button
        className="btn remove-image"
        onClick={() => toggle(file.fileName, i)}
        type="button"
      >
        <IconClose />
      </button>
    </div>
  ));

  const goDetail = (item: any) => {
    console.log(item);

    history.push(
      `/jewellery-set-details?id=${item.id}`,
    );
    // history.push({
    //   pathname: "/jewellery-set-details",
    //   state: { item: item },
    // });
  };

  return (
    <Layout title="Jewellery sets">
      <div className="flex-between mb-4">
        <SearchInput
          handleSerachEnterAction={handleSerachEnterAction}
          searchString={searchString}
          setSearchString={setSearchString}
          handleClearSearch={() => {
            handleGetData(0, inventoryType);
            setSearchString("");
          }}
        />
        <div className="d-flex align-items-center">
          {page.total ? (
            <span>
              <small className="text-muted">Result : </small>
              {numberFormat(page.total)}
            </span>
          ) : (
            <></>
          )}

          <div className="ms-3">
            <Form.Select
              value={inventoryType}
              onChange={(e: any) => handleChangeInventoryType(e.target.value)}
            >
              {inventoryTypesForSet?.map((item: any, index: number) => (
                <option key={index} value={item?.key}>
                  {item?.label}
                </option>
              ))}
            </Form.Select>
          </div>

          <ButtonGroup className="ms-3">
            <OverlayTrigger overlay={<Tooltip>Grid view</Tooltip>}>
              <Button
                onClick={() => setIsGrid(true)}
                className={
                  isGrid ? "text-white bg-grad" : "bg-white text-primary"
                }
              >
                <IconGrid />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>List view</Tooltip>}>
              <Button
                onClick={() => setIsGrid(false)}
                className={
                  isGrid ? "bg-white text-primary" : "text-white bg-grad"
                }
              >
                <IconList />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconAdd />{" "}
            <span className="ms-2">
              <Lang label="createNew" />
            </span>
          </Button>
        </div>
      </div>

      {isGrid ? (
        <div>
          {loading ? (
            <GridLoading />
          ) : (
            <div>
              {data.length > 0 ? (
                <>
                  <Row>
                    {data.map((item: any, index: number) => (
                      <Col md={6} key={index}>
                        <div className="product-card">
                          <div
                            className="product-card-image"
                            onClick={() => goDetail(item)}
                          >
                            {item?.images ? (
                              <Image
                                src={`${RES_API_URL}${item?.images?.url}`}
                                alt="no_found"
                              />
                            ) : (
                              <Image src={"images/no-image.png"} />
                            )}
                          </div>
                          <div className="product-card-info">
                            <h6
                              className="fw-bold mb-1"
                              onClick={() => goDetail(item)}
                            >
                              {item?.itemName}
                            </h6>
                            <div className="mb-2">
                              <Badge className="bg-success me-2">
                                {
                                  inventoryTypes.filter(
                                    (type: any) =>
                                      type.key === item?.inventoryType
                                  )[0]?.label
                                }
                              </Badge>
                              <Badge
                                className={
                                  item?.availability ? "bg-grad" : "bg-danger"
                                }
                              >
                                {item?.availability
                                  ? "Available"
                                  : "Unavailable"}
                              </Badge>
                            </div>

                            <div onClick={() => goDetail(item)}>
                              <small className="text-muted d-block mb-2">
                                {item?.sku}
                              </small>
                              {item?.setsJewellery ? (
                                <small className="d-block mb-2">
                                  <span className="text-muted">
                                    Included :{" "}
                                  </span>
                                  {item?.setsJewellery}
                                </small>
                              ) : (
                                <></>
                              )}
                              {item?.jewelleryCount ? (
                                <small className="d-block mb-2">
                                  <span className="text-muted">
                                    Jewellery count :{" "}
                                  </span>
                                  {item?.jewelleryCount}
                                </small>
                              ) : (
                                <></>
                              )}
                              {item?.notes ? (
                                <small className="d-block mb-2">
                                  <span className="text-muted">Note : </span>
                                  {item?.notes}
                                </small>
                              ) : (
                                <></>
                              )}
                              {/* {item?.createdAt && <small className="d-block mb-2"><span className="text-muted">Create date : </span>{handleDateTimeFormat(item.createdAt)}</small>} */}
                            </div>
                            <div className="d-flex  justify-content-between align-items-center ">
                              <div onClick={goDetail}>
                                <small className="d-block mb-1 fw-bold">
                                  {item?.sellingPrice
                                    ? `${item?.currency} ${numberFormat(
                                      item?.sellingPrice
                                    )}`
                                    : ""}
                                </small>
                              </div>
                              <span className="flex-center">
                                <OverlayTrigger
                                  overlay={<Tooltip>View detail</Tooltip>}
                                >
                                  <Button
                                    className="btn-sm bg-grad"
                                    onClick={() =>
                                      goDetail(item)
                                    }
                                  >
                                    <IconFile />
                                  </Button>
                                </OverlayTrigger>
                                <Button
                                  className="btn-sm ms-2 bg-grad"
                                  onClick={() => showImage(item)}
                                >
                                  <IconImage />
                                </Button>
                                <TableRowButtons
                                  item={item}
                                  showForm={showForm}
                                  handleDelete={handleDelete}
                                  flex="flex-center"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <div>
                    {page && page.total > limit && (
                      <PgComponent
                        currentPage={page.current + 1 || 0}
                        fromCount={page.current || 0}
                        total={page.total || 0}
                        paginationLimit={limit}
                        pageInfo={page}
                        setPage={setPage}
                        handleGetData={handleGetData}
                      />
                    )}
                  </div>
                </>
              ) : (
                <NoData noImage />
              )}
            </div>
          )}
        </div>
      ) : (
        <TableLayout
          loading={loading}
          data={data}
          page={page}
          setPage={setPage}
          handleGetData={handleGetData}
        >
          <thead>
            <tr>
              <th>No.</th>
              <th>Jewellery set item</th>
              <th>Included</th>
              <th>Jewellery quantity</th>
              <th>Note</th>
              <th>
                <Lang label="date" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((item: any, index: any) => (
              <tr key={index}>
                <td>
                  <span className="flex-center">
                    {getNumber(index, page?.current)}
                  </span>
                </td>
                <td>
                  <span className="d-flex align-items-center">
                    <div className="thumbnail-list-image me-2">
                      {item?.images ? (
                        <Image
                          src={`${RES_API_URL}${item?.images?.url}`}
                          alt="no_found"
                        />
                      ) : (
                        <Image src={"images/no-image.png"} />
                      )}
                    </div>
                    <div>
                      <div>{item?.itemName}</div>
                      <small className="text-muted mt-2">{item?.sku}</small>
                    </div>
                  </span>
                </td>
                <td>{item?.setsJewellery || "-"}</td>
                <td>
                  <span className="flex-center">{item?.jewelleryCount}</span>
                </td>
                <td>
                  {" "}
                  <span className={item.notes ? "" : "flex-center"}>
                    {item.notes || "-"}
                  </span>
                </td>
                <td>
                  <span className="flex-center">
                    {handleDateFormat(item.createdAt)}
                  </span>
                </td>
                <td>
                  <span className="flex-center">
                    <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                      <Button
                        className="btn-sm bg-grad"
                        onClick={() =>
                          goDetail(item)
                        }
                      >
                        <IconFile />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      className="btn-sm ms-2 bg-grad"
                      onClick={() => showImage(item)}
                    >
                      <IconImage />
                    </Button>
                    <TableRowButtons
                      item={item}
                      showForm={showForm}
                      handleDelete={handleDelete}
                      flex="flex-center"
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </TableLayout>
      )}

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.id}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        {!formData.id && (
          <div>
            {uploadedFiles.length > 0 ? (
              <div className="d-flex justify-content-center  mt-4">
                {uploadedThumbs}
              </div>
            ) : (
              <div {...getRootProps({ className: " mb-3" })}>
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center">
                  {/* {imageUploadLoading ? <ImageLoading /> : <Button className="text-white mt-3 bg-grad"><IconUpload /> Upload Image</Button>} */}
                  {imageUploadLoading ? (
                    <ImageLoading />
                  ) : (
                    <div className="position-relative">
                      <div className="avatar">
                        <IconImage size={60} />
                      </div>

                      <div className="btn-upload">
                        <IconCamera />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={12}
        />
      </CustomModal>

      <Modal
        show={showImageModel}
        onHide={() => handleClose(setShowImageModel)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadedFiles.length > 0 ? (
            <div className="d-flex justify-content-center  mt-4">
              {uploadedThumbs}
            </div>
          ) : (
            <div {...getRootProps({ className: " mb-3" })}>
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center">
                {imageUploadLoading ? (
                  <ImageLoading />
                ) : (
                  <Button className="text-white mt-3 bg-grad">
                    <IconUpload /> Upload Image
                  </Button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button className='bg-success' onClick={() => handleClose(setShowDeleteMode)}>
                        Cancel
                    </Button>
                    <Button className='bg-danger' onClick={() => handleDeleteUploaded(target?.fileName, target?.id)}>
                        Delete
                    </Button>
                </Modal.Footer> */}
      </Modal>

      <Modal
        show={showDeleteModel}
        onHide={() => handleClose(setShowDeleteMode)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete image?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => handleClose(setShowDeleteMode)}
          >
            Cancel
          </Button>
          <Button
            className="bg-danger"
            onClick={() => handleDeleteUploaded(target?.fileName, target?.id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Index;
