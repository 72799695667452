import React from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import {
  disableScroll,
  netPrice,
  numberFormat,
  totalAmount,
} from "../../../../services";
import { CartContext } from "../../../../context";

interface ItemPriceModalProps {
  item: any;
  show: boolean;
  handleToggleModal: () => void;
}

const ItemPriceModal: React.FC<ItemPriceModalProps> = ({
  show,
  item,
  handleToggleModal,
}) => {
  const [cart, setCart] = React.useContext(CartContext);
  const [tempItem, setTempItem] = React.useState<any>();

  React.useEffect(() => {
    setTempItem(item);
  }, [item]);

  const handleClose = () => {
    handleToggleModal();
  };

  const handleInputChange = (value: any, key: any) => {
    setTempItem({ ...tempItem, [key]: value });
  };

  const handleGiftItem = (status: boolean) => {
    setTempItem({
      ...tempItem,
      isGift: status,
      discountType: "by_amount",
      discountValue: "",
      sellingPrice: status ? "" : item?.price?.sellingPrice,
    });
  };

  const handleSwitchChange = (status: boolean) => {
    const temp = {
      ...tempItem,
      discountType: status ? "by_percentage" : "by_amount",
      discountValue: "",
    };

    setTempItem(temp);
  };

  const discountAmont = () => {
    let total: any = tempItem?.sellingPrice;
    let amount: any = tempItem?.discountValue;

    if (tempItem?.discountType === "by_percentage" && amount) {
      let discountPercent = parseFloat(amount);
      amount = (parseInt(total) / 100) * discountPercent;
    }

    // tempItem.discountValue = amount;
    return parseInt(amount) || 0;
  };

  const total = () => {
    let discount = discountAmont();
    let total: any = Number(tempItem?.sellingPrice) - discount || 0;

    // tempItem.totalAmount = parseInt(total);

    return parseInt(total);
  };

  const handleSave = () => {
    const temp: any = [...cart?.items];

    temp?.forEach((data: any, index: number) => {
      if (data?.id === tempItem?.id) {
        temp[index] = tempItem;
      }
    });

    setCart({
      ...cart,
      items: temp,
      amount: totalAmount(netPrice(temp)?.toString(), { discountValue: 0 }),
    });

    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{tempItem?.itemName}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col>
              <Form.Group className="input-form-control mb-3">
                <Form.Label>Net price</Form.Label>

                <Form.Control
                  type={"number"}
                  id={"sellingPrice"}
                  value={tempItem?.sellingPrice}
                  disabled={tempItem?.isGift}
                  onWheel={disableScroll}
                  onChange={(e: any) =>
                    handleInputChange(e.target.value, "sellingPrice")
                  }
                />
              </Form.Group>

              <Form.Group className="input-form-control">
                <div className="flex-between">
                  <Form.Label>{`Discount ${
                    tempItem?.discountType === "by_amount" ? "amount" : "(%)"
                  }`}</Form.Label>

                  <FormCheck
                    id="switch"
                    className="switch"
                    type="switch"
                    label={"%"}
                    disabled={tempItem?.isGift}
                    checked={tempItem?.discountType === "by_percentage"}
                    onChange={(e: any) => handleSwitchChange(e.target.checked)}
                  />
                </div>

                <Form.Control
                  type={"number"}
                  id={"discountValue"}
                  value={tempItem?.discountValue}
                  disabled={tempItem?.isGift}
                  onChange={(e: any) =>
                    handleInputChange(e.target.value, "discountValue")
                  }
                  onWheel={disableScroll}
                />
              </Form.Group>
            </Col>

            <Col>
              <div className="bg-light small p-4">
                <div className="d-flex justify-content-between mb-3">
                  <span>Net price</span>
                  {tempItem?.sellingPrice ? (
                    <span>
                      {tempItem?.currency}{" "}
                      {numberFormat(tempItem?.sellingPrice)}
                    </span>
                  ) : (
                    "0"
                  )}
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <span>
                    {`Discount ${
                      tempItem?.discountType === "by_amount" ? "amount" : "(%)"
                    }`}
                  </span>
                  {tempItem?.discountValue &&
                  tempItem?.discountValue !== "0" ? (
                    <span className="text-danger">
                      - {tempItem?.currency} {numberFormat(discountAmont())}
                    </span>
                  ) : (
                    "0"
                  )}
                </div>

                <div className="d-flex fw-bold fs-6 justify-content-between border-top pt-3">
                  <span>Total</span>
                  {total() ? (
                    <span className="">
                      {tempItem?.currency} {numberFormat(total())}
                    </span>
                  ) : (
                    "0"
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            <Form.Check
              type="checkbox"
              label="Gift item"
              id={`giftItem`}
              className="cursor"
              checked={tempItem?.isGift}
              onChange={(e) => handleGiftItem(e?.target.checked)}
            />
          </div>

          <div>
            <Button className="bg-success" onClick={handleClose}>
              Cancel
            </Button>

            <Button className="btn btn-grad ms-2" onClick={handleSave}>
              <span className="ms-2">Save</span>
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemPriceModal;
