import { Button, Col, Form, Image, Modal, Table } from "react-bootstrap";
import {
  IconAdd,
  IconClose,
  IconImage,
  IconUpload,
} from "../../../../components/widgets/Icons";
import {
  CustomModal,
  // CustomModalService,
  FormFieldService,
  ImageLoading,
  NoData,
  TableRowButtons,
} from "../../../../components";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../context/Lang";
import {
  csvToArr,
  handleClose,
  handleShow,
  inputChangeService,
} from "../../../../services";
import {
  actionData,
  dataService,
  deleteImage,
  deleteMethod,
  deleteService,
  deleteUploadTwo,
  // gemsClarityRoute,
  // gemsColorRoute,
  // gemsRoute,
  // gemsShapeAndCutRoute,
  // gemsTreatmentRoute,
  // gemsWeightUnitRoute,
  getMethod,
  postMethod,
  responseService,
  setUpload,
} from "../../../../services/api";
import { FileWithPath, useDropzone } from "react-dropzone";
import Lightbox from "react-image-lightbox";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  gemstonesStageId?: string;
  fileName?: string;
  image?: string;
}

const Stages = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [weightUnits, setWeigthUnits] = useState([]);
  const [colors, setColors] = useState([]);
  const [clarities, setClarities] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [showDeleteImage, setShowDeleteImage] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [target, setTarget] = useState<Target>();

  const [formData, setFormData] = useState<any>({
    gemstonesStageId: "",
    numberOfPcs: 1,
    dimension: "",
    weight: "",
    weightUnit: "grams",
    color: "",
    clarity: "",
    shapeAndCut: "",
    treatment: [],
    stageStaus: "Initial",
  });

  const required = ["numberOfPcs", "weight"];

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      let temp: any = [];
      setImageLoading(true);
      temp = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setTimeout(() => {
        let tempFormData = new FormData();
        temp &&
          temp.map((file: any) =>
            tempFormData.append("files", file, file.name)
          );

        setUpload(tempFormData).then((data) => {
          let tempFiles: any = [];
          uploadedFiles?.forEach((item: any) => tempFiles.push(item));
          data?.forEach((item: any) => tempFiles.push(item));
          setUploadedFiles(tempFiles);
          setImageLoading(false);
          dataService(
            postMethod,
            { ...data[0], thumbnail: false },
            [],
            `gemstonesStage/${target?.gemstonesStageId}/images`
          ).then((result) => {
            handleResponse(result);
          });
        });
      }, 500);
    },
  });

  const fields = [
    // {
    //   id: "numberOfPcs",
    //   label: "Number Of pcs *",
    //   value: formData.numberOfPcs,
    //   inputType: "number",
    // },
    { id: "dimension", label: "Dimension", value: formData.dimension },
    {
      id: "weight",
      label: "Weight *",
      value: formData.weight,
      inputType: "number",
      type: "select-group",
      secondId: "weightUnit",
      secondValue: formData.weightUnit,
      data: weightUnits,
      wantString: true,
    },
    {
      id: "color",
      label: "Color",
      value: formData.color,
      type: "select",
      data: colors,
      wantString: true,
    },
    {
      id: "clarity",
      label: "Clarity",
      value: formData.clarity,
      type: "select",
      data: clarities,
      wantString: true,
    },
    {
      id: "shapeAndCut",
      label: "Shape and cut",
      value: formData.shapeAndCut,
      type: "select",
      data: shapes,
      wantString: true,
    },
    // {
    //   id: "treatment",
    //   label: "Treatment",
    //   value: formData.treatment,
    //   type: "select",
    //   data: treatments,
    //   wantString: true,
    // },
  ];

  useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, `cali-gems-weight-unit`).then((result) => {
      setWeigthUnits(result.data);
    });
    actionData(getMethod, `cali-gems-color`).then((result) => {
      setColors(result.data);
    });
    actionData(getMethod, `cali-gems-clarity`).then((result) => {
      setClarities(result.data);
    });
    actionData(getMethod, `cali-gems-shape-and-cut`).then((result) => {
      setShapes(result.data);
    });
    actionData(getMethod, `cali-gems-treatment`).then((result) => {
      setTreatments(result.data);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const handleCheckboxChange = (evt: any) => {
    let temp: any = [...formData?.treatment];
    const checked = evt.target.checked;
    const value = evt.target.value;

    if (checked) {
      temp.push(value);
      setFormData({ ...formData, treatment: temp });
    } else {
      temp = temp.filter((v: any) => v !== value);
    }

    setFormData({ ...formData, treatment: temp });
  };

  const showForm = (item?: any) => {
    setFormData({
      gemstonesStageId: item?.id || null,
      numberOfPcs: item?.numberOfPcs || 1,
      dimension: item?.dimension || "",
      weight: item?.weight || "",
      weightUnit: item?.weightUnit || "",
      color: item?.color || "",
      clarity: item?.clarity || "",
      shapeAndCut: item?.shapeAndCut || "",
      treatment: csvToArr(item?.treatment, ", "),
      stageStaus: "Initial",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    const _formData = {
      ...formData,
      treatment: formData?.treatment?.join(", "),
    };

    setLoading(true);
    dataService(
      method,
      _formData,
      required,
      `cali_gemstones/${method === postMethod ? product.id : formData.gemstonesStageId
      }/stage`
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      "this stage",
      null,
      `cali_gemstones/${item.id}/stage`,
      handleResponse
    );
  };

  const toggle = (fileName: string, id: number) => {
    setTarget({ id: id, fileName: fileName });
    handleShow(setShowDeleteImage);
  };

  const showAddImageForm = (item?: any) => {
    let tempImages = item.images;
    if (tempImages.length > 0) {
      setUploadedFiles(tempImages);
    } else {
      setUploadedFiles([]);
    }
    setTarget({ gemstonesStageId: item.id });
    handleShow(setShowAddImage);
  };

  const uploadedThumbs = uploadedFiles.map((file: any, i) => (
    <div className="image-area col-6 rounded" key={file.fileName}>
      <img
        src={`${RES_API_URL}${file.url}`}
        className="thumb-img"
        alt={file.fileName}
        key={file.fileName}
      />
      <button
        className="btn remove-image-2"
        onClick={() => toggle(file.fileName, i)}
        type="button"
      >
        <IconClose />
      </button>
    </div>
  ));

  const stageImage = (item: any) => {
    let tempImages = item.images;

    let tempDiv: any = (
      <section className="product-card-small-image cursor">
        <Image src="images/no-image.png" alt="Thumbnail" />
      </section>
    );

    if (tempImages.length > 0) {
      let imageUrl = tempImages[0].url;
      tempDiv = (
        <section className="product-card-small-image cursor">
          <Image
            src={`${RES_API_URL}${imageUrl}`}
            onClick={() => {
              setTarget({ image: tempImages[0].url });
              handleShow(setShowImage);
            }}
          />
        </section>
      );
    }

    return tempDiv;
  };

  const handleDeleteImage = async () => {
    deleteUploadTwo(target?.fileName).then((data) => {
      deleteImage(`remove/cali_gemstones/images`, target?.fileName).then(
        (res) => {
          dataService(
            deleteMethod,
            { fileName: target?.fileName },
            [],
            `remove/cali_gemstones/images`
          ).then((result) => {
            handleResponse(result);
          });
        }
      );
    });
  };

  return (
    <Col md={12}>
      {showImage && (
        <Lightbox
          mainSrc={`${RES_API_URL}${target?.image}`}
          imageTitle={product?.itemName}
          // imageCaption={`${translate('color')} - ${target?.colorName}`}
          onCloseRequest={() => handleClose(setShowImage)}
        />
      )}

      <section className="bg-card mb-4">
        <section className="flex-between mb-3">
          <h6>Stages</h6>
          {!product?.stages?.length && (
            <Button className="text-white bg-grad" onClick={showForm}>
              <IconAdd /> <span className="ms-2">{translate("createNew")}</span>
            </Button>
          )}
        </section>

        {product && product?.stages && product?.stages.length > 0 ? (
          <>
            <Table responsive className="mb-2" striped>
              <thead>
                <tr>
                  <th>
                    <span className="flex-center">Image</span>
                  </th>
                  {/* <th>
                    <span className="flex-center">Pcs</span>
                  </th> */}
                  <th>Dimension</th>
                  <th>Weight</th>
                  <th>Color</th>
                  <th>Clarity</th>
                  <th>Shape and cut</th>
                  <th>Treatment</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {product?.stages.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <span className="flex-center">
                        {item.images ? stageImage(item) : "-"}
                      </span>
                    </td>
                    {/* <td>
                      <span className="flex-center">
                        {item.numberOfPcs || "-"}
                      </span>
                    </td> */}
                    <td>{item.dimension || "-"}</td>
                    <td>
                      {item.weight} {item.weightUnit}
                    </td>
                    <td>{item.color || "-"}</td>
                    <td>{item.clarity || "-"}</td>
                    <td>{item.shapeAndCut || "-"}</td>
                    <td>{item.treatment || "-"}</td>
                    <td>
                      <span className="flex-end">
                        <Button
                          className="btn-sm bg-grad ms-2"
                          disabled={Boolean(product?.childGemstones?.length)}
                          onClick={() => showAddImageForm(item)}
                        >
                          <IconImage />
                        </Button>
                        <TableRowButtons
                          item={item}
                          showForm={showForm}
                          handleDelete={handleDelete}
                          disabled={Boolean(product?.childGemstones?.length)}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <NoData noImage />
        )}
      </section>

      <CustomModal
        show={show}
        handleSubmit={handleSubmit}
        handleClose={() => handleClose(setShow)}
        id={formData?.gemstonesStageId}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
        />

        <Form.Group>
          <Form.Label className="d-block">Treatment</Form.Label>
          {treatments?.map((item: any, index: number) => (
            <Form.Check
              key={index}
              inline
              label={item?.label}
              name="treatment[]"
              type="checkbox"
              id={`treatment-${index}`}
              value={item?.label}
              checked={formData?.treatment?.includes(item?.label)}
              onChange={handleCheckboxChange}
            />
          ))}
        </Form.Group>
      </CustomModal>

      {/* <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.gemstonesStageId}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        lg
      /> */}

      <Modal
        show={showAddImage}
        onHide={() => handleClose(setShowAddImage)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-4">{uploadedThumbs}</div>

          <div {...getRootProps({ className: " mb-3" })}>
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center">
              {imageLoading ? (
                <ImageLoading />
              ) : (
                <Button className="text-white bg-grad mt-3">
                  <IconUpload /> Upload image
                </Button>
              )}
            </div>
          </div>
          {/* {uploadedFiles.length > 0 ? (
            <div className="d-flex justify-content-center  mt-4">
              {uploadedThumbs}
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center  mt-4">
                {uploadedThumbs}
              </div>

              <div {...getRootProps({ className: " mb-3" })}>
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center">
                  {imageLoading ? (
                    <ImageLoading />
                  ) : (
                    <Button className="text-white bg-grad mt-3">
                      <IconUpload /> Upload image
                    </Button>
                  )}
                </div>
              </div>
            </>
          )} */}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button className='bg-success' onClick={() => handleClose(setShowAddImage)}>
                        Cancel
                    </Button>
                    <Button className='bg-grad' disabled={loading}>
                        <LoadingButtonLabel label="Save" disabled={loading} />
                    </Button>
                </Modal.Footer> */}
      </Modal>

      <Modal
        show={showDeleteImage}
        onHide={() => handleClose(setShowDeleteImage)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Deleting image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this image?</Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => handleClose(setShowDeleteImage)}
          >
            Cancel
          </Button>
          <Button className="bg-danger" onClick={handleDeleteImage}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default Stages;
