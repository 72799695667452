import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { NoData } from "../../../components";
import { CartContext } from "../../../context/Cart";
import {
  getStoreUser,
  handleShow,
  netPrice,
  numberFormat,
  totalAmount,
} from "../../../services";
import {
  dataService,
  posSalesCalibratedGemRoute,
  postMethod,
  responseForRoutes,
} from "../../../services/api";
import CartItem from "./cpn/CartItem";
import SaleConfirmModal from "./cpn/SaleConfirmModal";
import CustomerInfo from "../cpn/CustomerInfo";

const PosCart = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState<any>();
  const [cart, setCart] = useContext(CartContext);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  const [formData, setFormData] = useState<any>({
    sellerId: user?.userId,
    buyerId: "",
    saleNotes: "",
    paymentMethodId: "",
    paymentSeries: "down_payment",
    amount: "",
    serviceFee: "",
    discountType: "by_amount",
    discountValue: "",
    items: [],
  });

  const saleAction = () => {
    if (cart?.items?.length > 0) {
      if (person) {
        const amount = Number(
          totalAmount(netPrice(cart?.items)?.toString(), formData)
        );
        setFormData({
          ...formData,
          amount: amount,
          serviceFee: Number(amount * 0.05),
          saleNotes: "",
          paymentMethodId: "",
          paymentSeries: "down_payment",
          discountType: "by_amount",
          discountValue: "",
        });
        handleShow(setShow);
      } else {
        toast.error("Please select customer!");
      }
    } else {
      toast.error("Please select gem!");
    }
  };

  const handleSaleConfirm = () => {
    const allItemsAreGifts = Array.isArray(cart?.items) && cart.items.every((item: any) => item.isGift);
    if (!allItemsAreGifts) {
      if (!formData.paymentMethodId || !formData.paymentSeries || !formData.amount) {
        toast.error("Please provide payment method, series, and amount!");
        return;
      }
    } else {
      formData.paymentMethodId = formData.paymentSeries = formData.amount = null;
    }

    if ((allItemsAreGifts || !disabled) && !disabled) {
      formData.serviceFee = cart?.items?.some((item: any) =>
        item.inventoryType === "shop_inventory" ||
        item.inventoryType === "private_item"
      ) ? 0 : formData.serviceFee;

      formData.sellerId = getStoreUser()?.userId;
      formData.buyerId = person?.value;
      formData.discountValue = Number(formData?.discountValue);

      formData.items = cart?.items?.map((item: any) => ({
        itemId: item?.id,
        currency: item?.currency,
        price: Number(item?.sellingPrice),
        discountType: item?.discountType,
        discountValue: Number(item?.discountValue),
        isGift: item?.isGift,
      }));

      const total = totalAmount(netPrice(cart?.items)?.toString(), formData);
      formData.paymentStatus = Number(formData.amount) <= 0
        ? "Unpaid"
        : Number(formData.amount) < total
          ? "Partially"
          : "Paid";
      setDisabled(true);
      setLoading(true)
      dataService(postMethod, formData, [], posSalesCalibratedGemRoute, true).then((result) => {
        if (result.success) {
          setLoading(false)
          responseForRoutes(result, history, "calibrated-gems-sales");
          setCart({ ...cart, items: {}, amount: "" });
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
      });
    }
  };


  // const handleSaleConfirm = () => {
  //   // if (!formData.paymentMethodId) {
  //   //   toast.error("Please choose payment method!");
  //   // }
  //   const allItemsAreGifts = cart?.items?.every((item: any) => item.isGift);

  //   if (allItemsAreGifts) {
  //     formData.paymentMethodId = null;
  //   } else {
  //     toast.error("Please choose payment method!");
  //     if (!formData.paymentSeries) {
  //       toast.error("Please choose payment series!");
  //     }

  //     if (!formData.amount) {
  //       toast.error("Please provide payment amount!");
  //     }
  //   }
  //   if (
  //     formData.paymentMethodId &&
  //     formData.paymentSeries &&
  //     formData.amount &&
  //     !disabled
  //   ) {
  //     if (
  //       cart?.item.inventoryType === "shop_inventory" ||
  //       cart?.item.inventoryType === "private_item"
  //     ) {
  //       formData.serviceFee = 0;
  //     }
  //     formData.sellerId = getStoreUser()?.userId;
  //     formData.buyerId = person?.value;
  //     formData.discountValue = Number(formData?.discountValue);

  //     const temp: any = [];

  //     cart?.items?.map((item: any) =>
  //       temp.push({
  //         itemId: item?.id,
  //         currency: item?.currency,
  //         price: Number(item?.sellingPrice),
  //         discountType: item?.discountType,
  //         discountValue: Number(item?.discountValue),
  //         isGift: item?.isGift,
  //       })
  //     );

  //     formData.items = temp;


  //     const total = totalAmount(netPrice(cart?.items)?.toString(), formData);
  //     if (Number(formData.amount) <= 0) {
  //       formData.paymentStatus = "Unpaid";
  //     } else if (Number(formData.amount) < total) {
  //       formData.paymentStatus = "Partially";
  //     } else {
  //       formData.paymentStatus = "Paid";
  //     }

  //     setDisabled(true);
  //     dataService(
  //       postMethod,
  //       formData,
  //       [],
  //       posSalesCalibratedGemRoute,
  //       true
  //     ).then((result) => {
  //       if (result.success) {
  //         responseForRoutes(result, history, "calibrated-gems-sales");
  //         setCart({ ...cart, items: {}, amount: "" });
  //         toast.success(result?.message);
  //       } else {
  //         toast.error(result?.message);
  //       }
  //     });
  //   }
  // };

  return (
    <section
      className="pos-cart"
      style={{ position: cart?.items?.length > 0 ? "fixed" : "relative" }}
    >
      <section className="p-4 pos-cart-scroll-area">
        <CustomerInfo person={person} setPerson={setPerson} />

        {cart?.items?.length > 0 ? (
          <>
            <CartItem />
          </>
        ) : (
          <div className="mt-5">
            <NoData title="item" />
          </div>
        )}
      </section>

      <SaleConfirmModal
        loading={loading}
        show={show}
        setShow={setShow}
        person={person}
        formData={formData}
        setFormData={setFormData}
        handleSaleConfirm={handleSaleConfirm}
      />

      {cart?.items?.length > 0 && person && (
        <div className="pos-sale-btn" onClick={saleAction}>
          <label>Sale</label>
          <label>
            {cart?.items?.[0]?.currency} {numberFormat(netPrice(cart?.items))}
          </label>
        </div>
      )}
    </section>
  );
};

export default PosCart;
