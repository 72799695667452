import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { InfoCard, Lang } from "../../../../components";
import { IconGem } from "../../../../components/widgets/Icons";
import { VariableContext } from "../../../../context";
import { numberFormat } from "../../../../services";

function Count({
  inventoryCard,
  handleChangeInventoryType,
}: {
  inventoryCard: any;
  handleChangeInventoryType: any;
}) {
  const [variable, setVariable] = useContext(VariableContext);

  const cards = [
    {
      id: "shop_inventory",
      label: <Lang label="shopItems" />,
      value: numberFormat(inventoryCard?.shopInventory || 0),
      color: "primary",
    },
    {
      id: "service_item",
      label: <Lang label="serviceItems" />,
      value: numberFormat(inventoryCard?.serviceItem || 0),
      color: "warning",
    },
    {
      id: "ordered_item",
      label: <Lang label="orderedItems" />,
      value: numberFormat(inventoryCard?.orderedItem || 0),
      color: "info",
    },
    {
      id: "private_item",
      label: <Lang label="privateItems" />,
      value: numberFormat(inventoryCard?.privateItem || 0),
      color: "success",
    },
  ];

  const icon = <IconGem size={24} />;

  return (
    <Row className="mb-4">
      {cards?.map((item: any, index: number) => (
        <Col md={3} key={index}>
          <InfoCard
            id={item.id}
            label={item.label}
            value={item.value}
            color={item.color}
            state={variable?.inventoryJewelleryType}
            icon={icon}
            cardAction={() => {
              handleChangeInventoryType(item.id);
              setVariable({
                ...variable,
                inventoryJewelleryType: item.id,
                jewelleryFilterActionData: {},
                isJewelleryFilter: false,
              });
            }}
            hover
          />
        </Col>
      ))}
    </Row>
  );
}

export default Count;
