import { Table } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { NoData } from "..";
import Header from "./Header";
import { TableLoading } from "../widgets/Loading";
import { PgComponent } from "../widgets/Pagination";
import LeftSidebar from "./Sidebar";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

export const Layout = ({
  children,
  title,
  noPadding = false,
}: {
  children: any;
  title: any;
  noPadding?: boolean;
}) => {
  return (
    <section className="d-flex bg-light">
      <ToastContainer autoClose={2000} />
      <LeftSidebar />

      <section className="w-100">
        <Header title={title} />
        <section className={!noPadding ? "p-4" : ""}>{children}</section>
      </section>
    </section>
  );
};

export const TableLayout = ({
  loading,
  data,
  children,
  page,
  setPage,
  handleGetData,
  isFilter = false,
  handleChangePage,
}: {
  loading: boolean;
  data: any;
  children: any;
  page?: any;
  setPage?: any;
  handleGetData?: any;
  isFilter?: boolean;
  handleChangePage?: any;
}) => {
  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <>
          {data?.length > 0 ? (
            <section className="mb-4">
              <Table
                responsive
                className={page ? "mb-4" : "mb-0"}
                striped
                bordered
              >
                {children}
              </Table>

              {page && page.total > limit && (
                <PgComponent
                  currentPage={page.current + 1 || 0}
                  fromCount={page.current || 0}
                  total={page.total || 0}
                  paginationLimit={limit}
                  pageInfo={page}
                  setPage={setPage}
                  handleGetData={handleGetData}
                  isFilter={isFilter}
                  handleChangePage={handleChangePage}
                />
              )}
            </section>
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  );
};
