import { useState } from "react";
import { Image } from "react-bootstrap";
import { handleShow, numberFormat } from "../../../../services";
import { actionData, getMethod } from "../../../../services/api";
import SetItemModel from "./SetItemModal";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const SetCard = ({ item }: { item: any }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const handelDetailAction = () => {
    setLoading(true);
    actionData(getMethod, "pos/sets/" + item.id).then((result) => {
      setData(result.data[0]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
    handleShow(setShow);
  };

  return (
    <>
      <div className="pos-item mb-4" onClick={handelDetailAction}>
        <div className="pos-item-image">
          {item?.setsImage ? (
            <Image
              src={`${RES_API_URL}${item?.setsImage?.url}`}
              alt="no_found"
            />
          ) : (
            <Image src={"images/no-image.png"} />
          )}
        </div>
        <div
          className="p-3 d-flex flex-column justify-content-between"
          // style={{ height: "8rem" }}
        >
          <small className="d-block xs-label mb-1 text-truncate">
            {item?.itemName}
          </small>

          <small className="xs-label text-muted d-block fw-bold mb-1">
            {item?.sku}
          </small>
          {item?.jewelleryCount ? (
            <small className="xs-label d-block mb-1">
              <span className="text-muted">Jewellery count : </span>
              {item?.jewelleryCount}
            </small>
          ) : (
            <></>
          )}

          <div className="flex-between mt-2">
            <small className="d-block fw-bold">
              {item?.sellingPrice
                ? `${item?.currency} ${numberFormat(item?.sellingPrice)}`
                : ""}
            </small>
            {/* <IconCart /> */}
          </div>
        </div>
      </div>
      <SetItemModel
        show={show}
        setShow={setShow}
        item={data}
        setInfo={item}
        loading={loading}
      />
    </>
  );
};

export default SetCard;
