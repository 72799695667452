import { Route, Redirect } from "react-router-dom";

// import Home from "./home";
import Login from "./auth/Login";
import Dashboard from "./dashboard";
import Gems from "./gems/lists";
import AddGems from "./gems/add";
import EditGems from "./gems/edit";
import SplitGem from "./gems/split";
import GemsDetail from "./gems/detail";
import GemsLabels from "./gems/labels";
import GemsStages from "./gems/stages";
import CalibratedGems from "./calibrated-gems/lists";
import AddCalibratedGem from "./calibrated-gems/add";
import EditCalibratedGems from "./calibrated-gems/edit";
import CalibratedGemsDetail from "./calibrated-gems/detail";
// import SplitCalibratedGems from "./calibrated-gems/split";
// import CalibratedGemsLabels from "./calibrated-gems/labels";
// import CalibratedGemsStages from "./calibrated-gems/stages";
import Jewelleries from "./jewelleries/lists";
import AddJewellery from "./jewelleries/add";
import EditJewellery from "./jewelleries/edit";
import JewelleryDetail from "./jewelleries/detail";
import JewelleryLabels from "./jewelleries/labels";
import JewellerySets from "./sets";
import JewellerySetDetails from "./sets/Details";
import ReturnJewelleries from "./return/jewelleries";
import ReturnJewellerySets from "./return/set";
import JewelleryPointOfSale from "./pos/jewelleries";
import JewellerySetsPOS from "./pos/jewellery_sets";
import GemsPointOfSale from "./pos/gems";
import CalibratedGemsPointOfSale from "./pos/calibrated-gems";
import JewellerySales from "./sales/jewelleries";
import JewellerySetSales from "./sales/jewellery_sets";
import GemsSales from "./sales/gems";
import GemsSalesDetail from "./sales/gems/Detail";
import CalibtatedGemsSales from "./sales/calibrated-gems";
import CalibtatedGemsSalesDetail from "./sales/calibrated-gems/Detail";
import SaleDetail from "./sales/jewelleries/Detail";
import SetSaleDetail from "./sales/jewellery_sets/Detail";
import JewelleryConsigments from "./consignments/jewelleries";
import JewelleryConsigmentForm from "./consignments/jewelleries/Form";
import JewelleryConsigmentDetail from "./consignments/jewelleries/Detail";
import GemsConsigments from "./consignments/gems";
import GemConsigmentForm from "./consignments/gems/Form";
import GemConsigmentDetail from "./consignments/gems/Detail";
import CalibratedGemsConsigments from "./consignments/calibrated-gems";
import CalibratedGemConsigmentForm from "./consignments/calibrated-gems/Form";
import CalibratedGemConsigmentDetail from "./consignments/calibrated-gems/Detail";
import JewelleryInventoryReports from "./reports/jewellery_inventory";
import GemInventoryReports from "./reports/gems_inventory";
import CalibratedGemInventoryReports from "./reports/calibrated_gems_inventory";
import SetInventoryReports from "./reports/set_inventory";
import JewellerySaleReports from "./reports/jewellery_sale";
import GemSaleReports from "./reports/gems_sale";
import CalibratedGemSaleReports from "./reports/calibrated_gems_sale";
import SetSaleReports from "./reports/set_sale";
import Persons from "./persons";
import Config from "./config";
import PaymentMethods from "./config/PaymentMethods";
import ChangePrices from "./config/ChangePrices";
import ChangeRates from "./config/ChangeRates";
import Users from "./config/Users";
import GemColors from "./config/GemColors";
import CalibratedGemColors from "./config/CalibratedGemColors";
import StoneTypes from "./config/StoneTypes";
import Profile from "./account/Profile";
import ChangePassword from "./account/ChangePassword";
import JewelleryTrash from "./config/trash/Jewelleries";
import JewellerySetTrash from "./config/trash/Sets";
import GemsTrash from "./config/trash/Gems";
import CalibratedGemsTrash from "./config/trash/CalibratedGems";
import EmailNoti from "./config/EmailNoti";
import Receivable from "./accounting/Receivable";
import { defineAccess, isLogin } from "../services";
import ShapeAndCut from "./config/ShapeAndCut";
import GemShapeAndCut from "./config/GemShapeAndCut";

export const publicRoutes = [
  { route: "", component: Login },
  { route: "login", component: Login },
];

const all = defineAccess([1, 2, 3, 4, 5, 6]);

export const privateRoutes = [
  { route: "dashboard", component: Dashboard, access: all },
  { route: "gems", component: Gems, access: all },
  { route: "add-gems", component: AddGems, access: all },
  { route: "edit-gems", component: EditGems, access: all },
  { route: "split-gem", component: SplitGem, access: all },
  { route: "gems-detail", component: GemsDetail, access: all },
  { route: "gems-labels", component: GemsLabels, access: all },
  { route: "gems-stages", component: GemsStages, access: all },
  { route: "calibrated-gems", component: CalibratedGems, access: all },
  { route: "add-calibrated-gems", component: AddCalibratedGem, access: all },
  { route: "edit-calibrated-gems", component: EditCalibratedGems, access: all },
  {
    route: "calibrated-gems-detail",
    component: CalibratedGemsDetail,
    access: all,
  },
  { route: "jewelleries", component: Jewelleries, access: all },
  { route: "add-jewellery", component: AddJewellery, access: all },
  { route: "edit-jewellery", component: EditJewellery, access: all },
  { route: "jewellery-detail", component: JewelleryDetail, access: all },
  { route: "jewellery-labels", component: JewelleryLabels, access: all },
  { route: "jewellery-sets", component: JewellerySets, access: all },
  {
    route: "jewellery-set-details",
    component: JewellerySetDetails,
    access: all,
  },
  { route: "jewellery-labels", component: JewelleryLabels, access: all },
  {
    route: "jewellery-return-lists",
    component: ReturnJewelleries,
    access: all,
  },
  {
    route: "jewellery-set-return-lists",
    component: ReturnJewellerySets,
    access: all,
  },
  {
    route: "jewellery-point-of-sale",
    component: JewelleryPointOfSale,
    access: all,
  },
  { route: "jewellery-sets-pos", component: JewellerySetsPOS, access: all },
  { route: "gems-point-of-sale", component: GemsPointOfSale, access: all },
  {
    route: "calibrated-gems-point-of-sale",
    component: CalibratedGemsPointOfSale,
    access: all,
  },
  { route: "jewellery-sales", component: JewellerySales, access: all },
  { route: "jewellery-set-sales", component: JewellerySetSales, access: all },
  { route: "gems-sales", component: GemsSales, access: all },
  { route: "gems-sale-detail", component: GemsSalesDetail, access: all },
  { route: "sale-detail", component: SaleDetail, access: all },
  { route: "set-sale-detail", component: SetSaleDetail, access: all },
  {
    route: "calibrated-gems-sales",
    component: CalibtatedGemsSales,
    access: all,
  },
  {
    route: "calibrated-gems-sale-detail",
    component: CalibtatedGemsSalesDetail,
    access: all,
  },
  {
    route: "jewellery-consignments",
    component: JewelleryConsigments,
    access: all,
  },
  {
    route: "jewellery-consignment-form",
    component: JewelleryConsigmentForm,
    access: all,
  },
  {
    route: "jewellery-consignment-detail",
    component: JewelleryConsigmentDetail,
    access: all,
  },
  { route: "gems-consignments", component: GemsConsigments, access: all },
  { route: "gem-consignment-form", component: GemConsigmentForm, access: all },
  {
    route: "gem-consignment-detail",
    component: GemConsigmentDetail,
    access: all,
  },
  {
    route: "calibrated-gems-consignments",
    component: CalibratedGemsConsigments,
    access: all,
  },
  {
    route: "calibrated-gem-consignment-form",
    component: CalibratedGemConsigmentForm,
    access: all,
  },
  {
    route: "calibrated-gem-consignment-detail",
    component: CalibratedGemConsigmentDetail,
    access: all,
  },
  {
    route: "jewellery-inventory-reports",
    component: JewelleryInventoryReports,
    access: all,
  },
  {
    route: "gem-inventory-reports",
    component: GemInventoryReports,
    access: all,
  },
  {
    route: "calibrated-gem-inventory-reports",
    component: CalibratedGemInventoryReports,
    access: all,
  },
  {
    route: "set-inventory-reports",
    component: SetInventoryReports,
    access: all,
  },
  {
    route: "jewellery-sale-reports",
    component: JewellerySaleReports,
    access: all,
  },
  { route: "gem-sale-reports", component: GemSaleReports, access: all },
  {
    route: "calibrated-gem-sale-reports",
    component: CalibratedGemSaleReports,
    access: all,
  },
  { route: "set-sale-reports", component: SetSaleReports, access: all },
  { route: "persons", component: Persons, access: all },
  { route: "configuration", component: Config, access: all },
  { route: "payment-methods", component: PaymentMethods, access: all },
  { route: "change-prices", component: ChangePrices, access: all },
  { route: "change-rates", component: ChangeRates, access: all },
  { route: "users", component: Users, access: all },
  { route: "gem-colors", component: GemColors, access: all },
  {
    route: "calibrated-gem-colors",
    component: CalibratedGemColors,
    access: all,
  },
  {
    route: "stone-types",
    component: StoneTypes,
    access: all,
  },
  {
    route: "shape-and-cut",
    component: ShapeAndCut,
    access: all,
  },
  {
    route: "gem-shape-and-cut",
    component: GemShapeAndCut,
    access: all,
  },
  { route: "profile", component: Profile, access: all },
  { route: "change-password", component: ChangePassword, access: all },
  { route: "jewellery-trash", component: JewelleryTrash, access: all },
  { route: "jewellery-set-trash", component: JewellerySetTrash },
  { route: "gems-trash", component: GemsTrash, access: all },
  {
    route: "calibrated-gems-trash",
    component: CalibratedGemsTrash,
    access: all,
  },
  { route: "email-notification", component: EmailNoti, access: all },
  { route: "receivable", component: Receivable, access: all },
];

// export const Routes = () => {
//   return (
//     <>
//       {publicRoutes.map((item: any, index: number) =>
//         <PublicRoute exact path={`/${item.route}`} component={item.component} key={index} />
//       )}

//       {privateRoutes.map((item: any, index: number) =>
//         <PrivateRoute exact path={`/${item.route}`} access={item.access} component={item.component} key={index} />
//       )}

//       <PrivateRoute component={NotFound} />
//     </>
//   )
// }

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    // <Route {...rest} render={props => (
    //   isLogin() ?
    //     access ?
    //       <Component {...props} /> :
    //       <Redirect to={defineAccess([1, 2]) ? "/dashboard" : "/jewellery-point-of-sale"} />
    //     : <Redirect to="/" />
    // )} />
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export const PublicRoute = ({
  component: Component,
  restricted,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Redirect
            to={
              defineAccess([1, 2]) ? "/dashboard" : "/jewellery-point-of-sale"
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export function NotFound() {
  return (
    <div className="center-container">
      <h1 className="text-success">Page not found!</h1>
    </div>
  );
}
