import React from "react";
import { getPersonsService } from "../../../../services";
import {
  actionData,
  buyingStateRoute,
  getMethod,
  stockTypesRoute,
  stoneTypesRoute,
} from "../../../../services/api";
import { FormFieldService } from "../../../../components";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { inventoryTypes } from "../../../../data";

interface GemInfoProps {
  formData: any;
  setFormData: (data: any) => void;
}

const GemInfo: React.FC<GemInfoProps> = ({ formData, setFormData }) => {
  const [owners, setOwners] = React.useState([]);
  const [brokers, setBrokers] = React.useState([]);
  const [stoneTypes, setStoneTypes] = React.useState([]);
  const [stockTypes, setStockTypes] = React.useState([]);
  const [buyingStates, setBuyingStates] = React.useState([]);
  const [outlets, setOutlets] = React.useState<any>([]);

  const fields = [
    { id: "itemName", label: "Item name *", value: formData.gemInfo?.itemName },
    // { id: "sku", label: "SKU *", value: formData.gemInfo?.sku },
    {
      id: "stoneType",
      label: "Stone type *",
      value: formData.gemInfo?.stoneType,
      type: "select",
      data: stoneTypes,
      wantString: true,
    },
    {
      id: "stockType",
      label: "Stock type *",
      value: formData.gemInfo?.stockType,
      type: "select",
      data: stockTypes,
      wantString: true,
    },
    {
      id: "gemsOwnerId",
      label: "Gems owner",
      value: formData.gemInfo?.gemsOwnerId,
      type: "suggestion-select",
      data: owners,
    },
    {
      id: "gemsBrokerId",
      label: "Gems broker",
      value: formData.gemInfo?.gemsBrokerId,
      type: "suggestion-select",
      data: brokers,
    },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.gemInfo?.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "buyingState",
      label: "Buying state",
      value: formData.gemInfo?.buyingState,
      type: "select",
      data: buyingStates,
      wantString: true,
    },
    {
      id: "miningLocation",
      label: "Mining location",
      value: formData.gemInfo?.miningLocation,
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData.gemInfo?.outletName,
      type: "select",
      data: outlets,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "purchaseDate",
      label: "Purchase date",
      value: formData.gemInfo?.purchaseDate,
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: formData.gemInfo?.notes,
      inputType: "textarea",
    },
  ];

  React.useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    getPersonsService("", setOwners, null);
    getPersonsService("", setOwners, setBrokers);
    // actionData(getMethod, gemsWeightUnitRoute).then(result => { setWeightUnits(result) })
    actionData(getMethod, buyingStateRoute).then((result) => {
      setBuyingStates(result?.data);
    });

    actionData(getMethod, stoneTypesRoute).then((result) => {
      setStoneTypes(result?.data);
    });

    actionData(getMethod, stockTypesRoute).then((result) => {
      setStockTypes(result?.data);
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result?.data?.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
        setFormData({
          ...formData,
          gemInfo: {
            ...formData.gemInfo,
            outletName: temp?.[0]?.label,
          },
        });
      }
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.type === "text" ? target.value : target.value;
    }

    setFormData({
      ...formData,
      gemInfo: {
        ...formData.gemInfo,
        [name]: value,
      },
    });
  };

  const handleInputChange = (newValue: string) => {
    getPersonsService(newValue, setOwners, null);
    // getPersonsService(newValue, setOwners, setBrokers);
  };

  const handleNext = () => {
    const gemInfo = formData?.gemInfo;

    if (
      gemInfo?.itemName &&
      // gemInfo?.sku &&
      gemInfo?.stoneType &&
      gemInfo?.stockType
    ) {
      setFormData({
        ...formData,
        step: 2,
      });
    } else {
      toast.error("Please fill data!");
    }
  };

  return (
    <div className={`${formData?.step === 1 ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>Gem information (Step 1)</div>
      </div>

      <FormFieldService
        fields={fields}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        colCount={4}
        bg
      />

      <div className="mt-3 d-flex justify-content-end">
        <Button className="text-white bg-grad px-4" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default GemInfo;
