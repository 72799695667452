export const outlets = [{ label: "Bangkok" }, { label: "Other" }];

export const availabilityData = [
  { label: "Available" },
  { label: "Unavailable" },
];

export const stockTrans = [{ label: "In" }, { label: "Out" }];

export const inventoryStatus = [{ label: "In stock" }, { label: "On hold" }];

export const inventoryTypes = [
  { key: "shop_inventory", label: "Shop item", customKey: true },
  { key: "service_item", label: "Service item", customKey: true },
  { key: "ordered_item", label: "Ordered item", customKey: true },
  { key: "private_item", label: "Private item", customKey: true },
];

export const personTypes = [
  { key: "ord", label: "Ordinary", customKey: true },
  { key: "vip", label: "VIP", customKey: true },
  { key: "vvip", label: "VVIP", customKey: true },
];

export const inventoryTypesForSet = [
  { key: "shop_inventory", label: "Shop items", customKey: true },
  { key: "service_item", label: "Service items", customKey: true },
  { key: "private_item", label: "Private item", customKey: true },
];

export const paymentSeries = [
  { key: "down_payment", label: "Down Payment", customKey: true },
  { key: "advanced", label: "Advanced", customKey: true },
  { key: "final", label: "Final Payment", customKey: true },
];

export const inventoryStatusForAddForm = [
  { key: "received", label: "Received", customKey: true },
  { key: "purchased", label: "Purchased", customKey: true },
];

export const priceFromData = [
  { label: "Below 10 (lakh)", value: "<1000000" },
  { label: "10 Lakhs", value: "1000000" },
  { label: "25 Lakhs", value: "2500000" },
  { label: "50 Lakhs", value: "5000000" },
  { label: "100 Lakhs", value: "10000000" },
  { label: "200 Lakhs", value: "20000000" },
  { label: "300 Lakhs", value: "30000000" },
  { label: "400 Lakhs", value: "40000000" },
  { label: "500 Lakhs", value: "50000000" },
  { label: "600 Lakhs", value: "60000000" },
  { label: "700 Lakhs", value: "70000000" },
  { label: "800 Lakhs", value: "80000000" },
  { label: "900 Lakhs", value: "90000000" },
  { label: "1,000 Lakhs", value: "100000000" },
  { label: "2,000 Lakhs", value: "200000000" },
  { label: "3,000 Lakhs", value: "300000000" },
  { label: "4,000 Lakhs", value: "400000000" },
  { label: "5,000 (lakh) and Above", value: ">500000000" },
];

export const priceToData = [
  { label: "25 Lakhs", value: "2500000" },
  { label: "50 Lakhs", value: "5000000" },
  { label: "100 Lakhs", value: "10000000" },
  { label: "200 Lakhs", value: "20000000" },
  { label: "300 Lakhs", value: "30000000" },
  { label: "400 Lakhs", value: "40000000" },
  { label: "500 Lakhs", value: "50000000" },
  { label: "600 Lakhs", value: "60000000" },
  { label: "700 Lakhs", value: "70000000" },
  { label: "800 Lakhs", value: "80000000" },
  { label: "900 Lakhs", value: "90000000" },
  { label: "1,000 Lakhs", value: "100000000" },
  { label: "2,000 Lakhs", value: "200000000" },
  { label: "3,000 Lakhs", value: "300000000" },
  { label: "4,000 Lakhs", value: "400000000" },
  { label: "5,000 Lakhs", value: "500000000" },
];

export const personData = {
  avatar: "",
  id: "",
  fullName: "",
  phone: "",
  phoneOther: "",
  email: "",
  address: "",
  note: "",
  city: "",
  state: "",
  stateId: "",
  country: "",
  countryId: null,
};

export const shopAddress = `12 Mahesak 3 Alley Suriyawong, Bangrak, Bangkok 10500, Thaialand. Ph: +66 61 562 2455`;
