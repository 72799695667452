import React, { useContext } from "react";
import { Col, Image, Table } from "react-bootstrap";
import { QRView } from "../../../../components";
import { LangContext } from "../../../../context";
import {
  getDiscountAmountByPercentage,
  handleDateFormat,
  numberFormat,
  totalAmount,
} from "../../../../services";
import InvoiceHeader from "../../../../components/pdf/InvoiceHeader";
import { RES_API_URL } from "../../../../services/api";

const Invoice = ({
  data,
  showDataCheck,
}: {
  data: any;
  showDataCheck: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <div className="bg-card">
      <style type="text/css" media="print">
        {`
          .bg-card{
              box-shadow: none !important;
            }
        `}
      </style>
      {/* <section className="text-center mb-4">
        <img src="images/mr.spinel.png" alt="no_found" width={300} />
        <span className="d-block my-2">
          <small>78 street, Bet; 34 x 35 street, Mandalay | </small>{" "}
          <small>Ph : 09-43200898 , 09-799075549 , 09-425291390</small>
        </span>
      </section> */}
      <InvoiceHeader />

      <div className="flex-between align-items-center">
        <div style={{ width: "50%" }}>
          <span className="d-block mb-2">
            <small className="text-muted">Customer</small> :{" "}
            <small>{data?.gems_buyer?.fullName || "-"}</small>
          </span>
          <span className="d-block mb-2">
            <small className="text-muted">Phone</small> :{" "}
            <small>{data?.gems_buyer?.phone || "-"}</small>
          </span>
          {data?.gems_buyer?.address && (
            <span className="d-block mb-2">
              <small className="text-muted">{translate("address")}</small> :{" "}
              <small>{data?.gems_buyer?.address || "-"} </small>
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          <QRView text={data?.invoiceNumber} width={50} />
          <div className="ms-3 d-flex flex-column align-items-end">
            <span className="d-block mb-2 mt-1">
              <small>{data?.invoiceNumber}</small>
            </span>
            <span className="d-block mb-2">
              <small className="text-muted">{translate("date")}</small> :{" "}
              <small>{handleDateFormat(data?.createdAt)}</small>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <h6 className="fw-bold border text-center py-2 px-4">
          SALE GEM INVOICE
        </h6>
      </div>

      <Table responsive striped bordered className="bg-light border mt-3 mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th style={{ width: "18%" }}>Code</th>
            <th>Item</th>
            <th>Stock Type</th>
            <th>Stone Type</th>
            <th>Buying State</th>
            <th>Mining Location</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">
              <span className="flex-center">1</span>
            </td>
            <td>{data?.gemstones?.sku}</td>
            <td>{data?.gemstones?.itemName}</td>
            <td>{data?.gemstones?.stockType}</td>
            <td>{data?.gemstones?.stoneType || "N/A"}</td>
            <td>{data?.gemstones?.buyingState || "N/A"}</td>
            <td>{data?.gemstones?.miningLocation || "N/A"}</td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mb-4 mt-4">
        <div className="d-flex align-items-center">
          <div className="receit-item-image">
            {data?.gemstones?.images ? (
              <Image
                src={`${RES_API_URL}${data?.gemstones?.images?.url}`}
                alt="no_found"
              />
            ) : (
              <Image src={"images/no-image.png"} />
            )}
          </div>
          <div className="ms-3">
            <small className="xs-label d-block mb-2 fw-bold">
              {data?.gemstones?.itemName}
            </small>
            <small className="xs-label d-block mb-2 text-muted">
              {data?.gemstones?.sku}
            </small>
          </div>
        </div>

        {data?.isGift ? (
          <></>
        ) : (
          <Col md={4} className="me-2 w-25">
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">Subtotal</small>
              <small>{numberFormat(data?.netPrice)}</small>
            </div>
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">
                Discount{" "}
                {data?.discountType === "by_percentage" &&
                  `(${data?.discountValue}%)`}
              </small>
              <small>
                {data?.discountValue && data?.discountValue !== "0" && "-"}{" "}
                {data?.discountValue
                  ? numberFormat(
                      data?.discountType === "by_percentage"
                        ? getDiscountAmountByPercentage(data?.netPrice, data)
                        : data?.discountValue
                    )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top">
              <small className="text-muted d-block">
                Total ({data?.currency})
              </small>
              <small>{numberFormat(totalAmount(data?.netPrice, data))}</small>
            </div>
          </Col>
        )}
      </div>

      {data?.saleNotes && showDataCheck.showSaleNotes && (
        <span className="d-block">
          <small className="text-muted">Notes </small> :{" "}
          <small>{data?.saleNotes}</small>
        </span>
      )}

      <small className="text-danger text-center d-block mb-3 mt-5">
        {translate("receipt-tip")}
      </small>
      <small className="text-muted text-center d-block">
        {translate("thankForYourPurchase")}
      </small>
    </div>
  );
};

export default Invoice;
