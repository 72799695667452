import { CartContext, CartProvider } from "./Cart";
import { LangContext, LangProvider } from "./Lang";
import { PageContext, PageProvider } from "./Page";
import { SetCartContext, SetCartProvider } from "./SetCart";
import { VariableContext, VariableProvider } from "./Variable";

export function Context({ children }: { children: any }) {
  return (
    <VariableProvider>
      <LangProvider>
        <PageProvider>
          <CartProvider>
            <SetCartProvider>{children}</SetCartProvider>
          </CartProvider>
        </PageProvider>
      </LangProvider>
    </VariableProvider>
  );
}

export {
  LangContext,
  PageContext,
  VariableContext,
  CartContext,
  SetCartContext,
};
