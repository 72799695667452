import { useState } from "react";
import { ThumbsnailImage } from "../../../components";
import { handleShow, numberFormat } from "../../../services";
import { getDataService, jewelleryRoute } from "../../../services/api";
import ItemModal from "./ItemModal";

const ItemCard = ({ item }: { item: any }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const handelDetailAction = () => {
    getDataService(jewelleryRoute(item.id), setLoading, setData, null, 0);
    handleShow(setShow);
  };

  return (
    <>
      <div className="pos-item mb-4" onClick={handelDetailAction}>
        <div className="pos-item-image">
          <ThumbsnailImage item={item} />
        </div>
        <div
          className="p-3 d-flex flex-column justify-content-between"
          // style={{ height: "8rem" }}
        >
          <small className="d-block xs-label mb-2 text-truncate">
            {item?.itemName}
          </small>
          <small className="xs-label text-muted d-block fw-bold mb-1">
            {item?.sku}
          </small>
          <small className="xs-label d-block mb-1 text-muted">
            {item?.jewelleryType}
          </small>
          <small className="d-block fw-bold mt-2">
            {item?.price?.sellingPrice &&
              `${item?.price?.currency} ${numberFormat(
                item?.price?.sellingPrice
              )}`}
          </small>
        </div>
      </div>

      <ItemModal
        show={show}
        setShow={setShow}
        item={data}
        jewellery={item}
        loading={loading}
      />
    </>
  );
};

export default ItemCard;
