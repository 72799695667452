import { useContext, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Lang, Layout, TableLayout } from "../../../components";
import { IconDelete } from "../../../components/widgets/Icons";
import { PageContext } from "../../../context/Page";
import { inventoryTypes } from "../../../data";
import { getNumber, numberFormat } from "../../../services";
import {
  actionData,
  deleteService,
  getMethod,
  responseService,
} from "../../../services/api";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    setLoading(true);
    actionData(
      getMethod,
      `return/sets/${skip * limit}/${limit}?sort=desc`
    ).then((result) => {
      setData(result.data);
      setPage &&
        setPage({
          current: skip,
          total: result.count,
        });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleResponse = (result: any) => {
    responseService(result, handleGetData, null, null, null, setLoading);
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.itemName,
      null,
      `return/jewellery/${item?.jewelleryId}`,
      handleResponse
    );
  };

  return (
    <Layout title="Jewellery set return lists">
      {/* <div className="mb-4">
                <SearchInput />
            </div> */}

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>
              <span className="flex-center">
                <Lang label="no." />
              </span>
            </th>
            <th>Item</th>
            <th>Accepted by</th>
            <th>Refunded by</th>
            <th>Refund amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  <div>
                    <div>{item?.itemName}</div>
                    <small className="text-muted mt-1 d-block">
                      {item?.sku}
                    </small>
                    <div>
                      <Badge className="bg-success me-2 mt-2">
                        {
                          inventoryTypes.filter(
                            (type: any) => type.key === item?.inventoryType
                          )[0]?.label
                        }
                      </Badge>
                    </div>
                  </div>
                </span>
              </td>
              <td>{item.acceptedPerson}</td>
              <td>{item.refundedPerson}</td>
              <td>
                <span className="flex-end">
                  {item?.currency} {numberFormat(item.refundAmount)}
                </span>
              </td>
              <td>
                <span className="flex-center">
                  {" "}
                  <Button
                    className="bg-danger btn-sm"
                    onClick={() => handleDelete(item)}
                  >
                    {" "}
                    <IconDelete />{" "}
                  </Button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>
    </Layout>
  );
};

export default Index;
