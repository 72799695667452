import React from "react";
import { Form } from "react-bootstrap";
import { GoBack, Layout } from "../../../components";
import GemInfo from "./cpn/GemInfo";
import Price from "./cpn/Price";
import Stage from "./cpn/Stage";

const Index = () => {
  const [formData, setFormData] = React.useState({
    step: 1,
    basedFormula: "rate",
    published: true,
    forSale: false,
    gemInfo: {
      itemName: "",
      // sku: "",
      stoneType: "",
      stockType: "",
      buyingState: "",
      miningLocation: "",
      outletName: "",
      gemsOwnerId: null,
      gemsBrokerId: null,
      inventoryType: "shop_inventory",
      published: true,
      purchaseDate: "",
      notes: "",
      serviceItem: false,
      userLoginId: null,
    },
    stage: {
      numberOfPcs: "",
      dimension: "",
      weight: "",
      weightUnit: "carat",
      color: "",
      clarity: "",
      shapeAndCut: "",
      treatment: [],
      stageStaus: "Initial",
      pcsOneWeight: "",
      pcsTwoWeight: "",
      size: "",
      sizeUnit: "pcs_per_cts",
    },
    price: {
      sharedInvestorsQty: null,
      interestFrom: "",
      interestRate: "",
      investedUSDRate: "",
      currency: "",
      buyingPrice: "",
      otherCost: "",
      paymentDueDate: "",
      sellingPrice: "",
      sellingPricePerCts: "",
    },
  });

  return (
    <Layout title="Add  gem">
      <div className="flex-between mb-4">
        <GoBack label="Gems" />

        <div className=" d-flex align-items-center">
          {formData?.gemInfo?.inventoryType === "private_item" && (
            <Form.Group className="border-none me-3">
              <Form.Check
                type="checkbox"
                label="For sale"
                id="forSale"
                checked={formData?.forSale}
                onChange={(e: any) =>
                  setFormData({ ...formData, forSale: e.target.checked })
                }
              />
            </Form.Group>
          )}

          <Form.Group className="border-none">
            <Form.Check
              type="checkbox"
              label="Publish gems"
              id="published"
              checked={formData?.published}
              onChange={(e: any) =>
                setFormData({ ...formData, published: e.target.checked })
              }
            />
          </Form.Group>
        </div>
      </div>

      <div>
        <GemInfo formData={formData} setFormData={setFormData} />
        <Stage formData={formData} setFormData={setFormData} />
        <Price formData={formData} setFormData={setFormData} />
      </div>
    </Layout>
  );
};

export default Index;
