import React, { useContext, useEffect, useState } from "react";
import { Lang, Layout } from "../../../components";
import { PageContext } from "../../../context/Page";
import {
  customRouteTwo,
  calibratedDetailRoute,
  calibratedRoute,
  getDataService,
} from "../../../services/api";
import { Count, Data, Header } from "./cpn";
import { getStoreUser } from "../../../services";

const Index = () => {
  const [countData, setCountData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = React.useState("");
  const [type, setType] = React.useState("all");
  const [page, setPage] = useContext(PageContext);
  const [inventoryType, setInventoryType] = React.useState("shop_inventory");
  const [stoneType, setStoneType] = React.useState("");
  const [shapeAndCut, setShapeAndCut] = React.useState("");

  const handleGetCountData = (skip: number) => {
    getDataService(
      calibratedDetailRoute,
      setLoading,
      setCountData,
      setPage,
      skip
    );
  };

  const handleGetData = (
    skip: number,
    type: any,
    inventoryType: any,
    keyword: any,
    stoneType: any,
    shapeAndCut: any
  ) => {
    getDataService(
      customRouteTwo(
        calibratedRoute,
        skip,
        type,
        keyword,
        getStoreUser()?.userId,
        inventoryType,
        null,
        null,
        stoneType,
        shapeAndCut
      ),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  useEffect(() => {
    handleGetCountData(page.current);
    handleGetData(
      page.current,
      type,
      inventoryType,
      "",
      stoneType,
      shapeAndCut
    );
    // eslint-disable-next-line
  }, []);

  const handleResponse = () => {
    handleGetCountData(page.current);
    handleGetData(
      page.current,
      type,
      inventoryType,
      "",
      stoneType,
      shapeAndCut
    );
  };

  return (
    <Layout title={<Lang label="calibratedGems" />}>
      <Count data={countData} />
      <Header
        type={type}
        keyword={keyword}
        stoneType={stoneType}
        shapeAndCut={shapeAndCut}
        inventoryType={inventoryType}
        handleSetKeyword={setKeyword}
        handleSetType={setType}
        handleStoneType={setStoneType}
        handleShapeAndCut={setShapeAndCut}
        handleSetInventoryType={setInventoryType}
        handleGetData={(v: any, inv: any, k: any, st: any, sc: any) =>
          handleGetData(page.current, v, inv, k, st, sc)
        }
      />
      <Data
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={(page: any) =>
          handleGetData(
            page,
            type,
            inventoryType,
            keyword,
            stoneType,
            shapeAndCut
          )
        }
        handleResponse={handleResponse}
      />
    </Layout>
  );
};

export default Index;
