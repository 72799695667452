import { Image, Navbar } from "react-bootstrap";
import { useContext } from "react";
import AvatarDropdown from "../widgets/AvatarDropdown";
import { VariableContext } from "../../context/Variable";
import { IconMenu } from "../widgets/Icons";
import { LangContext } from "../../context/Lang";

function Header({ title }: { title: string }) {
  const [variable, setVariable] = useContext(VariableContext);
  const {
    state: { language },
    dispatch: { setLanguage },
  } = useContext(LangContext);

  const chooseLanguageHandler = (value: string) => {
    setLanguage(value);
  };

  const toggle = () => {
    setVariable({ ...variable, isSidebarActive: !variable?.isSidebarActive });
  };

  return (
    <Navbar className="header bg-white shadow-sm px-4  d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <span className="primary-text fs-10 me-3 cursor" onClick={toggle}>
          <IconMenu />
        </span>
        <h6 className="m-0 fw-bold">{title}</h6>
      </div>
      <div className="d-flex align-items-center">
        {language === "EN" ? (
          <label className="cursor" onClick={() => chooseLanguageHandler("MM")}>
            <Image
              src={"images/lang/myanmar-circle.svg"}
              width="25"
              className="me-2"
            />
          </label>
        ) : (
          <label className="cursor" onClick={() => chooseLanguageHandler("EN")}>
            <Image
              src={"images/lang/flag-eng-vn.svg"}
              width="25"
              className="me-2"
            />
          </label>
        )}

        <AvatarDropdown />
      </div>
    </Navbar>
  );
}

export default Header;
