import React, { useState, createContext } from "react";

interface Variable {
  isSidebarActive: boolean;
  isList: boolean;
  isSKU: boolean;
  isJewelleryFilter: boolean;
  jewelleryFilterData: object;
  jewelleryFilterActionData: object;
  isJewelleryPOSFilter: boolean;
  jewelleryPOSFilterData: object;
  jewelleryPOSFilterActionData: object;
  inventoryJewelleryType: string;
}

const contextDefaultValues: Variable = {
  isSidebarActive: true,
  isList: false,
  isSKU: false,
  isJewelleryFilter: false,
  jewelleryFilterData: {},
  jewelleryFilterActionData: {},
  isJewelleryPOSFilter: false,
  jewelleryPOSFilterData: {},
  jewelleryPOSFilterActionData: {},
  inventoryJewelleryType: "shop_inventory",
};

const VariableContext = createContext<any>(contextDefaultValues);

function VariableProvider(props: any) {
  const [variable, setVariable] = useState<Variable>(contextDefaultValues);

  return (
    <VariableContext.Provider value={[variable, setVariable]}>
      {props.children}
    </VariableContext.Provider>
  );
}

export { VariableContext, VariableProvider };
