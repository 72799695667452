import React from "react";
import { Button, Form } from "react-bootstrap";
import { actionData, getMethod } from "../../../../services/api";
import { FormFieldService } from "../../../../components";
import { toast } from "react-toastify";

interface StageProps {
  formData: any;
  setFormData: (data: any) => void;
}

const Stage: React.FC<StageProps> = ({ formData, setFormData }) => {
  const [weightUnits, setWeigthUnits] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [clarities, setClarities] = React.useState([]);
  const [shapes, setShapes] = React.useState([]);
  const [treatments, setTreatments] = React.useState([]);
  const fields = [
    // {
    //   id: "numberOfPcs",
    //   label: "Number Of pcs *",
    //   value: formData.numberOfPcs,
    //   inputType: "number",
    // },
    { id: "dimension", label: "Dimension", value: formData.dimension },
    {
      id: "weight",
      label: "Weight *",
      value: formData.weight,
      inputType: "number",
      type: "select-group",
      secondId: "weightUnit",
      secondValue: formData.weightUnit,
      data: weightUnits,
      wantString: true,
    },
    {
      id: "color",
      label: "Color",
      value: formData.color,
      type: "select",
      data: colors,
      wantString: true,
    },
    {
      id: "clarity",
      label: "Clarity",
      value: formData.clarity,
      type: "select",
      data: clarities,
      wantString: true,
    },
    {
      id: "shapeAndCut",
      label: "Shape and cut",
      value: formData.shapeAndCut,
      type: "select",
      data: shapes,
      wantString: true,
    },
    // {
    //   id: "treatment",
    //   label: "Treatment",
    //   value: formData.treatment,
    //   type: "select",
    //   data: treatments,
    //   wantString: true,
    // },
  ];

  React.useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, `cali-gems-weight-unit`).then((result) => {
      setWeigthUnits(result.data);
    });

    actionData(getMethod, `cali-gems-color`).then((result) => {
      setColors(result.data);
    });

    actionData(getMethod, `cali-gems-clarity`).then((result) => {
      setClarities(result.data);
    });

    actionData(getMethod, `cali-gems-shape-and-cut`).then((result) => {
      setShapes(result.data);
    });

    actionData(getMethod, `cali-gems-treatment`).then((result) => {
      setTreatments(result.data);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.type === "text" ? target.value : target.value;
    }

    setFormData({
      ...formData,
      stage: {
        ...formData.stage,
        [name]: value,
      },
    });
  };

  const handleCheckboxChange = (evt: any) => {
    let temp: any = [...formData?.stage?.treatment];
    const checked = evt.target.checked;
    const value = evt.target.value;

    if (checked) {
      temp.push(value);
      setFormData({
        ...formData,
        stage: {
          ...formData.stage,
          treatment: temp,
        },
      });
    } else {
      temp = temp.filter((v: any) => v !== value);
    }

    setFormData({
      ...formData,
      stage: {
        ...formData.stage,
        treatment: temp,
      },
    });
  };

  const handleNext = () => {
    const stage = formData?.stage;

    if (stage?.weight) {
      setFormData({
        ...formData,
        step: 3,
      });
    } else {
      toast.error("Please fill data!");
    }
  };

  return (
    <div className={`${formData?.step === 2 ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>Stage information (Step 2)</div>
      </div>

      <div className="bg-card mt-3">
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
        />

        <Form.Group>
          <Form.Label className="d-block">Treatment</Form.Label>
          {treatments?.map((item: any, index: number) => (
            <Form.Check
              key={index}
              inline
              label={item?.label}
              name="treatment[]"
              type="checkbox"
              id={`treatment-${index}`}
              value={item?.label}
              checked={formData?.stage?.treatment?.includes(item?.label)}
              onChange={handleCheckboxChange}
            />
          ))}
        </Form.Group>
      </div>

      <div className="mt-3 d-flex justify-content-between">
        <Button
          className="text-white bg-grad px-4"
          onClick={() => setFormData({ ...formData, step: 1 })}
        >
          Previous
        </Button>

        <Button className="text-white bg-grad px-4" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default Stage;
