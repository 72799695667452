import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  IconAdd,
  IconDelete,
  IconEdit,
} from "../../../../components/widgets/Icons";
import {
  handelGetPersons,
  handleClose,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import {
  actionData,
  currencyRoute,
  dataService,
  deleteService,
  getMethod,
  jewelleryRoute,
  postMethod,
  responseService,
} from "../../../../services/api";
import { CustomModalService, NoData } from "../../../../components";

const Prices = ({
  product,
  handleGetData,
}: {
  product: any;
  handleGetData: any;
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persons, setPersons] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [formData, setFormData] = useState({
    interestFrom: "",
    interestFromDefaultValue: "",
    interestRate: "",
    investedGoldPriceRate: "",
    investedUSDRate: "",
    currency: "",
    otherCost: "",
    sellingPrice: "",
  });

  useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    handelGetPersons("", setPersons);
    actionData(getMethod, currencyRoute).then((result) => {
      const temp: any = [];
      result.data?.map((d: any) =>
        temp.push({
          label: d?.value,
          value: d?.value,
        })
      );
      setCurrencies(temp);
    });
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const getTotalGoldCost = () => {
    let total = 0;
    if (product?.gold && product?.gold.length > 0) {
      product?.gold.forEach((item: any) => {
        total = total + parseInt(item.totalGoldCost || 0);
      });
    }
    return total;
  };

  const getTotalGemCost = () => {
    let total = 0;
    if (product?.gemstones && product?.gemstones.length > 0) {
      product?.gemstones.forEach((item: any) => {
        total = total + parseInt(item.totalGemsCost || 0);
      });
    }
    return total;
  };

  const getTotalDiamondCost = () => {
    let total = 0;
    if (product?.diamonds && product?.diamonds.length > 0) {
      product?.diamonds.forEach((item: any) => {
        total = total + parseInt(item.totalDiamondsCost || 0);
      });
    }
    return total;
  };

  const getAllTotalCost = () => {
    return (
      getTotalGoldCost() +
      getTotalGemCost() +
      getTotalDiamondCost() +
      parseInt(product?.price?.otherCost || 0)
    );
  };

  const fields = [
    {
      id: "interestFrom",
      label: "Interest from",
      value: formData.interestFrom,
      type: "suggestion-select",
      data: persons,
      defaultLabel: formData.interestFromDefaultValue,
      hide: true,
    },
    {
      id: "interestRate",
      label: "Interest rate",
      value: formData.interestRate,
      hide: true,
    },
    {
      id: "investedGoldPriceRate",
      label: "Invested gold price rate",
      value: formData.investedGoldPriceRate,
      inputType: "number",
      wantString: true,
    },
    {
      id: "investedUSDRate",
      label: "Invested USD rate",
      value: formData.investedUSDRate,
      inputType: "number",
      wantString: true,
    },
    {
      id: "currency",
      label: "Currency",
      value: formData.currency,
      type: "select",
      data: currencies,
      wantString: true,
      keyType: "value",
    },
    {
      id: "otherCost",
      label: "Jewellery cost",
      value: formData.otherCost,
      inputType: "number",
      wantString: true,
    },
    {
      id: "sellingPrice",
      label: "Selling price",
      value: formData.sellingPrice,
      inputType: "number",
      wantString: true,
    },
    {
      id: "allTotalCost",
      label: "All total cost",
      value: numberFormat(
        getTotalGoldCost() +
          getTotalGemCost() +
          getTotalDiamondCost() +
          (formData.otherCost ? parseInt(formData.otherCost) : 0)
      ),
      disable: true,
    },
  ];

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = () => {
    setFormData({
      interestFrom: product?.price?.interest_from?.id || "",
      interestFromDefaultValue: product?.price?.interest_from?.fullName || "",
      interestRate: product?.price?.interestRate || "",
      investedGoldPriceRate: product?.price?.investedGoldPriceRate || "",
      investedUSDRate: product?.price?.investedUSDRate || "",
      currency: product?.price?.currency || "",
      otherCost: product?.price?.otherCost || "",
      sellingPrice: product?.price?.sellingPrice || "",
    });
    handleShow(setShow);
  };

  const handleSubmit = (method: string) => {
    setLoading(true);
    dataService(
      method,
      formData,
      [],
      `${jewelleryRoute(
        method === postMethod ? product.id : product?.price?.id
      )}/price`
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleDelete = () => {
    deleteService(
      "prices",
      null,
      `jewellery/${product?.price?.id}/price`,
      handleResponse
    );
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const totalCost = [
    {
      label: "Total gold cost",
      value:
        product?.gold.length > 0
          ? `${product?.price?.currency} ${numberFormat(getTotalGoldCost())} `
          : "-",
    },
    {
      label: "Total diamonds cost",
      value:
        product?.diamonds.length > 0
          ? `${product?.price?.currency}  ${numberFormat(
              getTotalDiamondCost()
            )}`
          : "-",
    },
    {
      label: "Total gems cost",
      value:
        product?.gemstones.length > 0
          ? `${product?.price?.currency} ${numberFormat(getTotalGemCost())}`
          : "-",
    },
    {
      label: "Jewellery cost",
      value: product?.price?.otherCost
        ? `${product?.price?.currency} ${numberFormat(
            product?.price?.otherCost
          )}`
        : "-",
    },
    {
      label: "All total cost",
      value:
        getAllTotalCost() > 0
          ? `${product?.price?.currency} ${numberFormat(getAllTotalCost())}`
          : "-",
    },
    {
      label: "Initial selling price",
      value: product?.price?.initialSellingPrice
        ? `${product?.price?.currency} ${numberFormat(
            product?.price?.initialSellingPrice
          )}`
        : "-",
    },
    {
      label: "Selling price",
      value: product?.price?.sellingPrice
        ? `${product?.price?.currency} ${numberFormat(
            product?.price?.sellingPrice
          )}`
        : "-",
    },
  ];

  const prices = [
    // {
    //   label: "Interest from",
    //   value: product?.price?.interest_from?.fullName || "-",
    // },
    // { label: "Interest rate", value: product?.price?.interestRate || "-" },
    {
      label: "Invested gold price rate",
      value: product?.price?.investedGoldPriceRate
        ? `${numberFormat(product?.price?.investedGoldPriceRate)}`
        : "-",
    },
    {
      label: "Invested USD rate",
      value: product?.price?.investedUSDRate
        ? `${numberFormat(product?.price?.investedUSDRate)}`
        : "-",
    },
    { label: "Currency", value: product?.price?.currency || "-" },
  ];

  return (
    <section className="bg-card my-4">
      <section className="flex-between mb-4">
        <h6>Prices</h6>
        <div>
          <Button className="text-white bg-grad" onClick={showForm}>
            {product?.price?.id ? (
              <>
                <IconEdit /> <span className="ms-2">Edit prices</span>
              </>
            ) : (
              <>
                <IconAdd /> <span className="ms-2">Add prices</span>
              </>
            )}
          </Button>
          {product?.price?.id && (
            <Button
              className="text-white bg-danger ms-2"
              onClick={handleDelete}
            >
              <IconDelete /> <span className="ms-2">Delete prices</span>
            </Button>
          )}
        </div>
      </section>

      {product?.price?.id ? (
        <Row>
          <Col>
            <Table responsive striped className="mb-0 border">
              <tbody>
                {prices.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="text-muted " style={{ width: "50%" }}>
                      {item.label}
                    </td>
                    <td style={{ width: "50%" }}>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table responsive striped className="mb-0 border">
              <tbody>
                {totalCost.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="text-muted " style={{ width: "50%" }}>
                      {item.label}
                    </td>
                    <td style={{ width: "50%" }}>
                      <span className="flex-end">{item.value}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <NoData noImage />
      )}

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={product?.price?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        lg
      />
    </section>
  );
};

export default Prices;
