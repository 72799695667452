import { useState } from "react";
import { Col, Image, Row, Table } from "react-bootstrap";
import { handleDateTimeFormat, numberFormat } from "../../../../services";
import Lightbox from "react-image-lightbox";
import { inventoryTypes } from "../../../../data";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

function Info({ product }: { product: any }) {
  const images = product?.images;
  const [imageView, setImageView] = useState({ photoIndex: 0, isOpen: false });
  const handleCloseImageModal = () =>
    setImageView({ ...imageView, isOpen: false });
  const inventoryTypeLabel = inventoryTypes.filter(
    (item: any) => item.key === product?.inventoryType
  )[0]?.label;

  const details = [
    { label: "Name", value: product?.itemName },
    { label: "SKU", value: product?.sku },
    { label: "Jewellery type", value: product?.jewelleryType || "-" },
    { label: "Jewellery style", value: product?.jewelleryStyle || "-" },
    {
      label: "Jewellery weight",
      value: product?.jewelleryWeight
        ? `${product?.jewelleryWeight} ${product?.jewelleryWeightUnit}`
        : "-",
    },
    { label: "Outlet name", value: product?.outletName || "-" },
    { label: "Inventory type", value: inventoryTypeLabel || "-" },
    // { label: "Inventory status", value: <Badge className={product?.availability === "Available" ? "bg-grad" : "bg-danger"}>{product?.availability}</Badge> },
  ];

  const otherDetails = [
    {
      label: "Bangle width",
      value: product?.width || "-",
      hide: !(product?.jewelleryType === "Bangles"),
    },
    {
      label: "Ring width",
      value: product?.width || "-",
      hide: !(product?.jewelleryType === "Rings"),
    },
    {
      label: "Bracelet length",
      value: product?.length || "-",
      hide: !(product?.jewelleryType === "Bracelets"),
    },
    {
      label: "Necklace length",
      value: product?.length || "-",
      hide: !(product?.jewelleryType === "Necklaces"),
    },
    // { label: "Inventory type", value: inventoryTypeLabel || "-" },
    {
      label: "Jewellery owner",
      value: product?.jewellery_owner?.fullName || "-",
      hide: !(
        product?.inventoryType === "private_item" ||
        product?.inventoryType === "service_item"
      ),
    },
    { label: "Published", value: product?.published ? "Yes" : "No" },
    {
      label: product?.inventoryType === "private_item" ? "For sale" : "",
      value: product?.forSale ? "Yes" : "No",
    },
    { label: "Description", value: product?.description || "-" },
    {
      label: "Purchase date",
      value: product?.purchaseDate
        ? handleDateTimeFormat(product?.purchaseDate)
        : "-",
    },
    {
      label: "Selling date",
      value: product?.sellingDate
        ? handleDateTimeFormat(product?.sellingDate)
        : "-",
    },
    // { label: "Created by", value: product?.sellingDate ? handleDateTimeFormat(product?.sellingDate) : "-" },
    { label: "Created date", value: handleDateTimeFormat(product?.createdAt) },
  ];

  const thumbsnailImage = (item: any) => {
    let tempImages = item.images;
    let isExist = false;
    let tempDiv = (
      <img src="/images/no-image.png" alt="Thumbnail" className="float-right" />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="no img"
              height={250}
              className="object-fit float-right cursor"
              onClick={() => {
                setImageView({
                  ...imageView,
                  photoIndex: 0,
                  isOpen: true,
                });
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="no img"
            height={250}
            className="object-fit float-right cursor"
            onClick={() => {
              setImageView({
                ...imageView,
                photoIndex: 0,
                isOpen: true,
              });
            }}
          />
        );
      }
    }

    return tempDiv;
  };

  return (
    <section className="bg-card my-4">
      {imageView.isOpen && (
        <Lightbox
          mainSrc={`${RES_API_URL}${images[imageView.photoIndex].url}`}
          nextSrc={`${RES_API_URL}${images[(imageView.photoIndex + 1) % images.length].url
            }`}
          prevSrc={`${RES_API_URL}${images[(imageView.photoIndex + images.length - 1) % images.length]
            .url
            }`}
          imageTitle={`${product?.itemName} (${product?.sku})`}
          imageCaption={`${product?.jewelleryType} / ${product?.jewelleryWeight
            } ${product?.jewelleryWeightUnit} / ${numberFormat(
              product?.price?.sellingPrice
            )} ${product?.price?.currency}`}
          onCloseRequest={handleCloseImageModal}
          onMovePrevRequest={() =>
            setImageView({
              ...imageView,
              photoIndex:
                (imageView.photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            setImageView({
              ...imageView,
              photoIndex: (imageView.photoIndex + 1) % images.length,
            })
          }
        />
      )}

      <Row>
        <Col md={3}>
          <section className="detail-image object-fit">
            {product && thumbsnailImage(product)}
          </section>

          <Row>
            {product && product?.images && product?.images.length > 0 && (
              <>
                {product?.images.map((item: any, index: number) => (
                  <Col md={3} key={index} className="mt-3">
                    <section className="product-card-small-image cursor ">
                      <Image
                        src={`${RES_API_URL}${item.url}`}
                        onClick={() => {
                          setImageView({
                            ...imageView,
                            photoIndex: index,
                            isOpen: true,
                          });
                        }}
                      />
                    </section>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Col>
        <Col>
          <Table responsive striped className="mb-0 border">
            <tbody>
              {details.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="text-muted ">{item.label}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table responsive striped className="mb-0 border">
            <tbody>
              {otherDetails.map(
                (item: any, index: number) =>
                  !item.hide &&
                  item?.label && (
                    <tr key={index}>
                      <td className=" text-muted ">{item.label}</td>
                      <td>{item.value}</td>
                    </tr>
                  )
              )}
            </tbody>
          </Table>

          {product?.notes && (
            <div className="p-2 rounded-3 bg-light mt-4">
              <small className="fw-bold text-muted">Remarks</small>
              <br />
              <small className="wrap-text">{product?.notes}</small>
            </div>
          )}
        </Col>
      </Row>
    </section>
  );
}

export default Info;
