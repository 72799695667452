import { Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";

export function StockInfo({
  total,
  inStock,
  onHold,
  soldOut,
}: {
  total: number;
  inStock: number;
  onHold: number;
  soldOut: number;
}) {
  return (
    <div className="shadow-sm p-4 text-center mb-4 bg-white">
      <h5 className="fw-bold mb-2">Jewelleries ({total})</h5>
      <Row>
        <Col md={4}>
          <InstockDonut inStock={inStock} total={total - inStock} />
          <h6 className="fw-bold mt-3">In stock ({inStock})</h6>
          <label>
            {parseFloat(
              ((inStock / total) * 100).toFixed(2).replace(/\.?0+$/, "")
            )}{" "}
            %
          </label>
        </Col>
        <Col md={4}>
          <OnHoldDonut onHold={onHold} total={total - onHold} />
          <h6 className="fw-bold mt-3">On hold ({onHold})</h6>
          <label>
            {parseFloat(
              ((onHold / total) * 100).toFixed(2).replace(/\.?0+$/, "")
            )}{" "}
            %
          </label>
        </Col>
        <Col md={4}>
          <SoldoutDonut soldOut={soldOut} total={total - soldOut} />
          <h6 className="fw-bold mt-3">Sold out ({soldOut})</h6>
          <label>
            {parseFloat(
              ((soldOut / total) * 100).toFixed(2).replace(/\.?0+$/, "")
            )}{" "}
            %
          </label>
        </Col>
      </Row>
    </div>
  );
}

function InstockDonut({ total, inStock }: { total: number; inStock: number }) {
  return (
    <>
      <Col lg={12}>
        <Doughnut
          data={{
            labels: ["In stock", "Others"],
            datasets: [
              {
                label: "Jewelleries",
                data: [inStock, total],
                backgroundColor: ["#55af3e", "#dbdbdb"],
                borderWidth: 1,
                offset: 3,
              },
            ],
          }}
          height={150}
          width={150}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                // title: {
                //     display: true,
                //     text: 'Legend Title',
                // },
              },
            },
          }}
        />
      </Col>
    </>
  );
}

function OnHoldDonut({ total, onHold }: { total: number; onHold: number }) {
  return (
    <>
      <Col lg={12}>
        <Doughnut
          data={{
            labels: ["On Hold", "Others"],
            datasets: [
              {
                label: "Jewelleries",
                data: [onHold, total],
                backgroundColor: ["#55af3e", "#dbdbdb"],
                borderWidth: 1,
                offset: 3,
              },
            ],
          }}
          height={150}
          width={150}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                // title: {
                //     display: true,
                //     text: 'Legend Title',
                // },
              },
            },
          }}
        />
      </Col>
    </>
  );
}

function SoldoutDonut({ total, soldOut }: { total: number; soldOut: number }) {
  return (
    <>
      <Col lg={12}>
        <Doughnut
          data={{
            labels: ["Sold out", "Others"],
            datasets: [
              {
                label: "Jewelleries",
                data: [soldOut, total],
                backgroundColor: ["#BA2D3A", "#dbdbdb"],
                borderWidth: 1,
                offset: 3,
              },
            ],
          }}
          height={150}
          width={150}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                // title: {
                //     display: true,
                //     text: 'Legend Title',
                // },
              },
            },
          }}
        />
      </Col>
    </>
  );
}
