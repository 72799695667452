import { useContext, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomToggle, NoData, Thumbsnail } from "../../../components";
import { IconEdit } from "../../../components/widgets/Icons";
import { SetCartContext } from "../../../context";
import {
  disableScroll,
  getStoreUser,
  handleDateFormat,
  handleShow,
  numberFormat,
} from "../../../services";
import {
  dataService,
  postMethod,
  responseForRoutes,
} from "../../../services/api";
import CustomerInfo from "../cpn/CustomerInfo";
import SaleConfirmModal from "./cpn/SaleConfirmModal";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

const PosCart = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [person, setPerson] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [setCart, setSetCart] = useContext(SetCartContext);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  const [formData, setFormData] = useState<any>({
    currency: "",
    refundAmount: 0,
    sellerId: user.ownerId,
    buyerId: "",
    itemId: "",
    netPrice: "",
    saleNotes: "",
    paymentMethodId: "",
    paymentSeries: "down_payment",
    amount: "",
    serviceFee: null,
    discountType: "by_amount",
    discountValue: "",
    isGift: false,
  });

  const saleAction = () => {
    if (setCart?.item?.itemName) {
      if (person) {
        setFormData({
          ...formData,
          amount: setCart?.netPrice,
          serviceFee: setCart?.netPrice * 0.05,
          saleNotes: "",
          paymentMethodId: "",
          paymentSeries: "down_payment",
          discountType: "by_amount",
          discountValue: "",
        });
        handleShow(setShow);
      } else {
        toast.error("Please select customer!");
      }
    } else {
      toast.error("Please select jewellery!");
    }
  };

  const handleSaleConfirm = () => {
    formData.createdAt = handleDateFormat(formData?.createdAt, "YYYY-MM-DD");
    setLoading(true)
    if (formData.isGift) {
      formData.buyerId = person?.value;
      formData.sellerId = getStoreUser()?.userId;
      formData.itemId = setCart?.item?.id;
      formData.netPrice = "";
      formData.discountType = "";
      formData.discountValue = "";
      formData.paymentMethodId = "";
      formData.serviceFee = "";
      formData.paymentSeries = "";
      formData.amount = "";

      dataService(postMethod, formData, [], "sales/sets", true).then(
        (result) => {
          setLoading(false)
          responseForRoutes(result, history, "jewellery-set-sales");
          setSetCart({ ...setCart, item: {}, netPrice: "" });
        }
      );
    } else {
      if (formData.paymentMethodId) {
        if (setCart?.item.inventoryType === "shop_inventory") {
          formData.serviceFee = "";
        }
        formData.sellerId = getStoreUser()?.userId;
        formData.buyerId = person?.value;
        console.log(setCart?.item?.id);

        formData.itemId = setCart?.item?.id;
        formData.netPrice = setCart?.netPrice || "";
        formData.currency = setCart?.currency || "";
        // formData.discountType = formData.discountValue || "";

        dataService(postMethod, formData, [], "sales/sets", true).then(
          (result) => {
            setLoading(false)
            responseForRoutes(result, history, "jewellery-set-sales");
            setSetCart({ ...setCart, item: {}, netPrice: "" });
          }
        );
      } else {
        toast.error("Please choose payment method!");
      }
    }
  };

  const removeItem = (item: any) => {
    setSetCart({ ...setCart, item: {} });
  };

  return (
    <section
      className="pos-cart"
      style={{ position: setCart?.item?.itemName ? "fixed" : "relative" }}
    >
      <section className="p-4 pos-cart-scroll-area">
        <CustomerInfo person={person} setPerson={setPerson} />

        {setCart?.item?.itemName ? (
          <>
            <div className="bg-light p-4 rounded">
              <div className="flex-between  mb-4">
                <Form.Check
                  type="checkbox"
                  label="Gift item"
                  id="giftItem"
                  className="cursor"
                  checked={formData.isGift}
                  onChange={(e) =>
                    setFormData({ ...formData, isGift: !formData.isGift })
                  }
                />
                {person ? (
                  <Button className="bg-grad" onClick={saleAction}>
                    Sale now
                  </Button>
                ) : (
                  <></>
                )}
              </div>

              <Row>
                <Col md={4}>
                  <div className="cart-item-image">
                    {setCart?.item?.setsImage ? (
                      <Image
                        src={`${RES_API_URL}${setCart?.item?.setsImage?.url}`}
                        alt="no_found"
                      />
                    ) : (
                      <Image src={"images/no-image.png"} />
                    )}
                  </div>

                  <div className="text-center mt-3 cursor">
                    <small className="text-danger" onClick={removeItem}>
                      Remove item
                    </small>
                  </div>
                </Col>
                <Col>
                  <label className="d-block fw-bold mb-3">
                    {setCart?.item?.itemName}
                  </label>
                  <small className="xs-label text-muted d-block mb-2">
                    {setCart?.item?.sku}
                  </small>
                  {setCart?.item?.jewelleryCount ? (
                    <small className="xs-label d-block mb-2">
                      <span className="text-muted">Jewellery count : </span>
                      {setCart?.item?.jewelleryCount}
                    </small>
                  ) : (
                    <></>
                  )}
                  {setCart?.item?.jewelleryIncluded ? (
                    <small className="xs-label d-block mb-2">
                      <span className="text-muted">Jewellery included : </span>
                      {setCart?.item?.jewelleryIncluded}
                    </small>
                  ) : (
                    <></>
                  )}
                  {formData.isGift ? (
                    <p className="text-muted">Gift item</p>
                  ) : (
                    <Accordion defaultActiveKey="" className="mt-1">
                      <div className="d-flex align-items-center">
                        <small className="fw-bold mb-0 me-2">
                          <span className="text-muted">Net price : </span>{" "}
                          {setCart?.item?.price?.currency}{" "}
                          {numberFormat(setCart?.netPrice)}
                        </small>

                        <CustomToggle eventKey="0">
                          <OverlayTrigger
                            overlay={<Tooltip>Edit net price</Tooltip>}
                          >
                            <span className="text-muted cursor">
                              <IconEdit />
                            </span>
                          </OverlayTrigger>
                        </CustomToggle>
                      </div>
                      <Accordion.Collapse eventKey="0" className="border-none">
                        <div className="d-flex align-items-center mt-3">
                          <Col md={9}>
                            <Form.Group className="input-form-control">
                              <Form.Control
                                type={"number"}
                                onWheel={disableScroll}
                                value={setCart?.netPrice}
                                onChange={(e) =>
                                  setSetCart({
                                    ...setCart,
                                    netPrice: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </Col>
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                  )}
                </Col>
                <Col md={12}>
                  <Table
                    responsive
                    striped
                    bordered
                    className="bg-light border mt-4 mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {setCart?.item?.setItems.map(
                        (subItem: any, index: any) => (
                          <tr key={index}>
                            <td>
                              <span className="d-flex align-items-center">
                                <div className="thumbnail-list-image me-2">
                                  <Thumbsnail images={subItem?.images} />
                                </div>
                                <div>
                                  {/* <div>{subItem?.itemName}</div> */}
                                  <small className="">{subItem?.sku}</small>
                                  <small className="mt-1 d-block">
                                    Gems : {subItem.gemstones.totalWeight} carat
                                  </small>
                                  <small className="mt-1 d-block">
                                    Diamonds : {subItem.diamonds.totalWeight}{" "}
                                    carat
                                  </small>
                                  <small className="mt-1 d-block">
                                    Gold : {subItem.gold.totalGoldWeight} gram
                                  </small>
                                </div>
                              </span>
                            </td>
                            <td>
                              {subItem?.sellingPrice && (
                                <span className="flex-end">
                                  {subItem?.currency}{" "}
                                  {numberFormat(subItem?.sellingPrice)}
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <div className="mt-5">
            <NoData title="item" />
          </div>
        )}
      </section>
      <SaleConfirmModal
        loading={loading}
        show={show}
        setShow={setShow}
        handleSaleConfirm={handleSaleConfirm}
        person={person}
        formData={formData}
        setFormData={setFormData}
      />
      {/* {setCart?.item?.itemName && person && <section className="pos-sale-btn" onClick={saleAction}>
                <label>Sale</label>
                {setCart?.netPrice && <label>MMK {numberFormat(setCart?.netPrice)}</label>}
            </section>} */}
    </section>
  );
};

export default PosCart;
