import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export const GridLoading = ({ rowCount = 2 }: { rowCount?: number }) => {
  return (
    <>
      <Row>
        <Col>
          <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
        </Col>
        <Col>
          <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
        </Col>
        {rowCount !== 2 && (
          <Col>
            <Skeleton
              count={1}
              height={250}
              className="mb-4"
              borderRadius={10}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
        </Col>
        <Col>
          <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
        </Col>
        {rowCount !== 2 && (
          <Col>
            <Skeleton
              count={1}
              height={250}
              className="mb-4"
              borderRadius={10}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
        </Col>
        <Col>
          <Skeleton count={1} height={250} className="mb-4" borderRadius={10} />
        </Col>
        {rowCount !== 2 && (
          <Col>
            <Skeleton
              count={1}
              height={250}
              className="mb-4"
              borderRadius={10}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export const TableLoading = ({ rowCount = 15 }: { rowCount?: number }) => {
  return (
    <Skeleton count={rowCount} height={60} className="mb-2" borderRadius={10} />
  );
};

export const DetailLoading = () => {
  return (
    <>
      <Skeleton count={1} height={500} className="mb-4" borderRadius={10} />
      <Skeleton count={1} height={200} borderRadius={10} />
    </>
  );
};

export const DivLoading = () => {
  return (
    <>
      <Skeleton count={1} height={230} borderRadius={10} />
    </>
  );
};

export const POSDetailLoading = () => {
  return (
    <>
      <Row>
        <Col md={2}>
          <Skeleton count={1} height={200} className="mb-4" borderRadius={10} />
        </Col>
        <Col>
          <Skeleton count={1} height={200} className="mb-4" borderRadius={10} />
        </Col>
        <Col>
          <Skeleton count={1} height={200} className="mb-4" borderRadius={10} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton count={1} height={200} className="mb-4" borderRadius={10} />
        </Col>
        <Col>
          <Skeleton count={1} height={200} className="mb-4" borderRadius={10} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton count={1} height={200} className="mb-4" borderRadius={10} />
        </Col>
      </Row>
    </>
  );
};

export const ImageLoading = () => {
  return <Skeleton count={1} height={180} width={180} borderRadius={10} />;
};

export const LoadingButtonLabel = ({
  label,
  disabled,
}: {
  label: string;
  disabled: boolean;
}) => {
  return (
    <>
      {!disabled ? (
        label
      ) : (
        <div className="text-white">
          {" "}
          <div className="spinner-grow spinner-grow-sm" /> Processing . . .
        </div>
      )}
    </>
  );
};
