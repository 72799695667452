import { useContext, useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { NoData } from "../../../components";
import { IconQRScan } from "../../../components/widgets/Icons";
import { GridLoading } from "../../../components/widgets/Loading";
import { PgComponent } from "../../../components/widgets/Pagination";
import { PageContext, VariableContext } from "../../../context";
import { handleShow, numberFormat } from "../../../services";
import {
  actionData,
  getDataService,
  getMethod,
  getPOSJewellerySetRoute,
  getRouteWithPaginate,
  // postMethod,
} from "../../../services/api";
// import Filter from "../cpn/Filter";
import SetCard from "./cpn/SetCard";
import { toast } from "react-toastify";
import SetItemModel from "./cpn/SetItemModal";
import { inventoryTypesForSet } from "../../../data";

const PosItem = () => {
  const [data, setData] = useState([]);
  const [item, setItem] = useState();
  const [detail, setDetail] = useState();
  const [search, setSearch] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [page, setPage] = useContext(PageContext);
  const [inventoryType, setInventoryType] = useState("shop_inventory");
  const [variable, setVariable] = useContext(VariableContext);

  useEffect(() => {
    setInventoryType(variable?.inventoryJewelleryType);
    handleGetData(page.current, variable?.inventoryJewelleryType);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number, type: any) => {
    const _newType = type || inventoryType;
    getDataService(
      getRouteWithPaginate("pos/sets", skip, _newType),
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleChangeInventoryType = (type: any) => {
    setInventoryType(type);
    handleGetData(0, type);
    setVariable({ ...variable, inventoryJewelleryType: type });
  };

  // const handleGetDataByFilter = (skip: number, _formData: any) => {
  //   console.log(_formData);
  //   setLoading(true);
  //   actionData(postMethod, getPOSJewelleriesRoute(skip), _formData).then(
  //     (result) => {
  //       setData(result.data);
  //       setPage({
  //         current: skip,
  //         total: result.count,
  //         sorting: _formData.orderBy,
  //       });
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //     }
  //   );
  // };

  const handleEnterAction = (e: any) => {
    if (e.keyCode === 13) {
      const sku = e.target.value;
      const _data = data?.find((d: any) => d?.sku === sku);
      setItem(_data);

      actionData(
        getMethod,
        getPOSJewellerySetRoute(page.current, sku, inventoryType)
      ).then((result) => {
        if (result && result.data && result.data.length > 0) {
          handelDetailAction(result.data[0]?.id);
        } else {
          toast.error("No data result!");
        }
      });
    }
  };

  const handelDetailAction = (id: any) => {
    setLoadingTwo(true);
    actionData(getMethod, "pos/sets/" + id).then((result: any) => {
      setDetail(result.data[0]);
      setTimeout(() => {
        setSearch("");
        setLoadingTwo(false);
      }, 500);
    });
    handleShow(setShowDetail);
  };

  const handleChangePage = (skip: number) => {
    handleGetData(skip, inventoryType);
  };

  return (
    <div className="pt-4 px-4 pos-item-view">
      <div className="flex-between mb-4">
        <Col md={7}>
          <InputGroup className="input-form-control">
            <InputGroup.Text className="bg-grad text-white">
              <IconQRScan />
            </InputGroup.Text>
            <Form.Control
              placeholder="Scan qrcode or type sku"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => handleEnterAction(e)}
              autoFocus
            />
          </InputGroup>
        </Col>

        <div className="d-flex align-items-center">
          {page.total ? (
            <span>
              <small className="text-muted">Result : </small>
              {numberFormat(page.total)}
            </span>
          ) : (
            <></>
          )}

          <div className="ms-3">
            <Form.Select
              value={inventoryType}
              onChange={(e: any) => handleChangeInventoryType(e.target.value)}
            >
              {inventoryTypesForSet?.map((item: any, index: number) => (
                <option key={index} value={item?.key}>
                  {item?.label}
                </option>
              ))}
            </Form.Select>
          </div>
          {/* <Button className="bg-grad ms-3" onClick={() => handleShow(setShow)}> <IconFilter /></Button> */}
        </div>
      </div>

      {loading ? (
        <GridLoading rowCount={3} />
      ) : (
        <Row className="mb-4">
          {data?.length > 0 ? (
            <>
              {data?.map((item: any, index: number) => (
                <Col md={4} key={index}>
                  <SetCard item={item} />
                </Col>
              ))}

              {page && (
                <PgComponent
                  currentPage={page.current + 1 || 0}
                  fromCount={page.current || 0}
                  total={page.total || 0}
                  pageInfo={page}
                  setPage={setPage}
                  handleGetData={handleGetData}
                  disablePageInput
                  isFilter={variable?.isJewelleryPOSFilter}
                  handleChangePage={handleChangePage}
                />
              )}
            </>
          ) : (
            <NoData noImage />
          )}
        </Row>
      )}

      <SetItemModel
        show={showDetail}
        setShow={setShowDetail}
        item={detail}
        setInfo={item}
        loading={loadingTwo}
      />
      {/* <Filter
        show={show}
        setShow={setShow}
        handleGetData={handleGetData}
        handleGetDataByFilter={handleGetDataByFilter}
      /> */}
    </div>
  );
};

export default PosItem;
