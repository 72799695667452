import { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormCheck,
  Row,
  Table,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  CustomInput,
  CustomModal,
  FormFieldService,
  GoBack,
  Layout,
} from "../../../components";
import {
  IconCancel,
  IconEdit,
  IconPrint,
} from "../../../components/widgets/Icons";
import { DetailLoading } from "../../../components/widgets/Loading";
import {
  disableScroll,
  getDiscountAmountByPercentage,
  handelGetPersons,
  handleClose,
  handleDateFormat,
  handleShow,
  inputChangeService,
  numberFormat,
  totalAmount,
  totalBalance,
  totalPaidAmount,
} from "../../../services";
import {
  actionData,
  dataService,
  getMethod,
  putMethod,
} from "../../../services/api";
import Invoice from "./cpn/Invoice";
import Payments from "./cpn/Payments";
// import SetInvoicePdf from "../../../components/pdf/SetInvoicePdf";

const Detail = () => {
  const location: any = useLocation();
  const item = location.state.item;
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>();
  const [persons, setPersons] = useState([]);
  const [loading, setLoading] = useState(false);
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);

  const [showDataCheck, setShowDataCheck] = useState({
    showGemsLotWeight: false,
    showGemsPairWeight: true,
    showGemsSingleWeight: true,
    showGemsWeight: true,
    showSaleNotes: false,
  });

  const [formData, setFormData] = useState<any>({
    sellerId: user?.userId,
    buyerId: null,
    currency: "",
    netPrice: 0,
    discountType: "by_amount",
    discountValue: 0,
    createdAt: new Date().toISOString(),
    saleNotes: null,
    isConsigned: false,
  });

  const required = ["buyerId", "netPrice"];
  const fields = [
    {
      id: "buyerId",
      label: "Customer *",
      value: formData.buyerId || item?.gems_buyer?.id,
      type: "suggestion-select",
      data: persons,
      defaultLabel: `${item?.gems_buyer?.fullName} (${item?.gems_buyer?.phone})`,
    },
    {
      id: "netPrice",
      label: "Net price *",
      value: formData.netPrice,
      inputType: "number",
      wantString: true,
      colCount: 6,
    },
    // { id: "discountValue", label: "Discount", value: formData.discountValue, inputType: "number", wantString: true, colCount: 6, },
    // { id: "saleNotes", label: "Sale note", value: formData.saleNotes, inputType: "textarea", disableMargin: true },
  ];

  const showForm = (item?: any) => {
    setFormData({
      sellerId: user?.userId,
      buyerId: item?.gems_buyer?.id || null,
      netPrice: item?.netPrice || "",
      saleNotes: item?.saleNotes || null,
      discountType: item?.discountType || "by_amount",
      discountValue: item?.discountValue || "",
      createdAt: item?.createdAt || "",
      currency: item?.currency,
    });
    handleShow(setShow);
  };

  const handleInputChange = (newValue: string) => {
    handelGetPersons(newValue, setPersons);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  // print
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  const handleGetData = () => {
    setLoading(true);
    actionData(getMethod, "sales/gemstones/" + item.id).then((result) => {
      setData(result.data);
      formData.buyerId = result.data?.gems_buyer?.id;
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  const handleSubmit = () => {
    if (formData?.buyerId && formData.netPrice) {
      setLoading(true);
      formData.discountValue = Number(formData.discountValue);
      formData.netPrice = Number(formData.netPrice);

      dataService(
        putMethod,
        formData,
        required,
        `sales/gemstones/${data.id}`,
        true
      ).then((result) => {
        if (result.success) {
          toast.success(result.message);
          handleGetData();
          handleClose(setShow);
        } else {
          toast.error(result?.message);
        }
      });
    } else {
      toast.error("Please provide required data!");
    }
  };

  const detailInfo = [
    { label: "Invoice no.", value: data?.invoiceNumber || "-" },
    { label: "Seller name", value: data?.gems_seller?.fullName || "-" },
    { label: "Selling date", value: handleDateFormat(data?.createdAt) },
    // { label: "Inventory type", value: inventoryTypes.filter((item: any) => item.key === data?.jewellery?.inventoryType)[0]?.label },
    // { label: "Service fee", value: numberFormat(data?.serviceFee) || "-", hide: data?.jewellery?.inventoryType === 'shop_inventory' || data?.jewellery?.inventoryType === 'private_item' },
    { label: "Sale notes", value: data?.saleNotes || "-" },
    {
      label: "Sale status",
      value: <Badge className="bg-danger">Canceled</Badge>,
      hide: !data?.isCancel,
    },
  ];

  const paymentInfo = [
    {
      label: "Total amount",
      value: numberFormat(totalAmount(data?.netPrice, data)) || "-",
    },
    {
      label: "Paid amount",
      value: numberFormat(totalPaidAmount(data?.paymentTrx)) || "-",
    },
    { label: "Balance", value: numberFormat(totalBalance(data)) || "-" },
  ];

  const handleCancel = () => {
    swal({
      text: `Are you sure to cancel this sale?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        actionData(putMethod, `cancel/gemstones/${data?.id}`, {}).then(
          (result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
                buttons: [false],
                timer: 1500,
              });
              handleGetData();
            } else {
              toast.error(result.message);
            }
          }
        );
      }
    });
  };

  return (
    <Layout title="Gems sale detail">
      {loading ? (
        <DetailLoading />
      ) : (
        <div>
          <div className="flex-between mb-4">
            <GoBack label="Gems sales" />
            {/* <div style={{ display: "none" }}>
              <SetInvoicePdf
                ref={componentRef}
                data={data}
                showDataCheck={showDataCheck}
              />
            </div> */}
            {!data?.isCancel ? (
              <div>
                <Button
                  className="text-white bg-grad me-3"
                  onClick={() => showForm(data)}
                >
                  <IconEdit /> <span className="ms-2">Edit sale</span>
                </Button>
                <Button
                  className="text-white bg-grad me-3"
                  onClick={PrintAction}
                >
                  <IconPrint /> <span className="ms-2">Print invoice</span>
                </Button>
                {(user?.userRole === "Owner" ||
                  user?.userRole === "Admin" ||
                  user?.userRole === "Manager") && (
                    <Button
                      className="text-white bg-danger border-none"
                      onClick={handleCancel}
                    >
                      <IconCancel /> <span className="ms-2">Sale cancel</span>
                    </Button>
                  )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <Row>
            <Col md={8}>
              <div ref={componentRef}>
                <Invoice data={data} showDataCheck={showDataCheck} />
              </div>
            </Col>
            <Col>
              <div
                className="d-flex flex-column justify-content-between"
                style={{ height: "100%" }}
              >
                <div>
                  <Table responsive striped className="border">
                    <tbody>
                      {detailInfo.map(
                        (item: any, index: number) =>
                          !item.hide && (
                            <tr key={index}>
                              <td
                                className="text-muted"
                                style={{ width: "40%" }}
                              >
                                {item.label}
                              </td>
                              <td>{item.value}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>

                  {/* {!data?.isCancel ? (
                    <Form.Check
                      type="checkbox"
                      label="Show gems weight"
                      id="showGemsWeight"
                      className="mb-3 cursor"
                      checked={showDataCheck.showGemsWeight}
                      onChange={(e) =>
                        setShowDataCheck({
                          ...showDataCheck,
                          showGemsWeight: e.target.checked,
                        })
                      }
                    />
                  ) : (
                    <></>
                  )} */}
                  {!data?.isCancel ? (
                    <Form.Check
                      type="checkbox"
                      label="Show sale notes"
                      id="showSaleNotes"
                      className="me-4 cursor"
                      checked={showDataCheck.showSaleNotes}
                      onChange={(e) =>
                        setShowDataCheck({
                          ...showDataCheck,
                          showSaleNotes: e.target.checked,
                        })
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>

                {data?.isGift ? (
                  <></>
                ) : (
                  <Table responsive striped className="border mb-0">
                    <tbody>
                      {paymentInfo.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="text-muted" style={{ width: "40%" }}>
                            {item.label}
                          </td>
                          <td>
                            <span className="flex-end">{item.value}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </Col>
            {data?.isGift ? (
              <></>
            ) : (
              <Col md={12} className="mt-4">
                <Payments detail={data} handleGetData={handleGetData} />
              </Col>
            )}
          </Row>
        </div>
      )}

      <CustomModal
        show={show}
        handleClose={() => handleClose(setShow)}
        id={data?.gems_buyer?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        lg
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          formData={formData}
          setFormData={setFormData}
        />

        <Row>
          <Col md={6}>
            <Form.Group className="input-form-control mb-3">
              <div className="flex-between">
                <Form.Label>{`Discount ${formData.discountType === "by_amount" ? "amount" : "(%)"
                  }`}</Form.Label>

                <FormCheck
                  id="switch"
                  className="switch"
                  type="switch"
                  label={"%"}
                  checked={formData.discountType === "by_percentage"}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      discountType:
                        formData.discountType === "by_amount"
                          ? "by_percentage"
                          : "by_amount",
                      discountValue: "",
                    })
                  }
                />
              </div>

              <Form.Control
                type={"number"}
                id={"discountValue"}
                value={formData.discountValue}
                onChange={(e: any) =>
                  setFormData({ ...formData, discountValue: e.target.value })
                }
                onWheel={disableScroll}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <CustomInput
              id={"saleNotes"}
              label={"Sale note"}
              value={formData.saleNotes}
              handleChange={(e: any) =>
                setFormData({ ...formData, saleNotes: e.target.value })
              }
              inputType={"textarea"}
            />
          </Col>
        </Row>

        <div className="flex-end">
          <div className="w-50">
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">Net price</small>
              <small>{numberFormat(formData?.netPrice)}</small>
            </div>
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">
                Discount{" "}
                {formData.discountType === "by_percentage" &&
                  `(${formData.discountValue}%)`}
              </small>
              <small>
                {formData.discountValue &&
                  formData.discountValue !== "0" &&
                  "-"}{" "}
                {formData.discountValue
                  ? numberFormat(
                    formData.discountType === "by_percentage"
                      ? getDiscountAmountByPercentage(
                        formData?.netPrice,
                        formData
                      )
                      : formData.discountValue
                  )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top">
              <small className="text-muted d-block">Total</small>
              <small>
                {formData?.currency}{" "}
                {numberFormat(totalAmount(formData?.netPrice, formData))}
              </small>
            </div>
          </div>
        </div>
      </CustomModal>
    </Layout>
  );
};

export default Detail;
