import React, { useState } from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { GoBack, InfoTable, Layout } from "../../../components";
import { useHistory, useLocation } from "react-router-dom";
import { handleDateTimeFormat, numberFormat } from "../../../services";
import {
  consignmentJewellery,
  dataService,
  putMethod,
  responseForRoutes,
} from "../../../services/api";
import swal from "sweetalert";
import ConfirmModal from "./cpn/ConfirmModal";
import { useReactToPrint } from "react-to-print";
import { IconPrint } from "../../../components/widgets/Icons";
import Invoice from "./cpn/Invoice";
import { toast } from "react-toastify";

const Detail = () => {
  const location: any = useLocation();
  const history = useHistory();
  const item = location.state.item;
  const [confirmModal, setConfirmModal] = useState(false);

  // print
  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  const data = [
    {
      label: "Consignment status",
      value: (
        <Badge
          className={
            item?.consignmentStatus === "Pending" ? "bg-grad" : "bg-success"
          }
        >
          {item?.consignmentStatus}
        </Badge>
      ),
    },
    {
      label: "Asking price",
      value: item?.askingPrice
        ? `${item?.consigned_jewellery?.currency} ${numberFormat(
            item?.askingPrice
          )}`
        : "-",
    },
    {
      label: "Dealing price",
      value: item?.dealingPrice
        ? `${item?.consigned_jewellery?.currency} ${numberFormat(
            item?.dealingPrice
          )}`
        : "-",
    },
    {
      label: "Broker fee",
      value: item?.brokerFee
        ? `${item?.consigned_jewellery?.currency} ${numberFormat(
            item?.brokerFee
          )}`
        : "-",
    },
    { label: "Consignment date", value: handleDateTimeFormat(item?.createdAt) },
    { label: "Dealing date", value: handleDateTimeFormat(item?.dealAt) },
    {
      label: "Return date",
      value: item?.returnAt ? handleDateTimeFormat(item?.returnAt) : "-",
    },
    { label: "Note", value: item?.consignmentNotes },
  ];

  const handleConfirmAction = (_newData: any) => {
    if (_newData?.dealingPrice && _newData?.brokerFee && _newData?.dealAt) {
      const _formData = {
        jewelleryId: Number(_newData?.consigned_jewellery?.id),
        consignerId: _newData?.consignerId,
        consigneeId: _newData?.consigneeId,
        askingPrice: Number(_newData?.askingPrice),
        brokerFee: Number(_newData?.brokerFee),
        dealingPrice: Number(_newData?.dealingPrice),
        consignmentStatus: "Completed",
        consignmentNotes: _newData?.notes || null,
        createdAt: _newData?.createdAt,
        returnAt: _newData?.returnAt,
        dealAt: new Date(_newData?.dealAt).toISOString(),
      };

      dataService(
        putMethod,
        _formData,
        [],
        `${consignmentJewellery}/${item?.id}`,
        true
      ).then((result) => {
        responseForRoutes(result, history, "jewellery-consignments");
      });
    } else {
      toast.error("Please provide required data");
    }
  };

  const handleConfirm = () => {
    swal({
      text: `Are you sure to confirm this action?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        handleReturnAction();
      }
    });
  };

  const handleReturnAction = () => {
    const _formData = {
      jewelleryId: Number(item?.consigned_jewellery?.id),
      consignerId: item?.consignerId,
      consigneeId: item?.consigneeId,
      askingPrice: Number(item?.askingPrice),
      brokerFee: Number(item?.brokerFee),
      dealingPrice: Number(item?.dealingPrice),
      consignmentStatus: "Returned",
      consignmentNotes: item?.notes || null,
      createdAt: item?.createdAt,
      returnAt: new Date().toISOString(),
      dealAt: item?.dealAt,
    };

    dataService(
      putMethod,
      _formData,
      [],
      `${consignmentJewellery}/${item?.id}`,
      true
    ).then((result) => {
      responseForRoutes(result, history, "jewellery-consignments");
    });
  };

  return (
    <Layout title="Jewellery consignment detail">
      <div className="flex-between mb-4">
        <GoBack label="Jewellery consignments" />
        <div className="d-flex">
          <Button className="text-white bg-grad me-3" onClick={PrintAction}>
            <IconPrint />
            <span className="ms-2">Print invoice</span>
          </Button>

          <Button
            className="text-white bg-grad px-4 me-3"
            disabled={item?.consignmentStatus !== "Pending"}
            onClick={() => setConfirmModal(!confirmModal)}
          >
            Sale confirm
          </Button>

          <Button
            className="text-white bg-grad px-4"
            disabled={item?.consignmentStatus !== "Completed"}
            onClick={() => handleConfirm()}
          >
            Consignment return
          </Button>
        </div>
      </div>

      <Row>
        <Col md={7}>
          <div ref={componentRef}>
            <Invoice data={item} showDataCheck={undefined} />
          </div>
        </Col>

        <InfoTable data={data} />
      </Row>

      <ConfirmModal
        show={confirmModal}
        data={item}
        handleToggle={() => setConfirmModal(!confirmModal)}
        handleSave={handleConfirmAction}
      />
    </Layout>
  );
};

export default Detail;
