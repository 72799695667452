import React from "react";
import {
  GoBack,
  Layout,
  LoadingButtonLabel,
  TableLayout,
} from "../../components";
import { Button, Form, Modal } from "react-bootstrap";
import { IconEdit, IconSync } from "../../components/widgets/Icons";
import { handleDateTimeFormat, numberFormat } from "../../services";
import { toast } from "react-toastify";
import { actionData } from "../../services/api";

interface ChangeRatesProps {}

const ChangeRates: React.FC<ChangeRatesProps> = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [process, setProcess] = React.useState(false);
  const [syncModal, setSyncModal] = React.useState(false);
  const [syncData, setSyncData] = React.useState<any>();
  const [show, setShow] = React.useState(false);
  const [formData, setFormData] = React.useState<any>();

  const handleGetData = () => {
    actionData("GET", `price-rates`).then((result) => {
      if (result.success) {
        setLoading(true);
        setData(result.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      } else {
        toast.error("Something Wrong!");
      }
    });
  };

  React.useEffect(() => {
    handleGetData();
  }, []);

  const handleSyncModal = (data: any) => {
    setSyncModal(!syncModal);
    setSyncData(data);
  };

  const handleShowModal = (data: any) => {
    setFormData(data);
    setShow(!show);
  };

  const handleChange = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = () => {
    var isInvalid = false;

    isInvalid = Object.values(formData).includes("");

    if (!isInvalid) {
      setProcess(true);
      handleUpdate();
    } else {
      toast.error("Please fill required field!");
    }
  };

  const handleUpdate = () => {
    const _formData = {
      price: parseInt(formData?.price, 10),
    };

    actionData("PUT", `price-rates/${formData?.id}`, _formData).then(
      (result) => {
        handleShowModal(null);
        if (result.success) {
          toast.success(result?.message);
          setTimeout(() => {
            handleGetData();
            setProcess(!true);
          }, 500);
        } else {
          toast.error(result?.message);
        }
      }
    );
  };

  const handleSync = () => {
    setProcess(true);
    actionData("GET", `price-rates/sync/${syncData?.id}`).then((result) => {
      handleSyncModal(null);
      if (!result.messages.includes("cannot")) {
        setTimeout(() => {
          setProcess(!true);
          toast.success(result?.messages);
        }, 500);
      } else {
        toast.error(result?.messages);
      }
    });
  };

  return (
    <Layout title={"Change Rates"}>
      <div className="flex-between mb-4">
        <GoBack label="Configuration" />
      </div>

      <TableLayout loading={loading} data={data} handleGetData={handleGetData}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Updated Date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: number) => (
            <tr key={index}>
              <td className="text-center">{index + 1}</td>
              <td className="text-start">{item?.label}</td>
              <td className="text-start">{`${item?.rate} ${item?.rateUnit}`}</td>
              <td className="text-start">{`${
                item?.priceUnit === "Kyats" ? "Ks" : "$"
              } ${numberFormat(item?.price)}`}</td>
              <td className="text-start">
                {handleDateTimeFormat(item?.updatedAt)}
              </td>
              <td>
                <span className="flex-center">
                  {item?.type !== "dollar" && (
                    <Button
                      className="btn-sm bg-grad ms-2"
                      onClick={() => handleSyncModal(item)}
                    >
                      <IconSync />
                    </Button>
                  )}

                  <Button
                    className="btn-sm bg-grad ms-2"
                    onClick={() => handleShowModal(item)}
                  >
                    <IconEdit />
                  </Button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>

      {/* Sync rate modal */}
      <Modal show={syncModal} onHide={() => setSyncModal(!syncModal)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Synchronize {syncData?.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to synchronize this data? This action can not be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success"
            onClick={() => setSyncModal(!syncModal)}
          >
            Cancel
          </Button>
          <Button className="bg-grad" onClick={handleSync} disabled={process}>
            <LoadingButtonLabel label="Sync Now" disabled={process} />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Sync rate edit modal */}
      <Modal
        show={show}
        onHide={() => handleShowModal(null)}
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="">
            <Form.Label className="required">Price</Form.Label>
            <Form.Control
              placeholder="Current price"
              value={formData?.price}
              onChange={(e: any) => handleChange("price", e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-success" onClick={() => handleShowModal(null)}>
            Cancel
          </Button>
          <Button className="bg-grad" onClick={handleSubmit} disabled={process}>
            <LoadingButtonLabel label="Save" disabled={process} />
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default ChangeRates;
