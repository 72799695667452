import React from "react";
import { shopAddress } from "../../data";

interface InvoiceHeaderProps {}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = () => {
  return (
    <section className="text-center mb-4 bg-black">
      <img
        src="images/mr.spinel.png"
        className="p-5 pb-4"
        alt="no_found"
        width={300}
      />
      <span className="d-block pb-4 text-white-50">
        <small>{shopAddress}</small>
      </span>
    </section>
  );
};

export default InvoiceHeader;
