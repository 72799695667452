import React from "react";
import { inventoryTypes } from "../../../../data";
import { getPersonsService } from "../../../../services";
import { actionData, getMethod } from "../../../../services/api";
import { FormFieldService } from "../../../../components";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

interface CaliGemProps {
  formData: any;
  setFormData: (data: any) => void;
}

const CaliGem: React.FC<CaliGemProps> = ({ formData, setFormData }) => {
  const [owners, setOwners] = React.useState([]);
  const [brokers, setBrokers] = React.useState([]);
  const [stoneTypes, setStoneTypes] = React.useState([]);
  const [stockTypes, setStockTypes] = React.useState([]);
  const [outlets, setOutlets] = React.useState<any>([]);
  // const [buyingStates, setBuyingStates] = useState([]);

  const fields = [
    {
      id: "itemName",
      label: "Item name *",
      value: formData?.caliGem?.itemName,
    },
    { id: "sku", label: "SKU *", value: formData?.caliGem?.sku },
    {
      id: "stoneType",
      label: "Stone type *",
      value: formData?.caliGem?.stoneType,
      type: "select",
      data: stoneTypes,
      wantString: true,
    },
    {
      id: "stockType",
      label: "Stock type",
      value: formData?.caliGem?.stockType,
      type: "select",
      data: stockTypes,
      wantString: true,
    },
    {
      id: "caliGemsOwnerId",
      label: "Gems owner",
      value: formData?.caliGem?.caliGemsOwnerId,
      type: "suggestion-select",
      data: owners,
    },
    {
      id: "caliGemsBrokerId",
      label: "Gems broker",
      value: formData?.caliGem?.caliGemsBrokerId,
      type: "suggestion-select",
      data: brokers,
    },
    {
      id: "inventoryType",
      label: "Inventory type",
      value: formData.caliGem?.inventoryType,
      type: "select",
      data: inventoryTypes,
      wantString: true,
      disableInititalSelect: true,
    },
    // {
    //   id: "buyingState",
    //   label: "Buying state",
    //   value: formData?.caliGem?.buyingState,
    //   type: "select",
    //   data: buyingStates,
    //   wantString: true,
    // },
    {
      id: "miningLocation",
      label: "Mining location",
      value: formData?.caliGem?.miningLocation,
    },
    {
      id: "outletName",
      label: "Outlet name (Location)",
      value: formData?.caliGem?.outletName,
      type: "select",
      data: outlets,
      wantString: true,
      disableInititalSelect: true,
    },
    {
      id: "purchaseDate",
      label: "Purchase date",
      value: formData?.caliGem?.purchaseDate,
      type: "date",
    },
    {
      id: "notes",
      label: "Notes",
      value: formData?.caliGem?.notes,
      inputType: "textarea",
    },
  ];

  React.useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    getPersonsService("", setOwners, null);
    getPersonsService("", setOwners, setBrokers);

    actionData(getMethod, "cali-gems-stone-type").then((result) => {
      setStoneTypes(result?.data);
    });

    actionData(getMethod, "cali-gems-stock-type").then((result) => {
      setStockTypes(result?.data);
    });

    actionData(getMethod, "outlet/all").then((result) => {
      if (result?.data?.length) {
        const temp: any = [];
        result?.data?.map((i: any) => temp.push({ label: i?.name }));
        setOutlets(temp);
        setFormData({
          ...formData,
          caliGem: {
            ...formData.caliGem,
            outletName: temp?.[0]?.label,
          },
        });
      }
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.type === "text" ? target.value : target.value;
    }

    setFormData({
      ...formData,
      caliGem: {
        ...formData.caliGem,
        [name]: value,
      },
    });
  };

  const handleInputChange = (newValue: string) => {
    getPersonsService(newValue, setOwners, null);
  };

  const handleNext = () => {
    const caliGem = formData?.caliGem;

    if (caliGem?.itemName && caliGem?.sku && caliGem?.stoneType) {
      setFormData({
        ...formData,
        step: 2,
      });
    } else {
      toast.error("Please fill data!");
    }
  };

  return (
    <div className={`${formData?.step === 1 ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>Calibrate gem information (Step 1)</div>
      </div>

      <FormFieldService
        fields={fields}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        colCount={4}
        bg
      />

      <div className="mt-3 d-flex justify-content-end">
        <Button className="text-white bg-grad px-4" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CaliGem;
