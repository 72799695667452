import { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { NoData } from "../../../../components";
import {
  GridLoading,
  TableLoading,
} from "../../../../components/widgets/Loading";
import { PgComponent } from "../../../../components/widgets/Pagination";
import { VariableContext } from "../../../../context/Variable";
import { Grid } from "./Grid";
import { List } from "./List";
import { IconPrint } from "../../../../components/widgets/Icons";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

const Data = ({
  loading,
  data,
  page,
  setPage,
  handleResponse,
  handleGetData,
}: {
  loading: boolean;
  data: any;
  page: any;
  setPage: any;
  handleResponse: any;
  handleGetData: any;
}) => {
  const [variable] = useContext(VariableContext);
  const [, setIsCheckAll] = useState(false);
  const [isCheckData, setCheckData] = useState<any[]>();
  const [selectedItems, setSelectedItems] = useState({});
  const [printItems, setPrintItems] = useState<any[]>();

  useEffect(() => {
    setIsCheckAll(false);
    setCheckData([]);
    setPrintItems([]);
    // eslint-disable-next-line
  }, []);


  const handleClick = (evt: any, item: any) => {
    const { id, checked } = evt.target;

    let temp: any[] = [];
    if (checked) {
      isCheckData?.forEach((_item: any) => temp.push(_item));
      temp.push(parseInt(id));
    } else {
      isCheckData?.forEach((_item: any) => {
        if (_item !== parseInt(id)) {
          temp.push(_item);
        }
      });
    }
    setCheckData(temp);

    let tempPrintItems: any[] = [];
    printItems?.forEach((_item) => tempPrintItems.push(_item));

    if (checked && tempPrintItems.length >= 100) {
      alert('You can only select up to 100 items.');
      return;
    }

    if (tempPrintItems.length > 0) {
      let isExist = false;

      tempPrintItems.forEach((data, index) => {
        if (data.id === item.id) {
          tempPrintItems.splice(index, 1);
          isExist = true;
        }
      });

      if (!isExist) {
        tempPrintItems.push(item);
      }
    } else {
      tempPrintItems.push(item);
    }

    setPrintItems(tempPrintItems);
    const extractedIds = tempPrintItems?.map(item => item.id);
    setSelectedItems({ "ids": extractedIds })
  };

  const handleDownload = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cali_gemstones/export-sheet-with-ids`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedItems),
    });
    if (!response.ok) {
      alert('Failed to generate the Excel file.');
      return;
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'labels.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  };


  return (
    <div>
      {printItems && printItems.length > 0 && (
        <div className="float-btns">
          {data.length > 0 && (
            <Button
              onClick={() => {
                handleDownload()
                setSelectedItems({})
                // PrintLabelAction();
                setIsCheckAll(false);
                // setPrintItems([]);
                setCheckData([]);
              }}
              className="btn btn-grad me-2"
            >
              <IconPrint /> <span className="ps-2">Download label sheet</span>
            </Button>
          )}
        </div>
      )}
      {variable?.isList ? (
        <>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {/* {data.map((item: any, index: number) => (
                                <Col md={12} key={index}>
                                    <ListCard item={item} handleResponse={handleResponse} />
                                </Col>
                            ))} */}
                  <Table responsive bordered className="mb-4" striped>
                    <thead>
                      <tr>
                        <th className="ps-4">#</th>
                        <th>Name</th>
                        <th>SKU</th>
                        <th>Item Info</th>
                        {/* <th>Origin</th> */}
                        {/* <th>Stock type</th>
                        <th>Stone type</th> */}
                        {/* <th><span className="flex-end">Weight</span></th> */}
                        <th>
                          <span className="flex-end">Price</span>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item: any, index: any) => (
                        <tr key={index}>
                          <List
                            item={item}
                            handleResponse={handleResponse}
                            index={index}
                            pageCurrent={page.current} isCheckData={isCheckData} handleCheckClick={handleClick} />
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </>
      ) : (
        <Row>
          {loading ? (
            <GridLoading />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item: any, index: number) => (
                    <Col md={6} key={index}>
                      <Grid item={item} handleResponse={handleResponse} index={index} handleCheckClick={handleClick} isCheckData={isCheckData} />
                    </Col>
                  ))}
                </>
              ) : (
                <NoData />
              )}
            </>
          )}
        </Row>
      )}

      {page && page.total > limit && (
        <PgComponent
          currentPage={page.current + 1 || 0}
          fromCount={page.current || 0}
          total={page.total || 0}
          paginationLimit={limit}
          pageInfo={page}
          setPage={setPage}
          handleGetData={(page: any) => handleGetData(page)}
        />
      )}
    </div>
  );
};

export default Data;
