import { useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  IconDelete,
  IconFile,
  IconRecycle,
} from "../../../components/widgets/Icons";
import { Layout, TableLayout, GoBack } from "../../../components";
import {
  actionData,
  deleteMethod,
  gemsRoute,
  getDataService,
  putMethod,
} from "../../../services/api";
import { PageContext } from "../../../context";
import swal from "sweetalert";
import { getNumber, numberFormat } from "../../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;
// const RES_API_URL = process.env.REACT_APP_RES_API_URL

const Gems = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService(
      `trash/gemstones/${skip * limit}/${limit}?sort=desc`,
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handleResponse = (result: any) => {
    if (result?.success || result?.id || result?.persons?.id) {
      toast.success(result?.message || "Successfully done!");
      handleGetData(0);
    } else {
      toast.error(result?.message);
      setLoading && setLoading(false);
    }
  };

  const handleRestore = (item: any) => {
    swal({
      text: `Are you sure to restore this item!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        actionData(putMethod, `restore/gemstones/${item?.id}`, null).then(
          (result) => {
            handleResponse(result);
          }
        );
      }
    });
  };

  const handleDelete = (item: any) => {
    swal({
      text: `Are you sure to delete this item permanently!`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        actionData(deleteMethod, `${gemsRoute()}/${item?.id}`, null).then(
          (result) => {
            handleResponse(result);
          }
        );
      }
    });
  };

  // const thumbsnailImage = (item: any) => {
  //     let tempImages = item.images
  //     let isExist = false
  //     let tempDiv = <img src="images/no-image.png" alt="Thumbnail" width={80} height={80} className="object-fit rounded my-2" />;

  //     if (tempImages && tempImages.length > 0) {
  //         tempImages.forEach((img: any) => {
  //             if (img.thumbnail) {
  //                 isExist = true

  //                 tempDiv = <img src={`${RES_API_URL}${img.url}`} alt="Thumbnail" width={80} height={80} className="object-fit rounded cursor my-2"
  //                 />
  //             }
  //         })

  //         if (!isExist) {
  //             tempDiv = <img src={`${RES_API_URL}${tempImages[0].url}`} alt="Thumbnail" width={80} height={80} className="rounded cursor my-2"
  //             />
  //         }
  //     }

  //     return tempDiv;
  // }

  return (
    <Layout title="Deleted gemstone lists">
      <div className="mb-4">
        <GoBack label="Configuration" />
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Item</th>
            <th>SKU</th>
            <th>Stone type</th>
            <th>Price</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <td>
                <span className="">
                  <div>
                    <div>{item?.itemName}</div>
                  </div>
                </span>
              </td>
              <td>
                <span className="">{item?.sku || "-"}</span>
              </td>
              <td>
                <span className="">{item?.stoneType}</span>
              </td>
              <td>
                <span className="flex-end">
                  {item?.price?.sellingPrice
                    ? `${item?.price?.currency} ${numberFormat(
                        item?.price?.sellingPrice
                      )}`
                    : "-"}
                </span>
              </td>
              <td>
                <span className="flex-center">
                  <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                    <Button
                      className="bg-grad btn-sm"
                      onClick={() =>
                        history.push({
                          pathname: "/gems-detail",
                          state: { item: item },
                        })
                      }
                    >
                      <IconFile />
                    </Button>
                  </OverlayTrigger>
                  <Button
                    className="bg-grad btn-sm ms-2"
                    onClick={() => handleRestore(item)}
                  >
                    {" "}
                    <IconRecycle />{" "}
                  </Button>
                  <Button
                    className="bg-danger btn-sm ms-2"
                    onClick={() => handleDelete(item)}
                  >
                    {" "}
                    <IconDelete />{" "}
                  </Button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>
    </Layout>
  );
};

export default Gems;
