// import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InfoTable, ThumbsnailImage } from "..";
// import JewelleryDetailInfo from "./JewelleryDetailInfo";

const GemInfo = ({ gem }: { gem: any }) => {
  // const [show, setShow] = useState(false);

  const data = [
    { label: "Name", value: gem?.itemName },
    { label: "SKU", value: gem?.sku },
    { label: "Stock type", value: gem?.stockType || "-" },
    { label: "Stone type", value: gem?.stoneType || "-" },
    {
      label: "Gem weight",
      value: gem?.stages[0].weight ? `${gem?.stages[0].weight} ${gem?.stages[0].weightUnit}` : gem?.stockType === "Pair" ? `${gem?.stages[0].pcsOneWeight} , ${gem?.stages[0].pcsTwoWeight} ${gem?.stages[0].weightUnit}` : "-",
    },
    { label: "Number of Pcs", value: gem?.stockType === "Pair" ? 2 : gem?.stages?.[0]?.numberOfPcs || "-" },
    { label: "Currency", value: gem?.price?.currency || "-" },
  ];

  return (
    <div>

      <Row>
        <Col md={4}>
          <div className="detail-item-image">
            <ThumbsnailImage item={gem} />
          </div>
        </Col>
        <InfoTable data={data} />
      </Row>
    </div>
  );
};

export default GemInfo;
