import { useCallback, useContext, useRef } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { InfoTable, ThumbsnailImage } from "../../../components";
import { IconCart, IconPrint } from "../../../components/widgets/Icons";
import { POSDetailLoading } from "../../../components/widgets/Loading";
import POSDetailPdf from "../../../components/pdf/POSDetailPdf";
import { CartContext } from "../../../context/Cart";
import { handleClose, numberFormat } from "../../../services";
import { inventoryTypes } from "../../../data";

const ItemModel = ({
  show,
  setShow,
  item,
  loading,
  jewellery,
}: {
  show: boolean;
  setShow: any;
  item: any;
  loading: boolean;
  jewellery: any;
}) => {
  let gold = item && item?.gold && item?.gold.length > 0 ? item?.gold[0] : {};
  const [cart, setCart] = useContext(CartContext);

  const inventoryTypeLabel = inventoryTypes.filter(
    (inventory: any) => inventory.key === item?.inventoryType
  )[0]?.label;

  //For pdf
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  const data = [
    { label: "SKU", value: item?.sku },
    { label: "Jewellery type", value: item?.jewelleryType },
    { label: "Jewellery style", value: item?.jewelleryStyle || "-" },
    {
      label: "Jewellery weight",
      value: item?.jewelleryWeight
        ? `${item?.jewelleryWeight} ${item?.jewelleryWeightUnit}`
        : "-",
    },
  ];

  const otherData = [
    {
      label: "Bangle width",
      value: item?.width || "-",
      hide: !(item?.jewelleryType === "Bangles"),
    },
    {
      label: "Ring width",
      value: item?.width || "-",
      hide: !(item?.jewelleryType === "Rings"),
    },
    {
      label: "Bracelet length",
      value: item?.length || "-",
      hide: !(item?.jewelleryType === "Bracelets"),
    },
    {
      label: "Necklace length",
      value: item?.length || "-",
      hide: !(item?.jewelleryType === "Necklaces"),
    },
    { label: "Inventory type", value: inventoryTypeLabel || "-" },
    { label: "Description", value: item?.description || "-" },
  ];

  const totalDiamondsQuantity = () => {
    let total = 0;
    if (item && item?.diamonds && item?.diamonds.length > 0) {
      item?.diamonds.forEach((diamond: any) => {
        if (diamond.diamondsStockType === "Lot")
          total += diamond.diamondsQuantity;
      });
    }
    return total;
  };

  const totalGemsQuantity = () => {
    let total = 0;
    if (item && item?.gemstones && item?.gemstones.length > 0) {
      item?.gemstones.forEach((gemstone: any) => {
        if (gemstone.gemsStockType === "Lot") total += gemstone.gemsQuantity;
      });
    }
    return total;
  };

  const handleAddToCart = () => {
    if (cart?.item?.itemName) {
      toast.error("Already added item!");
    } else {
      setCart({
        ...cart,
        item: item,
        netPrice: item?.price?.sellingPrice,
        currency: item?.price?.currency,
      });
    }

    handleClose(setShow);
  };

  return (
    <Modal show={show} onHide={() => handleClose(setShow)} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{!loading && item?.itemName}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <POSDetailLoading />
        ) : (
          <>
            <div className="p-2">
              <Row className="mb-4">
                <Col md={2}>
                  <div className="pos-detail-image">
                    <ThumbsnailImage item={item} />
                  </div>
                  {item?.price?.sellingPrice && (
                    <div className="p-3 rounded-3 bg-light text-center mt-4">
                      <h6 className="fw-bold mb-0 text-danger">
                        {item?.price?.sellingPrice &&
                          `${item?.price?.currency} ${numberFormat(
                            item?.price?.sellingPrice
                          )}`}
                      </h6>
                    </div>
                  )}
                </Col>
                <InfoTable data={data} />
                <InfoTable data={otherData} notes={item?.notes} />
              </Row>

              <Row className="mb-4">
                {item && item?.gemstones && item?.gemstones.length > 0 && (
                  <Col>
                    <h6 className="mb-3">Gemstones</h6>
                    <Table
                      responsive
                      striped
                      bordered
                      className="mb-0 bg-light border"
                    >
                      <thead>
                        <tr>
                          <th>Stock type</th>
                          <th>Stone type</th>
                          <th>Size</th>
                          <th>No. of pcs</th>
                          <th>Weight ({item?.gemstones[0].gemsWeightUnit})</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.gemstones.map((gems: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <span className="flex-center">
                                {gems.gemsStockType}
                              </span>
                            </td>
                            <td>
                              <span className="flex-center">
                                {gems.gemsStoneType}
                              </span>
                            </td>
                            <td>
                              {gems.gemsStockType === "Lot" && gems.gemsSize ? (
                                <span className="flex-center">
                                  {gems.gemsSize || ""}{" "}
                                  {gems.gemsSizeUnit === "avg_size"
                                    ? "ct."
                                    : "pcs/ct."}
                                </span>
                              ) : (
                                <span className="flex-center"></span>
                              )}
                            </td>
                            <td>
                              {gems.gemsStockType === "Lot" ? (
                                <span className="flex-center">
                                  {gems.gemsQuantity || ""}
                                </span>
                              ) : (
                                <span className="flex-center"></span>
                              )}
                            </td>
                            <td>
                              {gems.gemsStockType === "Pair" ? (
                                <span className="flex-end">
                                  {gems.gemsPcsOneWeight
                                    ? `${gems.gemsPcsOneWeight}`
                                    : "-"}{" "}
                                  {gems.gemsPcsTwoWeight &&
                                    ` , ${gems.gemsPcsTwoWeight}`}
                                </span>
                              ) : (
                                <span className="flex-end">
                                  {gems.gemsWeight ? `${gems.gemsWeight}` : "-"}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                        {item?.gemstones.length > 1 && (
                          <tr>
                            <th colSpan={3}>
                              <span className="flex-center">Total</span>
                            </th>
                            <th>
                              {totalGemsQuantity() !== 0 && totalGemsQuantity()}
                            </th>
                            <th>
                              <span className="flex-end">
                                {item?.weight?.totalGemsWeight}
                              </span>
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                )}

                {item && item?.diamonds && item?.diamonds.length > 0 && (
                  <Col>
                    <h6 className="mb-3">Diamonds</h6>
                    <Table
                      responsive
                      striped
                      bordered
                      className="mb-0 bg-light border"
                    >
                      <thead>
                        <tr>
                          <th>Stock type</th>
                          <th>Size</th>
                          <th>No. of pcs</th>
                          <th>
                            Weight ({item?.diamonds[0].diamondsWeightUnit})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.diamonds.map((diamond: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <span className="flex-center">
                                {diamond.diamondsStockType}
                              </span>
                            </td>
                            <td>
                              {diamond.diamondsStockType === "Lot" &&
                              diamond.diamondsSize ? (
                                <span className="flex-center">
                                  {diamond.diamondsSize || "-"}{" "}
                                  {diamond.diamondsSizeUnit === "avg_size"
                                    ? "ct."
                                    : "pcs/ct."}
                                </span>
                              ) : (
                                <span className="flex-center"></span>
                              )}
                            </td>
                            <td>
                              {diamond.diamondsStockType === "Lot" ? (
                                <span className="flex-center">
                                  {diamond.diamondsQuantity || "-"}
                                </span>
                              ) : (
                                <span className="flex-center"></span>
                              )}
                            </td>
                            <td>
                              {diamond.diamondsStockType === "Pair" ? (
                                <span className="flex-end">
                                  {diamond.diamondsPcsOneWeight
                                    ? `${diamond.diamondsPcsOneWeight}`
                                    : "-"}{" "}
                                  {diamond.diamondsPcsTwoWeight &&
                                    ` , ${diamond.diamondsPcsTwoWeight}`}
                                </span>
                              ) : (
                                <span className="flex-end">
                                  {diamond.diamondsWeight
                                    ? `${diamond.diamondsWeight}`
                                    : "-"}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                        {item?.diamonds.length > 1 && (
                          <tr>
                            <th colSpan={2}>
                              <span className="flex-center">Total</span>
                            </th>
                            <th>
                              {totalDiamondsQuantity() !== 0 &&
                                totalDiamondsQuantity()}
                            </th>
                            <th>
                              <span className="flex-end">
                                {item?.weight?.totalDiamondsWeight}
                              </span>
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                )}
              </Row>

              {item && item?.gold && item?.gold.length > 0 && (
                <div>
                  <h6 className="mb-3">Gold</h6>
                  <Table responsive striped bordered className="mb-0 bg-light ">
                    <thead>
                      <tr>
                        <th>Material type used</th>
                        <th>Material purity</th>
                        <th>Weight (grams)</th>
                        <th>Goldsmith loss (grams)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="flex-center">
                            {gold?.materialTypeUsed || "-"}
                          </span>
                        </td>
                        <td>
                          <span className="flex-center">
                            {gold?.materialPurity || "-"}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="flex-center">
                            {gold?.totalGoldWeight
                              ? `${gold?.totalGoldWeight}`
                              : "-"}
                          </span>
                        </td>
                        <td>
                          <span className="flex-center">
                            {gold?.goldSmithLoss
                              ? `${gold?.goldSmithLoss} - ${gold?.goldSmithLossNote}`
                              : "-"}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-success" onClick={() => handleClose(setShow)}>
          Cancel
        </Button>
        <div style={{ display: "none" }}>
          <POSDetailPdf ref={componentRef} data={item} jewellery={jewellery} />
        </div>
        <Button className="btn btn-grad ms-3" onClick={PrintAction}>
          <IconPrint /> <span className="ms-2">Print details</span>
        </Button>
        <Button className="btn btn-grad ms-3" onClick={handleAddToCart}>
          <IconCart /> <span className="ms-2">Add to cart</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemModel;
