import { useContext, useEffect, useState } from "react";
import { Lang, Layout } from "../../../components";
import { VariableContext } from "../../../context";
import { PageContext } from "../../../context/Page";
import {
  actionData,
  customRouteTwo,
  getDataService,
  getJewelleriesRoute,
  getMethod,
  JewelleryFilterRoute,
  postMethod,
  responseService,
} from "../../../services/api";
import { Count, Data, Header } from "./cpn";
import { getStoreUser } from "../../../services";

const Index = () => {
  const [data, setData] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [outletName, setOutletName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAvailable, setChangeAvailable] = useState(true);
  const [inventoryCard, setInventoryCard] = useState({
    shopInventory: 0,
    privateItem: 0,
    serviceItem: 0,
    orderedItem: 0,
  });
  const [inventoryType, setInventoryType] = useState("shop_inventory");
  const [page, setPage] = useContext(PageContext);
  const [variable] = useContext(VariableContext);

  useEffect(() => {
    getData();
    handleOutlets();
    setInventoryType(variable?.inventoryJewelleryType);
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    variable?.isJewelleryFilter
      ? handleGetDataByFilter(page.current, variable?.jewelleryFilterActionData)
      : handleGetData(
          page.current,
          variable?.inventoryJewelleryType,
          outletName,
          isAvailable
        );
    handleGetCount(isAvailable, outletName);
  };

  const handleOutlets = () => {
    actionData(getMethod, "outlet/all").then((result) => {
      if (result.data.length) {
        setOutlets(result.data);
      }
    });
  };

  const handleGetData = (
    skip: number,
    inventoryType: string,
    outletName: any,
    availability: boolean
  ) => {
    getDataService(
      customRouteTwo(
        "jewellery",
        skip,
        "",
        "",
        getStoreUser()?.userId,
        inventoryType,
        outletName,
        availability ? "Available" : "Unavailable"
      ),
      setLoading,
      setData,
      setPage,
      skip
    );

    handleGetCount(isAvailable, outletName);
  };

  const handleGetCount = (availability: boolean, outletName: any) => {
    actionData(
      getMethod,
      `inventory-type/count/${getStoreUser()?.userId}?availability=${
        availability ? "Available" : "Unavailable"
      }${outletName ? `&outletName=${outletName}` : ""}`
    ).then((result) => {
      if (result && result.data.length > 0) {
        let tempShopInventoryCount = 0;
        let tempPrivateItemCount = 0;
        let tempServiceItemCount = 0;
        let tempOrderedItemCount = 0;
        result.data.forEach((item: any) => {
          if (item.inventoryType === "shop_inventory")
            tempShopInventoryCount = item.numOfInventories;
          if (item.inventoryType === "private_item")
            tempPrivateItemCount = item.numOfInventories;
          if (item.inventoryType === "service_item")
            tempServiceItemCount = item.numOfInventories;
          if (item.inventoryType === "ordered_item")
            tempOrderedItemCount = item.numOfInventories;
        });

        setInventoryCard({
          shopInventory: tempShopInventoryCount,
          privateItem: tempPrivateItemCount,
          serviceItem: tempServiceItemCount,
          orderedItem: tempOrderedItemCount,
        });
      }
    });
  };

  const handleGetDataByFilter = (skip: number, _formData: any) => {
    setLoading(true);
    actionData(postMethod, JewelleryFilterRoute(skip), _formData).then(
      (result) => {
        setData(result.data);
        setPage({
          current: skip,
          total: result.count,
          sorting: _formData.orderBy,
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    );
  };

  const handleResponse = (result: any) => {
    responseService(result, handleGetData, null, page.current);
  };

  const handleChangeInventoryType = (invType: string) => {
    handleGetCount(isAvailable, outletName);
    handleGetData(0, invType, outletName, isAvailable);
    setInventoryType(invType);
  };

  const handleChangeAvailable = (changeType: boolean) => {
    setChangeAvailable(changeType);
    handleGetCount(changeType, outletName);
    getDataService(
      getJewelleriesRoute(
        0,
        false,
        variable?.inventoryJewelleryType,
        "",
        "",
        changeType ? "Available" : "Unavailable"
      ),
      setLoading,
      setData,
      setPage,
      0
    );
  };

  return (
    <Layout title={<Lang label="jewelleries" />}>
      <Header
        result={page.total}
        handleGetDataByFilter={handleGetDataByFilter}
        isAvailable={isAvailable}
        inventoryType={inventoryType}
        outlets={outlets}
        outletName={outletName}
        setOutletName={setOutletName}
        setInventoryType={setInventoryType}
        handleChangeAvailable={handleChangeAvailable}
        handleGetData={(v: any, o: any) =>
          handleGetData(page.current, v, o, isAvailable)
        }
      />

      <Count
        inventoryCard={inventoryCard}
        handleChangeInventoryType={handleChangeInventoryType}
      />

      <Data
        loading={loading}
        data={data}
        handleGetDataByFilter={handleGetDataByFilter}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
        handleResponse={handleResponse}
      />
    </Layout>
  );
};

export default Index;
