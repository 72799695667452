import { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Layout } from "../../components";
import { actionData, putMethod } from "../../services/api";

function ChangePassword() {
  const tempUser: any = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: user?.email,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (evt: any) => {
    const target = evt.target;
    const value = target.value;
    const name = target.id;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    actionData(putMethod, "change-password", formData).then((result) => {
      if (result.success) {
        swal("Successfully password changed!", {
          icon: "success",
          buttons: [false],
          timer: 1500,
        });
        history.push("/dashboard");
      } else {
        toast.error(result.message);
      }
    });
  };

  return (
    <Layout title="Change password">
      <div className="bg-light rounded-3 my-3 p-3">
        <form className="inventory-form row g-3">
          <div className="col-12">
            <p>Please follow this guide for a strong password:</p>
            <small>
              <ul className="text-muted">
                <li>One special characters</li>
                <li>Must be 8-20 characters long</li>
                <li>One number (2 are recommended)</li>
                <li>Must not contain spaces, special characters, or emoji</li>
                <li>Change it often</li>
              </ul>
            </small>
          </div>
          <div className="col-md-4">
            <label
              htmlFor="inputCurrentPassword"
              className="form-label fw-bold text-success"
            >
              Current password
            </label>
            <input
              type="password"
              className="form-control"
              id="oldPassword"
              onChange={handleChange}
              value={formData.oldPassword}
              autoComplete={"false"}
            />
          </div>
          <div className="col-md-4">
            <label
              htmlFor="inputNewPassword"
              className="form-label fw-bold text-success"
            >
              New password
            </label>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              onChange={handleChange}
              value={formData.newPassword}
              autoComplete={"false"}
            />
          </div>
          <div className="col-md-4">
            <label
              htmlFor="inputConfirmPassword"
              className="form-label fw-bold text-success"
            >
              Confirm password
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              onChange={handleChange}
              value={formData.confirmPassword}
              autoComplete={"false"}
            />
          </div>
          <div className="col-12">
            <Button className="btn btn-grad" onClick={handleSubmit}>
              Change password
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default ChangePassword;
