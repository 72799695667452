import { useContext, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import {
  CustomModalService,
  Lang,
  Layout,
  TableLayout,
} from "../../../components";
import { IconDelete } from "../../../components/widgets/Icons";
import { PageContext } from "../../../context/Page";
import { inventoryTypes } from "../../../data";
import {
  getNumber,
  handelGetPersons,
  handleClose,
  inputChangeService,
  numberFormat,
} from "../../../services";
import {
  actionData,
  dataService,
  deleteService,
  getDataService,
  getJewellerySuggest,
  getMethod,
  postMethod,
  responseService,
} from "../../../services/api";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [, setPersons] = useState([]);
  const [jewelleries, setJewelleries] = useState([]);
  const [jewelleryOwners, setJewelleryOwners] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);
  // const [searchString, setSearchString] = useState("")
  const [formData, setFormData] = useState({
    id: "",
    sku: "",
    itemNameDefaultValue: "",
    acceptedBy: null,
    acceptedByDefaultValue: "",
    refundedBy: null,
    refundedByDefaultValue: "",
    refundAmount: "",
  });
  // const required = ["fullName"]

  const fields = [
    {
      id: "sku",
      label: "Jewellery *",
      value: formData.sku,
      type: "suggestion-select",
      data: jewelleries,
      defaultLabel: formData.itemNameDefaultValue,
    },
    // { id: "acceptedBy", label: "Accepted by", value: formData.acceptedBy, type: "suggestion-select", data: persons, defaultLabel: formData.acceptedByDefaultValue },
    {
      id: "acceptedBy",
      label: "Accepted by",
      value: formData.acceptedBy,
      type: "select",
      data: jewelleryOwners,
    },
    {
      id: "refundedBy",
      label: "Refunded by",
      value: formData.refundedBy,
      type: "select",
      data: jewelleryOwners,
    },
    {
      id: "refundAmount",
      label: "Refund amount",
      value: formData.refundAmount,
      inputType: "number",
      wantString: true,
    },
  ];

  useEffect(() => {
    handleGetData(page.current);
    handelJewellerySuggest("");
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    handelGetPersons("", setPersons);
    actionData(getMethod, "owners").then((result) => {
      let temp: any = [];
      if (result && result.data.length > 0) {
        result.data.forEach((item: any) => {
          temp.push({
            key: item.ownerId,
            label: item.fullName,
            customKey: true,
          });
        });
      }
      setJewelleryOwners(temp);
    });

    getDataService(
      `return/jewelleries/${skip * limit}/${limit}?sort=desc`,
      setLoading,
      setData,
      setPage,
      skip
    );
  };

  const handelJewellerySuggest = async (keyword: string) => {
    let formData = {
      keyword: keyword,
      sort: "desc",
      // availability: "Available",
      // inventoryType: "shop_inventory"
    };
    actionData(postMethod, getJewellerySuggest(), formData).then((result) => {
      let temp: any = [];

      result.data.forEach((data: any) => {
        temp.push({
          value: data.id,
          label: `${data.itemName} (${data.sku})`,
          sku: data.sku,
          itemName: data.itemName,
        });
      });
      setJewelleries(temp);
    });
  };

  const handleInputChange = (newValue: string) => {
    handelJewellerySuggest(newValue);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  // const showForm = (item?: any) => {
  //     setFormData({
  //         id: item.id || "",
  //         sku: item.sku || "",
  //         itemNameDefaultValue: "",
  //         acceptedBy: item.acceptedBy || "",
  //         acceptedByDefaultValue: "",
  //         refundedBy: item.refundedBy || "",
  //         refundedByDefaultValue: "",
  //         refundAmount: item.refundAmount || "",
  //     })
  //     handleShow(setShow);
  // }

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    let body = {
      acceptedBy: formData.acceptedBy,
      refundedBy: formData.refundedBy,
      refundAmount: formData.refundAmount,
    };

    setLoading(true);
    dataService(method, body, [], `return/jewellery/${formData.sku}`).then(
      (result) => {
        handleResponse(result);
      }
    );
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.itemName,
      null,
      `return/jewellery/${item?.jewelleryId}`,
      handleResponse
    );
  };

  // const handleSerachEnterAction = (e: any) => {
  //     if (e.keyCode === 13) {
  //         if (e.target.value) {
  //             handleGetSearchData(0, e.target.value)
  //         } else {
  //             handleGetData(0)
  //             setSearchString("")
  //         }
  //     }
  // }

  return (
    <Layout title="Jewellery return lists">
      {/* <div className="flex-between mb-4"> */}
      {/* <SearchInput handleSerachEnterAction={handleSerachEnterAction} searchString={searchString} setSearchString={setSearchString} handleClearSearch={() => { handleGetData(0); setSearchString("") }} /> */}
      {/* <div className='d-flex align-items-center'>
                    <Button className="text-white bg-grad ms-3" onClick={showForm}><IconAdd /> <span className="ms-2"><Lang label="createNew" /></span></Button>
                </div> */}
      {/* </div> */}

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>
              <span className="flex-center">
                <Lang label="no." />
              </span>
            </th>
            <th>Item</th>
            <th>Accepted by</th>
            <th>Refunded by</th>
            <th>Refund amount</th>
            {/* <th><span className='flex-start'>Currency</span></th> */}
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              <td>
                <span className="flex-center">
                  {getNumber(index, page?.current)}
                </span>
              </td>
              <td>
                <span className="d-flex align-items-center">
                  {/* <div className="thumbnail-list-image me-2">
                                        <Thumbsnail images={item?.jewellery.jewellery_images} />
                                    </div> */}
                  <div>
                    <div>{item?.itemName}</div>
                    <small className="text-muted mt-1 d-block">
                      {item?.sku}
                    </small>
                    <div>
                      <Badge className="bg-success me-2 mt-2">
                        {
                          inventoryTypes.filter(
                            (type: any) => type.key === item?.inventoryType
                          )[0]?.label
                        }
                      </Badge>
                      {/* {item.isGift ? <Badge className='bg-grad ms-1'>Gift item</Badge> : <></>} {item.isCancel ? <Badge className='bg-danger ms-2'>Canceled</Badge> : <></>} */}
                    </div>
                  </div>
                </span>
              </td>
              <td>{item.acceptedPerson}</td>
              <td>{item.refundedPerson}</td>
              <td>
                <span className="flex-end">
                  {item?.currency} {numberFormat(item.refundAmount)}
                </span>
              </td>
              {/* <td>MMK</td> */}
              <td>
                <span className="flex-center">
                  {" "}
                  <Button
                    className="bg-danger btn-sm"
                    onClick={() => handleDelete(item)}
                  >
                    {" "}
                    <IconDelete />{" "}
                  </Button>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.id}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        formData={formData}
        setFormData={setFormData}
        lg
      />
    </Layout>
  );
};

export default Index;
