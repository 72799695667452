import {
  Col,
  Form,
  FormCheck,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { disableScroll, handleKeyDown } from "../../services";
import { IconSearch, IconClose } from "./Icons";
var cn = require("classnames");

export const CustomInput = ({
  label,
  id,
  handleChange,
  value,
  inputType = "text",
  wantString = false,
  disable = false,
  disableMargin = false,
}: {
  label: string;
  id: string;
  handleChange: any;
  value: any;
  inputType?: string;
  wantString?: boolean;
  disable?: boolean;
  disableMargin?: boolean;
}) => (
  <Form.Group
    className={cn(
      inputType !== "textarea" ? "input-form-control" : "",
      !disableMargin && "mb-2"
    )}
  >
    <Form.Label>{label}</Form.Label>
    {inputType === "textarea" ? (
      <Form.Control
        as="textarea"
        rows={2}
        id={id}
        value={value}
        onChange={handleChange}
      />
    ) : (
      <Form.Control
        type={inputType === "number" ? "number" : "text"}
        id={id}
        onChange={(e) => handleChange(e, wantString)}
        value={value}
        onWheel={disableScroll}
        onKeyDown={handleKeyDown}
        disabled={disable}
      />
    )}
  </Form.Group>
);

export const CustomSelectInput = ({
  label,
  id,
  handleChange,
  value,
  data,
  wantString = false,
  disableMargin = false,
  disableInititalSelect = false,
  keyType,
  checkId,
  checkLabel,
  checkLabelValue,
  formData,
  setFormData,
  checkFirstValue,
  checkSecondValue,
}: {
  label: string;
  id: string;
  handleChange: any;
  value: any;
  data: any;
  wantString?: boolean;
  disableMargin?: boolean;
  disableInititalSelect?: boolean;
  keyType?: string;
  checkId?: any;
  checkLabel?: string;
  checkLabelValue?: boolean;
  formData?: any;
  setFormData?: any;
  checkFirstValue?: any;
  checkSecondValue?: any;
}) => (
  <Form.Group className={cn("input-form-control", !disableMargin && "mb-2")}>
    <section className="flex-between">
      <Form.Label>{label}</Form.Label>
      {checkLabel && (
        <FormCheck
          id="switch"
          className="switch"
          type="switch"
          label={checkLabel}
          checked={checkLabelValue === checkSecondValue}
          onChange={() =>
            setFormData({
              ...formData,
              [checkId]:
                checkLabelValue === checkFirstValue
                  ? checkSecondValue
                  : checkFirstValue,
              gemsSize: checkId === "gemsSizeUnit" ? "" : formData.gemsSize,
              gemsSizeFrom:
                checkId === "gemsSizeUnit" ? "" : formData.gemsSizeFrom,
              gemsSizeTo: checkId === "gemsSizeUnit" ? "" : formData.gemsSizeTo,
              diamondsSize:
                checkId === "diamondsSizeUnit" ? "" : formData.diamondsSize,
              diamondsSizeFrom:
                checkId === "diamondsSizeUnit" ? "" : formData.diamondsSizeFrom,
              diamondsSizeTo:
                checkId === "diamondsSizeUnit" ? "" : formData.diamondsSizeTo,
            })
          }
        />
      )}
    </section>
    <Form.Select
      id={id}
      onChange={(e) => handleChange(e, wantString)}
      value={value}
    >
      {!disableInititalSelect && <option value="">Select</option>}
      {data.map((item: any, index: number) => (
        <option
          value={
            item.customKey
              ? item.key
              : keyType === "value" || item.keyType === "value"
              ? item.value
              : item.label
          }
          key={index}
        >
          {item.label}
        </option>
      ))}
    </Form.Select>
  </Form.Group>
);

export const DateInput = ({
  label,
  id,
  handleChange,
  value,
}: {
  label: string;
  id: string;
  handleChange: any;
  value: any;
}) => (
  <Form.Group className="mb-2 input-form-control">
    <Form.Label>{label}</Form.Label>
    <input
      type="date"
      className="form-control input-form-control"
      id={id}
      value={value}
      onChange={handleChange}
    />
  </Form.Group>
);

export const CustomSelectInputGroup = ({
  label,
  id,
  secondId,
  handleChange,
  value,
  secondValue,
  data,
  wantString = false,
  inputType = "text",
  secondHandleChange,
}: {
  label: string;
  id: string;
  secondId: string;
  handleChange: any;
  value: any;
  secondValue: any;
  data: any;
  wantString?: boolean;
  inputType?: string;
  secondHandleChange?: any;
}) => (
  <Form.Group className="mb-2 input-form-control">
    <Form.Label>{label}</Form.Label>
    <section className="d-flex">
      <Col md={8}>
        <Form.Control
          className="first-select-group"
          type={inputType === "number" ? "number" : "text"}
          id={id}
          onChange={(e) => handleChange(e, wantString)}
          value={value}
          onWheel={disableScroll}
          onKeyDown={handleKeyDown}
        />
      </Col>
      <Form.Control
        as="select"
        aria-label="Select"
        className="second-select-group cursor bg-light"
        id={secondId}
        onChange={(e) =>
          secondHandleChange
            ? secondHandleChange(e, wantString)
            : handleChange(e, wantString)
        }
        value={secondValue}
      >
        {data.map((item: any, index: number) => (
          <option value={item.label} key={index}>
            {item.label}
          </option>
        ))}
      </Form.Control>
    </section>
  </Form.Group>
);

export const CustomeSuggestionSelect = ({
  id,
  label,
  data,
  formData,
  setFormData,
  value,
  defaulLabel,
  handleInputChange,
  showLabel = true,
  placeholder = "Select",
  disableMargin = false,
  secondId,
}: {
  id: string;
  label: string;
  data: any;
  formData: any;
  setFormData: any;
  value?: any;
  defaulLabel?: any;
  handleInputChange: any;
  showLabel?: boolean;
  placeholder?: string;
  disableMargin?: boolean;
  secondId?: string;
}) => (
  <Form.Group
    className={disableMargin ? "input-form-control" : "mb-2 input-form-control"}
  >
    {showLabel && <Form.Label>{label}</Form.Label>}
    <Select
      isClearable
      options={data}
      defaultValue={value && { label: defaulLabel, value: value }}
      onInputChange={handleInputChange}
      onChange={(person: any) => {
        if (secondId) {
          setFormData({
            ...formData,
            [id]: person ? person?.value : "",
            [secondId]: person ? person?.label : "",
          });
        } else {
          setFormData({ ...formData, [id]: person ? person?.value : "" });
        }
      }}
      placeholder={placeholder}
      classNamePrefix="react-select"
      className="react-select-container"
      styles={{
        control: (base: any, state: any) => ({
          ...base,
          boxShadow: state.isFocused ? 0 : 0,
        }),
      }}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: "#efefef",
          primary: "#e9edef",
          neutral0: "#212529",
          primary50: "#e9edef",
        },
      })}
    />
  </Form.Group>
);

// New Generate
export const CustomSuggestionSelect = ({
  label,
  data,
  value,
  defaulLabel,
  fieldName = "id",
  handleInputChange,
  handleOnChange,
  showLabel = true,
  placeholder = "Select",
  disableMargin = false,
}: {
  id: string;
  label: string;
  data: any;
  value?: any;
  defaulLabel?: any;
  fieldName?: any;
  handleInputChange: any;
  handleOnChange: any;
  showLabel?: boolean;
  placeholder?: string;
  disableMargin?: boolean;
}) => {
  const index = data.findIndex((x: any) => x[fieldName] === value);

  return (
    <Form.Group
      className={
        disableMargin ? "input-form-control" : "mb-2 input-form-control"
      }
    >
      {showLabel && <Form.Label>{label}</Form.Label>}
      <Select
        isClearable
        options={data}
        // defaultValue={value && { label: defaulLabel, value: value }}
        value={data[index] ?? null}
        onInputChange={handleInputChange}
        onChange={handleOnChange}
        placeholder={placeholder}
        classNamePrefix="react-select"
        className="react-select-container"
        styles={{
          control: (base: any, state: any) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
          }),
        }}
        theme={(theme: any) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "#efefef",
            primary: "#e9edef",
            neutral0: "#212529",
            primary50: "#e9edef",
          },
        })}
      />
    </Form.Group>
  );
};

export const SearchInput = ({
  colCount = 3,
  handleSerachEnterAction,
  searchString = "",
  setSearchString,
  handleClearSearch,
}: {
  colCount?: number;
  handleSerachEnterAction?: any;
  searchString?: any;
  setSearchString?: any;
  handleClearSearch?: any;
}) => (
  <Col md={colCount}>
    <InputGroup className="search-input">
      <InputGroup.Text className="bg-grad text-white">
        <IconSearch />
      </InputGroup.Text>
      <Form.Control
        placeholder="Search"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onKeyDown={handleSerachEnterAction}
      />
      {searchString && (
        <OverlayTrigger overlay={<Tooltip>Clear Search</Tooltip>}>
          <InputGroup.Text
            className="bg-danger cursor"
            onClick={handleClearSearch}
          >
            <IconClose />
          </InputGroup.Text>
        </OverlayTrigger>
      )}
    </InputGroup>
  </Col>
);

export const Checkbox = ({ label }: { label: string }) => {
  return (
    // onChange={handleClick} checked={isChecked}
    <div className="form-check">
      <input type="checkbox" className="form-check-input cursor" id={label} />
      <label className="form-check-label cursor" htmlFor={label}>
        {label}
      </label>
    </div>
  );
};
