import { Col, Form, Row } from "react-bootstrap";
import {
  CustomeSuggestionSelect,
  CustomInput,
  CustomSelectInput,
  CustomSelectInputGroup,
  DateInput,
} from "../widgets/CustomInput";

const FormFieldService = ({
  fields,
  handleChange,
  handleInputChange,
  formData,
  setFormData,
  colCount = 6,
  bg = false,
}: {
  fields: any;
  handleChange: any;
  handleInputChange?: any;
  formData?: any;
  setFormData?: any;
  colCount?: number;
  bg?: boolean;
}) => {
  return (
    <div className={bg ? "bg-card" : ""}>
      <Form>
        <Row>
          {fields.map(
            (item: any, index: number) =>
              !item.hide && (
                <Col md={item.colCount ? item.colCount : colCount} key={index}>
                  {item.type === "select" ? (
                    <CustomSelectInput
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      data={item.data}
                      wantString={item.wantString}
                      handleChange={handleChange}
                      disableMargin={item.disableMargin}
                      formData={formData}
                      setFormData={setFormData}
                      checkId={item.checkId}
                      checkLabel={item.checkLabel}
                      checkLabelValue={item.checkLabelValue}
                      checkFirstValue={item.checkFirstValue}
                      checkSecondValue={item.checkSecondValue}
                      disableInititalSelect={item.disableInititalSelect}
                      keyType={item.keyType}
                    />
                  ) : item.type === "select-group" ? (
                    <CustomSelectInputGroup
                      label={item.label}
                      id={item.id}
                      secondId={item.secondId}
                      value={item.value}
                      secondValue={item.secondValue}
                      handleChange={handleChange}
                      inputType={item.inputType}
                      wantString={item.wantString}
                      data={item.data}
                    />
                  ) : item.type === "date" ? (
                    <DateInput
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      handleChange={handleChange}
                    />
                  ) : item.type === "suggestion-select" ? (
                    <CustomeSuggestionSelect
                      handleInputChange={handleInputChange}
                      value={item.value}
                      id={item.id}
                      secondId={item.secondId}
                      label={item.label}
                      data={item.data}
                      formData={formData}
                      setFormData={setFormData}
                      defaulLabel={item.defaultLabel}
                    />
                  ) : (
                    <CustomInput
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      handleChange={handleChange}
                      inputType={item.inputType}
                      wantString={item.wantString}
                      disableMargin={item.disableMargin}
                      disable={item.disable}
                    />
                  )}
                </Col>
              )
          )}
        </Row>
      </Form>
    </div>
  );
};

export default FormFieldService;
