import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { QRView, Thumbsnail } from "..";
import {
  getDiscountAmountByPercentage,
  handleDateFormat,
  numberFormat,
  totalAmount,
} from "../../services";
import { LangContext } from "../../context";
import InvoiceHeader from "./InvoiceHeader";

const InvoicePdf = React.forwardRef((props: any, ref: any) => {
  let data = props.data;
  let showDataCheck = props.showDataCheck;
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  const totalGemsWeight = () => {
    let total = 0;
    if (data?.jewellery?.gemstones && data?.jewellery?.gemstones?.length > 0) {
      data?.jewellery?.gemstones.forEach((item: any) => {
        if (item.gemsStockType === "Lot" && showDataCheck.showGemsLotWeight) {
          total += parseFloat(item.gemsWeight);
        }
        if (item.gemsStockType === "Pair" && showDataCheck.showGemsPairWeight) {
          total +=
            parseFloat(item.gemsPcsOneWeight) +
            parseFloat(item.gemsPcsTwoWeight);
        }
        if (
          item.gemsStockType === "Single" &&
          showDataCheck.showGemsSingleWeight
        ) {
          total += parseFloat(item.gemsWeight);
        }
      });
    }
    return total.toFixed(3).replace(/\.?0+$/, "");
  };

  return (
    <div ref={ref} className="print-invoice-container">
      <div
        className="d-flex flex-column justify-content-around"
        style={{ height: "83vh" }}
      >
        <div>
          <InvoiceHeader />
          {/* <section className="text-center mb-4 bg-black">
            <img
              src="images/mr.spinel.png"
              className="p-5 pb-4"
              alt="no_found"
              width={380}
            />
            <span className="d-block pb-4 text-white-50">
              <small>
                78 street, Bet; 34 x 35 street, Mandalay | Ph : 09-43200898 ,
                09-799075549 , 09-425291390
              </small>
            </span>
          </section> */}

          <div className="flex-between align-items-center">
            <div style={{ width: "50%" }}>
              <span className="d-block mb-2 print-text d-flex">
                <span className="text-muted d-block" style={{ width: "75px" }}>
                  Customer
                </span>{" "}
                :{" "}
                <h6 className="ms-2 fw-bold">{data?.buyer?.fullName || "-"}</h6>
              </span>
              <span className="d-block mb-2 print-text d-flex">
                <span className="text-muted d-block" style={{ width: "75px" }}>
                  Phone
                </span>{" "}
                : <h6 className="ms-2 fw-bold">{data?.buyer?.phone || "-"}</h6>
              </span>
              {data?.buyer?.address && (
                <span className="d-block mb-2 print-text d-flex">
                  <span
                    className="text-muted d-block"
                    style={{ width: "75px" }}
                  >
                    {translate("address")}
                  </span>{" "}
                  : <span className="ms-2">{data?.buyer?.address || "-"} </span>
                </span>
              )}
            </div>
            <div className="d-flex align-items-center">
              <QRView text={data?.invoiceNumber} width={50} />
              <div className="ms-3 d-flex flex-column align-items-end">
                <span className="d-block mb-2 print-text mt-1">
                  <span>{data?.invoiceNumber}</span>
                </span>
                <span className="d-block mb-2 print-text">
                  <span className="text-muted">{translate("date")}</span> :{" "}
                  <span>{handleDateFormat(data?.createdAt)}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-center">
            <span className="fw-bold border text-center p-2 print-text">
              SALE INVOICE
            </span>
          </div>

          <Table responsive className="mt-4 mb-3 print-table" bordered striped>
            <thead>
              <tr>
                <th>Item</th>
                <th>Weight (grams)</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {data?.jewellery?.itemName}{" "}
                  {data?.jewellery?.gold?.materialPurity &&
                    `(${data?.jewellery?.gold?.materialPurity})`}
                </td>
                <td>
                  <span className="d-flex justify-content-center">
                    {data?.jewellery?.jewelleryWeight}
                  </span>
                </td>
                <td>
                  <span className={data?.isGift ? "flex-center" : "flex-end"}>
                    {data?.isGift ? "Gift item" : numberFormat(data?.netPrice)}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center mt-4">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="receit-item-image ">
                  <Thumbsnail images={data?.jewellery?.images} />
                </div>
                <small className="xs-label print-text text-muted d-block mt-2 text-center">
                  {data?.jewellery?.sku}
                </small>
              </div>
              <div className="ms-3">
                {totalGemsWeight() !== "0" ? (
                  <small className="d-block mb-2 print-text">
                    <span className="text-muted">Gems weight : </span>
                    {totalGemsWeight()}{" "}
                    {data?.jewellery?.weight?.totalGemsWeightUnit}
                  </small>
                ) : (
                  <></>
                )}
                {data?.jewellery?.weight?.totalDiamondsWeight ? (
                  <small className="d-block mb-2 print-text">
                    <span className="text-muted">Diamonds weight : </span>
                    {data?.jewellery?.weight?.totalDiamondsWeight}{" "}
                    {data?.jewellery?.weight?.totalDiamondsWeightUnit}
                  </small>
                ) : (
                  <></>
                )}
                {data?.jewellery?.gold?.totalGoldWeight ? (
                  <small className="d-block mb-4 print-text">
                    <span className="text-muted">Gold weight : </span>
                    {data?.jewellery?.gold?.totalGoldWeight}{" "}
                    {data?.jewellery?.gold?.totalGoldWeightUnit}
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {data?.isGift ? (
              <></>
            ) : (
              <div className="me-2 w-25">
                <div className="flex-between mb-2 pb-2 ">
                  <small className="text-muted d-block print-text ">
                    Discount{" "}
                    {data?.discountType === "by_percentage" &&
                      `(${data?.discountValue}%)`}
                  </small>
                  <small className="fw-bold print-text ">
                    {data?.discountValue && data?.discountValue !== "0" && "-"}{" "}
                    {data?.discountValue
                      ? numberFormat(
                          data?.discountType === "by_percentage"
                            ? getDiscountAmountByPercentage(
                                data?.netPrice,
                                data
                              )
                            : data?.discountValue
                        )
                      : "-"}
                  </small>
                </div>
                <div className="flex-between pt-2 border-top ">
                  <small className="text-muted d-block print-text ">
                    Total ({data?.currency})
                  </small>
                  <small className="fw-bold print-text ">
                    {numberFormat(totalAmount(data?.netPrice, data))}
                  </small>
                </div>
              </div>
            )}
          </div>

          {data?.saleNotes && showDataCheck.showSaleNotes && (
            <span className="d-block print-text mt-5">
              <span className="text-muted">Notes </span> :{" "}
              <span>{data?.saleNotes}</span>
            </span>
          )}
        </div>

        <div>
          <small className="text-danger print-text text-center d-block mb-3 mt-5">
            {translate("receipt-tip")}
          </small>
          <small className="text-muted print-text text-center d-block">
            {translate("thankForYourPurchase")}
          </small>
        </div>
      </div>
    </div>
  );
});

export default InvoicePdf;
