import React from "react";
import { Table } from "react-bootstrap";
import { inventoryTypes } from "../../data";
import { handleDateFormat } from "../../services";
import InvoiceImage from "./InvoiceImage";

const SetInventorySummaryPdf = React.forwardRef((props: any, ref: any) => {
  let weightCards = props.weightCards;
  let costCards = props.costCards;
  let inventoryType = props.inventoryType;
  // let totalByTypes = props.totalByTypes

  return (
    <section ref={ref} className="print-container">
      {/* <div className="d-flex flex-column align-items-center mb-4 bg-black">
        <Image src="images/mr.spinel.png" width={300} />
      </div> */}
      <InvoiceImage />

      <div className="flex-between mb-3 align-items-center">
        <label className="ms-1 fw-bold ">Set inventory report (summary)</label>
        <div className="d-flex justify-content-end">
          <div>
            <small className="flex-end mb-1">
              {" "}
              Report date :{" "}
              <label className="fw-bold ms-1">
                {" "}
                {handleDateFormat(new Date())}
              </label>
            </small>
            <small>
              Inventory type :{" "}
              <label className="fw-bold ms-1">
                {" "}
                {
                  inventoryTypes.filter(
                    (item: any) => item.key === inventoryType
                  )[0]?.label
                }
              </label>
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Table responsive striped className="mb-0 border">
            <tbody>
              {weightCards.map((item: any, index: number) => (
                <tr key={index}>
                  <td className=" text-muted ">{item.label}</td>
                  <td>
                    <h6 className="fw-bold flex-end">{item.value}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="col">
          <Table responsive striped className="mb-0 border">
            <tbody>
              {costCards.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="text-muted ">{item.label}</td>
                  <td>
                    <h6 className="fw-bold flex-end">{item.value}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* <Table responsive striped className="mb-0 border mt-4" bordered>
                    <thead>
                        <tr>
                            {totalByTypes && totalByTypes.map((item: any, index: number) => (
                                <th key={index} className=" text-muted ">{item.jewelleryType}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            {totalByTypes && totalByTypes.map((item: any, index: number) => (
                                <td key={index}><h6 className="fw-bold flex-end">{item.count}</h6></td>
                            ))}
                        </tr>
                    </tbody>
                </Table> */}
      </div>
    </section>
  );
});

export default SetInventorySummaryPdf;
