import { useContext, useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  FormFieldService,
  CustomModal,
} from "../../../../components";
import {
  IconDelete,
  IconEdit,
  IconFile,
  IconPointOfSale,
} from "../../../../components/widgets/Icons";
import { PageContext } from "../../../../context/Page";
import { inventoryStatus, inventoryTypes } from "../../../../data";
import {
  getNumber,
  handleClose,
  handleShow,
  inputChangeService,
  numberFormat,
} from "../../../../services";
import { moveToTrashService } from "../../../../services/api";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

interface Target {
  id?: number;
  itemName?: string;
  image?: string;
}

export const List = ({
  item,
  handleResponse,
  index,
  pageCurrent,
  isCheckData,
  handleCheckClick,
}: {
  item: any;
  handleResponse: any;
  index: number;
  pageCurrent: number;
  isCheckData: any;
  handleCheckClick: any;
}) => {
  const history = useHistory();
  const [page, setPage] = useContext(PageContext);
  const [target, setTarget] = useState<Target>();
  const [show, setShow] = useState(false);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [formData, setFormData] = useState<any>({
    status: "In",
  });

  const fields = [
    {
      id: "status",
      label: "Status",
      value: formData.status,
      type: "select",
      data: inventoryStatus,
      wantString: true,
      disableInititalSelect: true,
    },
  ];

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const goDetail = () => {
    history.push(
      `/jewellery-detail?id=${item.id}`,
    );
    setPage({ current: 0, total: 0, sorting: page.sorting });
  };

  const handleDelete: any = () => {
    moveToTrashService(
      item?.itemName,
      null,
      `trash/jewellery/${item?.id}`,
      handleResponse
    );
  };

  const thumbsnailImage = (item: any) => {
    let tempImages = item.images;
    let isExist = false;
    let tempDiv = (
      <img
        src="images/no-image.png"
        alt="Thumbnail"
        width={80}
        height={80}
        className="object-fit rounded my-2"
      />
    );

    if (tempImages && tempImages.length > 0) {
      tempImages.forEach((img: any) => {
        if (img.thumbnail) {
          isExist = true;

          tempDiv = (
            <img
              src={`${RES_API_URL}${img.url}`}
              alt="Thumbnail"
              width={80}
              height={80}
              className="object-fit rounded cursor my-2"
              onClick={() => {
                setTarget({ itemName: item?.itemName, image: img.url });
                handleShow(setShow);
              }}
            />
          );
        }
      });

      if (!isExist) {
        tempDiv = (
          <img
            src={`${RES_API_URL}${tempImages[0].url}`}
            alt="Thumbnail"
            width={80}
            height={80}
            className="rounded cursor my-2"
            onClick={() => {
              setTarget({ itemName: item?.itemName, image: tempImages[0].url });
              handleShow(setShow);
            }}
          />
        );
      }
    }

    return tempDiv;
  };

  return (
    <>
      {show && (
        <Lightbox
          mainSrc={`${RES_API_URL}${target?.image}`}
          imageTitle={`${item?.itemName} (${item?.sku})`}
          imageCaption={`${item?.jewelleryType} / ${item?.jewelleryWeight} ${item?.jewelleryWeightUnit
            } / ${numberFormat(item?.sellingPrice)} ${item?.currency}`}
          onCloseRequest={() => handleClose(setShow)}
        />
      )}
      <td>
        <span className="flex-center">
          <Checkbox
            key={index}
            type="checkbox"
            name={item.sku}
            id={item.id}
            handleClick={(e: any) => handleCheckClick(e, item)}
            isChecked={isCheckData && isCheckData!.includes(item.id)}
          />
          <span className="ms-3">{getNumber(index, pageCurrent)}</span>
        </span>{" "}
      </td>
      <td>
        <span className="d-flex align-items-center">
          <div className="me-2 object-fit">{thumbsnailImage(item)}</div>
          <div>
            <div>{item?.itemName}</div>
            <small className="text-muted my-2 d-block">{item?.sku}</small>
            <Badge className="bg-success me-2">
              {
                inventoryTypes.filter(
                  (type: any) => type.key === item?.inventoryType
                )[0]?.label
              }
            </Badge>
            <Badge
              className={
                item?.availability === "Available" ? "bg-grad" : "bg-danger"
              }
            >
              {item?.availability === "Available" ? "In stock" : "Sold out"}
            </Badge>
          </div>
        </span>
      </td>
      <td>
        <span className="flex-end">
          {item?.gemstones ? `${item?.gemstones?.totalWeight}` : "-"}
        </span>
      </td>
      <td>
        <span className="flex-end">
          {item?.diamonds ? `${item?.diamonds?.totalWeight}` : "-"}{" "}
        </span>
      </td>
      <td>
        <span className="flex-end">
          {item?.gold
            ? `${item?.gold?.totalGoldWeight} ${item?.gold?.goldSmithLossNote
              ? `, ${item?.gold?.goldSmithLossNote}`
              : ""
            }`
            : "-"}
        </span>
      </td>
      <td>
        <span className="flex-end">
          {item?.price?.sellingPrice
            ? `${item?.price?.currency} ${numberFormat(
              item?.price?.sellingPrice
            )}`
            : "-"}
        </span>
      </td>
      {/* <td>{item?.price?.currency}</td> */}
      <td>
        <span className="flex-center">
          <OverlayTrigger overlay={<Tooltip>Edit status</Tooltip>}>
            <Button
              className="text-white btn-sm bg-grad me-2"
              onClick={() => handleShow(setShowStatusForm)}
            >
              <IconPointOfSale />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
            <Button className="text-white btn-sm bg-grad" onClick={goDetail}>
              <IconFile />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip>Edit jewellery</Tooltip>}>
            <Button
              className="text-white btn-sm bg-grad mx-2"
              onClick={() =>
                history.push(
                  `/edit-jewellery?id=${item.id}`,
                )
              }
            >
              <IconEdit />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip>Move to trash</Tooltip>}>
            <Button className=" bg-danger btn-sm" onClick={handleDelete}>
              <IconDelete />
            </Button>
          </OverlayTrigger>
        </span>
      </td >

      <CustomModal
        show={showStatusForm}
        handleClose={() => handleClose(setShowStatusForm)}
        id={null}
        handleSubmit={() => handleClose(setShowStatusForm)}
        loading={false}
        customTitle="Inventory status"
      >
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={12}
        />
      </CustomModal>
    </>
  );
};
