import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  Layout,
  Lang,
  TableLayout,
  TableRowButtons,
  CustomModalService,
  GoBack,
} from "../../components";
import { IconAdd } from "../../components/widgets/Icons";
import { PageContext } from "../../context/Page";
import {
  getNumber,
  handleClose,
  handleShow,
  inputChangeService,
} from "../../services";
import {
  dataService,
  deleteService,
  getDataService,
  paymentMethodsRoute,
  responseService,
} from "../../services/api";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useContext(PageContext);
  const [formData, setFormData] = useState({
    paymentMenthodId: "",
    bankName: "",
    paymentMethod: "",
    accountNumber: "",
    branchName: "",
    accountHolder: "",
  });

  const required = ["bankName", "paymentMethod"];

  const fields = [
    { id: "bankName", label: "Bank name *", value: formData.bankName },
    {
      id: "paymentMethod",
      label: "Payment method *",
      value: formData.paymentMethod,
    },
    {
      id: "accountNumber",
      label: "Accout number",
      value: formData.accountNumber,
    },
    { id: "branchName", label: "Branch name", value: formData.branchName },
    {
      id: "accountHolder",
      label: "Account holder",
      value: formData.accountHolder,
    },
  ];

  useEffect(() => {
    handleGetData(page.current);
    // eslint-disable-next-line
  }, []);

  const handleGetData = (skip: number) => {
    getDataService("payment/methods", setLoading, setData, setPage, skip);
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    inputChangeService(evt, formData, setFormData, wantString);
  };

  const showForm = (item?: any) => {
    setFormData({
      paymentMenthodId: item?.id || "",
      bankName: item?.bankName || "",
      paymentMethod: item?.paymentMethod || "",
      accountNumber: item?.accountNumber || "",
      branchName: item?.branchName || "",
      accountHolder: item?.accountHolder || "",
    });
    handleShow(setShow);
  };

  const handleResponse = (result: any) => {
    responseService(
      result,
      handleGetData,
      handleClose(setShow),
      null,
      null,
      setLoading
    );
  };

  const handleSubmit = (method: string) => {
    setLoading(true);
    dataService(
      method,
      formData,
      required,
      paymentMethodsRoute +
        (formData.paymentMenthodId ? `/${formData.paymentMenthodId}` : "")
    ).then((result) => {
      handleResponse(result);
    });
  };

  const handleDelete = (item: any) => {
    deleteService(
      item?.bankName,
      {},
      paymentMethodsRoute + `/${item?.id}`,
      handleResponse
    );
  };

  return (
    <Layout title="Payment methods">
      <div className="flex-between mb-4">
        <GoBack label="Configuration" />
        <div className="d-flex align-items-center">
          <Button className="text-white bg-grad ms-3" onClick={showForm}>
            <IconAdd />{" "}
            <span className="ms-2">
              <Lang label="createNew" />
            </span>
          </Button>
        </div>
      </div>

      <TableLayout
        loading={loading}
        data={data}
        page={page}
        setPage={setPage}
        handleGetData={handleGetData}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Bank name</th>
            <th>Payment type</th>
            <th>Accout number</th>
            <th>Branch name</th>
            <th>Account holder</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: any) => (
            <tr key={index}>
              <td className="text-center">{getNumber(index, page.current)}</td>
              <td>{item.bankName}</td>
              <td>{item.paymentMethod}</td>
              <td>
                <span
                  className={
                    item.accountNumber && item.accountNumber !== "-"
                      ? ""
                      : "flex-center"
                  }
                >
                  {item.accountNumber}
                </span>
              </td>
              <td>
                <span
                  className={
                    item.branchName && item.branchName !== "-"
                      ? ""
                      : "flex-center"
                  }
                >
                  {item.branchName || "-"}
                </span>
              </td>
              <td>{item.accountHolder}</td>
              <td>
                <span className="flex-center">
                  {/* <OverlayTrigger overlay={<Tooltip>View detail</Tooltip>}>
                                        <Button className="btn-sm bg-grad"><IconFile /></Button>
                                    </OverlayTrigger> */}
                  <TableRowButtons
                    item={item}
                    showForm={showForm}
                    handleDelete={handleDelete}
                    flex="flex-center"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </TableLayout>

      <CustomModalService
        show={show}
        handleClose={() => handleClose(setShow)}
        id={formData?.paymentMenthodId}
        handleSubmit={handleSubmit}
        loading={loading}
        fields={fields}
        setShow={setShow}
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
        lg
      />
    </Layout>
  );
};

export default Index;
