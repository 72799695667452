import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GoBack, Layout } from "../../../components";
import { DetailLoading } from "../../../components/widgets/Loading";
import { getDataService } from "../../../services/api";
import { Info, Stages, Prices, Images } from "./cpn";
import ChildGem from "./cpn/ChildGem";
import { Button } from "react-bootstrap";
import { IconEdit } from "../../../components/widgets/Icons";
import { getSearchParam } from "../../../services";

const Index = () => {
  const history = useHistory();
  const itemId = getSearchParam('id')
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    getDataService(`cali_gemstones/${itemId}`, setLoading, setData, null, 0);
  };

  return (
    <Layout title="Calibrated gems detail">
      {loading ? (
        <DetailLoading />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <GoBack label="Calibrated gems" />

            <Button
              className="text-white bg-grad"
              disabled={Boolean(data?.childGemstones?.length)}
              onClick={() =>
                history.push({
                  pathname: "/edit-calibrated-gems",
                  state: { item: data },
                })
              }
            >
              <IconEdit />
              <span className="ms-2">Edit</span>
            </Button>
          </div>

          <Info product={data} />

          <Images product={data} handleGetData={handleGetData} />

          <Stages product={data} handleGetData={handleGetData} />

          <Prices product={data} handleGetData={handleGetData} />

          {data?.childGemstones?.length > 0 && (
            <ChildGem product={data} handleGetData={handleGetData} />
          )}
        </>
      )}
    </Layout>
  );
};

export default Index;
