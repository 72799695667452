import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { NoData } from "../../../../components";
import { IconPrint } from "../../../../components/widgets/Icons";
import {
  GridLoading,
  TableLoading,
} from "../../../../components/widgets/Loading";
import { PgComponent } from "../../../../components/widgets/Pagination";
import { VariableContext } from "../../../../context/Variable";
import { Grid } from "./Grid";
import { List } from "./List";
import { LabelPdf } from "../../../../components/pdf";
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

const Data = ({
  loading,
  data,
  handleGetDataByFilter,
  page,
  setPage,
  handleResponse,
  handleGetData,
}: {
  loading: boolean;
  data: any;
  handleGetDataByFilter: any;
  page: any;
  setPage: any;
  handleResponse: any;
  handleGetData: any;
}) => {
  const [variable] = useContext(VariableContext);
  const [, setIsCheckAll] = useState(false);
  const [isCheckData, setCheckData] = useState<any[]>();
  const [printItems, setPrintItems] = useState<any[]>();

  //For print
  // const componentRef = useRef(null);
  const printComponentRef = useRef(null);

  const reactToPrintLabelContent = useCallback(() => {
    return printComponentRef.current;
  }, []);

  const PrintLabelAction = useReactToPrint({
    content: reactToPrintLabelContent,
  });

  useEffect(() => {
    setIsCheckAll(false);
    setCheckData([]);
    setPrintItems([]);
    // eslint-disable-next-line
  }, []);

  // const handleSelectAll = (evt: any) => {
  //     setIsCheckAll(!isCheckAll);

  //     if (isCheckAll) {
  //         setCheckData([]);
  //     } else {
  //         let isCheckTemp: any[] = [];
  //         let itemsTemp: any[] = [];

  //         data.forEach((item: any) => {
  //             isCheckTemp.push(item.id)
  //             itemsTemp.push(item)
  //         })

  //         setCheckData(isCheckTemp);
  //     }
  // };

  // const handleClick = (evt: any, id: number) => {
  //     // Check uncheck action
  //     const { checked } = evt.target;

  //     let temp: any[] = [];
  //     isCheck?.forEach(item => temp.push(item));

  //     if (checked) {
  //         temp.push(id)
  //     }
  //     else {
  //         isCheck?.forEach((item: any, index: number) => {
  //             if (item === id) {
  //                 temp.splice(index, 1);
  //             }
  //         })
  //     };
  //     console.log(temp);

  //     setIsCheck(temp)
  // };

  const handleClick = (evt: any, item: any) => {
    // Check uncheck action
    const { id, checked } = evt.target;

    let temp: any[] = [];
    if (checked) {
      isCheckData?.forEach((_item: any) => temp.push(_item));
      temp.push(parseInt(id));
    } else {
      isCheckData?.forEach((_item: any) => {
        if (_item !== parseInt(id)) {
          temp.push(_item);
        }
      });
    }

    setCheckData(temp);

    // // Add item to  print
    let tempPrintItems: any[] = [];
    printItems?.forEach((_item) => tempPrintItems.push(_item));

    if (tempPrintItems.length > 0) {
      let isExist = false;

      tempPrintItems.forEach((data, index) => {
        if (data.id === item.id) {
          tempPrintItems.splice(index, 1);
          isExist = true;
        }
      });

      if (!isExist) {
        tempPrintItems.push(item);
      }
    } else {
      tempPrintItems.push(item);
    }

    setPrintItems(tempPrintItems);
  };

  const handleChangePage = (skip: number) => {
    variable?.isJewelleryFilter
      ? handleGetDataByFilter(skip, variable?.jewelleryFilterActionData)
      : handleGetData(skip, page.sorting, "");
  };

  return (
    <div>
      {/* <Header result={page.total} selectCount={printItems?.length} handleGetData={handleGetData} handleGetDataByFilter={handleGetDataByFilter} /> */}

      {/* <div style={{ display: "none" }}><DetailPdf ref={componentRef} items={printItems} /></div>*/}
      <div style={{ display: "none" }}>
        <LabelPdf ref={printComponentRef} items={printItems} />
      </div>

      {printItems && printItems.length > 0 && (
        <div className="float-btns">
          {data.length > 0 && (
            <>
              {/* <Button
                        onClick={() => {
                            PrintAction()
                            setIsCheckAll(false);
                            setPrintItems([]);
                            //   setItems([])
                        }}
                        className="btn btn-grad me-2">
                        <IconPrint /> Print details
                    </Button> */}
              <Button
                onClick={() => {
                  PrintLabelAction();
                  setIsCheckAll(false);
                  setPrintItems([]);
                  setCheckData([]);
                }}
                className="btn btn-grad me-2"
              >
                <IconPrint /> <span className="ps-2">Print labels</span>
              </Button>
            </>
          )}
        </div>
      )}

      {variable?.isList ? (
        <>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {/* {data.map((item: any, index: number) => (
                                <Col md={12} key={index}>
                                    <ListCard item={item} handleResponse={handleResponse} />
                                </Col>
                            ))} */}
                  <Table responsive className="mb-4" striped bordered>
                    <thead>
                      <tr>
                        {/* <th className="ps-4"> */}
                        {/* <Checkbox
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        handleClick={handleSelectAll}
                                                        isChecked={isCheckAll}
                                                    /> */}
                        {/* </th> */}
                        <th>No.</th>
                        <th>Item</th>
                        <th>Gems (carat)</th>
                        <th>Diamonds (carat)</th>
                        <th>Gold (grams)</th>
                        <th>Price</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item: any, index: any) => (
                        <tr key={index}>
                          <List
                            item={item}
                            handleResponse={handleResponse}
                            index={index}
                            isCheckData={isCheckData}
                            handleCheckClick={handleClick}
                            pageCurrent={page.current}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <NoData noImage />
              )}
            </>
          )}
        </>
      ) : (
        <Row>
          {loading ? (
            <GridLoading />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item: any, index: number) => (
                    <Col md={6} key={index}>
                      <Grid item={item} handleResponse={handleResponse} />
                    </Col>
                  ))}
                </>
              ) : (
                <NoData noImage />
              )}
            </>
          )}
        </Row>
      )}

      {page && page.total > limit && (
        <PgComponent
          currentPage={page.current + 1 || 0}
          fromCount={page.current || 0}
          total={page.total || 0}
          paginationLimit={limit}
          pageInfo={page}
          setPage={setPage}
          handleGetData={handleGetData}
          isFilter={variable?.isJewelleryFilter}
          handleChangePage={handleChangePage}
        />
      )}
    </div>
  );
};

export default Data;
