import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CustomInput, GoBack, Layout } from "../../../components";
import {
  actionData,
  dataService,
  gemstonesSplitRoute,
  getMethod,
  postMethod,
  responseForRoutes,
} from "../../../services/api";
import GemSelect from "../../../components/gems/GemSelect";
import { toast } from "react-toastify";
import { IconCancel } from "../../../components/widgets/Icons";
import { getStoreUser } from "../../../services";

interface SplitGemProps {}

const SplitGem: React.FC<SplitGemProps> = () => {
  const history = useHistory();
  const [gemStonesId, setGemStronesId] = React.useState();
  const [disabled, setDisabled] = React.useState(false);

  const [formData, setFormData] = React.useState<any>({
    splitRatio: [
      {
        numberOfPcs: "",
        sku: "",
        weight: "",
      },
    ],
  });

  const handleInputChange = (key: any, value: any, idx: any) => {
    const temp: any = [...formData?.splitRatio];
    temp[idx][key] = value;
    setFormData({ ...formData, splitRatio: temp });
  };

  const handleRemove = (idx: any) => {
    const temp: any = [...formData?.splitRatio];
    temp.splice(idx, 1);
    setFormData({ ...formData, splitRatio: temp });
  };

  const handleAddMore = () => {
    const temp: any = [...formData?.splitRatio];
    temp.push({ numberOfPcs: "", sku: "", weight: "" });
    setFormData({ ...formData, splitRatio: temp });
  };

  const handleSubmit = async () => {
    setDisabled(true);
    let formError = false;

    formData?.splitRatio?.map((i: any) =>
      !i?.sku || !i?.numberOfPcs || !i?.weight
        ? (formError = true)
        : (formError = false)
    );

    if (gemStonesId && !formError) {
      let numberOfPcs: number = 0;
      const maxSplitRatio = formData?.splitRatio?.reduce(
        (a: any, c: any) => a + Number(c.numberOfPcs),
        0
      );

      await actionData(getMethod, `gemstones/${gemStonesId}`).then((result) => {
        if (result?.success) {
          const stages = result?.data?.stages;
          numberOfPcs = stages ? stages?.[stages?.length - 1]?.numberOfPcs : 0;

          if (numberOfPcs === maxSplitRatio) {
            const temp: any = [];
            formData?.splitRatio?.map((i: any) =>
              temp.push({
                ...i,
                numberOfPcs: Number(i?.numberOfPcs),
                weight: Number(i?.weight),
              })
            );

            const _formData = {
              gemstonesId: Number(gemStonesId),
              splitRatio: temp,
              userLoginId: Number(getStoreUser()?.userId),
            };

            dataService(
              postMethod,
              _formData,
              [],
              gemstonesSplitRoute,
              true
            ).then((result) => {
              if (result.success) {
                responseForRoutes(result, history, "gems");
              } else {
                toast.error(result?.message);
                setTimeout(() => {
                  setDisabled(false);
                }, 300);
              }
            });
          } else {
            toast.error("Please provide valid split pieces!");
            setTimeout(() => {
              setDisabled(false);
            }, 300);
          }
        } else {
          toast.error(result?.message);
        }
      });
    } else {
      toast.error("Please fill all required data!");
      setTimeout(() => {
        setDisabled(false);
      }, 300);
    }
  };

  // const handleSubmit = async () => {
  //   if (gemStonesId && formData?.splitRatio?.length) {
  //     let numberOfPcs: number = 0;
  //     const temp = formData?.splitRatio?.split(",");
  //     const splitRatio = temp.map((v: any) => Number(v));
  //     const initValue = 0;
  //     const maxSplitRatio = splitRatio?.reduce(
  //       (acc: any, value: any) => acc + value,
  //       initValue
  //     );

  //     await actionData(getMethod, `gemstones/${gemStonesId}`).then((result) => {
  //       if (result?.success) {
  //         const stages = result?.data?.stages;
  //         numberOfPcs = stages ? stages?.[stages?.length - 1]?.numberOfPcs : 0;

  //         if (numberOfPcs === maxSplitRatio) {
  //           setDisabled(true);

  //           const _formData = {
  //             gemstonesId: Number(gemStonesId),
  //             splitRatio: splitRatio,
  //           };

  //           dataService(
  //             postMethod,
  //             _formData,
  //             required,
  //             gemstonesSplitRoute,
  //             true
  //           ).then((result) => {
  //             responseForRoutes(result, history, "gems");
  //           });

  //           setTimeout(() => {
  //             setDisabled(false);
  //           }, 300);
  //         } else {
  //           toast.error("Please provide valid split ratio!");
  //         }
  //       } else {
  //         toast.error(result?.message);
  //       }
  //     });
  //   } else {
  //     toast.error("Please fill data!");
  //   }
  // };

  return (
    <Layout title="Split gem">
      <div className="flex-between mb-4">
        <GoBack label="Gems" />
        <div className="d-flex align-items-center">
          <Button
            className="text-white bg-grad px-4 ms-4"
            onClick={handleSubmit}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={7}>
          <GemSelect
            gemStonesId={gemStonesId}
            setGemStronesId={setGemStronesId}
            inventoryType="shop_inventory"
          />
        </Col>

        <Col className="mt-3 mt-xl-0" xs={12} xl={5}>
          <div className="bg-card">
            <h6>Split pieces</h6>
            <Row>
              {formData?.splitRatio?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  <Col md={5}>
                    <CustomInput
                      id={`sku-${index + 1}`}
                      label={"SKU *"}
                      value={item?.sku}
                      handleChange={(e: any) =>
                        handleInputChange("sku", e.target.value, index)
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <CustomInput
                      id={`piece-${index + 1}`}
                      label={"Piece *"}
                      value={item?.numberOfPcs}
                      inputType={"number"}
                      handleChange={(e: any) =>
                        handleInputChange("numberOfPcs", e.target.value, index)
                      }
                    />
                  </Col>

                  <Col md={4} className="d-flex align-items-center">
                    <CustomInput
                      id={`weight-${index + 1}`}
                      label={"Weight *"}
                      value={item?.weight}
                      inputType={"number"}
                      handleChange={(e: any) =>
                        handleInputChange("weight", e.target.value, index)
                      }
                    />

                    <Button
                      className="ms-1"
                      style={{
                        marginTop: "25px",
                        padding: "0.55rem 0.75rem",
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      <IconCancel />
                    </Button>
                  </Col>
                </React.Fragment>
              ))}

              <div className="mt-1">
                <span
                  className="small cursor text-primary btn-link"
                  onClick={handleAddMore}
                >
                  Add more
                </span>
              </div>
            </Row>
          </div>

          {/* <FormFieldService
            fields={fields}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            colCount={12}
            bg
          /> */}
        </Col>
      </Row>
    </Layout>
  );
};

export default SplitGem;
