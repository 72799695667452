import React, { useContext } from "react";
import { Col, Table } from "react-bootstrap";
import { QRView } from "../../../../components";
import { LangContext } from "../../../../context";
import {
  getDiscountAmountByPercentage,
  handleDateFormat,
  netPrice,
  numberFormat,
  totalAmount,
} from "../../../../services";
import InvoiceHeader from "../../../../components/pdf/InvoiceHeader";

const Invoice = ({
  data,
  showDataCheck,
}: {
  data: any;
  showDataCheck: any;
}) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <div className="bg-card">
      <style type="text/css" media="print">
        {`
          .bg-card{
              box-shadow: none !important;
            }
        `}
      </style>
      {/* <section className="text-center mb-4">
        <img src="images/mr.spinel.png" alt="no_found" width={300} />
        <span className="d-block my-2">
          <small>78 street, Bet; 34 x 35 street, Mandalay | </small>{" "}
          <small>Ph : 09-43200898 , 09-799075549 , 09-425291390</small>
        </span>
      </section> */}
      <InvoiceHeader />

      <div className="flex-between align-items-center">
        <div style={{ width: "50%" }}>
          <span className="d-block mb-2">
            <small className="text-muted">Customer</small> :{" "}
            <small>{data?.cali_gems_buyer?.fullName || "-"}</small>
          </span>
          <span className="d-block mb-2">
            <small className="text-muted">Phone</small> :{" "}
            <small>{data?.cali_gems_buyer?.phone || "-"}</small>
          </span>
          {data?.cali_gems_buyer?.address && (
            <span className="d-block mb-2">
              <small className="text-muted">{translate("address")}</small> :{" "}
              <small>{data?.cali_gems_buyer?.address || "-"} </small>
            </span>
          )}
        </div>
        <div className="d-flex align-items-center">
          <QRView text={data?.invoiceNumber} width={50} />
          <div className="ms-3 d-flex flex-column align-items-end">
            <span className="d-block mb-2 mt-1">
              <small>{data?.invoiceNumber}</small>
            </span>
            <span className="d-block mb-2">
              <small className="text-muted">{translate("date")}</small> :{" "}
              <small>{handleDateFormat(data?.createdAt)}</small>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <h6 className="fw-bold border text-center py-2 px-4">
          SALE CALIBRATED GEM INVOICE
        </h6>
      </div>

      <Table responsive striped bordered className="bg-light border mt-3 mb-0">
        <thead>
          <tr>
            <th className="text-center">No.</th>
            <th>Item</th>
            <th className="text-end">Price</th>
            <th className="text-end">Dicount</th>
            <th className="text-end">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data?.sale_cali_gemstone_items?.map((item: any, index: number) => (
            <tr key={index}>
              <td className="text-center">
                <span className="flex-center">{index + 1}</span>
              </td>
              <td>
                <span className="d-block">
                  {item?.cali_gemstones?.itemName} {item?.isGift && "(Gift)"}
                </span>
                <span className="d-block text-muted">
                  {item?.cali_gemstones?.sku}
                </span>
                <span className="d-block text-muted">
                  {item?.cali_gemstones?.stoneType}
                </span>
              </td>
              <td className="text-end">{numberFormat(item?.price)}</td>
              <td className="text-end">{numberFormat(item?.discountValue)}</td>
              <td className="text-end">
                {numberFormat(totalAmount(item?.price, item))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mb-4 mt-4">
        {/* <div className="d-flex align-items-center">
          <div className="receit-item-image">
            {data?.cali_gemstones?.images ? (
              <Image
                src={`${RES_API_URL}${data?.cali_gemstones?.images?.url}`}
                alt="no_found"
              />
            ) : (
              <Image src={"images/no-image.png"} />
            )}
          </div>
          <div className="ms-3">
            <small className="xs-label d-block mb-2 fw-bold">
              {data?.cali_gemstones?.itemName}
            </small>
            <small className="xs-label d-block mb-2 text-muted">
              {data?.cali_gemstones?.sku}
            </small>
          </div>
        </div> */}
        <div />

        {data?.isGift ? (
          <></>
        ) : (
          <Col md={4} className="me-2 w-25">
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">Subtotal</small>
              <small>
                {numberFormat(netPrice(data?.sale_cali_gemstone_items))}
              </small>
            </div>
            <div className="flex-between mb-2 pb-2">
              <small className="text-muted d-block">
                Discount{" "}
                {data?.discountType === "by_percentage" &&
                  `(${data?.discountValue}%)`}
              </small>
              <small>
                {data?.discountValue && data?.discountValue !== "0" && "-"}{" "}
                {data?.discountValue && data?.discountValue !== "0"
                  ? numberFormat(
                      data?.discountType === "by_percentage"
                        ? getDiscountAmountByPercentage(data?.netPrice, data)
                        : data?.discountValue
                    )
                  : "-"}
              </small>
            </div>
            <div className="flex-between pt-2 border-top">
              <small className="text-muted d-block">
                Total ({data?.sale_cali_gemstone_items?.[0]?.currency})
              </small>
              <small>
                {numberFormat(
                  totalAmount(
                    netPrice(data?.sale_cali_gemstone_items)?.toString(),
                    data
                  )
                )}
              </small>
            </div>
          </Col>
        )}
      </div>

      {data?.saleNotes && showDataCheck.showSaleNotes && (
        <span className="d-block">
          <small className="text-muted">Notes </small> :{" "}
          <small>{data?.saleNotes}</small>
        </span>
      )}

      <small className="text-danger text-center d-block mb-3 mt-5">
        {translate("receipt-tip")}
      </small>
      <small className="text-muted text-center d-block">
        {translate("thankForYourPurchase")}
      </small>
    </div>
  );
};

export default Invoice;
