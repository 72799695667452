import React from "react";
import {
  dateFormat2,
  getStoreUser,
  handleDateFormat,
} from "../../../../services";
import {
  actionData,
  currencyRoute,
  dataService,
  gemsRoute,
  getMethod,
  postMethod,
  responseForRoutes,
} from "../../../../services/api";
import { CustomInput, FormFieldService } from "../../../../components";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { IconArrowLeftRight } from "../../../../components/widgets/Icons";

interface PriceProps {
  formData: any;
  setFormData: (data: any) => void;
}

const Price: React.FC<PriceProps> = ({ formData, setFormData }) => {
  const history = useHistory();
  const [disabled, setDisabled] = React.useState(false);
  const [currencies, setCurrencies] = React.useState([]);
  const numberOfInventories: any = [];

  const fields = [
    {
      id: "interestFrom",
      label: "Interest from",
      value: formData?.price?.interestFrom,
      hide: true,
    },
    {
      id: "interestRate",
      label: "Interest rate",
      value: formData?.price?.interestRate,
      hide: true,
    },
    {
      id: "investedUSDRate",
      label: "Invested USD rate",
      value: formData?.price?.investedUSDRate,
      inputType: "number",
      wantString: true,
    },
    {
      id: "currency",
      label: "Currency",
      value: formData?.price?.currency,
      type: "select",
      data: currencies,
      wantString: true,
    },
    {
      id: "sharedInvestorsQty",
      label: "Number of investors",
      value: formData?.price?.sharedInvestorsQty,
      type: "select",
      data: numberOfInventories,
    },
    {
      id: "otherCost",
      label: "Other cost",
      value: formData?.price?.otherCost,
      inputType: "number",
    },
    {
      id: "buyingPrice",
      label: "Buying price",
      value: formData?.price?.buyingPrice,
      inputType: "number",
      wantString: true,
    },
    // {
    //   id: "sellingPricePerCts",
    //   label: "Selling price Per Cts",
    //   value: formData?.price?.sellingPricePerCts,
    //   inputType: "number",
    //   wantString: true,
    // },
    // {
    //   id: "sellingPrice",
    //   label: "Selling price",
    //   value: formData?.gemInfo?.stockType === 'Pair' && formData?.price?.sellingPricePerCts !== "" ? Math.floor(
    //     ((formData?.stage?.pcsOneWeight
    //       ? parseFloat(formData?.stage?.pcsOneWeight)
    //       : 0) +
    //       (formData?.stage?.pcsTwoWeight
    //         ? parseFloat(formData?.stage?.pcsTwoWeight)
    //         : 0)) *
    //     (formData.price?.sellingPricePerCts)
    //   ) : formData.price?.sellingPricePerCts !== "" ? Math.floor(
    //     (formData?.stage?.weight) * (formData.price?.sellingPricePerCts)
    //   ) : formData?.price?.sellingPrice,
    //   inputType: "number",
    //   wantString: true,
    // },
    {
      id: "paymentDueDate",
      label: "Payment due date",
      value: handleDateFormat(formData?.price?.paymentDueDate, dateFormat2),
      type: "date",
    },
  ];

  [...Array(10)].forEach((_: any, i: any) => {
    numberOfInventories.push({ label: i + 1, value: i + 1 });
  });

  React.useEffect(() => {
    handleGetGemstones();
  }, []);

  const handleGetGemstones = async () => {
    actionData(getMethod, currencyRoute).then((result) => {
      const temp: any = [];
      result.data?.map((d: any) =>
        temp.push({
          label: d?.value,
          value: d?.value,
        })
      );
      setCurrencies(temp);
    });
  };

  const handleChange = (evt: any, wantString?: boolean) => {
    const target = evt.target;
    const name = target.id;
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number" || target.type === "select-one") {
      if (target.value === "") {
        value = "";
      } else {
        value = target.value;
        if (wantString) {
          value = target.value;
        } else {
          value = parseFloat(target.value);
        }
      }
    } else {
      value = target.type === "text" ? target.value : target.value;
    }

    setFormData({
      ...formData,
      price: {
        ...formData.price,
        [name]: value,
      },
    });
  };

  const handleSave = () => {
    formData.gemInfo = {
      ...formData.gemInfo,
      userLoginId: Number(getStoreUser()?.userId),
      published: formData?.published,
      forSale:
        formData?.gemInfo?.inventoryType === "private_item"
          ? formData?.forSale
          : false,
      gemsOwnerId: formData?.gemsOwnerId,
      gemsBrokerId: formData?.gemsBrokerId,
      purchaseDate: formData?.gemInfo?.purchaseDate
        ? new Date(formData?.gemInfo?.purchaseDate).toISOString()
        : null,
    };

    formData.stage = {
      ...formData.stage,

      numberOfPcs:
        formData?.stage?.numberOfPcs && formData?.gemInfo?.stockType !== "Pair"
          ? Number(formData?.stage?.numberOfPcs)
          : 2,
      pcsOneWeight: formData?.stage?.pcsOneWeight
        ? Number(formData?.stage?.pcsOneWeight)
        : null,
      pcsTwoWeight: formData?.stage?.pcsTwoWeight
        ? Number(formData?.stage?.pcsTwoWeight)
        : null,
      treatment: formData?.stage?.treatment?.length
        ? formData?.stage?.treatment?.join(", ")
        : null,
    };

    formData.price = {
      ...formData.price,
      otherCost: formData?.price?.otherCost
        ? formData?.price?.otherCost?.toString()
        : null,
      paymentDueDate: formData?.price?.paymentDueDate
        ? new Date(formData?.price?.paymentDueDate).toISOString()
        : null,
    };
    handleCreateGem();
  };

  const handleCreateGem = () => {
    setDisabled(true);
    dataService(postMethod, formData.gemInfo, [], gemsRoute(), true).then(
      (result) => {
        if (result?.success) {
          handleCreateStage(result?.data?.id);
        } else {
          toast.error(result?.message);
          setDisabled(false);
        }
      }
    );
  };

  const handleCreateStage = (stageId: any) => {
    let _stageData = { ...formData?.stage };

    if (
      formData?.gemInfo?.stockType === "Loose" ||
      formData?.gemInfo?.stockType === "Single"
    ) {
      _stageData = {
        ..._stageData,
        numberOfPcs: 1,
        size: "",
        pcsOneWeight: "",
        pcsTwoWeight: "",
      };
    } else if (formData?.gemInfo?.stockType === "Lots") {
      _stageData = {
        ..._stageData,
        pcsOneWeight: "",
        pcsTwoWeight: "",
      };
    } else {
      _stageData = {
        ..._stageData,
        numberOfPcs: 2,
        size: "",

      };
    }

    dataService(
      postMethod,
      _stageData,
      [],
      `${gemsRoute(stageId)}/stage`,
      true
    ).then((result) => {
      if (result?.success) {
        handleCreatePrice(result?.data?.gemstonesId);
      } else {
        toast.error(result?.message);
        setDisabled(false);
      }
    });
  };

  const handleCreatePrice = (gemId: any) => {
    dataService(
      postMethod,
      formData?.price,
      [],
      `${gemsRoute(gemId)}/price`,
      true
    ).then((result) => {
      if (result?.success) {
        responseForRoutes(result, history, "gems");
      } else {
        toast.error(result?.message);
        setDisabled(false);
      }
    });
  };

  return (
    <div className={`${formData?.step === 3 ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>Price information</div>
      </div>

      <div className="bg-card mt-3">
        <FormFieldService
          fields={fields}
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          colCount={6}
        />
        <Row className="align-items-center">
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"sellingPrircePerCts"}
                label={"Selling price per cts"}
                value={formData.price?.sellingPricePerCts}
                handleChange={(e: any) => {
                  if (formData?.gemInfo?.stockType === "Pair") {
                    setFormData({
                      ...formData,
                      price: {
                        sellingPricePerCts: e.target.value,
                        sellingPrice: Math.floor(
                          ((formData?.stage?.pcsOneWeight
                            ? parseFloat(formData?.stage?.pcsOneWeight)
                            : 0) +
                            (formData?.stage?.pcsTwoWeight
                              ? parseFloat(formData?.stage?.pcsTwoWeight)
                              : 0)) *
                          (e.target.value || 0)
                        ),
                      }
                    });
                  } else {
                    setFormData({
                      ...formData,
                      price: {
                        sellingPricePerCts: e.target.value,
                        sellingPrice: Math.floor(
                          (formData?.stage?.weight || 0) * (e.target.value || 0)
                        ),
                      }
                    });
                  }
                }}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"sellingPrice"}
                label={"Selling Price"}
                value={formData.price?.sellingPrice}
                handleChange={(e: any) => {
                  if (formData?.gemInfo?.stockType === "Pair") {
                    setFormData({
                      ...formData,
                      price: {
                        sellingPrice: e.target.value,
                        sellingPricePerCts: parseInt(
                          e.target.value === 0
                            ? formData.price?.sellingPricePerCts
                            : (e.target.value || 0) /
                            ((formData?.stage?.pcsOneWeight
                              ? parseFloat(formData?.stage?.pcsOneWeight)
                              : 0) +
                              (formData?.stage?.pcsTwoWeight
                                ? parseFloat(formData?.stage?.pcsTwoWeight)
                                : 0))
                        ),
                      }
                    });
                  } else {
                    setFormData({
                      ...formData,
                      price: {
                        sellingPrice: e.target.value,
                        sellingPricePerCts: parseInt(
                          e.target.value === 0
                            ? formData.price?.sellingPricePerCts
                            : (e.target.value || 0) / (formData?.stage?.weight || 0)
                        ),
                      }
                    });
                  }
                }}
                inputType={"number"}
              />
            )}
          </Col>
          <Col>
            <span className="flex-center">
              <Button
                className="bg-grad rounded-circle flex-center shadow-sm align-items-center"
                style={{ width: "40px", height: "40px" }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    basedFormula:
                      formData.basedFormula === "rate" ? "total" : "rate",
                  })
                }
              >
                <IconArrowLeftRight size={16} />
              </Button>
            </span>
          </Col>
          <Col md={5}>
            {formData.basedFormula === "rate" ? (
              <CustomInput
                id={"sellingPrice"}
                disable
                label={"Selling Price"}
                value={formData.price?.sellingPrice}
                handleChange={null}
                inputType={"number"}
              />
            ) : (
              <CustomInput
                id={"sellingPricePerCts"}
                label={"Selling price per cts"}
                value={formData.price?.sellingPricePerCts}
                disable
                handleChange={null}
                inputType={"number"}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mt-3 d-flex justify-content-between">
        <Button
          className="text-white bg-grad px-4"
          onClick={() => setFormData({ ...formData, step: 2 })}
        >
          Previous
        </Button>

        <Button
          disabled={disabled}
          className="text-white bg-grad px-4"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Price;
