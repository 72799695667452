import React from "react";
import { GoBack, Layout } from "../../../components";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Col, Image } from "react-bootstrap";
import Info from "../detail/cpn/Info";
import { RES_API_URL } from "../../../services/api";

interface StagesProps {}

const Stages: React.FC<StagesProps> = () => {
  const location: any = useLocation();
  const data = location.state.item;

  return (
    <Layout title="Gems Stages">
      <div className="flex-between mb-4">
        <GoBack label="Gems" />
      </div>

      <Info product={data} />

      {data?.stages?.length > 0 && (
        <div className="">
          {/* <h6 className="mb-3 text-center">{data?.itemName}</h6> */}

          <ul className="timeline">
            {data?.stages?.map((item: any, index: number) => (
              <li
                key={index}
                className="event"
                data-date={moment(item?.createdAt).format("MMM DD, YYYY")}
              >
                <div className=" d-flex justify-content-between">
                  <div className="w-50">
                    <p>Pcs: {item?.numberOfPcs || "-"}</p>
                    <p>Dimension: {item?.dimension || "-"}</p>
                    <p>
                      Weight: {`${item?.weight} ${item?.weightUnit || "-"} `}
                    </p>
                    <p>Color: {item?.color || "-"}</p>
                    <p>Clarity: {item?.clarity || "-"}</p>
                    <p>Shape and cut: {item?.shapeAndCut || "-"}</p>
                    <p>Treatment: {item?.treatment || "-"}</p>
                    <p className="mb-0">Status: {item?.stageStatus || "-"}</p>
                  </div>

                  <div className="w-50 row mb-3">
                    {data?.images?.length > 0 ? (
                      data?.images?.map((image: any, index: number) => (
                        <Col key={index} md={6} className="mb-3">
                          <Image
                            src={RES_API_URL + image?.url}
                            className="p-2 img-fluid img-thumbnail"
                          />
                        </Col>
                      ))
                    ) : (
                      <div className="text-end">
                        <Image
                          src={"images/logo.png"}
                          className="p-5 img-thumbnail bg-black"
                          width={200}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Layout>
  );
};

export default Stages;
