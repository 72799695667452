import { useCallback, useContext, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { InfoTable, ThumbsnailImage } from "../../../../components";
import { IconCart, IconPrint } from "../../../../components/widgets/Icons";
import { POSDetailLoading } from "../../../../components/widgets/Loading";
import { CartContext } from "../../../../context/Cart";
import { handleClose, numberFormat } from "../../../../services";
import { inventoryTypes } from "../../../../data";
import POSDetailPdf from "./POSDetailPdf";

const ItemModel = ({
  show,
  setShow,
  item,
  loading,
  gem,
}: {
  show: boolean;
  setShow: any;
  item: any;
  loading: boolean;
  gem: any;
}) => {
  const [cart, setCart] = useContext(CartContext);

  const inventoryTypeLabel = inventoryTypes.filter(
    (inventory: any) => inventory.key === item?.inventoryType
  )[0]?.label;

  //For pdf
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const PrintAction = useReactToPrint({
    content: reactToPrintContent,
  });

  const data = [
    { label: "SKU", value: item?.sku },
    { label: "Stock type", value: item?.stockType || "-" },
    { label: "Stone style", value: item?.stoneType || "-" },
    {
      label: "Dimension",
      value: item?.stages?.[item?.stages?.length - 1]?.dimension
        ? item?.stages?.[item?.stages?.length - 1]?.dimension
        : "-",
    },
    // {
    //   label: "Number Of Pcs",
    //   value: item?.stages?.[item?.stages?.length - 1]?.numberOfPcs
    //     ? item?.stages?.[item?.stages?.length - 1]?.numberOfPcs
    //     : "-",
    // },
    {
      label: "Weight",
      value: item?.stages?.[item?.stages?.length - 1]?.weight
        ? `${item?.stages?.[item?.stages?.length - 1]?.weight} ${item?.stages?.[item?.stages?.length - 1]?.weightUnit
        }`
        : "",
    },
  ];

  const otherData = [
    {
      label: "Color",
      value: item?.stages?.[item?.stages?.length - 1]?.color
        ? item?.stages?.[item?.stages?.length - 1]?.color
        : "-",
    },
    {
      label: "Clarity",
      value: item?.stages?.[item?.stages?.length - 1]?.clarity
        ? item?.stages?.[item?.stages?.length - 1]?.clarity
        : "-",
    },
    // {
    //   label: "Buying state",
    //   value: item?.buyingState || "-",
    // },
    {
      label: "Mining location",
      value: item?.miningLocation || "-",
    },
    { label: "Inventory type", value: inventoryTypeLabel || "-" },
    { label: "Description", value: item?.description || "-" },
  ];

  const handleAddToCart = () => {
    const cartItems: any = [...(cart?.items || [])];
    const exist = cartItems?.find((i: any) => i?.id === item?.id);
    const uniqueCurrency = cartItems?.[0]?.currency || item?.price?.currency;

    if (exist) {
      toast.error("Already added item!");
    } else {
      if (item?.price?.currency !== uniqueCurrency) {
        toast.error("Please select same currency item!");
      } else {
        cartItems?.push({
          ...cart,
          ...item,
          currency: item?.price?.currency,
          sellingPrice: item?.price?.sellingPrice,
          discountType: "by_amount",
          discountValue: "",
        });

        setCart({ ...cart, items: cartItems });
        handleClose(setShow);
      }
    }

    // if (cart?.item?.itemName) {
    //   toast.error("Already added item!");
    // } else {
    //   setCart({
    //     ...cart,
    //     item: item,
    //     netPrice: item?.price?.sellingPrice,
    //     currency: item?.price?.currency,
    //   });
    // }
  };

  return (
    <Modal show={show} onHide={() => handleClose(setShow)} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{!loading && item?.itemName}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <POSDetailLoading />
        ) : (
          <>
            <div className="p-2">
              <Row className="mb-4">
                <Col md={2}>
                  <div className="pos-detail-image">
                    <ThumbsnailImage item={item} />
                  </div>
                  {item?.price?.sellingPrice && (
                    <div className="p-3 rounded-3 bg-light text-center mt-4">
                      <h6 className="fw-bold mb-0 text-danger">
                        {item?.price?.sellingPrice &&
                          `${item?.price?.currency} ${numberFormat(
                            item?.price?.sellingPrice
                          )}`}
                      </h6>
                    </div>
                  )}
                </Col>
                <InfoTable data={data} />
                <InfoTable data={otherData} notes={item?.notes} />
              </Row>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-success" onClick={() => handleClose(setShow)}>
          Cancel
        </Button>
        <div style={{ display: "none" }}>
          <POSDetailPdf ref={componentRef} data={item} gem={gem} />
        </div>
        <Button className="btn btn-grad ms-3" onClick={PrintAction}>
          <IconPrint /> <span className="ms-2">Print details</span>
        </Button>
        <Button className="btn btn-grad ms-3" onClick={handleAddToCart}>
          <IconCart /> <span className="ms-2">Add to cart</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemModel;
